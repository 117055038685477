import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./docs/Header.css";

export default class Header extends Component {
  render() {
    return (
      <div className="shadow-lg height-8">
        <div className="container-header">
          <div className="container-logo">
            <a className="page-logo-link">
              <img
                className="logo-position-header"
                src="img/siempre-200.png"
                alt="Icon"
              />
              {/* <span className="page-logo-text">Siempre.Net</span> */}
            </a>
          </div>
          <span className="margin-left-38 margin-right-05 hidden-sm-down h5">
            Ya eres usuario?
          </span>
          <Link to="/" path="/" className="nav-link padding-0 h4 text-primary">
            Inicio de sesión seguro
          </Link>
        </div>
      </div>
    );
  }
}
