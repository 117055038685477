import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import { Card, CardHeader, CardBody, Button, Table } from "reactstrap";

import BillingPackageApp from "./BillingPackageApp";
import { COLOUR_WARNING } from "../../../Message/Message";
import { getapplicationpackage } from "../../../../services/Api/ApplicationPackage";
import { LimitReadMore } from '../../../../Global';
import "../../docs/Subscription.css";

export default class BillingPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 236,
      dataapp: [],
      assigned: false,
      billing: {
        openModal: false,
        load: false
      }
    };
  }

  componentDidUpdate() {
    if (this.props.style) {
      this.props.handleChangeStyle(this.props.data);
      this.props.hadleApplyStyle(true);

      let element = document.getElementById("billingpackage");
      element.classList.add("show-billingapp");
      element.classList.remove("hide-billingapp");
    }

    if (this.props.data !== null && this.props.loadapppackage === true) {
      this.props.handleChangeAppPackage();
      this.handleApp();
    }
  }

  ///Validar que la aplicación que este en el paquete no se ecuentre asignada a su empresa.
  handleValidateAssignation = () => {
    ///Aplicaciones ya asignadas.
    this.props.apps.forEach(c => {
      ///Aplicaciones del paquete.
      this.state.dataapp.filter(x => {
        if (x.codeapp === c.idapp) {
          x.state = true;
        }
        return true;
      });
    });

    ///Validar que alguna de las aplicaciones no se encuentre asociada al usuario.
    let isAssignedAll = false;
    this.state.dataapp.forEach(element => {
        if(element.state === false){
          isAssignedAll = true;
        }
    });

    this.setState({ dataapp: this.state.dataapp, assigned: isAssignedAll });
  };

  handleChangeStyle = () => {
    this.props.hadleApplyStyle(false);

    let element = document.getElementById("billingpackage");
    element.classList.remove("show-billingapp");
    element.classList.add("hide-billingapp");
  };

  handleOpenModal = param => {
    if (this.state.dataapp.length === 0) {
      notify(
        "Este paquete no cuenta con aplicaciones ho ya se encuentran asignada a su empresa seleccionada.",
        COLOUR_WARNING,
        4500
      );
    } else {
      this.setState({
        billing: {
          ...this.state.billing,
          openModal: !this.state.billing.openModal,
          load: param
        }
      });
    }
  };

  handleValidateLoad = () =>
    this.setState({
      billing: { ...this.state.billing, load: !this.state.billing.load }
    });

  handleToggle = () => {
    if (this.props.data.description.lenght === this.state.limit) {
      this.setState({ limit: LimitReadMore });
    } else {
      this.setState({ limit: this.props.data.description.lenght });
    }
  };

  handleApp = () => {
    const code = this.props.data.code;
    const subs = this.props.subscription;
    const response = getapplicationpackage(code, subs);
    const promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.objeto.forEach(element => {
            const obj = {
              codeapp: element.appCode,
              name: element.nameApp,
              description: element.descriptionApp,
              url: element.urlApp,
              img: element.img,
              state: element.state
            };
            array.push(obj);
          });
          this.setState({ dataapp: array });
          this.handleValidateAssignation();
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  render() {
    const {
      data,
      client,
      subscription,
      handleReloadGrid,
      handleLoadSubscription,
      handleOpenModalAssign
    } = this.props;
    const { openModal, load } = this.state.billing;
    const { limit, dataapp, assigned } = this.state;
    return (
      <div>
        <Card className="hide-billingapp" id="billingpackage">
          <CardHeader className="row">
            <div className="col-md-1">
              <Button
                type="button"
                className="bg-primary"
                onClick={this.handleChangeStyle}
                style={{ height: "80px", width: "43px" }}
                title="Volver"
              >
                <i
                  className="fas fa-arrow-alt-circle-left"
                  style={{ fontSize: "20px" }}
                />
              </Button>
            </div>
            <div className="col-md-2" style={{ padding: "initial" }}>
              <img
                className="img-thumbnail thumb80"
                src="Img/package/p6.png"
                alt="Icon"
              />
            </div>
            <div className="col-md-9" style={{ padding: "initial" }}>
              <div className="row d-flex">
                <strong>Paquete: </strong>
                <label>{data !== null ? data.name : "No registra"}</label>
                {assigned === true ? (
                  <Button
                    className="bg-primary ml-auto"
                    onClick={e => this.handleOpenModal(true)}
                  >
                    Asignar
                  </Button>
                ) : (
                  <Button className="bg-primary ml-auto" disabled={true}>
                    Asignada
                  </Button>
                )}
              </div>
              <div className="row">
                <p>
                  <strong>Descripción: </strong>
                  {data != null
                    ? data.description.substring(0, limit)
                    : ""}{" "}
                  {data === null ? null : data.description.length < limit ? null : (
                    <span className="more" onClick={this.handleToggle}>
                      {data != null
                        ? data.description.length > limit
                          ? "...Leer mas"
                          : "...Leer menos"
                        : ""}
                    </span>
                  )}{" "}
                </p>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <h3>Aplicaciones incluidas</h3>
            </div>
            <div>
              {dataapp.length === 0 ? (
                <strong>El paquete no tiene aplicaciones asignadas.</strong>
              ) : (
                dataapp.map((x, i) => (
                  <Card key={i + 1}>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="b0 wd-xs d-none d-md-table-cell">
                            <img
                              className="img-fluid thumb64"
                              src={x.img}
                              alt="Icon"
                            />
                          </td>
                          <td className="b0">
                            <p className="h4 mt-0">
                              <strong>Nombre:&nbsp;</strong>
                              {x.name}&nbsp;&nbsp;&nbsp;
                              {x.state === true ? (
                                <i
                                  className="fas fa-check-circle colour-blue"
                                  title="Aplicación asignada."
                                />
                              ) : null}
                            </p>
                            <p className="h5 mt-0">
                              <strong>Dirección web:&nbsp;</strong>
                              <a
                                className="align-middle text-bold link-unstyled"
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-external-link-alt" />
                              </a>
                            </p>
                            <div className="text-sm text-muted">
                              <strong>Descripción:&nbsp;</strong>
                              {x.description}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                ))
              )}
            </div>
          </CardBody>
        </Card>
        <BillingPackageApp
          openModal={openModal}
          client={client}
          app={dataapp}
          load={load}
          subscription={subscription}
          handleOpenModal={this.handleOpenModal}
          handleValidateLoad={this.handleValidateLoad}
          handleReloadGrid={handleReloadGrid}
          handleLoadSubscription={handleLoadSubscription}
          handleOpenModalAssign={handleOpenModalAssign}
        />
      </div>
    );
  }
}
