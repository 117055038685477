import React, { Fragment } from "react";
import { Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';

export default function CardResource(props) {
  return (
    <Fragment>
      <Card className="card-default">
        <CardHeader>
          <CardTitle>Nombre publico del recurso / <code>{props.displayName}</code></CardTitle>
        </CardHeader>
        <div className="list-group bb bt">
          <div className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-purple"></em>
                  <em className="fas fa-flag fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body text-truncate">
                <p className="mb-1"><small className="text-purple m-0 h5 text-uppercase">Identificador</small>
                </p>
                <p className="m-0">
                  <small className="h5">{props.name}</small>
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-success"></em>
                  <em className="far fa-clock fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body text-truncate">
                <p className="mb-1"><small className="text-success m-0 h5 text-uppercase">Fecha de creación</small>
                </p>
                <p className="m-0"><small className="h5">{props.created}</small>
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-pink"></em>
                  <em className="far fa-clock fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body text-truncate">
                <p className="mb-1"><small className="text-pink m-0 h5 text-uppercase">Fecha actualización</small>
                </p>
                <p className="m-0"><small className="h5">{props.updated}</small>
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-info"></em>
                  <em className="far fa-clock fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body">
                <p className="mb-1"><small className="text-info m-0 h5 text-uppercase">Descripción</small>
                </p>
                <p className="m-0"><small className="h5">{props.description} segundos</small>
                </p>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <div className="align-self-start mr-2">
                <span className="fa-stack">
                  <em className="fa fa-circle fa-stack-2x text-warning"></em>
                  <em className="fa fa-tasks fa-stack-1x fa-inverse text-white"></em>
                </span>
              </div>
              <div className="media-body text-truncate">
                <p className="mb-1"><small className="text-warning m-0 h5 text-uppercase">Alcances</small></p>
                <ul className="list-inline m-0 d-flex flex-wrap">
                  {props.apiScopes.map((scope, index) => (
                    <li className="list-inline-item" key={index + 1}>
                      <div className="badge bg-gray">{scope.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <CardFooter className="d-flex">
          <div>
            <button type="button" className="btn btn-xs btn-danger">Eliminar</button>
          </div>
          <div className="ml-auto">
            <button type="button" className="btn btn-xs btn-success">Ver</button>
          </div>
        </CardFooter>
      </Card>
    </Fragment>
  )
}