import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Card, CardBody, InputGroup, InputGroupAddon } from "reactstrap";

import {
  getclientbysubscription,
  getclientcode
} from "../../services/Api/Client";
import { _JsonStorage, ExecuteFn } from '../../Global';

import "./docs/Enterprise.css";

export default class EnterpriseFilterCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataClient: [],
      disabled: false,
      formClient: {
        clientypes: null,
        client: null
      }
    };
  }

  componentDidUpdate() {
    if (this.props.subscription > 0) this.handleEdit();
  }

  componentDidMount() {
    this.mounted = true;
    ExecuteFn(this.handleClients);
  }

  handleClients = () => {
    const storage = _JsonStorage.profile.id;
    const data = getclientcode(storage);
    const promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.response.forEach(element => {
            let obj = {
              value: element.enterpriseId,
              label: element.socialReason,
              nameForm: "formClient",
              nameSelect: "client"
            };
            array.push(obj);
          });
          this.setState({ dataClient: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleChangeSelect = event => {
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });

    this.props.handleChangeDataClient(event);
    this.props.handleChangeFilterClient(event.value);
    this.props.hadleChangeSidebarReset();
    this.props.handleNotFilterSubscription();
  };

  handleEdit = () => {
    if (this.props.isEdit) {
      let subscription = this.props.subscription;
      this.props.handleEditSubscription(subscription);

      let data = getclientbysubscription(subscription);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            this.setState({
              formClient: {
                clientypes: {
                  label: result.response.nameClientType,
                  value: result.response.idClientType
                },
                client: {
                  label: result.response.nameClient,
                  value: result.response.idEnterprise
                }
              },
              disabled: true
            });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      if (this.state.disabled === true && this.props.subscription === 0) {
        this.setState({
          formClient: {
            clientypes: null,
            client: null
          },
          disabled: false
        });
      }
    }
  };

  render() {
    const { dataClient } = this.state;
    const { client } = this.state.formClient;
    return (
      <div>
        <Card className="card-default">
          <form name="formClient">
            <CardBody>
              <div className="form-row align-items-center">
                <div className="col-md-12">
                  <strong>Filtro de empresa</strong>
                  <InputGroup className="md-12">
                    <Select
                      name="client"
                      className="width-filter-md"
                      placeholder="Lista de empresa"
                      options={dataClient}
                      onChange={this.handleChangeSelect}
                      value={client}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Link
                        to="/enterprise"
                        path="/enterprise"
                        className="nav-link btn btn-primary"
                        title="Crear empresa"
                      >
                        <i className="fas fa-plus-square" />
                      </Link>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </CardBody>
          </form>
        </Card>
      </div>
    );
  }

  componentWillMount() {
    this.mounted = false;
  }
}
