import React, { Component } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";

//#region
import FormApp from "../ApplicationForm";
import FormClient from "../ApplicationClient";
import "../docs/Application.css";
//#endregion

class FormWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appCode: 0,
      configCode: 0,
      formAppValid: false
    };
  }

  componentDidMount() {
    // VERTICAL
    // -----------------------------------
    // $(this.refs.examplevertical).steps({
    //   headerTag: "h4",
    //   bodyTag: "section",
    //   transitionEffect: "slideLeft",
    //   stepsOrientation: "vertical"
    // });
  }

  ///Agregar codigo de la aplicación
  handleAppCode = param => this.setState({ appCode: param });

  ///Agregar codigo de la configuración
  handleCofigCode = param => this.setState({ configCode: param });

  ///Formulario de aplicación es validado
  handleFormAppValid = () =>this.setState({ formAppValid:!this.state.formAppValid });

  render() {
    const { appCode, configCode, formAppValid } = this.state;
    const {
      isDataTable,
      handleUpdateDataTable,
      loadDataModal,
      openModal,
      handleResetLoadDataTable,
      loadDataConfig,
      handleEditConfigEmpty
    } = this.props;
    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Datos Basicos</Nav.Link>
              </Nav.Item>
              {formAppValid === true ? (
                <Nav.Item>
                  <Nav.Link eventKey="second">Acceso a Core</Nav.Link>
                </Nav.Item>
              ) : null}
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <FormApp
                  isDataTable={isDataTable}
                  loadDataModal={loadDataModal}
                  configCode={configCode}
                  openModal={openModal}
                  handleUpdateDataTable={handleUpdateDataTable}
                  handleResetLoadDataTable={handleResetLoadDataTable}
                  handleAppCode={this.handleAppCode}
                  handleFormAppValid={this.handleFormAppValid}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <FormClient
                  appCode={appCode}
                  openModal={openModal}
                  loadDataConfig={loadDataConfig}
                  handleCofigCode={this.handleCofigCode}
                  handleEditConfigEmpty={handleEditConfigEmpty}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

export default FormWizard;
