import React, { Component } from "react";
import {
  FormGroup,
  Input,
  Card,
  CardBody,
  CardFooter,
  Button
} from "reactstrap";
import Select from "react-select";
import notify from "devextreme/ui/notify";

import FormValidator from "../Validation/FormValidation";
import { ButtonSave, ButtonUpdate } from "../Element/Buttons";
import {
  getTypeBylling,
  getMeasures,
  postBilling
} from "../../services/Api/BillingCycle";
import { COLOUR_WARNING } from "../Message/Message";
import { postbillingcycle, putbillingcycle } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { showValidation } from "../Validation/ShowValidation";
import { _JsonStorage } from '../../Global';

export default class FormBilligCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      typeBillings: [],
      measureds: [],
      formCillingCycle: {
        identifier: 0,
        typebillingcycle: null,
        measuredunit: null,
        description: '',
        namebilling: ''
      },
      minimumunit: ''
    };
  }

  componentDidMount() {
    this.handleTypeBillingCycle();
    this.handleMeasuredUnit();
  }

  componentDidUpdate(){
    this.handleChangeEdit();
  }

  handleChangeEdit = () => {
      if(this.props.isEdit){
          this.props.handleEdit(null);
          var data = this.props.dataEdit;
          this.setState({ isEdit:true, formCillingCycle:{
            identifier: data.BillingTypes.idBillingTypes,
            measuredunit: { label: data.nameMeasuredUnit, value: data.idMeasuredUnit },
            description: data.description,
            namebilling: data.namebilling
          }});
      }
  }

  handleResetStateForm = () => {
    this.setState({
      formCillingCycle: {
        identifier: 0,
        typebillingcycle: null,
        measuredunit: null,
        description: "",
        namebilling:""
      },
      minimumunit: ''
    });
  };

  handleSubmit() {
    let url = null;
    let header = null;

    const dataFetch = new FormData();
    const data = this.state.formCillingCycle;
    dataFetch.append("IdMeasuredUnit", data.measuredunit.value);
    dataFetch.append("Description", data.description);
    dataFetch.append("NameBillingCycle", data.namebilling)

    if (this.state.isEdit) {
      dataFetch.append("IdBillingCycle", data.identifier);
      url = putbillingcycle;
      header = headerPut(dataFetch, _JsonStorage.access_token);
    } else {
      url = postbillingcycle;
      header = headerPost(dataFetch, _JsonStorage.access_token);
    }

    let dataResponse = postBilling(url, header);
    let promise = new Promise((rest, rej) => {
      rest(dataResponse);
      rej(Error(dataResponse));
    });
    promise.then(
      result => {
        if (result.success) {
          this.handleResetStateForm();
          this.props.handleChangeGrid();
          this.setState({ isEdit: false });
        }
        showValidation(result);
      },
      function(error) {
        console.log(error);
      }
    );
  }
 
  handleOnSubmit = e => {
    e.preventDefault();
    if (
      this.state.formCillingCycle.typebillingcycle === null ||
      this.state.formCillingCycle.measuredunit === null
    ) {
      notify(
        "Verifica haber seleccionado un tipo y unidad de facturación.",
        COLOUR_WARNING
      );
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
      );
      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit();
      }
    }
  };

  handleTypeBillingCycle = () => {
    let data = getTypeBylling();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameTypeBillingCycle,
              value: element.idTypeBillingCycle,
              nameForm: "formCillingCycle",
              nameSelect: "typebillingcycle"
            };
            array.push(obj);
          });
          this.setState({ typeBillings: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleMeasuredUnit = () => {
    let data = getMeasures();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameMeasuredUnit,
              value: element.idMeasuredUnit,
              nameForm: "formCillingCycle",
              nameSelect: "measuredunit",
              minimumunit: element.minimumUnit
            };
            array.push(obj);
          });
          this.setState({ measureds: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleValidateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleChangeSelect = event => {
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });

    if(event.nameSelect === 'measuredunit'){
        this.setState({ minimumunit: event.minimumunit });
    }
  };

  render() {
    const { isEdit, typeBillings, measureds, minimumunit } = this.state;
    const {
      typebillingcycle,
      measuredunit,
      description,
      namebilling 
    } = this.state.formCillingCycle;
    return (
      <div> 
        <Card>
          <form name="formCillingCycle" onSubmit={this.handleOnSubmit} autoComplete="off">
            <CardBody>
              <FormGroup>
                <Select
                  name="typebillingcycle"
                  placeholder="Tipo facturación"
                  options={typeBillings}
                  onChange={this.handleChangeSelect}
                  value={typebillingcycle}
                />
              </FormGroup>
              <FormGroup>
                <Select
                  name="measuredunit"
                  placeholder="Unidad de medida"
                  options={measureds}
                  onChange={this.handleChangeSelect}
                  value={measuredunit}
                />
                {
                  minimumunit === '' ? null 
                  :<strong>La unidad minima de medida es: {minimumunit}</strong>
                }
              </FormGroup>
              <FormGroup>
                <Input 
                type="text"
                name="namebilling"
                placeholder="Nombre facturación"
                onChange={this.handleValidateOnChange}
                value={namebilling}
                invalid={this.hasError(
                  "formCillingCycle",
                  "namebilling",
                  "required"
                )}
                data-validate='["required"]'
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Descripción"
                  onChange={this.handleValidateOnChange}
                  value={description}
                  invalid={this.hasError(
                    "formCillingCycle",
                    "description",
                    "required"
                  )}
                  data-validate='["required"]'
                />
              </FormGroup>
            </CardBody>
            <CardFooter> 
              {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}{" "}
              <Button type="btn" className="btn-secondary">
                Cancelar
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    );
  }
}
