///Requeridos.
import React from 'react';
import SweetAlert from 'sweetalert-react';
import { ToastContainer } from 'react-toastify';

///Estilos.
import 'sweetalert-react/node_modules/sweetalert/dist/sweetalert.css';
import 'react-toastify/dist/ReactToastify.css';

class SwalConfirm extends React.Component {

    render() {
        return (
            <div>
                <SweetAlert
                    show={this.props.openSwal}
                    title="Confirmar acción."
                    text="Desea realizar esta acción."
                    showCancelButton
                    onConfirm={() => {
                        this.props.onFetchCheck();
                        this.props.swalShowConfirm();
                    }}
                    onCancel={() => {
                        this.props.swalShowConfirm();
                    }}
                />
                <ToastContainer />
            </div>
        )
    }
}

export default SwalConfirm;