import React, { useState } from "react";

export const VerifyContext = React.createContext({});

export const VerifyConsumer = VerifyContext.Consumer;

const VerifyProvider = (props) => {
  const [state, setState] = useState({
    isSpinner: false,
    isClose: false,
    getVerify: true
  });

  const handleChangeState = (name, value, name2 = null) => {
    if (name2 === null) setState(state => ({ ...state, [name]: value }));
    else {
      setState(state => ({
        [name]: {
          ...state[name],
          [name2]: value
        }
      }))
    }
  }

  return (
    <VerifyContext.Provider value={{ data: state, handleChangeState }}>
      {props.children}
    </VerifyContext.Provider>
  );
}

export default VerifyProvider;