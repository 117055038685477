import MethodPost from '../MethodPost';
import MethodDelete from '../MethodDelete';
import { headerPostJson, headerDeleteJson } from '../Headers';
import { getNotification, deleteNotification, getTopNotification } from '../../components/RouteApi/RouteApi';
import { _JsonStorage } from '../../Global';

///Consultar notificaciones del usuario
export const getNotifications = () =>{
    let obj = {
        code: localStorage.getItem('NavContext')
    }
    let header = headerPostJson(obj, _JsonStorage.access_token);
    const response = MethodPost(getNotification, header)
                        .then(response=>response)
                        .catch(error=>error);

    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
}

///Eliminar notificaciones del usaurio
export const deleteNotifications = (notifications) =>{
    let obj ={
        notifications: notifications
    }
    let header = headerDeleteJson(obj, _JsonStorage.access_token);
    const response = MethodDelete(deleteNotification, header)
                        .then(response=>response)
                        .catch(error=>error);
    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
}

///Consultar notificaciones del usuario
export const getTopNotifications = () =>{
    let obj = {
        code: localStorage.getItem('NavContext')
    }
    let header = headerPostJson(obj, _JsonStorage.access_token);
    const response = MethodPost(getTopNotification, header)
                        .then(response=>response)
                        .catch(error=>error);

    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
}