import React, { Component } from "react";

import './docs/SpinnerAnimate.css'

export default class SpinnerAnimate extends Component {
  render() {
    const { isDisabled } = this.props;
    return (
      <>
        {isDisabled === false ? null : (
          <div className="child rl-0">
            <div className="card-body loader-demo d-flex align-items-center justify-content-center">
              <div className="ball-scale-multiple">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
