import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";

import SubscriptionDatagrid from "./SubscriptionDatagrid";
import SubscriptionAssignation from "./SubscriptionAssignation";
import "./docs/Subscription.css";

export default class SubscriptionCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataapp: null,
      priceremove: null,
      pricetotal: null,
      reloadgrid: false,
      apps: []
    };
  }

  componentDidUpdate() {
    if (this.props.reset) {
      this.props.hadleChangeSidebarReset();
    }
  } 

  handleaddapp = param => {
    let obj = {
      idapp: param.idapp,
      nameapp: param.nameapp,
      subscription: param.subscription,
      price: param.price,
      totalprice: param.totalprice,
      img: param.img,
      description: param.description,
      storage: param.storage
    };

    this.setState({ dataapp: obj });
  };
 
  handleCleanDataApp = () => this.setState({ dataapp: null });

  handleRemovePrice = param => this.setState({ priceremove: param });

  handlePriceTotal = param => this.setState({ pricetotal: param });

  handleReloadGrid = () =>this.setState({ reloadgrid: !this.state.reloadgrid });

  handleGetApps = array =>this.setState({ apps: array });

  render() {
    const { subscription, isEdit, reset, sharedCreate, handleReloadSidebar,
       handleEditSubscription, client } = this.props;
    const { dataapp, priceremove, pricetotal, reloadgrid, apps } = this.state;
    return (
      <div> 
        <Card className="card-default">
          <CardBody> 
            <SubscriptionAssignation
              reset={reset}
              sharedCreate={sharedCreate} 
              subscription={subscription}
              priceremove={priceremove}
              isEdit={isEdit}
              client={client}
              apps={apps}
              handlePriceTotal={this.handlePriceTotal}
              handleRemovePrice={this.handleRemovePrice}
              handleReloadSidebar={handleReloadSidebar}
              handleReloadGrid={this.handleReloadGrid}
            />

            <SubscriptionDatagrid
              reset={reset}
              isEdit={isEdit}
              reloadgrid={reloadgrid}
              subscription={subscription}
              dataapp={dataapp}
              pricetotal={pricetotal}
              handleCleanDataApp={this.handleCleanDataApp}
              handleRemovePrice={this.handleRemovePrice}
              handleReloadGrid={this.handleReloadGrid}
              handleEditSubscription={handleEditSubscription}
              handleGetApps={this.handleGetApps}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}
