import MethodGet from '../../services/MethodGet';
import { getAllMenu, getAllMainMenu } from '../../components/RouteApi/RouteApi';

import 'react-toastify/dist/ReactToastify.css';
import { headerGet } from '../Headers';
import { _JsonStorage } from '../../Global';

///Solicitud de lista de menu.
export const listMenu = () => {
  let header = headerGet( _JsonStorage.access_token);
  return  MethodGet(getAllMenu, header).then(response => response).catch(error => error);
};

///Solicitud de lista de macros. 
export const listMainMenu = () => {
  let header = headerGet( _JsonStorage.access_token);
    let response = MethodGet(getAllMainMenu, header)
    .then(response=>response)
    .catch(error=>error);

    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
};
