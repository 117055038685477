import React, { Component } from "react";
import { FormGroup, Button, Input, Progress } from "reactstrap";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import notify from "devextreme/ui/notify";
import FormValidator from "../Validation/FormValidation";

import { ButtonUpdate, ButtonSave } from "../Element/Buttons";
import { getClientType } from "../../services/Api/ClientType";
import { getDocumentType } from "../../services/Api/User";
import { postclient, putclient } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { sendClient, getclientid } from "../../services/Api/Client";
import { COLOUR_SUCCESS, COLOUR_WARNING } from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";
import { getCountry, getCityFilter } from "../../services/Api/Region";
import { _JsonStorage } from '../../Global';

import SpinnerAnimate from '../Progress/SpinnerAnimate';

export default class EnterpriseForm extends Component {
  constructor(props) {
    super(props);
    this.mounted = false
    this.state = {
      isEdit: false,
      progress: 0,
      clientTypes: [],
      documentTypes: [],
      countries: [],
      cities: [],
      idClientType: 0,
      pictures: [],
      formEnterprise: {
        identifierClient: 0,
        clientType: null,
        businessName: "",
        acronym: "",
        documentType: null,
        documentNumber: "",
        email: "",
        direction: "",
        subdivision: "",
        firstName: "",
        secondName: "",
        surname: "",
        secondSurname: "",
        city: null,
        country: null
      },
      isSpinner: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.handleClientType();
    this.handleDocumentType();
    this.handleCountry();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    this.handleEdit();
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () => this.setState({ isSpinner: !this.state.isSpinner });

  ///Solicitar datos a editar
  handleEdit = () => {
    if (this.props.isEdit) {
      this.props.handleEdit();
      let identifier = this.props.filter;
      let data = getclientid(identifier);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            this.handleShowData(result.objeto);
          }
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  ///Mostrar datos en formulario
  handleShowData = param => {
    this.setState({
      formEnterprise: {
        identifierClient: param.enterpriseId,
        clientType: { label: param.nameClientType, value: param.idClientType },
        businessName: param.socialReason,
        acronym: param.acronym === null ? "" : param.acronym,
        documentType: {
          label: param.nameDocumentType,
          value: param.idDocumentType
        },
        documentNumber: param.documentNumber,
        email: param.email,
        direction: param.direction,
        subdivision: param.subdivision,
        firstName: param.nameOne === null ? "" : param.nameOne,
        secondName: param.nameTwo === null ? "" : param.nameTwo,
        surname: param.lastNameOne === null ? "" : param.lastNameOne,
        secondSurname: param.lastNameTwo === null ? "" : param.lastNameTwo,
        city: { label: param.nameCity, value: param.idCity },
        country: { label: param.nameCountry, value: param.idCountry }
      },
      isEdit: true,
      idClientType: param.idClientType
    });
  };

  ///Limpiar campos de formulario
  handleResetInput = () => {
    this.setState({
      formEnterprise: {
        identifierClient: 0,
        clientType: null,
        businessName: "",
        acronym: "",
        documentType: null,
        documentNumber: "",
        email: "",
        direction: "",
        subdivision: "",
        firstName: "",
        secondName: "",
        surname: "",
        secondSurname: "",
        city: null,
        country: null
      },
      pictures: [],
      progress: 0
    });
  };

  ///Guardar datos
  handleSubmit = () => {
    this.handleSpinner();

    let url = null;
    let header = null;
    const dataFetch = new FormData();
    const data = this.state.formEnterprise;
    const code = _JsonStorage.profile.id;
    dataFetch.append("IdClientType", data.clientType.value);
    dataFetch.append("SocialReason", data.businessName);
    dataFetch.append("Acronym", data.acronym);
    dataFetch.append("IdDocumentType", data.documentType.value);
    dataFetch.append("DocumentNumber", data.documentNumber);
    dataFetch.append("Email", data.email);
    dataFetch.append("Direction", data.direction);
    dataFetch.append("Subdivision", data.subdivision);
    dataFetch.append("NameOne", data.firstName);
    dataFetch.append("NameTwo", data.secondName);
    dataFetch.append("LastNameOne", data.surname);
    dataFetch.append("LastNameTwo", data.secondSurname);
    dataFetch.append("IdCity", data.city.value);
    dataFetch.append('code', code);

    if (this.state.pictures.length > 0) {
      let index = this.state.pictures.length - 1;
      dataFetch.append("File", this.state.pictures[index]);
    }

    if (this.state.isEdit) {
      url = putclient;
      dataFetch.append("EnterpriseId", data.identifierClient);
      header = headerPut(dataFetch, _JsonStorage.access_token);
    } else {
      url = postclient;
      header = headerPost(dataFetch, _JsonStorage.access_token);
    }

    let responseFetch = sendClient(url, header);
    let promise = new Promise((rest, rej) => {
      rest(responseFetch);
      rej(Error(responseFetch));
    });
    promise.then(
      result => {
        if (result.success) {
          if (this.state.isEdit)this.props.handleChangeDetail();
          showValidation(0, 0, '', result.message, COLOUR_SUCCESS);
          this.setState({ progress: 0, isEdit: false });
          this.handleResetInput();
          this.props.handleEnterprise();
          this.props.handleReload();
        } else if (result.success === false) {
          showValidation(0, 0, '', result.message, COLOUR_WARNING);
        }else{
          showValidation(0, 0, '', 'Se presento problemas de conexión, intente nuevamente.'+
          'Si el problema persiste recargue su navegador e intente hacer nuevamente el proceso de lo contrario comunique a un administrador', COLOUR_WARNING);
        }
        this.handleSpinner();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Función para validar los datos que se guardaran
  handleOnSubmit = e => {
    e.preventDefault();
    if (this.state.formEnterprise.clientType === null ||
      this.state.formEnterprise.documentType === null) {
      notify(
        "Verifica haber selecciona un tipo de empresa y un tipo de documento.",
        COLOUR_WARNING,
        5000
      );
    } else {
      if (this.state.formEnterprise.city === null) {
        notify("Verifica haber seleccionado una ciudad.", COLOUR_WARNING, 5000);
      } else {
        const form = e.target;
        const inputs = [...form.elements].filter(i =>
          ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
        );

        const { errors, hasError } = FormValidator.bulkValidate(inputs);
        this.setState({
          [form.name]: {
            ...this.state[form.name],
            errors
          }
        });

        if (!hasError) {
          this.handleSubmit();
        }
      }
    }
  };

  ///Muestra los errores en la vista
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Cambio de estado de los campos
  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cambio de estado de los select
  handleChangeSelect = event => {
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });

    if (event.nameSelect === "clientType") {
      this.setState({ idClientType: event.value });
    } else {
      if (event.nameSelect === "country") {
        this.setState({
          formEnterprise: {
            ...this.state.formEnterprise,
            city: null,
            [event.nameSelect]: { label: event.label, value: event.value }
          },
          cities: []
        });
        this.handleFilterCity(event.value);
      }
    }
  };

  ///Filtrar ciudad por país.
  handleFilterCity = identifier => {
    if (identifier > 0) {
      let data = getCityFilter(identifier);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            let array = [];
            result.response.forEach(element => {
              let obj = {
                label: element.nameCity,
                value: element.idCity,
                nameForm: "formEnterprise",
                nameSelect: "city"
              };
              array.push(obj);
            });
            this.setState({
              cities: array
            });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  ///Cargar paises
  handleCountry = () => {
    let data = getCountry();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameCountry,
              value: element.idCountry,
              nameForm: "formEnterprise",
              nameSelect: "country"
            };
            array.push(obj);
          });
          this.setState({ countries: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Cargar lista de tipo de client
  handleClientType = () => {
    let data = getClientType();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameClientType,
              value: element.idClientType,
              nameForm: "formEnterprise",
              nameSelect: "clientType"
            };
            array.push(obj);
          });
          this.setState({ clientTypes: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Cargar lista de tipo de documento
  handleDocumentType = () => {
    let data = getDocumentType();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameDocumentType,
              value: element.idDocumentType,
              nameForm: "formEnterprise",
              nameSelect: "documentType"
            };
            array.push(obj);
          });
          this.setState({ documentTypes: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Cargar imagen.
  handleOnDrop = picture => {
    this.setState({
      pictures: this.state.pictures.concat(picture),
      progress: 0
    });

    for (let index = 0; index < 105; index++) {
      this.setState({ progress: index });
    }
  };

  render() {
    const {
      isEdit,
      clientTypes,
      documentTypes,
      idClientType,
      countries,
      cities,
      isSpinner
    } = this.state;
    const {
      clientType,
      businessName,
      acronym,
      documentType,
      documentNumber,
      email,
      direction,
      subdivision,
      firstName,
      secondName,
      surname,
      secondSurname,
      city,
      country
    } = this.state.formEnterprise;
    return (
      <div>
        <form name="formEnterprise" onSubmit={this.handleOnSubmit}
          autoComplete="off" className="parent">
          <SpinnerAnimate isDisabled={isSpinner} />
          <FormGroup>
            <Select
              name="clientType"
              placeholder="Tipo"
              options={clientTypes}
              onChange={this.handleChangeSelect}
              value={clientType}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="businessName"
              placeholder="Razón social"
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formEnterprise", "businessName", "required") ||
                this.hasError("formEnterprise", "businessName", "alphanumspace")
              }
              data-validate='["required","alphanumspace"]'
              value={businessName}
            />
            {this.hasError(
              "formEnterprise",
              "businessName",
              "alphanumspace"
            ) && (
                <span className="invalid-feedback">
                  El campo debe ser un campo válido.
                </span>
              )}
          </FormGroup>
          {idClientType !== 1 ? null : (
            <div>
              <FormGroup>
                <Input
                  type="text"
                  name="firstName"
                  placeholder="Primer nombre"
                  onChange={this.handleOnChange}
                  invalid={
                    this.hasError("formEnterprise", "firstName", "required") ||
                    this.hasError("formEnterprise", "firstName", "alphaspace")
                  }
                  data-validate='["required", "alphaspace"]'
                  value={firstName}
                />
                {this.hasError("formEnterprise", "firstName", "alphaspace") && (
                  <span className="invalid-feedback">
                    El campo debe ser un campo válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="secondName"
                  placeholder="Segundo nombre"
                  onChange={this.handleOnChange}
                  invalid={this.hasError(
                    "formEnterprise",
                    "secondName",
                    "alphaspace"
                  )}
                  data-validate='["alphaspace"]'
                  value={secondName}
                />
                {this.hasError(
                  "formEnterprise",
                  "secondName",
                  "alphaspace"
                ) && (
                    <span className="invalid-feedback">
                      El campo debe ser un campo válido.
                    </span>
                  )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="surname"
                  placeholder="Primer apellido"
                  onChange={this.handleOnChange}
                  invalid={
                    this.hasError("formEnterprise", "surname", "required") ||
                    this.hasError("formEnterprise", "surname", "alphaspace")
                  }
                  data-validate='["required","alphaspace"]'
                  value={surname}
                />
                {this.hasError("formEnterprise", "surname", "alphaspace") && (
                  <span className="invalid-feedback">
                    El campo debe ser un campo válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="secondSurname"
                  placeholder="Segundo apellido"
                  onChange={this.handleOnChange}
                  invalid={this.hasError(
                    "formEnterprise",
                    "secondSurname",
                    "alphaspace"
                  )}
                  data-validate='["alphaspace"]'
                  value={secondSurname}
                />
                {this.hasError(
                  "formEnterprise",
                  "secondSurname",
                  "alphaspace"
                ) && (
                    <span className="invalid-feedback">
                      El campo debe ser un campo válido.
                    </span>
                  )}
              </FormGroup>
            </div>
          )}
          <FormGroup>
            <Input
              type="text"
              name="acronym"
              placeholder="Sigla"
              onChange={this.handleOnChange}
              invalid={this.hasError(
                "formEnterprise",
                "acronym",
                "alphanumspace"
              )}
              data-validate='["alphanumspace"]'
              value={acronym}
            />
            {this.hasError("formEnterprise", "acronym", "alphanumspace") && (
              <span className="invalid-feedback">
                El campo debe ser un campo válido.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Select
              name="documentType"
              placeholder="Tipo documento"
              options={documentTypes}
              onChange={this.handleChangeSelect}
              value={documentType}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="documentNumber"
              placeholder="Numero documento"
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formEnterprise", "documentNumber", "required") ||
                this.hasError(
                  "formEnterprise",
                  "documentNumber",
                  "alphanumspace"
                )
              }
              data-validate='["required", "alphanumspace"]'
              value={documentNumber}
            />
            {this.hasError(
              "formEnterprise",
              "documentNumber",
              "alphanumspace"
            ) && (
                <span className="invalid-feedback">
                  El campo debe ser un campo válido.
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="email"
              placeholder="Correo electrónico"
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formEnterprise", "email", "required") ||
                this.hasError("formEnterprise", "email", "email")
              }
              data-validate='["required", "email"]'
              value={email}
            />
            {this.hasError("formEnterprise", "email", "email") && (
              <span className="invalid-feedback">
                El campo debe ser un campo válido.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Select
              name="country"
              placeholder="Escriba país de la empresa."
              options={countries}
              value={country}
              onChange={this.handleChangeSelect}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name="city"
              placeholder="Escriba ciudad de la empresa"
              options={cities}
              onChange={this.handleChangeSelect}
              value={city}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="direction"
              placeholder="Dirección"
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formEnterprise", "direction", "required") ||
                this.hasError("formEnterprise", "direction", "direction")
              }
              data-validate='["required","direction"]'
              value={direction}
            />
            {this.hasError("formEnterprise", "direction", "direction") && (
              <span className="invalid-feedback">
                El campo debe ser un campo válido.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="subdivision"
              placeholder="Barrio"
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formEnterprise", "subdivision", "required") ||
                this.hasError("formEnterprise", "subdivision", "alphanumspace")
              }
              data-validate='["required","alphanumspace"]'
              value={subdivision}
            />
            {this.hasError(
              "formEnterprise",
              "subdivision",
              "alphanumspace"
            ) && (
                <span className="invalid-feedback">
                  El campo debe ser un campo válido.
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <ImageUploader
              withIcon={true}
              buttonText="Elegir logo"
              onChange={this.handleOnDrop}
              label="Tamaño máximo de archivo: 1mb, acepta: jpg | png"
              imgExtension={[".jpg", ".png"]}
              maxFileSize={5242880}
            />
            <Progress color="success" value={this.state.progress}>
              Archivo listo para cargar
            </Progress>
          </FormGroup>
          <FormGroup>
            {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}{" "}
            <Button color="secondary" onClick={this.handleResetInput}>
              Cancelar
            </Button>
          </FormGroup>
        </form>
      </div>
    );
  }
}
