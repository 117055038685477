import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Time100 } from "../../Global";

export default class ProgressAnimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nowProgress: 20,
      max: 100,
      isValid: true
    };
  }

  componentDidUpdate = () => {
    if(this.state.isValid){
      this.handleLoadTime();
    }
  };

  ///Cargar porcentaje de progress
  handleLoadTime = () => {
    let _this = this;
    if (!_this.props.isProgress) {
      _this.setState({
        nowProgress: 20,
        max: 100,
        isValid: false
      });
    } else if (_this.state.nowProgress >= _this.state.max - 5) {
      _this.setState({
        nowProgress: _this.state.nowProgress,
        isValid: false
      });
    } else if (_this.state.nowProgress < _this.state.max - 5) {
      _this.setState({
        nowProgress: _this.state.nowProgress + 5,
        isValid: false
      });

      setTimeout(function() {
        _this.handleLoadTime();
      }, Time100);
    }
  };

  render() {
    const { nowProgress } = this.state;
    const { isProgress } = this.props;
    return (
      <div>
        {isProgress === false ? null : (
          <ProgressBar
            animated
            now={nowProgress}
            label={`Cargando información ${nowProgress}%`}
          />
        )}
      </div>
    );
  }
}
