import React, { Component } from "react";
import Header from "../Header";
import FormValidator from '../../Validation/FormValidation';
import QueryString from "query-string";
import { Card, CardHeader, CardBody, CardFooter, Row, Button, Input } from "reactstrap";
import { Pattern } from "../../../Global";
import { ResendAccess } from '../../../services/Api/User';
import { showValidation } from '../../Validation/ShowValidation';
import { COLOUR_SUCCESS } from '../../Message/Message';

import '../docs/Login.css';
import '../docs/ForgotPassword.css';
import '../../../styles/extra/Site.css';

export default class ResendPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formResend: {
        email: ""
      }
    };
  }

  componentDidMount(){
    this.handleEmailQuery();
  }

  ///Obtener email de query string
  handleEmailQuery = () =>{
    let emailQuery = QueryString.parse(this.props.location.search);
    this.setState({
      formResend:{
        ...this.state.formResend,
        email: emailQuery.email === undefined ? "": emailQuery.email
      }
    });
  }

  ///Permite el cambio de estado del state.
  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  /// Simplificar la comprobación de errores.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Validar datos al momento de intentar guardar los datos.
  handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      this.handleSubmit(e);
    }
  };

  ///Guardar datos.
  handleSubmit = () =>{
    ResendAccess(this.state.formResend.email)
    .then(response=>{
      if(response.success){
        showValidation(null, 0,"", "El correo se envio correctamente.", COLOUR_SUCCESS);
        this.setState({ 
          formResend:{
            ...this.state.formResend,
            email:''
          }
        });
      }else{
        showValidation(response);
      }
    },function(error){
      console.log(error);
    });
  }

  render() {
    const { email } = this.state.formResend;
    return (
      <div
        className="size-page"
        style={{
          background: { Pattern },
          height: "100%",
          overflow: "hidden",
          backgroundColor: "#ffffff"
        }}
      >
        <Header />
        <br />
        <br />
        <div className="wrapper">
          <aside>
            <div className="block-center col-md-6">
              <form name="formResend" onSubmit={this.handleOnSubmit} method="post" className="col-md-12" autoComplete="off">
                <Card className="card-default card">
                  <CardHeader className="text-center bg-dark">
                    <div className="card-title text-center">
                      <h4 className="text-white">Reenviar Correo</h4>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="panel-tag">
                      <p><strong>Nota: </strong>
                        Si presento problemas al momento de recibir o no ha llegado el correo de acceso, 
                        aquí podrá solicitar un nuevo correo electrónico si el mensaje 
                        no llego correctamente a su cuenta de usuario.</p>
                    </div>
                    <div className="form-group col-md-12">
                      <label>Correo electrónico <strong className="text-danger">*</strong></label>
                      <Input 
                        type="text" 
                        name="email" 
                        placeholder="Correo electrónico."
                        invalid={
                          this.hasError("formResend", "email", "required") ||
                          this.hasError("formResend", "email", "email")
                        }
                        onChange={this.handleOnChange}
                        data-validate='["required", "email"]'
                        value={email}
                        />
                        {this.hasError("formResend", "email", "email") && (
                          <span className="invalid-feedback">
                            El campo debe ser un correo electrónico válido.
                          </span>
                        )}
                    </div>
                  </CardBody>
                  <CardFooter className="hidden-cardfooter">
                    <Row className="align-right col-md-12">
                      <div className="form-group col-md-12">
                        <Button className="btn bg-primary col-md-11">Enviar</Button>
                      </div>
                    </Row>
                  </CardFooter>
                </Card>
              </form>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
