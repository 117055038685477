import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import { Card, CardBody } from "reactstrap";
import DataGrid, { Column } from "devextreme-react/data-grid";

import { getAppBillig, deleteAppBilling } from "../../services/Api/AppBilling";
import { showValidation } from "../Validation/ShowValidation";
import { MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING } from "../Message/Message";
import { Time600 } from "../../Global";
import SwalRemove from "../SweetAlert/SwalRemove";
import ProgressAnimate from "../Progress/ProgressAnimate";

import "./docs/Billing.css";

export default class DatagridBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      remove: {
        isRemove: false,
        nameRemove: "",
        identifierRemove: ""
      },
      dataBilling: [
        {
          Billing: { idBilling: 0 },
          nameBilling: "",
          price: "",
          identifier: 0
        }
      ],
      isProgress: false
    };
  }

  componentDidMount() {
    this.props.handleChangeDatagrid();
  }

  componentDidUpdate() {
    this.handleDataBillig();
  }

  ///Cargar progress
  handleProgress = () => this.setState({ isProgress: !this.state.isProgress });

  handleDataBillig = () => {
    if (this.props.isChangeDatagrid) {
      this.handleProgress();
      this.props.handleChangeDatagrid();
      let param = this.props.identifierapp;
      let data = getAppBillig(param);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            let array = [];
            result.response.forEach(element => {
              let obj = {
                Billing: { idBilling: element.idBillingCycle },
                nameBilling: element.nameBillingCycle,
                price:
                  "$" +
                  new Intl.NumberFormat().format(element.cost * element.factor),
                identifier: element.idApplicationBillingCycle,
                costbase: element.cost,
                factor: element.factor,
                description: element.description
              };
              array.push(obj);
            });
            this.setState({ dataBilling: array });
          }
          this.handleProgress();
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleOnclickEdit = event => {
    const data = event.row.data;
    this.props.handleShared(data);
    this.props.handleChangeIsEdit();
    this.setState({ selectedRowKeys: [data.Billing.idBilling] });
  };

  swalShow = e => {
    const _this = this;
    if (e !== undefined) {
      let event = e.event;
      event.preventDefault();

      let name = e.row.data.nameBilling;
      let identifier = e.row.data.identifier;
      _this.setState({
        remove: {
          ..._this.state.remove,
          isRemove: !_this.state.remove.isRemove,
          nameRemove: name,
          identifierRemove: identifier
        }
      });
    } else {
      _this.setState({
        remove: {
          ..._this.state.remove,
          isRemove: !_this.state.remove.isRemove
        }
      });
    }
  };

  handleRemove = (remove, val, isRemove) => {
    if (val !== remove) {
      this.swalShow();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, Time600);
    } else {
      let data = deleteAppBilling(isRemove);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            showValidation(result, 3, "facturación");
            this.props.handleChangeDatagrid();
          } else {
            showValidation(result);
          }
          this.swalShow();
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  render() {
    const { selectedRowKeys, dataBilling, isProgress } = this.state;
    const { isRemove, nameRemove, identifierRemove } = this.state.remove;
    return (
      <div>
        <Card>
          <CardBody>
            <DataGrid
              dataSource={dataBilling}
              keyExpr={"Billing.idBilling"}
              showBorders={true}
              onSelectionChanged={this.onSelectionChanged}
              hoverStateEnabled={true}
              selectedRowKeys={selectedRowKeys}
            >
              <Column
                dataField={"nameBilling"}
                caption={"Nombre"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                dataField={"costbase"}
                caption={"Precio unidad"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                dataField={"factor"}
                caption={"Tope maxímo"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                dataField={"price"}
                caption={"Precio factura"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                type={"buttons"}
                dataFiled={"Billing"}
                width={60}
                caption={"Eliminar"}
                buttons={[
                  {
                    cssClass: "buttons-edit",
                    hint: "Editar",
                    icon: "edit",
                    onClick: this.handleOnclickEdit
                  },
                  {
                    cssClass: "buttons-delete",
                    hint: "Eliminar",
                    icon: "clear",
                    onClick: this.swalShow
                  }
                ]}
              />
            </DataGrid>
          </CardBody>
          <ProgressAnimate isProgress={isProgress} />
        </Card>
        <SwalRemove
          swalShow={this.swalShow}
          openModal={isRemove}
          handleRemove={this.handleRemove}
          element={nameRemove}
          elementRemove={identifierRemove}
        />
      </div>
    );
  }
}
