import React from 'react';
import { Button } from 'reactstrap';

export const ButtonSave = () =>(
    <Button type="submit" color="primary">Guardar</Button>
);

export const  ButtonUpdate = () =>(
    <Button type="submit" className="bg-success">Actualizar</Button>
);

export const ButtonSecodary = () =>(
    <Button type="button" className="bg-gray">Cancelar</Button>
);