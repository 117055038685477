import MethodGet from "../MethodGet";
import MethodPost from "../MethodPost";
import {
  headerPost,
  headerPostJson,
  headerGet,
  headerPutJson
} from "../Headers";
import {
  getApplication,
  editApplication,
  getappfindbysubscription,
  getallappfiltersubs,
  getFindByName,
  getidsubscriptionuser,
  getownerasync,
  getthirasync,
  postClient,
  getClient,
  putClient,
  getClientId,
  getCodeConsecutive
} from "../../components/RouteApi/RouteApi";
import { _JsonStorage } from "../../Global";

///Lista de aplicaciones
export const getapplication = () => {
  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(getApplication, header)
    .then(response => response)
    .catch(error => error);
};

///Lista de aplicaciones por id
export const getapplicationid = param => {
  let urlBase = editApplication,
    params = {
      id: param
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Crear o actualizar aplicación
export const sendapplication = (url, data) => {
  let header = headerPost(data, _JsonStorage.access_token);
  return MethodPost(url, header)
    .then(response => response)
    .catch(error => error);
};

///Lista de aplicaciones por suscripción
export const getfindbysubscription = param => {
  let urlBase = getappfindbysubscription,
    params = {
      id: param
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Lista de todas las aplicaciones con estado de aplicaciones ya asignadas.
export const getAppFilterSubs = param => {
  let urlBase = getallappfiltersubs,
    params = {
      code: param
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Aplicación por nombre.
export const getByNameApp = param => {
  let urlBase = getFindByName,
    params = {
      name: param
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Lista de apliaciones de terceros.
export const asyncThirdApps = params => {
  let urlBase = getidsubscriptionuser,
    obj = {
      param: params
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(obj).forEach(item => url.searchParams.append(item, obj[item]));

  let header = headerGet( _JsonStorage.access_token);

  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Lista de aplicaciones del usuario
export const appOwnerAsync = () => {
  let obj = {
    code: _JsonStorage.profile === undefined? null: _JsonStorage.profile.id
  };
  let header = headerPostJson(obj, _JsonStorage.access_token);
  let response = MethodPost(getownerasync, header)
    .then(response => response)
    .catch(error => error);

  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Lista de aplicaciones asignadas al usuario
export const appThirAsync = () => {
  let obj = {
    code: _JsonStorage.profile === undefined? null: _JsonStorage.profile.id
  };
  let header = headerPostJson(obj, _JsonStorage.access_token);
  let response = MethodPost(getthirasync, header)
    .then(response => response)
    .catch(error => error);

  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Agregar configuración de la aplicación para conectar con identity
export const ClientPostAsync = data => {
  let header = headerPostJson(data, _JsonStorage.access_token);
  let result = MethodPost(postClient, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};

///Consultar configuración de aplicación por nombre
export const ClientGetNameAsync = data => {
  let obj = {
    clientId: data
  };

  let header = headerPostJson(obj, _JsonStorage.access_token);
  let result = MethodPost(getClient, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};

///Actualizar configuración de la aplicación
export const ClientPutAsync = data => {
  let header = headerPutJson(data, _JsonStorage.access_token);
  let result = MethodPost(putClient, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};

///Consultar configuración por identificador
export const ClientGetAsync = resource => {
  let obj = {
    id: resource
  };

  let header = headerPostJson(obj, _JsonStorage.access_token);
  let result = MethodPost(getClientId, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};

///Consultar codigo consecutivo
export const CodeConsecutiveAsync = () => {
  let header = headerGet( _JsonStorage.access_token);
  let result = MethodGet(getCodeConsecutive, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};
