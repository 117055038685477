import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";
import { TimeSetTime5 } from "../../Global";
import { infosingleview } from "../../services/Api/User";

import Now from "../Common/Now";

import "./docs/SingleView.css";

class NumberRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numbersuscription: 0,
      numberapp: 0,
      numberenterprise: 0
    };
  }

  componentDidMount() {
    // this.handleIsContext();
    this.handleSuscription();
  }

  componentDidUpdate() {
    if (
      (this.props.numberapp > 0) &
      (this.state.numberapp !== this.props.numberapp)
    ) {
      this.setState({ numberapp: this.props.numberapp });
    }
  }

  ///Validar si el nav context se encuentra en sesión
  handleIsContext = () => {
    let _this = this;
    const navContext = sessionStorage.getItem("NavContext");
    if (navContext === null) {
      setTimeout(function() {
        _this.handleIsContext();
      }, TimeSetTime5);
    } else {
      
    }
  };

  ///Consultar cantidad de suscripciones y empresas de usuario
  handleSuscription() {
    infosingleview()
      .then(response => {
        if (response.success) {
          this.setState({
            numbersuscription: response.subscriptions,
            numberenterprise: response.clients
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { numbersuscription, numberapp, numberenterprise } = this.state;
    return (
      <Row>
        <Col xl={3} lg={6} md={12}>
          {/* START card */}
          <Link to="/enterprise" path="/enterprise" className="display-link">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left">
                <em className="icon-briefcase fa-3x"></em>
                {/* <i class="fas fa-building"></i> */}
              </div>
              <div className="col-8 py-3 bg-green rounded-right">
                <div className="h2 mt-0">{numberenterprise}</div>
                <div className="text-uppercase">Empresas Creadas</div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={3} md={6}>
          {/* START card */}
          <Link
            to="/subscription"
            path="/subscription"
            className="display-link"
          >
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                <em className="icon-cloud-upload fa-3x"></em>
              </div>
              <div className="col-8 py-3 bg-primary rounded-right">
                <div className="h2 mt-0">{numbersuscription}</div>
                <div className="text-uppercase">Suscripciones Creadas</div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={3} md={6}>
          {/* START card */}
          <Link
            to="/subscription"
            path="/subscription"
            className="display-link"
          >
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
                <em className="icon-layers fa-3x"></em>
              </div>
              <div className="col-8 py-3 bg-purple rounded-right">
                <div className="h2 mt-0">
                  {numberapp}
                  {/* <small>GB</small> */}
                </div>
                <div className="text-uppercase">Aplicaciones Asignadas</div>
              </div>
            </div>
          </Link>
        </Col>
        <Col xl={3} lg={6} md={12}>
          {/* START date card */}
          <div className="card flex-row align-items-center align-items-stretch border-0">
            <div className="col-4 d-flex align-items-center bg-green justify-content-center rounded-left">
              <div className="text-center">
                <Now format="MMMM" className="text-sm" />
                <br />
                <Now format="D" className="h2 mt0" />
              </div>
            </div>
            <div className="col-8 py-3 rounded-right">
              <Now format="dddd" className="text-uppercase" />
              <br />
              <Now format="h:mm" className="h2 mt0 mr-sm" />
              <Now format="a" className="text-muted text-sm" />
            </div>
          </div>
          {/* END date card */}
        </Col>
      </Row>
    );
  }
}

export default translate("translations")(NumberRegister);
