import React from "react";
import ContentWrapper from "../Layout/ContentWrapper";
import { translate } from "react-i18next";

import NumberRegister from "./NumberRegister";
import ContainerApps from "./ContainerApplications";
import ContainerAppsAll from "./ContainerApplicationAll";

class SingleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numberapp: 0
    };
  }

  handleNumberApp = param => {
    this.setState({ numberapp: param });
  };

  render() {
    const { numberapp } = this.state;
    return (
      <ContentWrapper>
        <NumberRegister numberapp={numberapp} />
        <ContainerApps handleNumberApp={this.handleNumberApp} />
        <ContainerAppsAll />
      </ContentWrapper>
    );
  }
}

export default translate("translations")(SingleView);
