import React, { Component } from "react";
import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";
import "classlist.js";

import {
  getMeasurement,
  deleteMeasurement
} from "../../services/Api/Measurement";
import { COLOUR_WARNING, MESSAGE_REMOVE_DIFFERENT } from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";
import SwalRemove from "../SweetAlert/SwalRemove";
import notify from "devextreme/ui/notify";

// import 'devextreme/dist/css/dx.common.css';
// import 'devextreme/dist/css/dx.light.css';

import "./docs/Measurement.css";

export default class MeasurementDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      remove: {
        openModal: false,
        element: "",
        elementRemove: ""
      }
    };
  }

  componentDidMount() {
    this.handleLoadData();
  }

  componentDidUpdate() {
    if (this.props.isChange === true) {
      this.props.handleChangeData();
      this.handleLoadData();
    }
  }

  handleLoadData = () => {
    let data = getMeasurement();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              measurement: { idMeasuredUnit: element.idMeasuredUnit },
              name: element.nameMeasuredUnit,
              baseunit: element.baseUnit,
              classification: element.classification
            };
            array.push(obj);
          });
          this.setState({ data: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleOnClickEdit = event => {
    const data = event.row.data;
    this.props.hadleEdit(data);
    this.setState({ selectedRowKeys: [data.measurement.idMeasuredUnit] });
  };

  ///Abrir o cerrar modal de eliminar.
  swalShow = () =>
    this.setState({
      remove: {
        ...this.state.remove,
        openModal: !this.state.remove.openModal
      }
    });

  ///Validar eliminación de registro.
  handleRemove = (param1, param2, param3) => {
    if (param1 !== param2) {
      this.swalShow();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, 4000);
    } else {
      this.handleSendRemove(param3);
    }
  };

  ///Eliminar registro.
  handleSendRemove = param => {
    this.props.handleChangeData();
    this.setState({ selectedRowKeys: [param] });
    let response = deleteMeasurement(param);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        this.props.handleChangeData();
        if (result.success) {
          this.handleLoadData();
          showValidation(result, 3, "unidad de medida");
        }else{
          showValidation(result);
        }
        this.swalShow();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Seleccionar datos a eliminar
  handleDelete = event =>{
    let _this = this;
    let data = event.row.data;
    _this.setState({
      remove:{
        ..._this.state.remove,
        openModal: !_this.state.remove.openModal,
        element: data.name,
        elementRemove: data.measurement.idMeasuredUnit
      }
    });
}

  render() {
    const { data, selectedRowKeys } = this.state;
    const { openModal, element, elementRemove } = this.state.remove;
    return (
      <div>
        <DataGrid
          dataSource={data}
          keyExpr={"measurement.idMeasuredUnit"}
          showBorders={true}
          hoverStateEnabled={true}
          selectedRowKeys={selectedRowKeys}
        >
          <SearchPanel
            visible={true}
            width={360}
            placeholder={"Filtrar unida de medida."}
          />
          <Column
            dataField={"name"}
            caption={"Unidad de medida"}
            headerFilter={{ allowSearch: true }}
          />
          <Column
            dataField={"baseunit"}
            caption={"Unidad base"}
            headerFilter={{ allowSearch: true }}
          />
          <Column
            dataField={"classification"}
            caption={"Clasificación"}
            headerFilter={{ allowSearch: true }}
          />
          <Column
            type={"buttons"}
            width={60}
            caption={"Acción"}
            dataField={"measurement"}
            buttons={[
              {
                cssClass: "buttons-edit",
                hint: "Editar",
                icon: "edit",
                onClick: this.handleOnClickEdit
              },
              {
                cssClass: "buttons-delete",
                hint: "Eliminar",
                icon: "clear",
                onClick: this.handleDelete
              }
            ]}
          />
        </DataGrid>
        <SwalRemove
          openModal={openModal}
          element={element}
          elementRemove={elementRemove}
          swalShow={this.swalShow}
          handleRemove={this.handleRemove}
        />
      </div>
    );
  }
}
