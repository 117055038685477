export const OidcAccess = (props) => {
    if (props.oidc !== null && props.oidc !== undefined) {
    _JsonStorage = props.oidc;
  }

  if (props.config !== null && props.config !== undefined) {
    ConfigClientApp.KeyRecaptchat = props.config.KeyRecaptchat;
    ConfigClientApp.NameSession = props.config.KeyRecaptchat;
  }

  return props.children
}

export let ConfigClientApp = {
  KeyRecaptchat: '',
  NameSession: '',
  PathRouteApiCore: ''
};

export let KeyRecaptchat = ConfigClientApp.KeyRecaptchat;
export let NameSession = ConfigClientApp.NameSession;
export let PathRoutApiCore = ConfigClientApp.PathRouteApiCore
export let LengthMinPassword = 8;
export let LengthMaxPassword = 20;
export const MinNumber = 0;
export const TimeSetTime3 = 3000;
export const TimeSetTime5 = 5000;
export const RouteResendPassword = `${window.location.origin}/resend`;
export const Domain = `${window.location.origin}/`;
export const Pattern =
  "url('img/svg/pattern-1.svg')no-repeat center bottom fixed";
export const Time100 = 600;
export const Time300 = 3000;
export const Time500 = 5000;
export const Time700 = 7000;
export const Time600 = 6000;
export const Time900 = 9000;
export const NumberServiceRequest = 3;
export const LimitReadMore = 236;

const Storage = sessionStorage.getItem(NameSession);
export let _JsonStorage =
  JSON.parse(Storage) !== null
    ? JSON.parse(Storage)
    : { access_token: null, profile: { sub: null, id: null } };

export const ResendStorage = () => {
  let session = sessionStorage.getItem(NameSession);
  _JsonStorage =
    JSON.parse(session) !== null
      ? JSON.parse(session)
      : { access_token: null, profile: { code: null, id: null } };
};

export function ExecuteFn(fn) {
  try {
    if (_JsonStorage.access_token === null) {
      ResendStorage();
      fn();
      setTimeout(() => {
        ExecuteFn(fn);
      }, 9000);
    } else if (_JsonStorage.profile.id !== null) {
      fn();
    }
  } catch (exc) {
    console.log(exc);
  }
}
