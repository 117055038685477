import React, { Component } from "react";

///Componente
import CarouselForm from "./CarouselForm";
import CarouselImage from "./CarouselImage";
import CarouselPreview from "./CarouselPreview";

export default class CarouselBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: [],
      header: "",
      description: "",
      carousel: [],
      isChangeCarousel: false,
      apps: [],
      isfilter: false,
      appfilter: null,
      isEdit: false
    };
  }

  ///Cargar datos de la imagen que esta en el fomulario
  handleUploadImg = param => this.setState({ picture: param });

  ///Cargar datos del encabezado que esta en el fomulario
  handleUploadHeader = param => this.setState({ header: param });

  ///Cargar datos de descripción que esta en el fomulario
  handleUploadDescription = param => this.setState({ description: param });

  ///Cargar imagenes que se mostraran en el carousel por aplicación
  handleCaruselPreview = param => this.setState({ carousel: param });

  ///Cargar y heredar la lista de aplicaciones
  handleLoadApps = param =>this.setState({ apps: param });

  ///Filtrar los carouseles de una aplicación
  handleIsFilter = (bool,data) =>this.setState({ isfilter: bool, appfilter: data });

  ///Actualizar el carousel
  handleChangeCarousel = () =>
    this.setState({ isChangeCarousel: !this.state.isChangeCarousel });

  ///Enviar valores que se van a editar
  // handleEdit = param =>this.setState({  });


  render() {
    const { picture, header, description, carousel, isChangeCarousel, apps, isfilter, appfilter } = this.state;
    return (
      <div className="col-md-12">
        <div className="col-md-12 row">
          <div className="col-md-6">
            <CarouselForm 
              handleUploadImg={this.handleUploadImg}
              handleUploadHeader={this.handleUploadHeader}
              handleUploadDescription={this.handleUploadDescription}
              handleChangeCarousel={this.handleChangeCarousel}
              handleCaruselPreview={this.handleCaruselPreview}
              handleLoadApps={this.handleLoadApps}
              handleIsFilter={this.handleIsFilter}
            />
          </div>
          <div className="col-md-6">
            <CarouselImage
              picture={picture}
              header={header}
              description={description}
            />
          </div>
        </div>
        <div className="col-md-12">
          <CarouselPreview
            carousel={carousel}
            apps={apps}
            isfilter={isfilter}
            appfilter={appfilter}
            isChangeCarousel={isChangeCarousel}
            handleChangeCarousel={this.handleChangeCarousel}
            handleIsFilter={this.handleIsFilter}
          />
        </div>
      </div>
    );
  }
}
