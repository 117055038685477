import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import pubsub from "pubsub-js";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu, 
  DropdownItem,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import { Link } from "react-router-dom";

import TriggerResize from "../Common/TriggerResize";
import ToggleState from "../Common/ToggleState";
import HeaderRun from "./Header.run";
import Offsidebar from "./Offsidebar";

import MethodGet from "../../services/MethodGet";
import { getTopNotifications } from "../../services/Api/Notification";

import AppShortcuts from "../Extras/AppShortcuts";

import { MESSAGE_ERROR, COLOUR_ERROR } from "../Message/Message";

import "./docs/MyLayoutStyle.css";

const NavInternal = props => {
  return (
    <Link to="/notification" path="/notification" className="nav-link">
      <div className="media">
        <div className="align-self-start mr-2">
          <em className="fa fa-envelope fa-2x text-warning" />
        </div>
        <div className="media-body">
          <p className="m-0">{props.props.subject}</p>
          <p className="m-0 text-muted text-sm">
            {props.props.message + "..."}
          </p>
        </div>
      </div>
    </Link>
  );
};

const NavExternal = props => {
  return (
    <a href={props.props.link} className="nav-link" target={"_blank"}>
      <div className="media">
        <div className="align-self-start mr-2">
          <em className="fa fa-envelope fa-2x text-warning" />
        </div>
        <div className="media-body">
          <p className="m-0">{props.props.subject}</p>
          <p className="m-0 text-muted text-sm">
            {props.props.message + "..."}
          </p>
        </div>
      </div>
    </a>
  );
};
 
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownapp: false,
      notifications: {
        count: 0,
        array: [],
        link: "",
        subject: ""
      }
    };
  }

  componentDidMount() {
    HeaderRun();
    // this.handleNotifications();
    this.handleRemoveNavigation();
  }

  ///Eliminar menu de navegación
  handleRemoveNavigation = () =>{
      var element = document.getElementById("navigation_logout");
      element.addEventListener("click", function(){
        sessionStorage.removeItem("Navigation");
        sessionStorage.removeItem("Avatar");
      });
  }

  toggleUserblock(e) {
    e.preventDefault();
    pubsub.publish("toggleUserblock");
  } 

  logoutCookie = () => {
    MethodGet("v1/login/Logout")
      .then(response => {
        if (response.success) {
          window.localStorage.removeItem("menu");
          window.localStorage.removeItem("avatar");
          window.localStorage.removeItem("storage");
          window.localStorage.removeItem("icon");
          window.localStorage.removeItem("NavContext");
          window.location.href = "/login";
        } else {
          notify(MESSAGE_ERROR, COLOUR_ERROR);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Actualizar menu del usuario
  handleRefreshMenu = () => {
      sessionStorage.removeItem("Navigation");
      document.location.reload();
  };

  handleDropDownApp = value => this.setState({ dropdownapp: value });

  //TODO:No esta en función
  handleNotifications = () => {
    getTopNotifications()
      .then(response => {
        if (response.success) {
          const array = [];
          response.response.forEach(x => {
            let obj = {
              link: x.link === null ? "" : x.link,
              subject: x.subJect === null ? "" : x.subJect,
              message: x.message.substring(0, 19)
            };
            array.push(obj);
          });
          this.setState({
            notifications: {
              ...this.state.notifications,
              count: response.response.length,
              array: array
            }
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    const { dropdownapp } = this.state;
    const { count, array } = this.state.notifications;
    return (
      <AuthenticationContext.Consumer>
        {props => {
          return (
            <header className="topnavbar-wrapper">
              {/* START Top Navbar */}
              <nav className="navbar topnavbar">
                {/* START navbar header */}
                <div className="navbar-header">
                  <a className="navbar-brand" href="/home">
                    <div className="brand-logo">
                      <img
                        className="img-fluid resize-fluid"
                        src="img/CORE-LOGO_BLANCO.png"
                        alt="App Logo"
                      />
                    </div>
                    <div className="brand-logo-collapsed">
                      <img
                        className="img-fluid"
                        src="img/CORE-ISOTIPO-BLANCO.png"
                        alt="App Logo"
                      />
                    </div>
                  </a>
                </div>
                {/* END navbar header */}

                {/* START Barra de navegación izquierda */}
                <ul className="navbar-nav mr-auto flex-row">
                  <li className="nav-item">
                    {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                    <TriggerResize>
                      <ToggleState state="aside-collapsed">
                        <a
                          href=""
                          className="nav-link d-none d-md-block d-lg-block d-xl-block icon-header"
                        >
                          <em className="fas fa-bars"></em>
                        </a>
                      </ToggleState>
                    </TriggerResize>
                    {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                    <ToggleState state="aside-toggled" nopersist={true}>
                      <a
                        href=""
                        className="nav-link sidebar-toggle d-md-none icon-header"
                      >
                        <em className="fas fa-bars"></em>
                      </a>
                    </ToggleState>
                  </li>
                </ul>
                {/* FIN Barra de navegación izquierda */}

                {/* Menu de encabezado alineado a la derecha */}
                <ul className="navbar-nav flex-row">
                  {/* Pagína de inicio */}
                  <li className="nav-item" title="Pagína de inicio.">
                    <Link
                      to="/home"
                      path="/home"
                      className="nav-link icon-header"
                    >
                      <em className="icon-home" />
                    </Link>
                  </li>

                  {/* Aplicaciones */}
                  <li
                    className="nav-item d-md-block moreapp"
                    title="Mis aplicaciones."
                  >
                    <a
                      className="nav-link pointer moreapp icon-header"
                      onClick={() => this.handleDropDownApp(!dropdownapp)}
                    >
                      {/* <em className="icon-grid moreapp" /> */}
                      <i className="fas fa-th"></i>
                    </a>
                    {dropdownapp === false ? null : (
                      <AppShortcuts
                        handleCloseComponent={this.handleDropDownApp}
                      />
                    )}
                  </li>

                  {/* Notificaciones */}
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    className="dropdown-list"
                    title="Notificaciones."
                  >
                    <DropdownToggle nav className="dropdown-toggle-nocaret">
                      <em className="icon-bell" />
                      <span className="badge badge-danger">{count}</span>
                    </DropdownToggle>
                    {/* START Dropdown menu */}
                    <DropdownMenu
                      right
                      className="dropdown-menu-right animated flipInX"
                    >
                      <DropdownItem>
                        {/* START list group */}
                        <ListGroup>
                          {array.length === 0 ? (
                            <ListGroupItem>
                              {" "}
                              <h4>No tiene notificaciones nuevas</h4>{" "}
                            </ListGroupItem>
                          ) : (
                            array.map((x, i) => (
                              <ListGroupItem key={i + 1}>
                                {x.link === "" ? (
                                  <NavInternal props={x} />
                                ) : (
                                  <NavExternal props={x} />
                                )}
                              </ListGroupItem>
                            ))
                          )}
                          <ListGroupItem
                            href=""
                            onClick={e => e.preventDefault()}
                          >
                            <Link
                              to="/notification"
                              path="/notification"
                              className="nav-link icon-header"
                            >
                              <span className="d-flex align-items-center">
                                <span className="text-sm">
                                  Mostrar todas las notificaciones
                                </span>
                              </span>
                            </Link>
                          </ListGroupItem>
                        </ListGroup>
                        {/* END list group */}
                      </DropdownItem>
                    </DropdownMenu>
                    {/* END Dropdown menu */}
                  </UncontrolledDropdown>

                  {/* Ver perfil */}
                  <li className="nav-item d-md-block" title="Perfil.">
                    <Link
                      to="/profile"
                      path="/profile"
                      className="nav-link icon-header"
                    >
                      <em className="icon-user" />
                    </Link>
                  </li>

                  {/* Actualizar menu */}
                  <li className="nav-item d-md-block" title="Actualizar menú.">
                    <a
                      className="nav-link pointer icon-header"
                      onClick={this.handleRefreshMenu}
                    >
                      <em className="icon-refresh" />
                    </a>
                  </li>

                  {/* Configuracón de menú */}
                  <li className="nav-item" title="Configuración web.">
                    <ToggleState state="offsidebar-open" nopersist={true}>
                      <a className="nav-link icon-header" href="">
                        <em className="icon-settings" />
                      </a>
                    </ToggleState>
                  </li>

                  {/* Cerrar sesión */}
                  <li className="nav-item d-md-block" title="Cerrar sesión.">
                    <a
                      id="navigation_logout"
                      className="nav-link pointer icon-header"
                      onClick={props.logout}
                    >
                      <em className="icon-logout" />
                    </a>
                  </li>
                </ul>
              </nav>
              <Offsidebar />
            </header>
          );
        }}
      </AuthenticationContext.Consumer>
    );
  }
}

export default Header;
