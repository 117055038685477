///Mensages.
export const MESSAGE_SUCCESS = 'Acción ejecutada correctamente.';
export const MESSAGE_WARNING = 'La acción se realizo con problemas.';
export const MESSAGE_ERROR = 'La acción presento problema en su proceso.Intentelo nuevamente y si su problema persiste pongase en contacto con el administrador.';
export const MESSAGE_REQUIRED = 'Ingrese los campos requeridos.';
export const MESSAGE_REMOVE_EMPTY = "Para eliminar un registro ingrese el nombre solicitado.";
export const MESSAGE_REMOVE_DIFFERENT = "El texto ingresado no concuerda con el texto requerido, verifique el valor ingresado e intente nuevamente.";
export const INCORRECT_FORMAT = "El formato es incorrecto.";
export const MESSAGE_DELETE_CONFLICT = "El elemento a remover no se puede eliminar ya que es el creador de este registro.";
export const FORMAT_SQLINJECT = "El valor ingresado contiene caracteres inválidos";
export const MAX_LENGTH = "La longitud debe ser menor o igual a la cantidad de caracteres establecido.";
export const MIN_LENGTH_NUMBER = "El valor a ingresar debe ser mínimo o mayor a 1.";
export const FORMAT_URL = "El valor ingresado no corresponde a una Url valida";

///Mensages servicio.
export const SERVICE_ERROR_RESPONSE = 'Error en la respuesta del servicio. Intentelo nuevamente y si su problema persiste coloquese en contacto con el administrador.';
export const SERVICE_BAD_GATEWAY = 'La solicitud se realizo, pero un segundo proceso no se realizo correctamente.';
export const SERVICE_EMPTY = 'Estas credenciales no se encuentran en el sistema.';
export const SERVICE_EXISTS = 'Estas credenciales ya se encuentran en el sistema.';
export const SERVICE_CHECK_EMAIL = 'Verifica el correo electronico anteriormente ingresado.';
export const SERVICE_INVALID_DATA = 'La información solicitada no es valida.';
export const SERVICE_INVALID_TOKEN = 'El token de acceso no es valido.';
export const SERVICE_PRIVILEGES_TOKEN = 'El usuario no tiene permisos para ejecutar esta operación.';
export const SERVICE_SUCCESS_EMPTY = 'Acción ejecutada correctamente.';
export const SERVICE_ERROR_CONSTRAINT = 'Este registro no se ha podido eliminar. Recuerde que los valores guardados en configuración son manejados internamente.';


///Mensage de notificacion email.
export const TITLE_NOTIFY = 'Registro exitoso';
export const TITLE_NOTIFY_UPDATE_PASSWORD = 'Solicitud exitosa';
export const NOTIFY_SUCCESS_FORGOTPASSWORD = 'Revise su correo electronico para validar su cuenta.';

///Titulo de notificaciones.
export const TITLE_NOTIFY_SUCCESS = 'Consultar su correo electrónico';

///Titulo de mensage.
export const TITLE_SUCCESS = 'Exito';
export const TITLE_WARNING = 'Advertencia';
export const TITLE_ERROR = 'Error';

///Tipo de mensages.
export const TYPE_REGISTER = 'Registro';
export const TYPE_DELETE = 'Elimino';
export const TYPE_EDIT = 'Modifico';

///Color de mensage.
export const COLOUR_PRIMARY = 'primary';
export const COLOUR_SUCCESS = 'success';
export const COLOUR_DANGER = 'danger';
export const COLOUR_WARNING = 'warning';
export const COLOUR_INFO = 'info';
export const COLOUR_ERROR = 'error';
