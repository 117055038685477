import React, { Component } from "react";
import { Card, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import TabsApp from "./TabContent/TabContentApp";
import TabsPackage from "./TabContent/TabContentPackage";

export default class SubscriptionTabsAssignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "app"
    };
  }

  handleToggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { activeTab } = this.state;
    const {
      data,
      handleLoadSubscription,
      handleReloadGrid,
      filter,
      handleOnSearch,
      nameSearch,
      subscription,
      handleOpenModalAssign,
      client,
      apps
    } = this.props;
    return (
      <Card>
        <div role="tabpanel">
          <Nav tabs justified>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={this.state.activeTab === "app" ? "active" : ""}
                onClick={() => {
                  this.handleToggleTab("app");
                }}
              >
                Aplicaciones
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={this.state.activeTab === "package" ? "active" : ""}
                onClick={() => {
                  this.handleToggleTab("package");
                }}
              >
                Paquetes
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={this.state.activeTab}
            style={{ borderStyle: "hidden" }}
          >
            <TabPane tabId="app" role="tabpanel">
              {activeTab !== "app" ? null : (
                <TabsApp
                  data={data}
                  filter={filter}
                  client={client}
                  nameSearch={nameSearch}
                  handleLoadSubscription={handleLoadSubscription}
                  handleReloadGrid={handleReloadGrid}
                  handleOnSearch={handleOnSearch}
                />
              )}
            </TabPane>
            <TabPane tabId="package" role="tabpanel">
              {activeTab !== "package" ? null : (
                <TabsPackage
                  client={client}
                  apps={apps}
                  subscription={subscription}
                  handleReloadGrid={handleReloadGrid}
                  handleLoadSubscription={handleLoadSubscription}
                  handleOpenModalAssign={handleOpenModalAssign}
                />
              )}
            </TabPane>
          </TabContent>
        </div>
      </Card>
    );
  }
}
