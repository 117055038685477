import React from "react";
import ContentWrapper from "../Layout/ContentWrapper";

///Componentes.
import ApplicationDatagrid from "./ApplicationDatagrid";

class SidebarApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadDataGrid: false
    };
  }

  //#region Funciones
  ///Permitir que el data grid pueda actualizarse.
  loadDataGrid = () =>
    this.setState({ loadDataGrid: !this.state.loadDataGrid });
  //#endregion

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {" "}
            Aplicaciones
            <small>Configuración de aplicaciones.</small>
          </div>
        </div>
        <div className="row">
          <ApplicationDatagrid
            loadDataGrid={this.loadDataGrid}
            stateLoadDataGrid={this.state.loadDataGrid}
            handleEdit={this.handleEdit}
          />
        </div>
      </ContentWrapper>
    );
  }
}

export default SidebarApplication;
