import React, { Component } from "react";
import { Collapse } from "reactstrap";

import BillingCycleForm from './BillingCycleFormVertical';

export default class BillingCycleSidebar extends Component {
  render() {
    const { isEdit, handleChangeData, dataEdit, handleEdit } = this.props;
    return (
      <Collapse className="mb-boxes" isOpen={true}>
        <div className="card card-default">
          <div className="card-body">
            <ul className="nav nav-pills flex-column">
              {isEdit === false ? (
                <li className="nav-item p-2">REGISTRO DE TIPOS DE FACTURACIÓN</li>
              ) : (
                <li className="nav-item p-2">ACTUALIZACIÓN DE TIPOS DE FACTURACIÓN</li>
              )}
            </ul>
            <BillingCycleForm handleChangeData={handleChangeData} dataEdit={dataEdit} handleEdit={handleEdit} isEdit={isEdit} />
          </div>
        </div>
      </Collapse>
    );
  }
}
