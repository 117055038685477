import { DecryptAESPlainText } from "../Extras/Encrypt";

const nameConfigStandarService = "confStandar_service";

const renderRouteService = () => {
  const data = window.sessionStorage.getItem(nameConfigStandarService);
  if (data === null) return "https://core.api.v1.sinai.net.co/"; //https://core.api.v1.sinai.net.co/ - https://localhost:44326/

  return DecryptAESPlainText(data);
}

export const routeServiceBase = renderRouteService();

///Ruta base local servicio.
//export const routeServiceBase = `https://localhost:44326/`;
export const routeServiceIntern = `${window.location.origin}/`;// `https://localhost:44329/`;

///Ruta base servidor siempre server
//export const routeServiceBase = `https://core.api.v1.siempre.net.co/`;
// export const routeServiceIntern = `https://core.siempre.net.co/`;

///Ruta base Stoneone
//export const routeServiceBase = `https://apicore.sinai.net.co/`;
//export const routeServiceIntern = `https://webcore.sinai.net.co/`;


//#region Servicio core
///Controller countries
export const listCountries = routeServiceBase + `api/country/GetAllAsync`;

///Controller genre.
export const listGenre = routeServiceBase + `api/genre/Get`;

///Controller city.
export const listCitieId = routeServiceBase + `api/city/CitiesFinByIdCountry/`;

///Controller user.
export const createUser = routeServiceBase + `v1/user/post`;
export const forgotPassword = routeServiceBase + `v1/user/forgotpassword`;
export const resetPassword = routeServiceBase + `v1/user/resetpassword`;
export const sendemail = routeServiceBase + `v1/user/sendemail`;
export const getfindbyemail = routeServiceBase + `v1/user/findbyemail`;
export const putuser = routeServiceBase + `v1/user/put`;
export const getcountsingleview = routeServiceBase + `v1/user/asyncsingleviewuser`;
export const profileuser = routeServiceBase + `v1/user/profile`;
export const activateaccount = routeServiceBase + `v1/user/activateaccount`;
export const getTokenAccessAsync = routeServiceBase + `v1/user/permissionasync`;
export const getMenuAsync = routeServiceBase + `v1/user/menuasync`;
export const resendAccess = routeServiceBase + `v1/user/resendaccess`;
export const changeEmailOrPhone = routeServiceBase + `v1/user/ChangeEmailOrPhone`;
export const sendUserVerification = routeServiceBase + `v1/user/SendUserVerification`;
export const getUserVerification = routeServiceBase + `v1/user/GetUserVerification`;
export const verifyEmail = routeServiceBase + `v1/user/VerifyEmail`;

///Controller security.
export const validateCredential = routeServiceBase + `api/security/post`;

///Cotroller role.
export const roleList = routeServiceBase + `api/role/get`;
export const createRole = routeServiceBase + `api/role/post`;
export const deleteRole = routeServiceBase + `api/role/delete`;

///Controller main menu
export const getAllMainMenu = routeServiceBase + `v1/mainmenu/GetAll`;
export const postMainMenu = routeServiceBase + `v1/mainmenu/post`;
export const menuUser = routeServiceBase + `v1/mainmenu/MainMenuUser`;

///Controller menu
export const getIdMenu = routeServiceBase + `api/menu/GetId/`;
export const getAllMenu = routeServiceBase + `api/menu/Get/`;
export const postMenu = routeServiceBase + `api/menu/post`;
export const putMenu = routeServiceBase + `api/menu/put`;
export const deleteMenu = routeServiceBase + `api/menu/delete`;

///Controller menu role.
export const postMenuRole = routeServiceBase + `v1/menurole/post`;

///Controller role permission.
export const postRolePermission = routeServiceBase + `v1/rolepermission/post`;

///Controller icon
export const getAllIcon = routeServiceBase + `api/icon/get`;
export const putavataruser = routeServiceBase + `api/icon/put`;
export const geticonuser = routeServiceBase + `api/icon/findbyuser`;

///Controller permission.
export const getPermission = routeServiceBase + `api/permission/get`;
export const filterPermission = routeServiceBase + `api/permission/filter`;
export const postPermission = routeServiceBase + `api/permission/post`;
export const putPermission = routeServiceBase + `api/permission/put`;
export const deletePermission = routeServiceBase + `api/permission/delete`;

///Controller category.
export const getCategory = routeServiceBase + `api/category/get`;

///Controller applications.
export const getApplication = routeServiceBase + `v1/application/get`;
export const postApplication = routeServiceBase + `v1/application/post`;
export const putApplication = routeServiceBase + `v1/application/put`;
export const removeApplication = routeServiceBase + `v1/application/delete`;
export const editApplication = routeServiceBase + `v1/application/edit`;
export const getappfindbysubscription =
  routeServiceBase + `v1/application/findbysubscription`;
export const getFindByName = routeServiceBase + `v1/application/findbyname`;
export const getownerasync = routeServiceBase + `v1/application/appownerasync`;
export const getthirasync = routeServiceBase + `v1/application/appthirasync`;
export const postClient = routeServiceBase + `v1/application/clientpost`;
export const getClient = routeServiceBase + `v1/application/clientget`;
export const putClient = routeServiceBase + `v1/application/clientput`;
export const getClientId = routeServiceBase + `v1/application/clientid`;
export const getCodeConsecutive = routeServiceBase + `v1/application/consecutiveasync`;

///Controller details applicacion package.
export const getapppackages = routeServiceBase + `v1/applicationpackage/get`;
export const postapplicationpackage =
  routeServiceBase + `v1/applicationpackage/post`;
export const getapplicationpackage =
  routeServiceBase + `v1/applicationpackage/getid`;
export const applicationfinbypackage =
  routeServiceBase + `v1/applicationpackage/findbypackage`;
export const removeapplicationpackage =
  routeServiceBase + `v1/applicationpackage/delete`;

///Controller application type.
export const getApplicationType = routeServiceBase + `v1/applicationtype/get`;

///Controller package type
export const getpackagetype = routeServiceBase + `v1/packagetype/get`;

///Controller package.
export const postpackage = routeServiceBase + `v1/package/post`;
export const getpackages = routeServiceBase + `v1/package/get`;
export const updatepackage = routeServiceBase + `v1/package/put`;
export const editpackage = routeServiceBase + `v1/package/edit`;
export const deletePackage = routeServiceBase + `v1/package/delete`;

///Controller document type. 
export const getdocumenttype = routeServiceBase + `v1/documenttype/get`;

///Controller client type.
export const getclienttype = routeServiceBase + `v1/clienttype/get`;

///Controller client.
export const postclient = routeServiceBase + `v1/client/post`;
export const putclient = routeServiceBase + `v1/client/put`;
export const getclient = routeServiceBase + `v1/client/get`;
export const getclietbysubscription =
  routeServiceBase + `v1/client/findbysubscription`;
export const getfilterid = routeServiceBase + `v1/client/filterid`;
export const getclientidcode = routeServiceBase + `v1/client/getid`;
export const putImg = routeServiceBase + `v1/client/uploadavatar`;

///Controller client user
export const postuserclient = routeServiceBase + `v1/userclient/post`;
export const getuserclient = routeServiceBase + `v1/userclient/getid`;
export const getuserstandarclient = routeServiceBase + `v1/userclient/userstandar`;
export const deleteusercliet = routeServiceBase + `v1/userclient/delete`;
export const getasign = routeServiceBase + `v1/userclient/asign`;

///Controller type bylling cycle
export const getbilllingcycle = routeServiceBase + `v1/typebillingcycle/get`;

///Controller measure units
export const getmeasure = routeServiceBase + `v1/measuredunit/get`;
export const postmeasuredunits = routeServiceBase + `v1/measuredunit/post`;
export const putmeasuredunits = routeServiceBase + `v1/measuredunit/put`;
export const deletemeasurement = routeServiceBase + `v1/measuredunit/delete`;

///Controller billing cycle
export const postbillingcycle = routeServiceBase + `v1/billingcycle/post`;
export const putbillingcycle = routeServiceBase + `v1/billingcycle/put`;
export const getbilling = routeServiceBase + `v1/billingcycle/get`;
export const deletebilling = routeServiceBase + `v1/billingcycle/delete`;

///Controller application billing
export const postappbilling =
  routeServiceBase + `v1/applicationbillingcycle/post`;
export const getidappbilling =
  routeServiceBase + `v1/applicationbillingcycle/getid`;
export const deleteappbilling =
  routeServiceBase + `v1/applicationbillingcycle/delete`;
export const getbillingbyapp =
  routeServiceBase + `v1/applicationbillingcycle/findbyapp`;

///Controller subscription
export const postsubscription = routeServiceBase + `v1/subscription/post`;
export const getsubscriptionid = routeServiceBase + `v1/subscription/getid`;
export const putsubscription = routeServiceBase + `v1/subscription/put`;

///Controller subscription app
export const postsubscriptionapp = routeServiceBase + `v1/subscriptionapplication/post`;
export const postlsubscriptionapp = routeServiceBase + `v1/subscriptionapplication/postjson`;
export const getsubscriptionapp = routeServiceBase + `v1/subscriptionapplication/findbysubsapp`;
export const putsubscriptionapp =
  routeServiceBase + `v1/subscriptionapplication/put`;
export const getallappfiltersubs = routeServiceBase + `v1/subscriptionapplication/get`;
export const deletesubscriptionapp = routeServiceBase + `v1/subscriptionapplication/delete`;

///Controller subscription client
export const getsubscriptionclient = routeServiceBase + `v1/subscriptionclient/getid`;

///Controller user subscription
export const postsubscriptionuser = routeServiceBase + `v1/usersubscription/post`;
export const deletesubscriptionuser = routeServiceBase + `v1/usersubscription/delete`;
export const getidsubscriptionuser = routeServiceBase + `v1/usersubscription/getid`;
export const getstandarfindbyclient = routeServiceBase + `v1/usersubscription/standarfindbyclient`;
export const deletstandar = routeServiceBase + `v1/usersubscription/delete`;
export const postAssign = routeServiceBase + `v1/usersubscription/asign`;

///Controller carousel
export const postCarousel = routeServiceBase + `v1/carousel/post`;
export const getCarousel = routeServiceBase + `v1/carousel/get`;
export const deleteCarousel = routeServiceBase + `v1/carousel/delete`;
export const getAppCarousel = routeServiceBase + `v1/carousel/getbyapp`;
export const getIdCarousel = routeServiceBase + `v1/carousel/getid`;

///Controller notification
export const getNotification = routeServiceBase + `v1/notification/get`;
export const getTopNotification = routeServiceBase + `v1/notification/gettop`;
export const deleteNotification = routeServiceBase + `v1/notification/delete`;

//Controller Identity
export const getClientAndResource = routeServiceBase + `api/identity/GetClientAndResource`;

//#endregion

//#region Servicio interno
export const loginPost = `v1/login/post`;
export const authenticatedMenu = `v1/login/menu`;
export const logout = `v1/login/Logout`;
export const updatecookie = `v1/login/updatecookie`;

export const clientuser = routeServiceIntern + `v1/client/get`;

export const isvalid = `v1/user/isvalid`;
export const getavataruser = `v1/user/getavatar`;
export const postavatar = `v1/user/postavatar`;
export const credentials = `v1/user/getcookie`;

export const getsubcriptionusers = `v1/subscription/get`;
export const authenticated = `v1/authenticated/authenticated`;
export const validatepassword = `v1/authenticated/validateupdatepassword`;
export const userprofile = `v1/authenticated/profile`;
export const updateprofile = `v1/authenticated/updateprofile`;
export const getConfig = `v1/authenticated/getconfig`;
//#endregion
