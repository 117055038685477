import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import Select from "react-select";
import Slider from "rc-slider";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroup,
  FormGroup
} from "reactstrap";

import "rc-slider/assets/index.css";

import { getBillingByApp } from "../../../../services/Api/AppBilling";
import { postSubsApp } from "../../../../services/Api/Subscription";

import "../../docs/Subscription.css";
import { showValidation } from "../../../Validation/ShowValidation";
import { COLOUR_WARNING } from "../../../Message/Message";
import { LimitReadMore } from "../../../../Global";

export default class BillingApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description1: "",
      description2: "",
      limit: 236,
      databilling: [],
      app: 0,
      formbillingapp: {
        billing: null,
        unit: "",
        cost: 0,
        factor: 0,
        description: "",
        factor2: 0,
        price: 0
      }
    };
  }

  componentDidMount(){

  }

  componentDidUpdate() {
    if (this.props.style) {
      this.props.handleChangeStyle(this.props.data);
      this.props.hadleApplyStyle(true);

      let element = document.getElementById("billingapp");
      element.classList.add("show-billingapp");
      element.classList.remove("hide-billingapp");
    }

    if (this.props.data !== null) {
      if (this.props.data.code !== this.state.app) {
        this.handleBillingApp();
      }
    }

    if (this.props.reset) {
      this.props.handleResetBillingApp();
      this.setState({
        formbillingapp: {
          ...this.state.formbillingapp,
          billing: null,
          unit: "",
          cost: 0,
          factor: "0",
          description: "",
          factor2: 0,
          price: 0
        }
      });
    }
  }

  handleBillingApp = () => {
    const code = this.props.data.code;
    let response = getBillingByApp(code);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.response.forEach(element => {
            let obj = {
              cost: element.cost,
              description: element.description,
              value: element.idBillingCycle,
              label: element.nameBillingCycle,
              unit: element.nameMeasuredUnit,
              factor: element.factor
            };
            array.push(obj);
          });
          this.setState({ databilling: array, app: this.props.data.code });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeStyle = () => {
    this.props.hadleApplyStyle(false);

    let element = document.getElementById("billingapp");
    element.classList.remove("show-billingapp");
    element.classList.add("hide-billingapp");

    this.setState({
      formbillingapp: {
        ...this.state.formbillingapp,
        billing: null,
        unit: "",
        cost: 0,
        factor: "0",
        description: "",
        factor2: 0,
        price: 0
      }
    });
  };

  handleToggle = () => {
    if (this.props.data.description.lenght === this.state.limit) {
      this.setState({ limit: LimitReadMore });
    } else {
      this.setState({ limit: this.props.data.description.lenght });
    }
  };

  handleChangeSelect = event => {
    this.setState({
      formbillingapp: {
        ...this.state.formbillingapp,
        billing: { label: event.label, value: event.value },
        unit: event.unit,
        cost: event.cost,
        factor: event.factor,
        description: event.description,
        factor2: event.factor,
        price: parseInt(event.factor) * parseInt(event.cost)
      }
    });
  };

  handleValidation = () => {
    if (this.state.formbillingapp.billing === null) {
      notify(
        "Antes de comprar esta aplicación asegúrese de seleccionar un tipo de facturación.",
        COLOUR_WARNING,
        5000
      );
    } else {
      this.handleAssignAppSubs();
    }
  };

  handleAssignAppSubs = () => {
    const data = this.state.formbillingapp;
    const dataForm = new FormData();
    dataForm.append("amount", data.factor2);
    dataForm.append("price", data.cost);
    dataForm.append("subscription", this.props.datasubscription.code);
    dataForm.append("app", this.props.data.code);
    dataForm.append("measuredunit", data.billing.value);
    dataForm.append("client", this.props.client.value);

    const promise = postSubsApp(dataForm);
    promise.then(
      result => {
        if (result.success) {
          showValidation(result, 1, "aplicación");
          this.handleChangeStyle();
          this.props.handleAppFilter();
          this.props.handleLoadSubscription();
          this.props.handleReloadGrid();
        } else {
          const param = this.props;
          notify(
            "La aplicación " +
              param.data.name +
              " ya se encuentra asociada a la empresa " +
              param.client.label +
              ".",
            COLOUR_WARNING,
            5000
          );
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleSliderLog = value => {
    const formula = this.state.formbillingapp.cost * value;
    this.setState({
      formbillingapp: {
        ...this.state.formbillingapp,
        factor2: value,
        price: formula
      }
    });
  };

  render() {
    const { data, datasubscription, stateapp } = this.props;
    const {
      billing,
      unit,
      cost,
      factor,
      description,
      factor2,
      price
    } = this.state.formbillingapp;
    const { limit, databilling } = this.state;
    return (
      <Card className="hide-billingapp" id="billingapp">
        <CardHeader className="row">
          <div className="col-md-1">
            <Button
              type="button"
              className="bg-primary"
              onClick={this.handleChangeStyle}
              style={{ height: "80px", width: "43px" }}
              title="Volver"
            >
              <i className="fas fa-arrow-alt-circle-left size-20" />
            </Button>
          </div>
          <div className="col-md-2" style={{ padding: "initial" }}>
            <img
              className="img-thumbnail thumb80"
              src={data !== null ? data.route : "img/dummy.png"}
              alt="Avatar"
            />
          </div>
          <div className="col-md-9" style={{ padding: "initial" }}>
            <div className="row d-flex">
              <strong>Nombre:&nbsp;</strong>
              <label>{data !== null ? data.name : "No registra"}</label>
              {stateapp === false ? (
                <Button
                  className="bg-primary ml-auto"
                  onClick={this.handleValidation}
                >
                  Asignar
                </Button>
              ) : (
                <Button className="bg-primary ml-auto" disabled={true}>
                  Asignada
                </Button>
              )}
            </div>
            <div className="row">
              <p>
                <strong>Página web:&nbsp;</strong>
                <a
                  href={data != null ? data.url : "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fas fa-external-link-alt"
                    title="Link de pagina web"
                  />
                </a>
              </p>
            </div>
            <div className="row">
              <p>
                <strong>Descripción:&nbsp;</strong>
                {data != null ? data.description.substring(0, limit) : ""}{" "}
                <br />
                {data === null ?null: data.description.length < limit ? null : (
                  <span className="more" onClick={this.handleToggle}>
                    {data != null
                      ? data.description.length > limit
                        ? "...Leer mas."
                        : "...Leer menos."
                      : ""}
                  </span>
                )}{" "}
              </p>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <h3>Facturación de aplicación</h3>
          </div>
          <form name="formbillingapp" autoComplete="off">
            <div className="row">
              <FormGroup className="col-md-5">
                <div className="col-md-12">
                  <label>Tipo de facturación</label>
                  <InputGroup style={{ zIndex: "2" }}>
                    <Select
                      name="billing"
                      options={databilling}
                      className="width-select"
                      placeholder="Facturaciones por aplicación"
                      value={billing}
                      onChange={this.handleChangeSelect}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-12">
                  <label>Cantidad</label>
                  <Slider
                    onChange={this.handleSliderLog}
                    min={1}
                    max={parseInt(factor)}
                    value={parseInt(factor2)}
                    defaultValue={0}
                  />
                  <br />
                  <strong>Cantidad ingresada: </strong>
                  <label>{factor2}</label>
                </div>
                <div className="col-md-12">
                  <strong>Valor aplicación: </strong>
                  <label>${new Intl.NumberFormat().format(price)}</label>
                </div>
              </FormGroup>
              <FormGroup className="col-md-7">
                <div className="col-md-12">
                  <strong>Tipo de unidad: </strong>
                  <label>{unit}</label>
                </div>
                <div className="col-md-12">
                  <strong>Tope maximo: </strong>
                  <label>{factor}</label>
                </div>
                <div className="col-md-12">
                  <strong>Valor por unidad: </strong>
                  <label>${new Intl.NumberFormat().format(cost)}</label>
                </div>
                <div className="col-md-12">
                  <strong>Valor estipulado de la suscripción: </strong>
                  <label>
                    $
                    {datasubscription != null
                      ? new Intl.NumberFormat().format(
                          parseInt(datasubscription.PriceSubscription) +
                            parseInt(price)
                        )
                      : null}
                  </label>
                </div>
                <div className="col-md-12">
                  <strong>Este precio de la aplicación se basa en: </strong>
                  <p>{description}</p>
                </div>
              </FormGroup>
            </div>
          </form>
        </CardBody>
      </Card>
    );
  }
}
