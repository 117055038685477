import React from "react";
import SidebarPackage from "./SidebarPackage";

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: false
    };
  }

  handleIsUpdate = state => this.setState({ isUpdate: state });

  render() {
    return (
      <div>
        <SidebarPackage />
      </div>
    );
  }
}

export default Package;
