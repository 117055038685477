import React, { useContext, Fragment } from 'react';
import AppProvider, { AppContext } from "./docs/AppContext";


import SidebarApplication from './SidebarApplication';
import IdentityList from "./Identity/IdentityList";
import IdentityForm from "./Identity/IdentityForm";

const Application = () => {
    const context = useContext(AppContext);
    const showApp = !context.data.showIdentity && !context.data.showFormIdentity ? true : false;
    const showListIdentity = context.data.showIdentity && !context.data.showFormIdentity ? true : false;
    const showFormIdentity = !context.data.showIdentity && context.data.showFormIdentity ? true : false;

    return (
        <Fragment>
            {showApp && (
                <SidebarApplication />
            )}
            {showListIdentity && (
                <IdentityList />
            )}
            {showFormIdentity && (
                <IdentityForm />
            )}
        </Fragment>
    )
}

export default function ComponentContext() {
    return (
        <AppProvider>
            <Application />
        </AppProvider>
    )
};