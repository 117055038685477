import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Input } from "reactstrap";

import { getNotifications } from "../../services/Api/Notification";
import { Meses } from "../../services/Month";
import NotificationOption from "./NotificationOption";
import "./docs/Notification.css";

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameCheked: "checked_",
      checked_: false,
      checked_all: false,
      checked_array: [],
      checked_let: [],
      notify_array: [],
      notify_select_old: ""
    };
  }

  componentDidMount() {
    this.handleNotifications();
  }

  handleNotifications = () => {
    getNotifications()
      .then(response => {
        if (response.success) {
          this.handleEnumerableNotify(response.response);
          this.handleDeclareCheck();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  ///Lista las notificaciones
  handleEnumerableNotify = notifications => {
    const array = [];
    notifications.forEach(x => {
      const obj = {
        identitier: x.idNotification,
        reviewed: x.reviewed,
        subject: x.subJect === null ? "" : x.subJect,
        from: x.from === null ? "" : x.from,
        message: x.message === null ? "" : x.message,
        date:
          new Date(x.receivedDate).getDate() +
          " de " +
          Meses[new Date(x.receivedDate).getMonth()] +
          " de " +
          new Date(x.receivedDate).getFullYear() +
          " " +
          new Date(x.receivedDate).getHours() +
          ":" +
          new Date(x.receivedDate).getMinutes(),
        abbreviation: x.abbreviation === null ? "" : x.abbreviation,
        processName: x.processName === null ? "" : x.processName,
        link: x.link === null ? "" : x.link
      };
      array.push(obj);
    });
    this.setState({ notify_array: array });
  };

  ///Declarar variables del check
  handleDeclareCheck = () => {
    this.state.notify_array.forEach((x, i) => {
      let nameHolder = this.state.nameCheked + i;
      this.state.checked_let.push({
        [nameHolder]: false
      });
    });
  };

  ///Función que marca todos los check de la lista.
  handleChekedAll = bool => {
    this.setState({ checked_all: !this.state.checked_all });
    const nameCheked = this.state.nameCheked;
    ///Marcar o desmarcar los check
    this.state.checked_let.forEach(function(x, i) {
      x[nameCheked + i] = bool;
    });

    ///Asignar o eliminar los identificadores de las notificaciones
    this.state.notify_array.forEach(x => {
      this.handleIdentifiersAll(bool, x.identitier);
    });
  };

  ///Asigna o elimina los identificadores de las notificaciones a eliminar
  handleIdentifiersAll = (bool, param) => {
    let array = this.state.checked_array;
    let index = array.indexOf(param);
    if (bool) {
      if (array.indexOf(param) === -1) {
        array.push(param);
      }
    } else {
      array.splice(index, 1);
    }
    this.setState({ checked_array: array });
  };

  ///Agregar a un array los elementos a eliminar.
  handleChangeChecked = (param, position) => {
    ///Cambiar de valor el check seleccionado
    ///Buscar el check afectado
    let element = this.state.checked_let.find(function(x, i) {
      return i === position;
    });
    ///Cambiar de valor el check
    element[this.state.nameCheked + position] = !element[
      this.state.nameCheked + position
    ];

    ///Actualizar dom
    this.setState({ checked_let: this.state.checked_let });
    this.handleIdentifiers(param);
  };

  ///Asignar identificadores al array o eliminarlos
  handleIdentifiers = param => {
    const array = this.state.checked_array;
    if (array.indexOf(param) === -1) {
      array.push(param);
    } else {
      let index = array.indexOf(param);
      array.splice(index, 1);
    }
  };

  ///Eliminar notificaciones de la tabla
  handleRemoveElement = array => {
    if (array.length < this.state.notify_array.length) {
      array.forEach(x => {
        let element = this.state.notify_array.find(function(n) {
          return n.identitier === x;
        });
        let remove = this.state.notify_array.indexOf(element);
        this.state.notify_array.splice(remove, 1);
      });
    }
    this.setState({ notify_array: this.state.notify_array });
  };

  ///Mostrar mensaje seleccionado
  handleShowMessage = (obj, back) => {
    ///Marcar el mensaje seleccionado
    if (this.state.notify_select_old !== "") {
      document.getElementById(
        this.state.notify_select_old
      ).style.backgroundColor = "";
      document.getElementById(back).style.backgroundColor = "#e4e9fb59";
    } else {
      document.getElementById(back).style.backgroundColor = "#e4e9fb59";
    }
    this.setState({ notify_select_old: back });

    ///Emviar datos al componente de mensage
    this.props.handleViewMessage(obj);
  };

  render() {
    const { checked_let, checked_array, notify_array, nameCheked } = this.state;
    return (
      <div className="">
        <NotificationOption
          checked_array={checked_array}
          handleChekedAll={this.handleChekedAll}
          notify_array={notify_array.length}
          handleRemoveElement={this.handleRemoveElement}
        />
        <div className="card">
          <div className="card-body scroll-message">
            {/* p.lead.text-centerNo mails here */}
            <table className="table table-hover mb-mails height-100">
              <tbody>
                {notify_array.length === 0 ? (
                  <tr>
                    <td>
                      <h4 className="NotMessage">
                        <strong>No tiene notificaciones actualmente.</strong>
                      </h4>
                    </td>
                  </tr>
                ) : (
                  notify_array.map((x, i) => (
                    <tr key={i + 1} id={i} className="point height-0">
                      {/* Check de la notificación */}
                      <td>
                        <div className="checkbox c-checkbox">
                          <label>
                            <Input
                              type="checkbox"
                              checked={
                                checked_let[i] === undefined
                                  ? false
                                  : checked_let[i][nameCheked + i]
                              }
                              onChange={() =>
                                this.handleChangeChecked(x.identitier, i)
                              }
                            />
                            <span className="fa fa-check"></span>
                          </label>
                        </div>
                      </td>
                      {/* Verificar si el correo ya fue revisado */}
                      <td className="text-center" title="Revisado">
                        {x.reviewed === true ? (
                          <em className="fa-lg fa fa-star text-warning"></em>
                        ) : (
                          <em className="fa-lg far fa-star text-muted"></em>
                        )}
                      </td>
                      {/* Datos previos de la notificación */}
                      <td onClick={() => this.handleShowMessage(x, i)}>
                        <div className="d-flex">
                          <div className="rounded-circle img-thumbnail thumb48 colour-icon">
                            <div className="height-100">
                              <h4 className="position-icon with-icon39">
                                {x.abbreviation}
                              </h4>
                            </div>
                          </div>
                          <div className="mb-mail-meta">
                            <div className="mb-mail-subject">
                              <strong>Asunto: </strong>
                              {x.subject}
                            </div>
                            <div className="mb-mail-from">
                              <strong>De: </strong>
                              {x.from}
                            </div>
                            <div className="mb-mail-preview">
                              <strong>Mensaje: </strong>
                              {x.message.substring(0, 90) + "..."}
                            </div>
                          </div>
                          <div className="mb-mail-date ml-auto">{x.date}</div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(NotificationList);
