import React, { Component } from "react";
import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";

import { getBilling, deleteBilling } from "../../services/Api/BillingCycle";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING, MESSAGE_REMOVE_DIFFERENT } from "../Message/Message";
import SwalRemove from "../SweetAlert/SwalRemove";
import notify from "devextreme/ui/notify";
import ProgressAnimate from "../Progress/ProgressAnimate";

export default class BillingCycleDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: {
        isProgress: false,
      },
      datagrid: [],
      selectedRowKeys: [],
      remove: {
        openModal: false,
        element: "",
        elementRemove: ""
      }
    };
  }

  ///Cargar progress
  handleProgress = () => {
    this.setState({
      progress: {
        ...this.state.progress,
        isProgress: true
      }
    });
  };

  ///Restaurar progress
  handleResetProgress = () => {
    this.setState({
      progress: {
        ...this.state.progress,
        isProgress: false
      }
    });
  };

  componentDidUpdate() {
    if (this.props.isChangeData) {
      this.props.handleChangeData();
      this.handleLoadDatagrid();
    }
  }

  componentDidMount() {
    this.handleProgress();
    this.handleLoadDatagrid();
  }

  handleOnClickEdit = event => {
    const data = event.row.data;
    this.props.handleEdit(data);
    this.setState({ selectedRowKeys: [data.billingcycle.idbillingcycle] });
  };

  ///Eliminar registro.
  handleSendRemove = param => {
    this.props.handleChangeData();
    this.setState({ selectedRowKeys: [param] });
    let response = deleteBilling(param);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        this.props.handleChangeData();
        if (result.success) {
          this.handleLoadDatagrid();
          showValidation(result, 3, "facturación");
        } else {
          showValidation(result);
        }
        this.swalShow();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  //7Cargar datos
  handleLoadDatagrid = () => {
    let data = getBilling();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.response.forEach(element => {
            let obj = {
              billingcycle: { idbillingcycle: element.idBillingCycle },
              namebilling: element.nameBillingCycle,
              measurement: element.nameMeasuredUnit,
              description: element.description,
              factor: element.factor,
              measurementiden: element.idMeasuredUnit
            };
            array.push(obj);
          });
          ///Cargar datos y detener progress
          this.setState({ datagrid: array});
          this.handleResetProgress();
        } else {
          this.handleResetProgress();
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Abrir o cerrar modal de eliminar.
  swalShow = () =>
    this.setState({
      remove: {
        ...this.state.remove,
        openModal: !this.state.remove.openModal
      }
    });

  ///Validar eliminación de registro.
  handleRemove = (param1, param2, param3) => {
    if (param1 !== param2) {
      this.swalShow();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, 4000);
    } else {
      this.handleSendRemove(param3);
    }
  };

  ///Seleccionar datos a eliminar
  handleDelete = event => {
    let _this = this;
    let data = event.row.data;
    _this.setState({
      remove: {
        ..._this.state.remove,
        openModal: !_this.state.remove.openModal,
        element: data.namebilling,
        elementRemove: data.billingcycle.idbillingcycle
      }
    });
  };

  render() {
    const { datagrid, selectedRowKeys } = this.state;
    const { openModal, element, elementRemove } = this.state.remove;
    const { isProgress } = this.state.progress;
    return (
      <div className="card card-default">
        <div className="card-body">
          <DataGrid
            dataSource={datagrid}
            keyExpr={"billingcycle.idbillingcycle"}
            showBorders={true}
            hoverStateEnabled={true}
            selectedRowKeys={selectedRowKeys}
          >
            <SearchPanel
              visible={true}
              width={360}
              placeholder={"Filtrar tipo de faturación."}
            />
            <Column
              dataField={"namebilling"}
              caption={"Nombre"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"measurement"}
              caption={"Unidad de medida"}
              width={100}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"factor"}
              width={70}
              caption={"Factor"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"description"}
              caption={"Descripción"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              type={"buttons"}
              width={60}
              caption={"Acción"}
              dataField={"billingcycle"}
              buttons={[
                {
                  cssClass: "buttons-edit",
                  hint: "Editar",
                  icon: "edit",
                  onClick: this.handleOnClickEdit
                },
                {
                  cssClass: "buttons-delete",
                  hint: "Eliminar",
                  icon: "clear",
                  onClick: this.handleDelete
                }
              ]}
            />
          </DataGrid>
        </div>
        <SwalRemove
          openModal={openModal}
          element={element}
          elementRemove={elementRemove}
          swalShow={this.swalShow}
          handleRemove={this.handleRemove}
        />
        <ProgressAnimate isProgress={isProgress}  /> 
      </div>
    );
  }
}
