import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

class CarouselHeader extends Component {
  render() {
    return (
      <div className="content-heading">
        <div>
          {" "}
          Banner
          <small>En este modulo se configuran las imagenes que se mostraran en el slider del logín.</small>
        </div>
      </div>
    );
  }
}


export default withNamespaces("translations")(CarouselHeader);