import React, { Component } from "react";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import ImageUploader from "react-images-upload";
import "react-accessible-accordion/dist/fancy-example.css";

import { getclientid, putImgClient } from "../../services/Api/Client";
import { showValidation } from '../Validation/ShowValidation';

import "./docs/Enterprise.css";

export default class EnterpriseAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changedata: 0,
      img: "",
      type: null,
      socialreason: "",
      acronym: "",
      firstnameone: "",
      firstnametwo: "",
      lastnameone: "",
      lastnametwo: "",
      documenttype: null,
      documentnumber: "",
      email: "",
      country: null,
      city: null,
      direction: "",
      neighborhood: ""
    };
  }

  componentDidUpdate() {
    this.handleShowData();
  }

  handleShowData = () => {
    if (
      (this.state.changedata !== this.props.filter && this.props.filter > 0) ||
      this.props.isChangeDetail === true
    ) {
      this.props.handleChangeDetail();
      this.setState({ changedata: this.props.filter });
      let identifier = this.props.filter;
      let data = getclientid(identifier);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            this.handleData(result.objeto);
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleData = param => {
    this.setState({
      img: param.img,
      type: { label: param.nameClientType, value: param.idClientType },
      socialreason: param.socialReason === null ? "" : param.socialReason,
      acronym: param.acronym === null ? "" : param.acronym,
      firstnameone: param.nameOne === null ? "" : param.nameOne,
      firstnametwo: param.nameTwo === null ? "" : param.nameTwo,
      lastnameone: param.lastNameOne === null ? "" : param.lastNameOne,
      lastnametwo: param.lastNameTwo === null ? "" : param.lastNameTwo,
      documenttype: {
        label: param.nameDocumentType,
        value: param.idDocumentType
      },
      documentnumber: param.documentNumber === null ? "" : param.documentNumber,
      email: param.email === null ? "" : param.email,
      country: { label: param.nameCountry, value: param.idCountry },
      city: { label: param.nameCity, value: param.idCity },
      direction: param.direction === null ? "" : param.direction,
      neighborhood: param.subdivision === null ? "" : param.subdivision
    });
  };

  handleOnDrop = picture => {
    let client = this.props.filter;
    let img = [].concat(picture);
    let promise = putImgClient(img, client);
    promise.then(response=>{
        if(response.success){
          this.props.handleChangeDetail();
          showValidation(response, 2, 'imagen');
        }else{
          showValidation(response);
        }
    },function(error){
      console.log(error);
    });
  };

  render() {
    const {
      img,
      type,
      socialreason,
      acronym,
      firstnameone,
      firstnametwo,
      lastnameone,
      lastnametwo,
      documenttype,
      documentnumber,
      email,
      country,
      city,
      direction,
      neighborhood
    } = this.state;
    return (
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Información básica</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <form name="formEnterprise">
              <div className="form-row align-items-center float-right">
                <div className="py-4 contenedor">
                  {img === "" || img === null ? null : (
                    <>
                      <a className="container-icon pointer grow">
                        <img
                          className="img-fluid rounded-circle img-thumbnail thumb96 update-icon "
                          src={img}
                          alt="AvatarEnterprise"
                        />
                        <i className="camera fas fa-camera"></i>
                        {/* <Input type="file" src={img} className="img-fluid rounded-circle img-thumbnail thumb96 update-icon" /> */}
                        <ImageUploader
                        withIcon={true}
                        buttonText="Actualizar logo"
                        onChange={this.handleOnDrop}
                        label=""
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                      />
                      </a>
                    </>
                  )}
                </div>
              </div>
              <div className="form-row align-items-center">
                <div className="col-md-4">
                  <label>Tipo de empresa</label>
                  <FormGroup>
                    <Select
                      name="type"
                      placeholder="Tipo de empresa"
                      isDisabled={true}
                      value={type}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <label>Razón social</label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="socialreason"
                      placeholder="Razon social"
                      disabled={true}
                      value={socialreason}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <label>Siglas</label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="acronym"
                      placeholder="Sigla"
                      disabled={true}
                      value={acronym}
                    />
                  </FormGroup>
                </div>
              </div>
              {type === null ? null : type.value !== 1 ? null : (
                <div className="form-row align-items-center">
                  <div className="col-md-3">
                    <FormGroup>
                      <label>Primer nombre</label>
                      <Input
                        type="text"
                        name="firstnameone"
                        placeholder="Primer nombre"
                        disabled={true}
                        value={firstnameone}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <label>Segundo nombre</label>
                      <Input
                        type="text"
                        name="firstnametwo"
                        placeholder="Segundo nombre"
                        disabled={true}
                        value={firstnametwo}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <label>Primer apellido</label>
                      <Input
                        type="text"
                        name="lastnameone"
                        placeholder="Primer apellido"
                        disabled={true}
                        value={lastnameone}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-3">
                    <FormGroup>
                      <label>Segudo apellido</label>
                      <Input
                        type="text"
                        name="lastnametwo"
                        placeholder="Segundo apellido"
                        disabled={true}
                        value={lastnametwo}
                      />
                    </FormGroup>
                  </div>
                </div>
              )}
              <div className="form-row align-items-center">
                <div className="col-md-4">
                  <FormGroup>
                    <label>Tipo de documento</label>
                    <Select
                      name="documenttype"
                      placeholder="Tipo de documento"
                      isDisabled={true}
                      value={documenttype}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <label>Número de documento</label>
                    <Input
                      type="text"
                      name="documentnumber"
                      placeholder="Numero de documento"
                      disabled={true}
                      value={documentnumber}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <label>Correo electrónico</label>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Correo electrónico"
                      disabled={true}
                      value={email}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="form-row align-items-center">
                <div className="col-md-3">
                  <FormGroup>
                    <label>País de residencia</label>
                    <Select
                      name="country"
                      placeholder="País"
                      isDisabled={true}
                      value={country}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>Ciudad de residencia</label>
                    <Select
                      name="city"
                      placeholder="Ciudad"
                      isDisabled={true}
                      value={city}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>Dirección de residencia</label>
                    <Input
                      type="text"
                      name="direction"
                      placeholder="Dirección"
                      disabled={true}
                      value={direction}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>Barrio de residencia</label>
                    <Input
                      type="text"
                      name="neighborhood"
                      placeholder="Barrio"
                      disabled={true}
                      value={neighborhood}
                    />
                  </FormGroup>
                </div>
              </div>
            </form>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}