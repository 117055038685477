import notify from "devextreme/ui/notify";
import {
  SERVICE_ERROR_RESPONSE,
  SERVICE_BAD_GATEWAY,
  SERVICE_SUCCESS_EMPTY,
  SERVICE_PRIVILEGES_TOKEN,
  SERVICE_INVALID_DATA,
  SERVICE_EXISTS,
  SERVICE_INVALID_TOKEN,
  SERVICE_EMPTY,
  SERVICE_ERROR_CONSTRAINT,
  MESSAGE_REQUIRED,
  MESSAGE_SUCCESS,
  MESSAGE_DELETE_CONFLICT,
  COLOUR_ERROR,
  COLOUR_SUCCESS,
  COLOUR_WARNING,
  COLOUR_INFO
} from "../Message/Message";
import { Time900 } from '../../Global';

export function showValidation(request, action = 0, modul = "", message = "", colour="") {
  if(message !== ""){
    notify(message, colour, Time900);
  }else if (action !== 0) {
    const Message_Action = "La " + modul + " se " + TypeAction(action) + " correctamente.";
    notify(Message_Action, COLOUR_SUCCESS, Time900);
  } else {
    switch (request.state) {
      case 400:
        notify(MESSAGE_REQUIRED, COLOUR_WARNING, Time900);
        break;
      case 4012:
        notify(MESSAGE_DELETE_CONFLICT, COLOUR_INFO, Time900);
        break;
      case 422:
        notify(SERVICE_INVALID_DATA, COLOUR_WARNING, Time900);
        break;
      case 404:
        notify(SERVICE_EMPTY, COLOUR_WARNING, Time900);
        break;
      case 401:
        notify(SERVICE_INVALID_TOKEN, COLOUR_WARNING, Time900);
        break;
      case 403:
        notify(SERVICE_PRIVILEGES_TOKEN, COLOUR_WARNING, Time900);
        break;
      case 200:
        notify(MESSAGE_SUCCESS, COLOUR_SUCCESS, Time900);
        break;
      case 204:
        notify(SERVICE_SUCCESS_EMPTY, COLOUR_SUCCESS, Time900);
        break;
      case 409:
        notify(SERVICE_EXISTS, COLOUR_WARNING, Time900);
        break;
      case 500:
        notify(SERVICE_ERROR_RESPONSE, COLOUR_ERROR, Time900);
        break;
      case 502:
        notify(SERVICE_BAD_GATEWAY, COLOUR_WARNING, Time900);
        break;
      case 4010:
        notify(SERVICE_ERROR_CONSTRAINT, COLOUR_INFO, Time900);
        break;
      default:
        break;
    }
  }
}

const TypeAction = param => {
  switch (param) {
    case 1:
      return "guardo";
    case 2:
      return "actualizo";
    case 3:
      return "elimino";
    default:
      return 'acción';
  }
};
