import MethodGet from '../MethodGet';
import { getApplicationType } from '../../components/RouteApi/RouteApi';
import { headerGet } from '../Headers';
import { _JsonStorage } from '../../Global';

///Lista del tipo de aplicaciones.
export const GetApplicationType = () => {
    let header = headerGet( _JsonStorage.access_token);
    let response = MethodGet(getApplicationType, header)
    .then(response=>response)
    .catch(error=>error);

    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
}

export const getapplicationtypes = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(getApplicationType, header).then(response=>response).catch(error=>error);
}