import React, { Fragment, useState, useEffect } from 'react'

import { GrantTypes } from "../docs/data";
import { GenerateString } from "../../Extras/Encrypt";
import { PopoverItem } from "../../Element/Notifications";
import { hasError, hasErrorMessage } from "../../Extras/HasError";
import { FORMAT_SQLINJECT, MAX_LENGTH, MIN_LENGTH_NUMBER } from "../../Message/Message";
import { Form, FormText, Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Card, CardBody, CardFooter, Table, Alert } from "reactstrap";
import { allowAccessTokensViaBrowser, allowOfflineAccess, allowPlainTextPkce, clientSecrets, textRequirePkce, allowedGrantTypes, redirectUris, postLogoutUris, corsOrigins } from "./HelpsMessage";


import SelectOptions from "react-select";
import Scrollable from "./../../Common/Scrollable";
import FormValidator from "../../Validation/FormValidation";

import "../docs/Identity.css";

const ComponentListRedirect = (props) => {
  const { redirectUris } = props.formData;

  const removeItem = (item, props) => {

    let index = redirectUris.indexOf(item)
    if (index > -1) {

      redirectUris.splice(index, 1)
      props.setFormData(state => ({
        ...state,
        redirectUris
      }))

    }
  }

  return (
    <Fragment>
      {redirectUris.length > 0 && (
        <Table hover responsive>
          <thead>
            <tr>
              <th>URIs permitidas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {redirectUris.map((x, i) => (
              <tr key={i}>
                <td>{x}</td>
                <td className="d-flex justify-content-end">
                  <Button type="button" className="bg-danger btn-sm" onClick={() => removeItem(x, props)}>
                    <em className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {redirectUris.length === 0 && (
        <Alert color="secondary" className="text-center text-muted mt-2 bg-gray">
          No presenta rutas agregadas
        </Alert>
      )}
    </Fragment>
  )
}

const ComponentPostLogout = (props) => {
  const { postLogouts } = props.formData;

  const removeItem = (item, props) => {

    let index = postLogouts.indexOf(item)
    if (index > -1) {

      postLogouts.splice(index, 1)
      props.setFormData(state => ({
        ...state,
        postLogouts
      }))

    }
  }

  return (
    <Fragment>
      {postLogouts.length > 0 && (
        <Table hover responsive>
          <thead>
            <tr>
              <th>URIs permitidas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {postLogouts.map((x, i) => (
              <tr key={i}>
                <td>{x}</td>
                <td className="d-flex justify-content-end">
                  <Button type="button" className="bg-danger btn-sm" onClick={() => removeItem(x, props)}>
                    <em className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {postLogouts.length === 0 && (
        <Alert color="secondary" className="text-center text-muted mt-2 bg-gray">
          No presenta rutas agregadas
        </Alert>
      )}
    </Fragment>
  )
}

const ComponentCorsOrigins = (props) => {
  const { corsOrigins } = props.formData;

  const removeItem = (item, props) => {

    let index = corsOrigins.indexOf(item)
    if (index > -1) {

      corsOrigins.splice(index, 1)
      props.setFormData(state => ({
        ...state,
        corsOrigins
      }))

    }
  }

  return (
    <Fragment>
      {corsOrigins.length > 0 && (
        <Table hover responsive>
          <thead>
            <tr>
              <th>URIs permitidas</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {corsOrigins.map((x, i) => (
              <tr key={i}>
                <td>{x}</td>
                <td className="d-flex justify-content-end">
                  <Button type="button" className="bg-danger btn-sm" onClick={() => removeItem(x, props)}>
                    <em className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {corsOrigins.length === 0 && (
        <Alert color="secondary" className="text-center text-muted mt-2 bg-gray">
          No presenta rutas agregadas
        </Alert>
      )}
    </Fragment>
  )
}

const ComponentScopes = (props) => {
  const { scopes } = props.formData;

  const removeItem = (item, props) => {

    let index = scopes.indexOf(item)
    if (index > -1) {

      scopes.splice(index, 1)
      props.setFormData(state => ({
        ...state,
        scopes
      }))

    }
  }

  const heightList = scopes.length > 0 ? "120" : "50";
  return (
    <Fragment>
      <Scrollable className="list-group" height={heightList}>
        {scopes.map((x, i) => (
          <div className="list-group-item list-group-item-action" key={i}>
            <div className="media">
              <div className="media-body text-truncate">
                <p className="mb-1">
                  <strong className="text-primary">
                    <span>{x}</span>
                  </strong>
                </p>
              </div>
              <div className="ml-auto">
                <button type="button" className="btn btn-danger btn-xs" onClick={() => removeItem(x, props)}>
                  <em className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>
        ))}
        {scopes.length === 0 && (
          <strong className="text-center text-muted mt-2">
            <span>No presenta alcances agregados</span>
          </strong>
        )}
      </Scrollable>
    </Fragment>
  )
}

const ComponentProperties = (props) => {
  const { properties } = props.formData;
  const heightList = properties.length > 0 ? "120" : "50";
  return (
    <Fragment>
      <Scrollable className="list-group" height={heightList}>
        {properties.map((x, i) => (
          <div className="list-group-item list-group-item-action">
            <div className="media">
              <div className="media-body text-truncate">
                <p className="mb-1">
                  <strong className="text-primary">
                    <span>Catherine Ellis</span>
                  </strong>
                </p>
              </div>
              <div className="ml-auto">
                <button type="button" className="btn btn-danger btn-xs">
                  <em className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>
        ))}
        {properties.length === 0 && (
          <strong className="text-center text-muted mt-2">
            <span>No presenta propiedades personalizadas agregados</span>
          </strong>
        )}
      </Scrollable>
    </Fragment>
  )
}


const RenderLogoApp = ({ logoUri, logoUriDefault, isErrorLogo }) => {
  const url = logoUri !== '' ? logoUri : logoUriDefault;
  return (
    <Fragment>
      {isErrorLogo && (
        <em className="fa fa-file-image fa-3x" />
      )}
      {!isErrorLogo && (
        <img className="picture-app-client" src={url} />
      )}
    </Fragment>
  )
}

export default function IdentityFormBasic() {
  const [formData, setFormData] = useState({
    clientName: '',
    clientId: '',
    requireClientSecret: true,
    clientSecrets: '',
    description: '',
    allowedGrantTypes: [],

    logoUriDefault: '/img/CORE_ISOTIPO.png',
    logoUri: '',
    isErrorLogo: false,


    redirectUris: [],
    postLogouts: [],
    corsOrigins: [],

    allowOfflineAccess: true,
    absoluteRefreshTokenLifetime: '2592000',
    accessTokenLifetime: '3600',

    requirePkce: true,
    allowPlainTextPkce: false,
    allowAccessTokensViaBrowser: false,


    scopes: [],
    properties: [],

    errors: {},

    nameRedirectUri: '',
    namePostLogout: '',
    nameCorsOrigins: '',
    nameScope: '',
    namePropertied: '',
    valuePropertied: '',

    revealClientSecret: false
  })

  const [popover, setPopover] = useState({
    popoverOpen: false,
    id: '',
    placement: 'top',
    title: '',
    body: null
  })

  useEffect(() => {
    generateClientID()
    generateClientSecret()
  }, [])

  useEffect(() => {
    if (!formData.requireClientSecret && formData.clientSecrets !== '') {
      setFormData(state => ({
        ...state,
        clientSecrets: ''
      }))
    } else if (formData.requireClientSecret && formData.clientSecrets === '') generateClientSecret();
  }, [formData.requireClientSecret])

  const generateClientID = () => {
    let code = GenerateString(32);
    setFormData(state => ({
      ...state,
      clientId: code
    }))
  }

  const generateClientSecret = () => {
    if (formData.requireClientSecret) {
      let code = GenerateString(64);
      setFormData(state => ({
        ...state,
        clientSecrets: code
      }))
    }
  }

  const clientSecretShow = () => {
    if (formData.requireClientSecret) {
      setFormData(state => ({
        ...state,
        revealClientSecret: !formData.revealClientSecret
      }))
    }
  }

  const onChangeInput = event => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    setFormData(state => ({
      ...state,
      [input.name]: value,
      errors: {
        ...state.errors,
        [input.name]: result
      }
    }));
  };

  const toggle = () => setPopover(state => ({
    ...state,
    popoverOpen: !popover.popoverOpen
  }))

  const openPopover = (id, title, body) => {
    setPopover(state => ({
      ...state,
      popoverOpen: !popover.popoverOpen,
      id: id,
      title: title,
      body: body
    }))
  }

  const addGrantType = (parameters) => {
    setFormData(state => ({
      ...state,
      allowedGrantTypes: parameters
    }))
  }

  const addRedirectUris = () => {
    if (invalidClass("nameRedirectUri", formData.nameRedirectUri)) {
      const array = formData.redirectUris;
      array.push(formData.nameRedirectUri)

      setFormData(state => ({
        ...state,
        redirectUris: array,
        nameRedirectUri: ''
      }))
    }
  }

  const addPostLogout = () => {
    if (invalidClass("namePostLogout", formData.namePostLogout)) {
      const array = formData.postLogouts;
      array.push(formData.namePostLogout)

      setFormData(state => ({
        ...state,
        postLogouts: array,
        namePostLogout: ''
      }))
    }
  }

  const addCorsOrigins = () => {
    if (invalidClass("nameCorsOrigins", formData.nameCorsOrigins)) {
      const array = formData.corsOrigins;
      array.push(formData.nameCorsOrigins)

      setFormData(state => ({
        ...state,
        corsOrigins: array,
        nameCorsOrigins: ''
      }))
    }
  }

  const addScopes = () => {
    if (invalidClass("nameScope", formData.nameScope)) {
      const array = formData.scopes;
      array.push(formData.nameScope)

      setFormData(state => ({
        ...state,
        scopes: array,
        nameScope: ''
      }))
    }
  }

  const invalidClass = (name, value) => {
    let element = document.querySelector(`#${name}`)
    if (!element.classList.contains("is-invalid-other") && value === "") element.classList.add("is-invalid-other");
    else if (element.classList.contains("is-invalid-other") && value !== "") element.classList.remove("is-invalid-other");

    if (element.classList.contains("is-invalid") || value === "") return false;

    return true;
  }

  const isErrorLogoUri = (parameter) => {
    setFormData(state => ({
      ...state,
      isErrorLogo: parameter
    }))
  }

  const changeLogoClient = async (e) => {
    onChangeInput(e)
    const input = e.target;
    const value = input.value;

    if (value !== "") {
      await fetch(value)
        .then(resp => {
          resp.blob().then(function (miBlob) {
            if (miBlob.type !== "image/png") isErrorLogoUri(true);
            else isErrorLogoUri(false);
          })
        })
        .catch(err => {
          isErrorLogoUri(true);
        })
    } else {
      isErrorLogoUri(false);
    }
  }

  return (
    <Form autoComplete="off">
      <fieldset>
        <FormGroup row>
          <label className="col-md-2 c-label h4">Información básica</label>
          <div className="col-md-10">
            <FormGroup className="mb-3">
              <label className="c-label">
                Nombre <strong className="text-danger"> *</strong></label>
              <Input
                type="text"
                name="clientName"
                placeholder="Agregar nombre para la aplicación"
                value={formData.clientName}
                onChange={(e) => onChangeInput(e)}
                invalid={
                  hasError(formData, "clientName", "required") ||
                  hasError(formData, "clientName", "sqlinject")
                }
                data-validate='["required","sqlinject"]'
              />
              {hasErrorMessage(formData, "clientName", "sqlinject", FORMAT_SQLINJECT)}
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">
                Identificación del cliente</label>
              <InputGroup>
                <Input
                  type="text"
                  name="clientId"
                  placeholder="Identificador del cliente"
                  value={formData.clientId}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "clientId", "required") ||
                    hasError(formData, "clientId", "sqlinject")
                  }
                  data-validate='["required","sqlinject"]'
                />
                <InputGroupAddon addonType="append">
                  <Button type="button" className="bg-secondary" onClick={() => generateClientID()} title="Generar identificador del cliente">
                    <em className="fa fa-undo" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {hasErrorMessage(formData, "clientId", "sqlinject", FORMAT_SQLINJECT)}
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">Contraseña</label>
              <div className="mb-3">
                <label className="switch switch-lg">
                  <input type="checkbox" name="requireClientSecret" onChange={() => false} checked={formData.requireClientSecret} onClick={(e) => onChangeInput(e)} />
                  <span></span>
                </label>
              </div>
              <FormText className="h5">
                {clientSecrets()}
              </FormText>
              <InputGroup>
                <Input
                  type={formData.revealClientSecret ? "text" : "password"}
                  name="clientSecrets"
                  placeholder="Contraseña del cliente"
                  value={formData.clientSecrets}
                  disabled={!formData.requireClientSecret}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "clientSecrets", "sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                <InputGroupAddon addonType="append">
                  <Button type="button" className="bg-secondary" onClick={() => clientSecretShow()} title="Revelar contraseña del cliente">
                    {!formData.revealClientSecret && (
                      <em className="fa fa-eye" />
                    )}
                    {formData.revealClientSecret && (
                      <em className="fa fa-eye-slash" />
                    )}
                  </Button>
                  <Button type="button" className="bg-secondary" onClick={() => generateClientSecret()} title="Generar contraseña del cliente">
                    <em className="fa fa-undo" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {hasErrorMessage(formData, "clientSecrets", "sqlinject", FORMAT_SQLINJECT)}
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">Tipo de autenticación</label>
              <FormText className="h5">
                {allowedGrantTypes()}
              </FormText>
              <SelectOptions
                isMulti
                placeholder="Autenticación del cliente"
                closeMenuOnSelect={false}
                options={GrantTypes}
                value={formData.allowedGrantTypes}
                onChange={(e) => addGrantType(e)}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">Descripción </label>
              <Input
                type="textarea"
                rows="3"
                name="description"
                placeholder="Agrega una descripción en menos de 1000 caracteres"
                value={formData.description}
                onChange={(e) => onChangeInput(e)}
                invalid={
                  hasError(formData, "description", "required") ||
                  hasError(formData, "description", "sqlinject") ||
                  hasError(formData, "description", "maxlen")
                }
                data-validate='["required","sqlinject","maxlen"]'
                data-param="1000"
              />
              {hasErrorMessage(formData, "description", "sqlinject", FORMAT_SQLINJECT)}
              {hasErrorMessage(formData, "description", "maxlen", MAX_LENGTH)}
              <FormText className="h5">
                Una descripción de texto libre de la aplicación. El recuento máximo de caracteres es 1000.
              </FormText>
            </FormGroup>
          </div>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup row>
          <label className="col-md-2 c-label h4">URLs de aplicación</label>
          <div className="col-md-10">
            <FormGroup>
              <label className="c-label">Logo de la aplicación</label>
              <Card className="card-default">
                <CardBody className="content-logo-center">
                  <RenderLogoApp {...formData} />
                </CardBody>
                <CardFooter className="p-0">
                  <InputGroup>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <em className="fa fa-link" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="logoUri"
                      id="logoUri"
                      placeholder="https://path.to/my_logo.png"
                      value={formData.logoUri}
                      onChange={(e) => changeLogoClient(e)}
                    />
                  </InputGroup>
                </CardFooter>
              </Card>
              <FormText className="h5">
                URI al logotipo del cliente (utilizado en la pantalla de consentimiento).
                El tamaño recomendado es de 150x150 píxeles.
              </FormText>
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">
                URI de inicio de sesión y devolución de llamadas de la aplicación <strong className="text-danger"> *</strong></label>
              <InputGroup>
                <Input
                  type="text"
                  name="nameRedirectUri"
                  id="nameRedirectUri"
                  placeholder="https://myapp.org/login"
                  value={formData.nameRedirectUri}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "nameRedirectUri", "sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                <InputGroupAddon addonType="prepend">
                  <Button type="button" className="bg-default btn-sm" onClick={() => addRedirectUris()}>
                    <em className="fa fa-plus pr-1" />
                    Agregar
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {hasErrorMessage(formData, "nameRedirectUri", "sqlinject", FORMAT_SQLINJECT)}
              <FormText className="h5">
                {redirectUris()}
              </FormText>
              <ComponentListRedirect {...{ formData, setFormData }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">
                URI de cierre de sesión permitidas <strong className="text-danger"> *</strong></label>
              <InputGroup>
                <Input
                  type="text"
                  name="namePostLogout"
                  id="namePostLogout"
                  placeholder="https://myapp.org/logout"
                  value={formData.namePostLogout}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "namePostLogout", "sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                <InputGroupAddon addonType="prepend">
                  <Button type="button" className="bg-default btn-sm" onClick={() => addPostLogout()}>
                    <em className="fa fa-plus pr-1" />
                    Agregar
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {hasErrorMessage(formData, "namePostLogout", "sqlinject", FORMAT_SQLINJECT)}
              <FormText className="h5">
                {postLogoutUris()}
              </FormText>
              <ComponentPostLogout {...{ formData, setFormData }} />
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">
                Orígenes permitidos (CORS) <strong className="text-danger"> *</strong></label>
              <InputGroup>
                <Input
                  type="text"
                  name="nameCorsOrigins"
                  id="nameCorsOrigins"
                  placeholder="https://*.siempre.net.co"
                  value={formData.nameCorsOrigins}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "nameCorsOrigins", "sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                <InputGroupAddon addonType="prepend">
                  <Button type="button" className="bg-default btn-sm" onClick={() => addCorsOrigins()}>
                    <em className="fa fa-plus pr-1" />
                    Agregar
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              {hasErrorMessage(formData, "nameCorsOrigins", "sqlinject", FORMAT_SQLINJECT)}
              <FormText className="h5">
                {corsOrigins()}
              </FormText>
              <ComponentCorsOrigins {...{ formData, setFormData }} />
            </FormGroup>
          </div>
        </FormGroup>
      </fieldset>
      <fieldset>
        <FormGroup row>
          <label className="col-md-2 c-label h4">Token de identificación</label>
          <div className="col-md-10">
            <FormGroup className="mb-3">
              <label className="c-label">¿Requiere token de acceso?</label>
              <div className="mb-3">
                <label className="switch switch-lg">
                  <input type="checkbox" name="allowOfflineAccess" onChange={() => false} checked={formData.allowOfflineAccess} onClick={(e) => onChangeInput(e)} />
                  <span></span>
                </label>
              </div>
              <FormText className="h5">
                Los clientes deben estar explícitamente autorizados para solicitar tokens de actualización estableciendo <code>AllowOfflineAccess</code> en <code>true</code>.
              </FormText>
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">Caducidad del token de identificación</label>
              <InputGroup>
                <Input
                  type="number"
                  name="accessTokenLifetime"
                  disabled={!formData.allowOfflineAccess}
                  value={formData.accessTokenLifetime}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "accessTokenLifetime", "required") ||
                    hasError(formData, "accessTokenLifetime", "number") ||
                    hasError(formData, "accessTokenLifetime", "min")
                  }
                  data-validate='["required","number","min"]'
                  data-param="1"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    Segundos
                  </InputGroupText>
                </InputGroupAddon>
                {hasErrorMessage(formData, "accessTokenLifetime", "min", MIN_LENGTH_NUMBER)}
              </InputGroup>
              <FormText className="h5">
                Esta configuración le permite establecer la vida útil del <code>AccessTokenLifetime</code> (en segundos)
              </FormText>
            </FormGroup>
            <FormGroup className="mb-3">
              <label className="c-label">Duración absoluta del token de actualización</label>
              <InputGroup>
                <Input
                  type="number"
                  disabled={!formData.allowOfflineAccess}
                  name="absoluteRefreshTokenLifetime"
                  value={formData.absoluteRefreshTokenLifetime}
                  onChange={(e) => onChangeInput(e)}
                  invalid={
                    hasError(formData, "absoluteRefreshTokenLifetime", "required") ||
                    hasError(formData, "absoluteRefreshTokenLifetime", "number") ||
                    hasError(formData, "absoluteRefreshTokenLifetime", "min")
                  }
                  data-validate='["required","number","min"]'
                  data-param="1"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    Segundos
                  </InputGroupText>
                </InputGroupAddon>
                {hasErrorMessage(formData, "absoluteRefreshTokenLifetime", "min", MIN_LENGTH_NUMBER)}
              </InputGroup>
              <FormText className="h5">
                Duración máxima de un token de actualización en segundos.
                El valor predeterminado es 2592000 segundos / 30 días. El valor cero permite tokens de actualización que, cuando se usan con <code>RefreshTokenExpiration = Sliding</code>,
                solo caducan después de que se pasa <code>SlidingRefreshTokenLifetime</code>.
              </FormText>
            </FormGroup>
          </div>
        </FormGroup>
      </fieldset>


      <div className="form-row">

        <FormGroup className="col-12 col-lg-6 col-xl-4">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-redirectUris"
              onClick={() => openPopover('redirectUris', 'redirectUris', allowedGrantTypes())}
            />
          Alcances</label>
          <div className="card card-default">
            <div className="card-header">
              <InputGroup>
                <Input type="text" name="nameScope" id="nameScope" className="form-control-sm"
                  placeholder="Ingrese los alcances del cliente"
                  onChange={(e) => onChangeInput(e)}
                  value={formData.nameScope}
                  data-validate='["sqlinject"]'
                  invalid={
                    hasError(formData, "nameScope", "sqlinject")
                  }
                />
                <InputGroupAddon addonType="prepend">
                  <button type="button" className="btn btn-primary btn-sm" onClick={() => addScopes()}>
                    Agregar
                  </button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <ComponentScopes {...{ formData, setFormData }} />
          </div>
        </FormGroup>
        <FormGroup className="col-12 col-xl-4">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-redirectUris"
              onClick={() => openPopover('redirectUris', 'redirectUris', allowedGrantTypes())}
            />
          Valores personalizados</label>
          <div className="card card-default">
            <div className="card-header">
              <InputGroup>
                <Input type="text" className="form-control-sm" placeholder="Nombre" />
                <Input type="text" className="form-control-sm" placeholder="Valor" />
                <InputGroupAddon addonType="prepend">
                  <button type="button" className="btn btn-primary btn-sm">
                    Agregar
                    </button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <ComponentProperties {...{ formData }} />
          </div>
        </FormGroup>
      </div>
      <div className="form-row">
        <FormGroup className="col-lg-3 col-md-6 col-12">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-requirePkce"
              onClick={() => openPopover('requirePkce', 'RequirePkce', textRequirePkce())}
            />
          ¿Requiere código de autorización?</label>
          <div className="pl-1">
            <label className="switch switch-lg">
              <input type="checkbox" name="requirePkce" onChange={() => false} checked={formData.requirePkce} onClick={(e) => onChangeInput(e)} />
              <span></span>
            </label>
          </div>
        </FormGroup>
        <FormGroup className="col-lg-3 col-md-6 col-12">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-allowPlainTextPkce"
              onClick={() => openPopover('allowPlainTextPkce', 'allowPlainTextPkce', allowPlainTextPkce())}
            />
          ¿Código de autorización sin formato?</label>
          <div className="pl-1">
            <label className="switch switch-lg">
              <input type="checkbox" name="allowPlainTextPkce" onChange={() => false} checked={formData.allowPlainTextPkce} onClick={(e) => onChangeInput(e)} />
              <span></span>
            </label>
          </div>
        </FormGroup>
        <FormGroup className="col-lg-3 col-md-6 col-12">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-allowOfflineAccess"
              onClick={() => openPopover('allowOfflineAccess', 'allowOfflineAccess', allowOfflineAccess())}
            />
          ¿Solicitar token de autenticación?</label>
          <div className="pl-1">
            <label className="switch switch-lg">
              <input type="checkbox" name="allowOfflineAccess" onChange={() => false} checked={formData.allowOfflineAccess} onClick={(e) => onChangeInput(e)} />
              <span></span>
            </label>
          </div>
        </FormGroup>
        <FormGroup className="col-lg-3 col-md-6 col-12">
          <label className="c-label">
            <em className="fas fa-info-circle mr-2 fa-lg cursor-content"
              id="Popover-allowAccessTokensViaBrowser"
              onClick={() => openPopover('allowAccessTokensViaBrowser', 'allowAccessTokensViaBrowser', allowAccessTokensViaBrowser())}
            />
          ¿Recibir token de acceso via navegador?</label>
          <div className="pl-1">
            <label className="switch switch-lg">
              <input type="checkbox" name="allowAccessTokensViaBrowser" onChange={() => false} checked={formData.allowAccessTokensViaBrowser} onClick={(e) => onChangeInput(e)} />
              <span></span>
            </label>
          </div>
        </FormGroup>
      </div>

      {popover.popoverOpen && (
        <PopoverItem {...{ popover, toggle }} />
      )}
    </Form>
  )
}
