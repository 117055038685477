///Lista de encabezados a utilizar en los componentes con fetch.
import { _JsonStorage } from '../Global';

export const headerGet = (tokenP = null) => {
  let header = {
    method: "GET",
    mode: "cors",
    headers: {
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    }
  };
  return header;
};

export const headerPost = (data, tokenP = null) => {
  let header = {
    method: "POST",
    mode: "cors",
    headers: {
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    },
    body: data
  };
  return header;
};

export const headerPut = (data, tokenP = null) => {
  let header = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Authorization": "Bearer "+tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    },
    body: data
  };
  return header;
};

export const headerDelete = (tokenP = null) => {
  let header = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    }
  };
  return header;
};

export const headerEdit = (tokenP = null) => {
  let header = {
    method: "EDIT",
    mode: "cors",
    headers: {
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    }
  };
  return header;
};

export const headerPostJson = (data, tokenP = null) => {
  let header = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-type": "application/json",
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    },
    body: JSON.stringify(data)
  };
  return header;
};

export const headerPutJson = (data, tokenP = null) => {
  let header = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-type": "application/json",
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    },
    body: JSON.stringify(data)
  };
  return header;
};

export const headerDeleteJson = (data, tokenP = null) => {
  let header = {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-type": "application/json",
      "Authorization": "Bearer "+ tokenP,
      "sub":
        _JsonStorage !== null && _JsonStorage !== undefined
          ? _JsonStorage.profile.sub
          : null
    },
    body: JSON.stringify(data)
  };
  return header;
};
