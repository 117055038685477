import MethodGet from '../MethodGet';
import MethodPost from '../MethodPost';
import MethodDelete from '../MethodDelete';
import { headerPostJson, headerDelete, headerGet } from '../Headers';
import { getpackagetype, getpackages, postapplicationpackage, getapplicationpackage, editpackage, deletePackage } from '../../components/RouteApi/RouteApi';
import { _JsonStorage } from '../../Global';

export const packageType = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(getpackagetype, header).then(response=>response).catch(error=>error);
};

export const getpackage = () =>{
    let header = headerGet( _JsonStorage.access_token);
    let query = MethodGet(getpackages, header).then(response => response).catch(error => error);
    return query;
}; 

export const savepackage = (data) =>{
        let header = headerPostJson(data, _JsonStorage.access_token);
        let response = MethodPost(postapplicationpackage, header).then(response=>response).catch(error=>error);
        return response;        
}; 

export const getpackagecheck = (identifier) =>{
    let urlBase = getapplicationpackage,
    params = {
        id: identifier 
    };
    let url = new URL(urlBase);
    Object.keys(params).forEach(item => url.searchParams.append(item, params[item]));

    let header = headerGet( _JsonStorage.access_token);
    let query = MethodGet(url, header).then(response=>response).catch(error=>error);
    return query;
};

export const editpackaged = (param) =>{
    let urlBase = editpackage,
    params = {
        id: param 
    };
    let url = new URL(urlBase);
    Object.keys(params).forEach(item => url.searchParams.append(item, params[item]));

    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(url, header).then(response=>response).catch(error=>error);
};

export const removePackage = (param) =>{
    let urlBase = deletePackage,
    params = {
        id: param 
    };
    let url = new URL(urlBase);
    Object.keys(params).forEach(item => url.searchParams.append(item, params[item]));

    let header = headerDelete( _JsonStorage.access_token);
    return MethodDelete(url, header).then(response=>response).catch(error=>error);
};