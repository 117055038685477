import React from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Selection,
  Pager, Paging,
} from "devextreme-react/data-grid";
import { withNamespaces } from "react-i18next";
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  FormGroup,
  CardTitle
} from "reactstrap";

///Estilos.
import "react-toastify/dist/ReactToastify.css";
import "sweetalert-react/node_modules/sweetalert/dist/sweetalert.css";

///Componentes.
import ModalPermission from "../Modal/ModalPermission";
import SwalRemove from "../SweetAlert/SwalRemove";
import SwalConfirm from "../SweetAlert/SwalConfirm";
import ProgressAnimate from '../Progress/ProgressAnimate';
import SpinnerAnimate from '../Progress/SpinnerAnimate';

///Notificaciones.
import notify from "devextreme/ui/notify";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

///Metodos y rutas para realizar peticiones.
import MethodGet from "../../services/MethodGet";
import MethodPost from "../../services/MethodPost";
import { headerPostJson, headerGet } from "../../services/Headers";
import { permissionLoading } from "../../services/Api/Permission";
import {
  getPermission,
  deletePermission,
  filterPermission,
  postRolePermission
} from "../../components/RouteApi/RouteApi";
import { Time900, _JsonStorage } from '../../Global';

const pageSizes = [6, 14, 50, 100];
const selectionFilter = ['selection', '=', true];

class Permission extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = null;
    this.state = {
      modalPermission: false,
      dataPermission: [
        {
          Permission: {
            code: "",
            description: "",
            idCategory: 0,
            idPermission: 0,
            nameCategory: "",
            namePermission: "",
            order: ""
          },
          namePermission: ""
        }
      ],
      permissionCheck: [],
      selectedRowKeys: [],
      dataEdit: null,
      idDelete: null,
      editModal: false,
      openSwal: false,
      nameSwalDelete: "",
      openSwalConfirm: false,
      defaultRole: 0,
      isProgress: false,
      isSpinner: false
    };
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSelectionFilterChanged = this.onSelectionFilterChanged.bind(this);
  }

  componentDidMount() {
    this.handlePermission();
  }

  componentDidUpdate() {
    if (this.props.changeSelectPermission) {
      this.props.handleCheckPermission();
      this.handleFilterPermission();
    }
  }

  ///Cargar progress
  handleProgress = () => this.setState({
    isProgress: !this.state.isProgress
  });

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () => this.setState({ isSpinner: !this.state.isSpinner });

  ///Consumir el servicio de la lista de permisos.
  handlePermission = () => {
    this.handleProgress();
    let data = permissionLoading();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });

    promise.then(
      result => {
        if (result.success) {
          this.state.dataPermission.shift();
          const arrayobj = [];
          result.response.forEach(function (elements) {
            const obj = {
              Permission: elements,
              namePermission: elements.namePermission,
              selection: false
            };
            arrayobj.push(obj);
          });
          this.setState({ dataPermission: arrayobj });
        }
        this.handleProgress();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Control de cambio para abrir o cerrar un modal de permisos.
  onClickModal = () =>
    this.setState({
      modalPermission: !this.state.modalPermission,
      editModal: false,
      dataEdit: null
    });

  ///Función para editar registros.
  onClickEdit = event => {
    let itemEdit = null;
    if (event.row.key === undefined) {
      itemEdit = event.row.data;
    } else {
      itemEdit = event.row.data.Permission;
    }

    this.setState({
      dataEdit: itemEdit,
      editModal: !this.state.editModal,
      modalPermission: !this.state.modalPermission
    });
  };

  ///Función que valida y carga los datos del registro que se van a eliminar.
  onClickDelete = event => {
    let dataPermission = null;
    if (event.row.key === undefined) {
      dataPermission = event.row.data;
    } else {
      dataPermission = event.row.data.Permission;
    }

    ///Asignar al state la data a eliminar.
    this.setState({
      idDelete: dataPermission.idPermission,
      nameSwalDelete: dataPermission.namePermission
    });
    this.swalShow();
  };

  ///Actualizar la tabla una vez creado un evento de eliminar, crear, actualizar un registro.
  onChangeTable = () => {
    let header = headerGet( _JsonStorage.access_token);
    MethodGet(getPermission, header)
      .then(response => {
        if (response.success) {
          let arrayChangePermission = [];
          response.response.forEach(function (elements) {
            let dropDownElements = {
              Permission: elements,
              namePermission: elements.namePermission,
              selection: false
            };
            arrayChangePermission.push(dropDownElements);
          });
          this.setState({ dataPermission: arrayChangePermission });
        } else {
          showValidation(response);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Control de abrir y cerras el Swealert.
  swalShow = () => {
    this.setState({ openSwal: !this.state.openSwal });
  };

  ///Función realizar la solicitud para eliminar en registro.
  handleRemove = (items, val, isRemove) => {
    if (items !== this.state.nameSwalDelete) {
      this.swalShow();
      notify(
        "El nombre ingresado no coincide con el nombre del elemento seleccionado.",
        COLOUR_WARNING,
        Time900
      );
    } else {
      ///Asignar query string a la url.
      let urlBase = deletePermission,
        params = {
          id: this.state.idDelete
        };

      ///Composición en la instancia URL.
      let url = new URL(urlBase);
      Object.keys(params).forEach(item =>
        url.searchParams.append(item, params[item])
      );

      let header = headerGet( _JsonStorage.access_token);
      MethodGet(url, header)
        .then(response => {
          this.swalShow();
          this.onChangeTable();
          showValidation(response, 3, "permiso");
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  ///Función para listar los permisos y chequear los permisos asignados a ese rol.
  handleFilterPermission = () => {
    let urlBase = filterPermission,
      params = {
        id: this.props.roleSelect.value
      };
    ///composición en la instancia URL.
    let url = new URL(urlBase);
    Object.keys(params).forEach(item =>
      url.searchParams.append(item, params[item])
    );

    let header = headerGet( _JsonStorage.access_token);
    MethodGet(url, header)
      .then(response => {
        if (response.success) {
          this.setState({
            permissionCheck: response.response,
            defaultRole: this.props.roleSelect.value
          });
          this.onSelectionFilterChanged();
        } else {
          this.setState({
            selectedRowKeys: [],
            defaultRole: this.props.roleSelect.value
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Función que permite que se seleccione los menus dependiendo del rol seleccionado.
  onSelectionFilterChanged = () => {
    let arrayPermission = this.state.dataPermission;
    let selectedRowKeys = [];
    this.state.permissionCheck.forEach(element => {
      let result = arrayPermission.find(item => item.Permission.idPermission === element.idPermission);
      if (result !== undefined) {
        let position = this.handleFilter(arrayPermission, result.Permission.idPermission)
        arrayPermission.splice(position, 1);
        result.selection = true;
        arrayPermission.push(result);
        selectedRowKeys.push(result.Permission.idPermission);
      }
    });

    this.setState({
      dataPermission: arrayPermission,
      selectedRowKeys: selectedRowKeys
    });
  };

  ///Filtrar permisos del rol seleccionado
  handleFilter = (arrayPermissions, param) => {
    let indice = -1;
    arrayPermissions.filter(function (product, i) {
      if (product.idPermission === param) {
        indice = i;
      }
    });
    return indice;
  }

  ///Asignar/Desasociar menú de un rol.
  onSelectionChanged = event => {
    if (this.props.roleSelect === undefined) {
      notify(
        "Seleccione un rol para poder configurar sus menus y permisos.",
        COLOUR_WARNING
      );
      this.setState({ selectedRowKeys: [] });
    } else {
      this.setState({ selectedRowKeys: event.selectedRowKeys });
    }
  };

  ///Control de abrir y cerras el Swealert de confirmar.
  swalShowConfirm = () => {
    this.setState({ openSwalConfirm: !this.state.openSwalConfirm });
  };

  ///Guardar configuración de los permisos asignados.
  onFetchCheck = () => {
    if (this.props.roleSelect == null) {
      notify("Verifique haber seleccionado un rol.", COLOUR_WARNING, 3000);
    } else {
      this.handleSpinner();
      let object = {
        IdRole: this.props.roleSelect.value,
        ListPermission: this.state.selectedRowKeys
      };

      let header = headerPostJson(object, _JsonStorage.access_token);
      MethodPost(postRolePermission, header)
        .then(response => {
          if (response.success) {
            showValidation(response, 1, "permiso");
          } else {
            showValidation(response);
          }
          this.handleSpinner();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { selectedRowKeys, isProgress, isSpinner } = this.state;
    return (
      <div className="col-md-6">
        {/* START card */}
        <Card className="card-default">
          <CardBody>
            <form className="parent">
              <SpinnerAnimate isDisabled={isSpinner} />
              <Card>
                <CardHeader>
                  <CardTitle>Permisos</CardTitle>
                  <div className="text-sm">Lista de permisos disponibles.</div>
                  <FormGroup>
                    <Button type="button" color="primary" onClick={this.onClickModal}>Agregar Permiso</Button>
                    {" "}
                    <Button type="button" color="success" onClick={this.onFetchCheck}>Actualizar</Button>
                    <ModalPermission
                      openModal={this.state.modalPermission}
                      onClickModal={this.onClickModal}
                      onChangeTable={this.onChangeTable}
                      openModalEdit={this.state.editModal}
                      item={this.state.dataEdit}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>
                  <DataGrid
                    dataSource={this.state.dataPermission}
                    hoverStateEnabled={true}
                    onSelectionChanged={this.onSelectionChanged}
                    selectedRowKeys={selectedRowKeys}
                    defaultSelectionFilter={selectionFilter}
                    keyExpr={"Permission.idPermission"}
                    showBorders={true}
                  >

                    <Selection mode={"multiple"} deferred={false} />
                    <SearchPanel visible={true} width={240} placeholder={"Buscar..."} />
                    <Column
                      dataField={"namePermission"}
                      caption={"Nombre permiso"}
                      headerFilter={{ allowSearch: true }}
                    />
                    <Column
                      type={"buttons"}
                      dataFiled={"Permission"}
                      buttons={[
                        {
                          cssClass: "buttons-edit",
                          hint: "Editar",
                          icon: "edit",
                          onClick: this.onClickEdit
                        },
                        {
                          cssClass: "buttons-delete",
                          hint: "Eliminar",
                          icon: "clear",
                          onClick: this.onClickDelete
                        }
                      ]}
                    />
                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                    <Paging defaultPageSize={6} />
                  </DataGrid>
                </CardBody>
                {/* Progress cargar lista de app */}
                <ProgressAnimate isProgress={isProgress} />
              </Card>
            </form>
          </CardBody>
        </Card>
        {/* END card */}
        <SwalRemove
          openModal={this.state.openSwal}
          swalShow={this.swalShow}
          handleRemove={this.handleRemove}
          elementRemove={this.state.idDelete}
          element={this.state.nameSwalDelete}
        />

        <SwalConfirm
          openSwal={this.state.openSwalConfirm}
          swalShowConfirm={this.swalShowConfirm}
          onFetchCheck={this.onFetchCheck}
        />
      </div>
    );
  }
}

export default withNamespaces("translations")(Permission);
