import React, { useState } from "react";

export const AppContext = React.createContext({});

export const AppConsumer = AppContext.Consumer;

const AppProvider = (props) => {
  const [state, setState] = useState({
    isClient: false,
    showIdentity: false,
    showFormIdentity: true,
    showListWithFormIdentity: false,

    arrayClientOrResource: [],
    isClientOrResource: false,

    dismissIdentityListSidebar: false
  });

  const viewAllClose = () => {
    setState(state => ({
      ...state,
      showIdentity: false,
      showFormIdentity: false,
      showListWithFormIdentity: false
    }))
  }

  const viewListIdentity = () => {
    setState(state => ({
      ...state,
      showIdentity: true,
      showFormIdentity: false,
      showListWithFormIdentity: false
    }))
  }

  const viewFormIdentity = () => {
    setState(state => ({
      ...state,
      showIdentity: false,
      showFormIdentity: true,
      showListWithFormIdentity: false
    }))
  }

  const viewListWithFormIdentity = () => {
    setState(state => ({
      ...state,
      showIdentity: false,
      showFormIdentity: false,
      showListWithFormIdentity: true
    }))
  }

  const handleChangeState = (name, value, name2 = null) => {
    if (name2 === null) setState(state => ({ ...state, [name]: value }));
    else {
      setState(state => ({
        [name]: {
          ...state[name],
          [name2]: value
        }
      }))
    }
  }

  return (
    <AppContext.Provider value={{ data: state, handleChangeState, viewAllClose, viewListIdentity, viewFormIdentity, viewListWithFormIdentity }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppProvider;