import React from "react";
import queryString from "query-string";
import FormValidator from "../Validation/FormValidation";
import SpinnerAnimate from "../Progress/SpinnerAnimate";
import Header from "./Header";
import notify from "devextreme/ui/notify";
import MethodPost from "../../services/MethodPost";

import { Redirect } from "react-router";
import { Input } from "reactstrap";
import { resetPassword } from "../RouteApi/RouteApi";
import { headerPostJson } from "../../services/Headers";
import { showValidation } from "../Validation/ShowValidation";
import { LengthMinPassword, LengthMaxPassword } from '../../Global';
import { MESSAGE_REQUIRED, COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";

import "./docs/ForgotPassword.css";

const moonLanding = new Date();

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: queryString.parse(this.props.location.search),
      array: [],
      redirect: false,
      isSpinner: false,
      formForgotPassword: {
        password: "",
        password2: ""
      }
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (hasError) {
      notify(MESSAGE_REQUIRED, COLOUR_WARNING);
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    ///Datos de formulario.
    let obj = {
      password: this.state.formForgotPassword.password,
      token: this.state.token.token
    }

    let header = headerPostJson(obj);
    this.setState({ isSpinner: true })
    MethodPost(resetPassword, header).then(response => {
      if (response.success) {
        showValidation(0, 0, '', response.message, COLOUR_SUCCESS);
        this.handleRedirect();
      } else if (response.success === false) {
        showValidation(0, 0, '', response.message, COLOUR_WARNING);
      } else {
        ///Redirigir a que ingrese nuevamente su correo para un nuevo token y validar el usuario.
        // this.setState({ array: ["/id/forgotpassword"], redirect: true });
        showValidation(0, 0, '', 'Se presento un falló de conexión, intente nuevamente.' +
          ' Si el problema persiste actualice su navegador e intente nuevamente', COLOUR_WARNING);
      }
      this.setState({ isSpinner: false })
    });
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleRedirect = () => {
    this.setState({ array: ["/"], redirect: true });
  };

  render() {
    const { isSpinner } = this.state;
    return (
      <div
        className="size-page"
        style={{
          background:
            'url("img/svg/pattern-1.svg")no-repeat center bottom fixed',
          height: "100%",
          overflow: "hidden",
          backgroundColor: "#ffffff"
        }}
      >
        <Header />
        <div className="wrapper content">
          <aside>
            <div className="block-center mt-4 wd-xl bg-white">
              {/* START card */}
              <div className="card-default card">
                <div className="card-header text-center bg-dark border-radius-0">
                  <h4 className="text-with">Actualizar Contraseña</h4>
                </div>
                <div className="card-body">
                  <form
                    name="formForgotPassword"
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label className="text-muted">Contraseña</label>
                      <div className="input-group with-focus">
                        <Input
                          className="form-control border-right-0"
                          name="password"
                          type="password"
                          id="id-source"
                          placeholder="Ingrese su nueva contraseña"
                          value={this.state.formForgotPassword.password}
                          onChange={this.validateOnChange}
                          invalid={
                            this.hasError("formForgotPassword", "password", "required") ||
                            this.hasError("formForgotPassword", "password", "len") ||
                            this.hasError("formForgotPassword", "password", "strongpassword")
                          }
                          data-validate='["required", "len","strongpassword"]'
                          data-param={`[${LengthMinPassword},${LengthMaxPassword}]`}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text text-muted bg-transparent border-left-0">
                            <em className="fa fa-unlock-alt" />
                          </span>
                        </div>
                        {this.hasError("formForgotPassword", "password", "len") && (
                          <span className="invalid-feedback">
                            La longitud minima de la contraseña es de {LengthMinPassword} y un máximo de {LengthMaxPassword} caracteres.
                          </span>
                        )}
                        {this.hasError("formForgotPassword", "password", "strongpassword") && (
                          <span className="invalid-feedback">
                            Debe contar como mínimo con una letra minúscula y un numero.
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="text-muted">Confirmar contraseña</label>
                      <div className="input-group with-focus">
                        <Input
                          type="password"
                          name="password2"
                          className="form-control border-right-0"
                          placeholder="Confirme su nueva contraseña"
                          invalid={
                            this.hasError(
                              "formForgotPassword",
                              "password2",
                              "required"
                            ) ||
                            this.hasError(
                              "formForgotPassword",
                              "password2",
                              "equalto"
                            )
                          }
                          onChange={this.validateOnChange}
                          data-validate='["required","equalto"]'
                          data-param="id-source"
                          value={this.state.formForgotPassword.password2}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text text-muted bg-transparent border-left-0">
                            <em className="fa fa-unlock-alt" />
                          </span>
                        </div>
                        {this.hasError(
                          "formForgotPassword",
                          "password2",
                          "equalto"
                        ) && (
                            <span className="invalid-feedback">
                              El campo debe ser igual al anterior.
                            </span>
                          )}
                      </div>
                    </div>
                    <SpinnerAnimate isDisabled={isSpinner} />
                    <button
                      type="submit"
                      className="btn btn-principal btn-block"
                    >
                      <span className="letter-white">Guardar</span>
                    </button>
                  </form>
                </div>
              </div>
              {/* END card */}
              <div className="p-3 text-center">
                <span className="mr-2">&copy;</span>
                <span>{moonLanding.getFullYear()}</span>
                <span className="mx-2">-</span>
                <span>Core</span>
                <br />
                <span>Siempre.Net</span>
              </div>
            </div>
          </aside>
          {this.state.array.map((item, i) => {
            if (this.state.redirect) {
              return <Redirect key={i + 1} to={item} />;
            }
            return true;
          })}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
