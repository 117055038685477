import MethodGet from "../MethodGet";
import { deleteRole } from "../../components/RouteApi/RouteApi";
import { headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

export const Delete = param => {
  let urlBase = deleteRole,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header).then(response=>response).catch(error=>error);
  const promise = new Promise((rest,rej)=>{
      rest(response);
      rej(Error(response));
  });
  return promise;
};
