import React, { useEffect } from 'react';
import { getUserManager, useReactOidc } from '@axa-fr/react-oidc-context';

import LoadConfig from '../Extras/LoadConfig'
import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'
import VerificationContact from "../User/VerificationContact";

const Base = props => {
    const { oidcUser } = useReactOidc();

    useEffect(() => {
        getUserManager()
            .events.addUserSignedOut(() => {
                getUserManager()
                    .signoutRedirect()
                    .then(resp => {
                        console.log('success');
                    })
                    .catch(err => {
                        console.log(err);
                    });
            });
    });

    return (
        <LoadConfig oidcUser={oidcUser}>
            <div className="wrapper">
                {/*encabezado.*/}
                <Header />

                {/*menú de navegación.*/}
                <Sidebar />

                {/*cambiar el tema.*/}
                <Offsidebar />

                {/*contenido en el body.*/}
                <section className="section-container">
                    {props.children}
                </section>

                {/*Pie de pagína.*/}
                <Footer />

                <VerificationContact />
            </div>
        </LoadConfig>
    )
}

export default Base;
