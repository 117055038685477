import React from "react";


import { withNamespaces } from "react-i18next";
import MethodGet from "../../services/MethodGet";
import MethodPost from "../../services/MethodPost";
import { genreget } from "../../services/Api/Genre";
import { headerPost } from "../../services/Headers";
import { countriesget } from "../../services/Api/Country";
import { showValidation } from "../Validation/ShowValidation";
import { listCitieId, createUser } from "../RouteApi/RouteApi";
import { Input, Card, CardHeader, CardBody, CardFooter, Row } from "reactstrap";
import { Pattern, RouteResendPassword, KeyRecaptchat, ConfigClientApp } from "../../Global";
import { TITLE_NOTIFY, NOTIFY_SUCCESS_FORGOTPASSWORD, SERVICE_ERROR_RESPONSE, MESSAGE_REQUIRED, SERVICE_EXISTS, COLOUR_ERROR, COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";


import Header from "./Header";
import Select from "react-select";
import SendEmail from "./SendEmail";
import queryString from "query-string";
import notify from "devextreme/ui/notify";
import SpinnerAnimate from "../Progress/SpinnerAnimate";
import FormValidator from "../Validation/FormValidation";
import Recaptcha from 'react-google-invisible-recaptcha';


import "./docs/Login.css";
import "./docs/ForgotPassword.css";
import "sweetalert-react/node_modules/sweetalert/dist/sweetalert.css";
import "react-toastify/dist/ReactToastify.css";

import "./docs/Login.css";
import "./docs/ForgotPassword.css";

class CreateUser extends React.Component {
  defaultStateForm = {
    nameUser: "",
    lastName: "",
    expeditionCountry: null,
    expeditionCity: null,
    genre: null,
    email: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      formCreate: {
        nameUser: "",
        lastName: "",
        expeditionCountry: null,
        expeditionCity: null,
        genre: null,
        email: ""
      },
      optionExpeditionCountries: [],
      defaultCountries: null,
      optionExpeditionCity: [],
      optionGenre: [],
      showSwal: false,
      titleSwal: "",
      messageSwal: "",
      isSearchable: true,
      sendEmail: false,
      linkResend: "",
      isSubmit: false,
      isSpinner: false
    };
  }

  componentDidMount() {
    this.countries();
    this.genres();
    this.getReturnUrl()
  }

  getReturnUrl = () => {
    let query = queryString.parse(window.location.search);
  }

  onResolved = () => {
    if (this.state.isSubmit) this.handleSubmit();
  }

  ///Idioma de usuario.
  userLocation = array => {
    if (array.length > 0) {
      this.setState({
        formCreate: {
          ...this.state.formCreate,
          expeditionCountry: array[0]
        }
      });
      this.cities(array[0].value);
    }
  };

  ///Lista de países.
  countries = () => {
    let countries = localStorage.getItem("countries");
    const array = [], arrayStorage = [];

    if (countries === null || countries === undefined) {
      ///Promesa con las ciudades.
      const promise = countriesget();
      promise.then(
        result => {
          if (result.success) {
            result.response.forEach(element => {
              array.push({
                label: element.nameCountry,
                value: element.idCountry,
                nameForm: "formCreate",
                nameSelect: "expeditionCountry",
                nameSelectReset: "expeditionCity",
                language: element.language
              });

              arrayStorage.push({
                label: element.nameCountry,
                value: element.idCountry,
                language: element.language

              });
            });
            localStorage.setItem("countries", JSON.stringify(arrayStorage));
            this.userLocation(array);
            this.setState({ optionExpeditionCountries: array });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      let arrCountries = JSON.parse(countries);
      arrCountries.forEach(element => {
        const obj = {
          label: element.label,
          value: element.value,
          nameForm: "formCreate",
          nameSelect: "expeditionCountry",
          nameSelectReset: "expeditionCity",
          language: element.language
        };
        array.push(obj);
      });
      this.userLocation(array);
      this.setState({ optionExpeditionCountries: array });
    }
  };

  ///Cargar lista de géneros.
  genres = () => {
    let validateFetch = localStorage.getItem("genres");
    let array = [];
    if (validateFetch == null) {
      ///Llamar el metodo get de fetch para realizar la solicitud al servidor.
      const promise = genreget();
      promise.then(
        result => {
          if (result.success) {
            result.response.forEach(element => {
              const obj = {
                label: element.nameGenre,
                value: element.idGenre,
                nameForm: "formCreate",
                nameSelect: "genre"
              };
              array.push(obj);
            });
            localStorage.setItem("genres", JSON.stringify(array));
            this.setState({ optionGenre: array });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      ///array almacenado en locastorage
      let arrGenre = JSON.parse(validateFetch);
      arrGenre.forEach(element => {
        const obj = {
          label: element.label,
          value: element.value,
          nameForm: "formCreate",
          nameSelect: "genre"
        };
        array.push(obj);
      });

      this.setState({ optionGenre: array });
    }
  };

  ///Filtrar las ciudades.
  cities = element => {
    MethodGet(listCitieId + element)
      .then(response => {
        const citiesOptions = [];
        if (response.response.length > 0) {
          response.response.forEach(element => {
            let dropDownElemts = {
              label: element.nameCity,
              value: element.idCity,
              nameForm: "formCreate",
              nameSelect: "expeditionCity"
            };
            citiesOptions.push(dropDownElemts);
          });
        }
        this.setState({ optionExpeditionCity: citiesOptions });
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Subir navegación y mostrar mensaje
  handleScroll = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ formCreate: this.defaultStateForm, sendEmail: true });
  };

  ///Función para enviar datos a api.
  handleSubmit() {

    ///Datos de formulario.
    const dataFetch = new FormData();
    dataFetch.append("FirstName", this.state.formCreate.nameUser);
    dataFetch.append("LastName", this.state.formCreate.lastName);
    dataFetch.append("Email", this.state.formCreate.email);
    dataFetch.append("IdUserState", this.state.formCreate.genre.value);
    dataFetch.append("IdGenre", this.state.formCreate.genre.value);
    dataFetch.append(
      "IdCityResidence",
      this.state.formCreate.expeditionCity.value
    );

    let header = headerPost(dataFetch);
    let emailold = this.state.formCreate.email;

    this.setState({ isSpinner: true })
    MethodPost(createUser, header)
      .then(response => {
        if (response.success) {
          showValidation(0, 0, '', response.message, COLOUR_SUCCESS);
          this.handleScroll();
        } else {
          if (response.state === 502) {
            showValidation(0, 0, '', response.message, COLOUR_WARNING);
            this.handleScroll();
          } else {
            showValidation(0, 0, '', response.message, COLOUR_WARNING);
          }
        }
        this.setState({ isSpinner: false })
        this.setState({ linkResend: RouteResendPassword + "?email=" + emailold });
      })
      .catch(error => {
        notify(SERVICE_ERROR_RESPONSE, COLOUR_WARNING);
      });
  }

  ///Función para validar los campos al momento de realizar click en el botón ingresar.
  onSubmit = e => {
    e.preventDefault();
    this.recaptcha.execute();

    if (
      this.state.formCreate.expeditionCity === null ||
      this.state.formCreate.genre === null
    ) {
      notify(MESSAGE_REQUIRED, COLOUR_WARNING);
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT"].includes(i.nodeName)
      );
      ///Validar campos requeridos.
      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (hasError) {
        notify(MESSAGE_REQUIRED, COLOUR_WARNING);
        this.setState({ isSubmit: false })
      } else {
        this.setState({ isSubmit: true })
      }
    }
  };

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Función que permite cambiar el estado de los select.
  changeOptionSelect = event => {
    if (event.value !== undefined) {
      ///Validar el filtro de las ciudades dependiendo del país.
      if (event.nameSelect === "expeditionCountry") {
        this.cities(event.value);
      }

      ///Cambiar de estado los valores de los select.
      this.setState({
        [event.nameForm]: {
          ...this.state[event.nameForm],
          [event.nameSelect]: { label: event.label, value: event.value }
        }
      });
    }
  };

  ///Simplificar la comprobación de errores.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    const {
      isSearchable,
      sendEmail,
      optionExpeditionCountries,
      optionGenre,
      linkResend,
      isSpinner
    } = this.state;
    const { expeditionCountry, genre } = this.state.formCreate;
    return (
      <div
        className="size-page colour-centralize">
        <Header />
        <br />
        <br />
        <div className="wrapper">
          <aside>
            <div className="block-center col-md-9 col-lg-8 col-xl-6">
              {/* START card */}
              <form
                name="formCreate"
                id="formCreate"
                onSubmit={this.onSubmit}
                method="post"
                className="col-md-12"
                autoComplete="off"
              >
                <Card className="card-default card">
                  <CardHeader className="text-center bg-dark">
                    <div className="card-title text-center">
                      <h4 className="text-white">Registrarme</h4>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {sendEmail ? (
                      <SendEmail
                        rootRef={this.sendEmail}
                        title={TITLE_NOTIFY}
                        message={NOTIFY_SUCCESS_FORGOTPASSWORD}
                        link={linkResend}
                      />
                    ) : null}
                    <br />
                    <Row>
                      <div className="form-group col-md-6">
                        <Input
                          type="text"
                          name="nameUser"
                          placeholder="Ingrese sus nombres."
                          invalid={
                            this.hasError(
                              "formCreate",
                              "nameUser",
                              "required"
                            ) ||
                            this.hasError(
                              "formCreate",
                              "nameUser",
                              "alphaspace"
                            )
                          }
                          onChange={this.validateOnChange}
                          data-validate='["required", "alphaspace"]'
                          value={this.state.formCreate.nameUser}
                        />
                        {this.hasError(
                          "formCreate",
                          "nameUser",
                          "alphaspace"
                        ) && (
                            <span className="invalid-feedback">
                              El campo debe ser un nombre válido.
                            </span>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <Input
                          type="text"
                          name="lastName"
                          placeholder="Ingrese sus apellidos."
                          invalid={
                            this.hasError(
                              "formCreate",
                              "lastName",
                              "required"
                            ) ||
                            this.hasError(
                              "formCreate",
                              "lastName",
                              "alphaspace"
                            )
                          }
                          onChange={this.validateOnChange}
                          data-validate='["required", "alphaspace"]'
                          value={this.state.formCreate.lastName}
                        />
                        {this.hasError(
                          "formCreate",
                          "lastName",
                          "alphaspace"
                        ) && (
                            <span className="invalid-feedback">
                              El campo debe ser un nombre válido.
                            </span>
                          )}
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group col-md-6">
                        <Select
                          name="expeditionCountry"
                          isSearchable={isSearchable}
                          options={optionExpeditionCountries}
                          placeholder="-País de residencia-"
                          onChange={e => this.changeOptionSelect(e)}
                          value={expeditionCountry}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Select
                          name="expeditionCity"
                          isSearchable={isSearchable}
                          options={this.state.optionExpeditionCity}
                          placeholder="-Ciudad de residencia-"
                          onChange={this.changeOptionSelect}
                          value={this.state.formCreate.expeditionCity}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="form-group col-md-6">
                        <Select
                          name="genre"
                          placeholder="-Seleccione su género-"
                          onChange={this.changeOptionSelect}
                          options={optionGenre}
                          value={genre}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Ingrese su correo electronico."
                          invalid={
                            this.hasError("formCreate", "email", "required") ||
                            this.hasError("formCreate", "email", "email")
                          }
                          onChange={this.validateOnChange}
                          data-validate='["required", "email"]'
                          value={this.state.formCreate.email}
                        />
                        {this.hasError("formCreate", "email", "email") && (
                          <span className="invalid-feedback">
                            El campo debe ser un nombre válido.
                          </span>
                        )}
                      </div>
                    </Row>
                    <Recaptcha
                      ref={ref => this.recaptcha = ref}
                      sitekey={ConfigClientApp.KeyRecaptchat}
                      onResolved={this.onResolved}
                    />
                  </CardBody>
                  <SpinnerAnimate isDisabled={isSpinner} />
                  <CardFooter className="hidden-cardfooter">
                    <div className="form-row align-right col-md-12 row">
                      <div className="form-group w-100">
                        <button
                          type="submit"
                          className="btn bg-primary w-100"
                        >
                          <span className="letter-white">Guardar</span>
                        </button>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </form>
              {/* END card */}
            </div>
          </aside>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(CreateUser);
