import React, { Component } from "react";
import { InputGroup, Input, Button, InputGroupAddon } from "reactstrap";
import DataGrid, { Column } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";

import FormValidator from "../Validation/FormValidation";
import { getuseremail, postuserclientadmin } from "../../services/Api/User";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_SUCCESS, COLOUR_WARNING } from '../Message/Message';

export default class UserFormAssignAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser: [],
      selectedRowKeys: [],
      formAssignUser: {
        email: ""
      }
    };
  }

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleOnSubmit = e => {
    e.preventDefault();
    let exist = true;
    let email = '';
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    inputs.forEach(element => {
      email = element.value;
    });

    let array = this.state.dataUser;
    array.forEach(element => {
      if (element.UserEmail === email) {
        exist = false;
      }
    });

    if (exist) {
      if (!hasError) {
        this.handleSubmit();
      }
    } else {
      notify('El usuario ya se encuentra en proceso de asignación a esta empresa.', COLOUR_WARNING, 3000);
    }
  };

  handleSubmit = () => {
    let email = this.state.formAssignUser.email;
    let array = [];
    let data = getuseremail(email);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          showValidation(0, 0, '', result.message, COLOUR_SUCCESS);
          let obj = {
            User: { code: result.response.code },
            UserName:
              result.response.firstName + " " + result.response.lastName,
            UserEmail: result.response.email
          };

          let array2 = this.state.dataUser;
          array2.forEach(element => {
            array.push(element);
          });

          array.push(obj);
          this.setState({
            dataUser: array,
            formAssignUser: {
              ...this.formAssignUser,
              email: ""
            }
          });
        } else if (result.success === false) {
          this.setState({
            formAssignUser: {
              ...this.formAssignUser,
              email: ""
            }
          });
          showValidation(0, 0, '', result.message, COLOUR_WARNING);
        } else {
          showValidation(0, 0, '', 'Se presento un falló de conexión, intente nuevamente.' +
            'Si el problema persiste actualice su navegador e intente nuevamente', COLOUR_WARNING);
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleAssignUser = () => {
    let code = this.state.dataUser;
    let enterprise = this.props.filter;
    let data = postuserclientadmin(code, enterprise);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          showValidation(0, 0, '', result.message, COLOUR_SUCCESS);
          this.props.handleChangeGrid();
          this.props.handleShowModal();
          this.setState({
            formAssignUser: {
              ...this.state.formAssignUser,
              email: ""
            },
            dataUser: []
          });
        } else if (result.success === false) {
          showValidation(0, 0, '', result.message, COLOUR_WARNING);
        } else {
          showValidation(0, 0, '', 'Se presento un falló de conexión, intente nuevamente.' +
            'Si el problema persiste actualice su navegador e intente nuevamente', COLOUR_WARNING);
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleDeleteElement = event => {
    let row = event.row.data;
    let data = this.state.dataUser;
    let array = [];
    let index = null;
    data.forEach((element, i) => {
      if (element.User.code === row.User.code) {
        index = i;
      }
    });

    if (index !== null) {
      data.splice(index, 1);
    }

    data.forEach(element => {
      array.push(element);
    });

    this.setState({ dataUser: array });
  };

  render() {
    const { dataUser, selectedRowKeys } = this.state;
    const { email } = this.state.formAssignUser;
    return (
      <form name="formAssignUser" onSubmit={this.handleOnSubmit} autoComplete="off">
        <label>Buscar usuario por correo electrónico</label>
        <InputGroup>
          <Input
            type="text"
            name="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={this.handleOnChange}
            invalid={
              this.hasError("formAssignUser", "email", "required") ||
              this.hasError("formAssignUser", "email", "email")
            }
            data-validate='["required","email"]'
          />
          <InputGroupAddon addonType="append">
            <Button className="bg-purple">Buscar</Button>
          </InputGroupAddon>
          {this.hasError("formAssignUser", "email", "email") && (
            <span className="invalid-feedback">
              El campo debe ser un campo válido.
            </span>
          )}
        </InputGroup>
        <br />
        <div>
          <DataGrid
            id={"grid-container"}
            dataSource={dataUser}
            keyExpr={"User.code"}
            ref={ref => (this.dataGrid = ref)}
            selectedRowKeys={selectedRowKeys}
            showBorders={true}
          >
            <Column
              dataField={"UserName"}
              caption={"Nombre"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"UserEmail"}
              caption={"Correo"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              type={"buttons"}
              dataFiled={"User"}
              width={50}
              buttons={[
                {
                  hint: "Eliminar",
                  icon: "fas fa-trash-alt",
                  onClick: this.handleDeleteElement
                }
              ]}
            />
          </DataGrid>
        </div>
        <div>
          {dataUser.length === 0 ? null : (
            <Button
              type="button"
              className="bg-primary"
              style={{ float: "right" }}
              onClick={this.handleAssignUser}
            >
              Asignar Usuario
            </Button>
          )}
        </div>
      </form>
    );
  }
}
