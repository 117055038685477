import React, { Component } from "react";
import { Link } from "react-router-dom";
import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";
import { 
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import { getSubscriptionByClient } from '../../services/Api/Subscription';

export default class SubscriptionAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changedata: 0,
      dataSubscriptions: [],
      selectedRowKeys: []
    };
  }

  componentDidUpdate(){
    this.handleChangeData();
  }

  handleChangeData = () =>{
      if(this.state.changedata !== this.props.filter && this.props.filter > 0){
        this.setState({ changedata: this.props.filter });
        let identifier = this.props.filter;
        let data = getSubscriptionByClient(identifier);
        let promise = new Promise((rest,rej)=>{
          rest(data);
          rej(Error(data));
        });
        promise.then(result=>{
          if(result.success){
            let array = [];
            result.response.forEach(element => {                
              let obj = {
                Subscription: { idSubscription: element.idSuscription },
                subscriptionDate: element.startDate,
                subscriptionName: element.nameSubscription
              }
              array.push(obj);
            });
            this.setState({ dataSubscriptions: array });
          }
        },function(error){
          console.log(error);
        });
      }
  }

  render() {
    const { dataSubscriptions, selectedRowKeys } = this.state;
    return (
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Suscripciones
              <Link  to="/subscription" path="/subscription" className="btn float-right bg-primary">
                Gestionar Suscripción
              </Link>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <DataGrid
                id={"grid-container"}
                dataSource={dataSubscriptions}
                keyExpr={"Subscription.idSubscription"}
                ref={ref => (this.dataGrid = ref)}
                selectedRowKeys={selectedRowKeys}
                showBorders={true}
              >
                <SearchPanel
                  visible={true}
                  width={360}
                  placeholder={"Filtrar suscripción..."}
                />
                <Column
                  dataField={"subscriptionDate"}
                  caption={"Fecha suscripción"}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  dataField={"subscriptionName"}
                  caption={"Nombre suscripción"}
                  headerFilter={{ allowSearch: true }}
                />{" "} 
              </DataGrid>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}
