import Crypto from "crypto-js";

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const Encrypt = "k7%NWHBL$WN#RxJpDCzj&M!4VEIRYfk$@S#0N9Aik4TSB#FPSC";

export const EncryptAESObject = (data) => {
  return Crypto.AES.encrypt(JSON.stringify(data), Encrypt).toString();
}

export const DecryptAESObject = (ciphertext) => {
  var bytes = Crypto.AES.decrypt(ciphertext, Encrypt);
  return JSON.parse(bytes.toString(Crypto.enc.Utf8));
}

export const EncryptAESPlainText = (message) => {
  return Crypto.AES.encrypt(message, Encrypt).toString();
}

export const DecryptAESPlainText = (ciphertext) => {
  var bytes = Crypto.AES.decrypt(ciphertext, Encrypt);
  return bytes.toString(Crypto.enc.Utf8);
}

export const GenerateString = (length) => {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}