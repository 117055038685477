import React, { Component } from "react";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import notify from "devextreme/ui/notify";

import {
  MESSAGE_REMOVE_DIFFERENT,
  COLOUR_WARNING
} from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";
import {
  getapplicationpackage,
  postaddapplication,
  deletegetapplicationpackage
} from "../../services/Api/ApplicationPackage";
import SwalRemove from "../SweetAlert/SwalRemove";
import ModalApplication from "../Application/ModalApplication";

export default class ApplicationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataapplication: [],
      stop: 0,
      isRemove: false,
      nameRemove: "",
      identifierRemove: 0,
      isreload: false,
      isaddapplication: false,
      openModal: false,
      isEdit: true,
      identifier: 0
    };
  }

  componentDidUpdate() {
    this.handleLoadApplication();
    this.handleSaveApplication();
  }

  handleLoadApplication = () => {
    if (this.props.filterPackage > 0) {
      let identifier = this.props.filterPackage;

      if (this.state.stop !== identifier || this.state.isreload === true) {
        let data = getapplicationpackage(identifier);
        let promise = new Promise((rest, rej) => {
          rest(data);
          rej(Error(data));
        });
        promise.then(
          result => {
            if (result.success) {
              let array = [];
              result.objeto.forEach(element => {
                let obj = {
                  identifier: element.appCode,
                  nameapplication: element.nameApp,
                  img: element.img,
                  description: element.descriptionApp
                };
                array.push(obj);
              });
              this.setState({
                dataapplication: array,
                stop: identifier,
                isreload: false
              });
            }
          },
          function (error) {
            console.log(error);
          }
        );
      }
    }
  };

  handleRemove = (remove, validation, identifier) => {
    if (validation !== remove) {
      this.handleOpenRemove();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING);
    } else {
      let packagecode = this.props.filterPackage;
      let data = deletegetapplicationpackage(identifier, packagecode);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            this.setState({ isreload: true });
            this.handleOpenRemove();
          }
          showValidation(result, 3, 'aplicación');
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  handleOpenRemove = event => {
    if (event !== undefined) {
      let elemet = event.currentTarget;
      this.setState({
        isRemove: !this.state.isRemove,
        identifierRemove: elemet.value,
        nameRemove: elemet.name
      });
    } else {
      this.setState({ isRemove: !this.state.isRemove });
    }
  };

  handleSaveApplication = () => {
    if (this.props.addapplication > 0 && this.props.filterPackage > 0) {
      this.props.handleResetState();
      let application = this.props.addapplication;
      let packages = this.props.filterPackage;
      let data = postaddapplication(application, packages);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          showValidation(result, 1, 'aplicación');
          this.setState({ isreload: true });
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      if (this.props.addapplication > 0 && this.props.filterPackage === 0) {
        this.props.handleResetState();
        notify("Primero seleccione un paquete.", COLOUR_WARNING, 5000);
      }
    }
  };

  handleReset = () => this.setState({ identifier: 0 });

  handleChangeModal = event => {
    if (event !== undefined) {
      let identifier = event.currentTarget.value;
      this.setState({
        openModal: !this.state.openModal,
        identifier: identifier
      });
    } else {
      this.setState({ openModal: !this.state.openModal });
    }
  };

  handleIsReload = () => this.setState({ isreload: !this.state.isreload });

  render() {
    const {
      dataapplication,
      isRemove,
      nameRemove,
      identifierRemove,
      openModal,
      isEdit,
      identifier
    } = this.state;
    return (
      <div className="row">
        {dataapplication.length === 0 ? (
          <div className="col-12">
            <h4>El valor filtrado no contiene aplicaciones.</h4>
          </div>
        ) : (
            dataapplication.map((x, i) => {
              return (
                <div className="col-lg-4" key={i + 1}>
                  <Card outline color="primary" className="mb-3">
                    <CardHeader className="text-white header-card d-flex align-items-center">
                      <div className="d-flex col">
                        <div className="h4 m-0">
                          <label className="text-black">
                            {x.nameapplication}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn bg-danger ml-auto"
                          onClick={this.handleOpenRemove}
                          name={x.nameapplication}
                          value={x.identifier}
                        >
                          <i className="fas fa-trash-alt" />
                        </Button>
                        {""}
                        <Button
                          className="btn bg-success ml-auto"
                          onClick={this.handleChangeModal}
                          value={x.identifier}
                        >
                          <i className="fas fa-pen" />
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <img
                        src={x.img}
                        alt="Sample"
                        className="img-left thumb96"
                      />
                      <p>{x.description}</p>
                    </CardBody>
                  </Card>
                </div>
              );
            })
          )}
        <SwalRemove
          swalShow={this.handleOpenRemove}
          openModal={isRemove}
          handleRemove={this.handleRemove}
          element={nameRemove}
          elementRemove={identifierRemove}
        />
        <ModalApplication
          openModal={openModal}
          isEdit={isEdit}
          showPackage={this.handleChangeModal}
          identifier={identifier}
          handleReset={this.handleReset}
          handleIsReload={this.handleIsReload}
        />
      </div>
    );
  }
}
