import MethodGet from "../MethodGet";
import MethodPost from "../MethodPost";
import { headerPut, headerPostJson, headerPost, headerGet, headerPutJson } from "../Headers";
import {
  getdocumenttype,
  credentials,
  isvalid,
  forgotPassword,
  getfindbyemail,
  postuserclient,
  authenticated,
  validatepassword,
  putuser,
  getcountsingleview,
  logout,
  validateCredential,
  loginPost,
  profileuser,
  activateaccount,
  getMenuAsync,
  resendAccess,
  changeEmailOrPhone,
  sendUserVerification,
  getUserVerification,
  verifyEmail
} from "../../components/RouteApi/RouteApi";
import { _JsonStorage } from '../../Global';

export const getUserProfile = () => {
  let obj = {
    CodeUser: _JsonStorage.profile.id
  };
  let header = headerPostJson(obj, _JsonStorage.access_token);
  let response = MethodPost(profileuser, header)
    .then(response => response)
    .catch(error => error);

  let promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const updateProfile = param => {
  let header = headerPut(param, _JsonStorage.access_token);
  return MethodPost(putuser, header)
    .then(respose => respose)
    .catch(error => error);
};

export const getDocumentType = () => {
  let header = headerGet(_JsonStorage.access_token);
  return MethodGet(getdocumenttype, header)
    .then(response => response)
    .catch(error => error);
};

export const getcredential = () => {
  let header = headerGet(_JsonStorage.access_token);
  let data = MethodGet(credentials, header)
    .then(response => response)
    .catch(error => error);
  let promise = new Promise((rest, rej) => {
    rest(data);
    rej(Error(data));
  });
  return promise;
};

export const getisvalid = () => {
  let header = headerGet(_JsonStorage.access_token);
  return MethodGet(isvalid, header)
    .then(response => response)
    .catch(error => error);
};

export const getMenuUser = () => {
  const object = {
    JsonCode: _JsonStorage.profile.id
  }
  let header = headerPostJson(object, _JsonStorage.access_token);
  let result = MethodPost(getMenuAsync, header)
    .then(respose => respose)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(result);
    rej(Error(result));
  });
};

export const updatePassword = param => {

  let header = headerPost(param, _JsonStorage.access_token);
  return MethodPost(forgotPassword, header)
    .then(response => response)
    .catch(error => error);
};

export const getuseremail = param => {
  let urlBase = getfindbyemail,
    params = {
      value: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet(_JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const postuserclientadmin = (array, param2) => {
  let arraString = [];
  const fetchData = new FormData();
  const code = _JsonStorage.profile.id;
  fetchData.append("enterprise", param2);
  fetchData.append("codemanagment", code);
  array.forEach(element => {
    arraString.push(element.User.code);
  });
  fetchData.append("jsoncode", JSON.stringify(arraString));

  let header = headerPost(fetchData, _JsonStorage.access_token);
  return MethodPost(postuserclient, header)
    .then(response => response)
    .catch(error => error);
};

export const getauthenticated = () => {
  let header = headerGet(_JsonStorage.access_token);
  return MethodGet(authenticated, header)
    .then(response => response)
    .catch(error => error);
};

export const isupdatepassword = param => {
  let fetchData = new FormData();
  fetchData.append("token", param);
  let header = headerPost(fetchData, _JsonStorage.access_token);

  return MethodPost(validatepassword, header)
    .then(response => response)
    .catch(error => error);
};

export const infosingleview = param => {
  let obj = {
    code: _JsonStorage.profile.id
  }
  const header = headerPostJson(obj, _JsonStorage.access_token);
  const response = MethodPost(getcountsingleview, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const logoutUser = () => {
  let header = headerGet(_JsonStorage.access_token);
  const response = MethodGet(logout, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const validateUser = param => {
  const obj = {
    email: param.formLogin.email,
    password: param.formLogin.password,
    webpage: param.continue === "" ? window.location.origin : param.continue
  };

  const header = headerPostJson(obj, _JsonStorage.access_token);
  const response = MethodPost(validateCredential, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const postCredential = param => {
  const header = headerPost(param, _JsonStorage.access_token);
  const response = MethodPost(loginPost, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const ActivateAccount = param => {
  const obj = {
    token: param
  };
  const header = headerPostJson(obj, _JsonStorage.access_token);
  const response = MethodPost(activateaccount, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const ChangeContactAsync = param => {
  param.codeUser = _JsonStorage.profile.id;
  const header = headerPutJson(param, _JsonStorage.access_token);
  const response = MethodPost(changeEmailOrPhone, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const SendVerificationAsync = () => {
  const header = headerPostJson({
    codeUser: _JsonStorage.profile.id
  }, _JsonStorage.access_token);
  const response = MethodPost(sendUserVerification, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const GetUserVerificationAsync = () => {
  let urlBase = getUserVerification,
    params = {
      codeUser: _JsonStorage.profile.id
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet(_JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const VerifyPhoneAsync = (code) => {
  const header = headerPostJson({
    codeEmail: code,
    codeUser: _JsonStorage.profile.id
  }, _JsonStorage.access_token);
  const response = MethodPost(verifyEmail, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Reenviar correo de acceso
export const ResendAccess = param => {
  const obj = {
    email: param
  }

  const header = headerPostJson(obj);
  const request = MethodPost(resendAccess, header)
    .then(response => response)
    .catch(error => error);

  const promise = new Promise((rest, rej) => {
    rest(request);
    rej(Error(request));
  });

  return promise;
}