import React, { Component } from "react";

export default class SubscriptionHeader extends Component {
  render() {
    return (
      <div className="content-heading">
        <div>
          {" "}
          Suscripciones
          <small>Configuración de suscripciones.</small>
        </div>
      </div>
    );
  } 
}
