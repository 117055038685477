import React from "react";
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  CardTitle
} from "reactstrap";
import DataGrid, {
  Column,
  SearchPanel,
  Selection,
  Pager, Paging,
} from "devextreme-react/data-grid";

///Componentes.
import SwalRemove from "../SweetAlert/SwalRemove";
import SwalConfirm from "../SweetAlert/SwalConfirm";

///Notificaciones.
import notify from "devextreme/ui/notify";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

///Estilos.
import "react-toastify/dist/ReactToastify.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import "loaders.css/loaders.css";
import "spinkit/css/spinkit.css";

///Referencias personales.
import ModalMenu from "../Modal/ModalMenu";
import MethodGet from "../../services/MethodGet";
import MethodPost from "../../services/MethodPost";
import MethodDelete from "../../services/MethodDelete";
import { headerDelete, headerPostJson, headerGet } from "../../services/Headers";
import { listMenu } from "../../services/Api/Menu";
import { postMenuRole } from "../RouteApi/RouteApi";

///Rerefencias personales.
import { getIdMenu, getAllMenu, deleteMenu } from "../RouteApi/RouteApi";
import { Time600, _JsonStorage } from '../../Global';
import ProgressAnimate from '../Progress/ProgressAnimate';
import SpinnerAnimate from '../Progress/SpinnerAnimate';

import "./docs/Menu.css";

const pageSizes = [6, 14, 50, 100];

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = null;
    this.selectionChangedBySelectBox = false;
    this.state = {
      modalMenu: false,
      dataMenu: [],
      dataEdit: null,
      editModal: false,
      menuCheck: [],
      selectedRowKeys: [],
      openSwal: false,
      nameSwalDelete: "",
      openSwalConfirm: false,
      idDelete: null,
      isProgress: false,
      isSpinner: false
    };
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onSelectionFilterChanged = this.onSelectionFilterChanged.bind(this);
  }

  componentDidMount(){
    this.handleMenu();
  }

  componentDidUpdate() {
    if (this.props.changeSelectMenu) {
      this.props.handleCheckMenu();
      this.filterMenu();
    }
  }

  ///Cargar progress
  handleProgress = () =>this.setState({
    isProgress: !this.state.isProgress
  });

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Carga la lista en el datagrid.
  handleMenu = () => {
    this.handleProgress();
    let data = listMenu();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });

    promise.then(
      result => {
        if (result.success) {
          this.state.dataMenu.shift();
          const arrayobj = [];
          result.response.forEach(function(elements) {
            const obj = { Menu: elements, text: elements.text };
            arrayobj.push(obj);
          });
          this.setState({ dataMenu: arrayobj });
        }
        this.handleProgress();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Control de cambio para abrir o cerrar un modal.
  onClickModal = () =>
    this.setState({
      modalMenu: !this.state.modalMenu,
      editModal: false,
      dataEdit: null
    });

  ///Actualizar la tabla una vez creado un registro
  onChangeMenu = () => {
    let header = headerGet( _JsonStorage.access_token);
    MethodGet(getAllMenu, header)
      .then(response => {
        if (response.success) {
          let arrayChangeMenu = [];
          response.response.forEach(function(elements) {
            let dropDownElements = { Menu: elements, text: elements.text };
            arrayChangeMenu.push(dropDownElements);
          });
          this.setState({ dataMenu: arrayChangeMenu });
        } else {
          showValidation(response);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Función para filtar los menus de acuerdo al rol seleccionado.
  filterMenu = () => {
    let urlBase = getIdMenu,
      params = {
        id: this.props.roleSelect.value
      };
    ///composición en la instancia URL.
    let url = new URL(urlBase);
    Object.keys(params).forEach(item =>
      url.searchParams.append(item, params[item])
    );

    let header = headerGet( _JsonStorage.access_token);
    MethodGet(url, header)
      .then(response => {
        if (response.success) {
          this.setState({ menuCheck: response.response });
          this.onSelectionFilterChanged();
        } else {
          let selectedRowKeys = [];
          this.setState({ selectedRowKeys });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Función para editar registros.
  onClickEdit = event => {
    let itemEdit = null;
    if (event.row.key === undefined) {
      itemEdit = event.row.data;
    } else {
      itemEdit = event.row.data.Menu;
    }
    this.setState({
      dataEdit: itemEdit,
      editModal: !this.state.editModal,
      modalMenu: !this.state.modalMenu
    });
  };

  ///Función que valida y carga los datos del registro que se van a eliminar.
  onClickDelete = event => {
    let dataMenu = null;
    if (event.row.key === undefined) {
      dataMenu = event.row.data;
    } else {
      dataMenu = event.row.data.Menu;
    }
    ///Asignar al state la data a eliminar.
    this.setState({ idDelete: dataMenu.idMenu, nameSwalDelete: dataMenu.text });
    this.swalShow();
  };

  ///Control de abrir y cerras el Swealert de eliminar.
  swalShow = () => {
    this.setState({ openSwal: !this.state.openSwal });
  };

  ///Control de abrir y cerras el Swealert de confirmar.
  swalShowConfirm = () => {
    this.setState({ openSwalConfirm: !this.state.openSwalConfirm });
  };

  ///Función realizar la solicitud para eliminar un registro.
  handleRemove = items => {
    if (items !== this.state.nameSwalDelete) {
      this.swalShow();
      notify(
        "El nombre ingresado no coincide con el nombre del elemento seleccionado.",
        COLOUR_WARNING,
        Time600
      );
    } else {
      ///Asignar query string a la url.
      let urlBase = deleteMenu,
        params = {
          id: this.state.idDelete
        };
      ///Composición en la instancia URL.
      let url = new URL(urlBase);
      Object.keys(params).forEach(item =>
        url.searchParams.append(item, params[item])
      );

      let header = headerDelete( _JsonStorage.access_token);
      MethodDelete(url, header)
        .then(response => {
          this.swalShow();
          this.onChangeMenu();
          showValidation(response, 3, "menu");
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  ///Función que permite que se seleccione los menus dependiendo del rol seleccionado.
  onSelectionFilterChanged = () => {
    this.selectionChangedBySelectBox = true;
    const selectedRowKeys = this.state.menuCheck.map(menu => menu.idMenu);
    this.setState({ selectedRowKeys });
  };

  ///Asignar/Desasociar menú de un rol.
  onSelectionChanged({ selectedRowKeys }) {
    this.selectionChangedBySelectBox = true;
    if (this.props.roleSelect === undefined) {
      notify(
        "Selecciona un rol para poder configurar sus menus y permisos.",
        COLOUR_WARNING
      );
      this.setState({ selectedRowKeys });
    } else {
      this.setState({ selectedRowKeys });
    }
  }

  ///Guardar configuración menu asignado.
  onFetchCheck = () => {
    if (this.props.roleSelect == null) {
      notify("Verifique haber seleccionado un rol.", COLOUR_WARNING, 3000);
    } else {
      this.handleSpinner();
      let object = {
        IdRole: this.props.roleSelect.value,
        ListMenu: this.state.selectedRowKeys
      };

      let header = headerPostJson(object, _JsonStorage.access_token);
      MethodPost(postMenuRole, header)
        .then(response => {
          if (response.success) {
            showValidation(response, 1, "menu");
          } else {
            showValidation(response);
          }
          this.handleSpinner();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    const { selectedRowKeys, isProgress, isSpinner } = this.state;
    return (
      <div className="col-md-6 loader-demo-sk d-flex align-items-center justify-content-center" id={"grid"}>
        <div className="sk-spinner sk-spinner-pulse" />
        {/* START card */}
        <Card className="card-default">
          <CardBody>
            <form className="parent">
              <SpinnerAnimate isDisabled={isSpinner} />
              <Card>
                <CardHeader>
                  <CardTitle>Menú</CardTitle>
                  <div className="text-sm">Lista de menús disponibles.</div>
                  <FormGroup>
                    <Button type="button" color="primary" onClick={this.onClickModal}>Agregar Menu</Button>
                    {" "}
                    <Button type="button" color="success" onClick={this.onFetchCheck}>Actualizar</Button>
                    <ModalMenu
                      openModal={this.state.modalMenu}
                      onClickModal={this.onClickModal}
                      onChangeMenu={this.onChangeMenu}
                      openModalEdit={this.state.editModal}
                      item={this.state.dataEdit}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody id="idBodyMenu">
                  <DataGrid
                    dataSource={this.state.dataMenu} ///data
                    keyExpr={"Menu.idMenu"}
                    showBorders={true}
                    onSelectionChanged={this.onSelectionChanged} ///Check
                    hoverStateEnabled={true}
                    selectedRowKeys={selectedRowKeys}
                  >
                    <SearchPanel
                      visible={true}
                      width={240}
                      placeholder={"Buscar..."}
                    />
                    <Selection mode={"multiple"} deferred={false} />
                    <Column
                      dataField={"text"}
                      caption={"Nombre menú"}
                      headerFilter={{ allowSearch: true }}
                    />
                    <Column
                      type={"buttons"}
                      dataFiled={"Menu"}
                      buttons={[
                        {
                          cssClass: "buttons-edit",
                          hint: "Editar",
                          icon: "edit",
                          onClick: this.onClickEdit
                        },
                        {
                          cssClass: "buttons-delete",
                          hint: "Eliminar",
                          icon: "clear",
                          onClick: this.onClickDelete
                        }
                      ]}
                    />
                    <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                    <Paging defaultPageSize={6} />
                  </DataGrid>
                </CardBody>

                {/* Progress cargar lista de app */}
                <ProgressAnimate isProgress={isProgress} />
              </Card>
            </form>
          </CardBody>
        </Card>
        {/* END card */}
        <SwalRemove
          openModal={this.state.openSwal}
          swalShow={this.swalShow}
          handleRemove={this.handleRemove}
          elementRemove={this.state.idDelete}
          element={this.state.nameSwalDelete}
        />

        <SwalConfirm
          openSwal={this.state.openSwalConfirm}
          swalShowConfirm={this.swalShowConfirm}
          onFetchCheck={this.onFetchCheck}
        />
      </div>
    );
  }
}

export default withNamespaces("translations")(Menu);
