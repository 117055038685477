import React, { Component, Fragment } from "react";
import { Collapse } from "reactstrap";
import ContentWrapper from "../Layout/ContentWrapper";

import SidebarUserBlock from "./SidebarUserBlock";
import FormProfile from "./FormProfile";
import FormEditPassword from "./FormEditPassword";
import FormContact from "./FormContact";

import queryString from "query-string";

import "./docs/Profile.css";

const ViewDefault = () => {
  return (
    <Fragment>
      <FormProfile />
      <FormContact />
    </Fragment>
  )
}

const LayoutContent = props => {
  switch (props.content) {
    case 1:
      return <ViewDefault />;
    case 2:
      return <FormEditPassword />;
  }
};

export default class SidebarProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      content: 1
    };
  }

  componentDidMount() {
    const query = queryString.parse(window.location.search);
    if (query.changePassword !== undefined) this.setState({ content: 2 })
  }

  handleChangeContent = param => this.setState({ content: param });

  render() {
    const { content } = this.state;
    return (
      <section>
        <ContentWrapper>
          <div className="content-heading">
            <div>
              {" "}
              Información personal
              <small>Información básica, como el correo, teléfono y datos personales, que utilizas en los servicios de SNET CORE</small>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <Collapse className="mb-boxes" isOpen={this.state.collapse}>
                <div className="card card-default">
                  {/* Body sidebar  */}
                  <div className="card-body">
                    <SidebarUserBlock
                      handleChangeContent={this.handleChangeContent}
                      content={content}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="container-fluid col-xl-8 col-lg-7">
              <LayoutContent content={content} />
            </div>
          </div>
        </ContentWrapper>
      </section>
    );
  }
}
