import React, { Fragment } from 'react'

export default function PageList(props) {

  const { currentPage, totalPages, totalCount, pageSize } = props.page;

  const prevPage = (e) => {
    e.preventDefault();
    const value = currentPage === 1 ? currentPage : currentPage - 1;
    props.setPage(state => ({
      ...state,
      currentPage: value
    }))
  }

  const nextPage = (e) => {
    e.preventDefault();
    const value = currentPage === totalPages ?
      currentPage : currentPage + 1;

    props.setPage(state => ({
      ...state,
      currentPage: value
    }))
  }

  const changePageSize = (e) => {
    let value = parseInt(e.target.value);
    let valuePage = (value > totalCount) && (currentPage === totalPages) ? 1 : currentPage;
    props.setPage(state => ({
      ...state,
      currentPage: valuePage,
      pageSize: value
    }))
  }

  const disabledPrev = currentPage === 1 ? true : false;
  const disabledNext = currentPage === totalPages ? true : false;

  return (
    <Fragment>
      <nav aria-label="..." className="p-2">
        <ul className="pagination justify-content-center flex-wrap">
          {!disabledPrev && (
            <li id="btn_prev">
              <a className="page-link" href="#" aria-label="Previous" onClick={(e) => prevPage(e)}>
                <span aria-hidden="true"><i className="fa fa-chevron-left"></i></span>
              </a>
            </li>
          )}
          <li className=" active" aria-current="page" style={{ zIndex: 0 }}>
            <span className="page-link">
              Pagina: {currentPage} de {totalPages} - Registros {totalCount}
            </span>
          </li>
          {!disabledNext && (
            <li id="btn_next">
              <a className="page-link" href="#" aria-label="Next" onClick={(e) => nextPage(e)}>
                <span aria-hidden="true"><i className="fa fa-chevron-right"></i></span>
              </a>
            </li>
          )}
          <li className=" form-row mx-1 mpage-sm-2">
            <div className="input-group">
              <div className="input-group-prepend ">
                <label className="input-group-text">Mostrar</label>
              </div>
              <select className="custom-select " onChange={(e) => changePageSize(e)} value={pageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </li>
        </ul>
      </nav>
    </Fragment>
  )
}