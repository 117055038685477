import React from "react";

import { headerGet } from "../../services/Headers";
import { EncryptAESObject, DecryptAESObject, EncryptAESPlainText } from "../Extras/Encrypt";

import MethodGet from "../../services/MethodGet";
import Spinner from '../Progress/SpinnerAnimate'
import Routes from '../../Routes'

export const GlobalContext = React.createContext({});

export const GlobalConsumer = GlobalContext.Consumer;

const nameConfigOidc = "confOidc";
const nameConfigStandar = "confStandar";
const nameConfigStandarService = "confStandar_service";

class GlobalProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfig: false,
      configClient: {
        client_id: '',
        redirect_uri: '',
        response_type: '',
        post_logout_redirect_uri: '',
        scope: '',
        authority: '',
        silent_redirect_uri: '',
        automaticSilentRenew: false,
        loadUserInfo: false,
      },
      config: {
        keyRecaptchat: '',
        nameSession: ''
      }
    }

    this.handleChangeState = this.handleChangeState.bind(this);
  }

  componentDidMount() {
    const configClientOidc = window.sessionStorage.getItem(nameConfigOidc)
    const configClientStandar = window.sessionStorage.getItem(nameConfigStandar)
    const configService = window.sessionStorage.getItem(nameConfigStandarService)

    if (configClientOidc == null || configClientStandar == null || configService == null) this.getConfigClient();
    else {
      const dataClientOidc = DecryptAESObject(configClientOidc);
      const dataClientStandar = DecryptAESObject(configClientStandar);
      this.setState({
        isConfig: true,
        configClient: dataClientOidc,
        config: dataClientStandar
      })
    }
  }

  handleChangeState = (name, value, name2 = null) => {
    if (name2 === null) this.setState({ [name]: value });
    else {
      this.setState({
        [name]: {
          ...this.state[name],
          [name2]: value
        }
      })
    }
  }

  getConfigClient = () => {
    let header = headerGet();
    MethodGet("v1/authenticated/getconfig", header)
      .then(response => {
        if (response.isSuccess) {
          let object = {}, otherObject = {}, routeService = "";
          response.objeto.forEach(element => {
            switch (element.key) {
              case "keyRecaptchat":
                otherObject.keyRecaptchat = element.value;
                break;
              case "nameSession":
                otherObject.nameSession = element.value;
                break;
              case "routeService":
                routeService = element.value;
                break;

              case "client_id":
                object.client_id = element.value;
                break;
              case "redirect_uri":
                object.redirect_uri = element.value;
                break;
              case "response_type":
                object.response_type = element.value;
                break;
              case "post_logout_redirect_uri":
                object.post_logout_redirect_uri = element.value;
                break;
              case "scope":
                object.scope = element.value;
                break;
              case "authority":
                object.authority = element.value;
                break;
              case "silent_redirect_uri":
                object.silent_redirect_uri = element.value;
                break;
              case "automaticSilentRenew":
                object.automaticSilentRenew = (element.value.toUpperCase() == 'true'.toUpperCase());
                break;
              case "loadUserInfo":
                object.loadUserInfo = (element.value.toUpperCase() == 'true'.toUpperCase());
                break;
              default:
                break;
            }
          });

          this.handleChangeState("configClient", object)
          this.handleChangeState("config", otherObject)

          window.sessionStorage.setItem(nameConfigOidc, EncryptAESObject(object));
          window.sessionStorage.setItem(nameConfigStandar, EncryptAESObject(otherObject));

          window.sessionStorage.setItem(nameConfigStandarService, EncryptAESPlainText(routeService));
        }
        this.handleChangeState("isConfig", true)
      })
      .catch(error => {
        this.handleChangeState("isConfig", true)
        window.sessionStorage.removeItem(nameConfigOidc);
        window.sessionStorage.removeItem(nameConfigStandar);
        window.sessionStorage.removeItem(nameConfigStandarService);
      })
  }

  render() {
    const data = this.state;
    const handleChangeState = this.handleChangeState;
    const { isConfig } = this.state;
    return (
      <GlobalContext.Provider value={{ data, handleChangeState }}>
        {!isConfig && <Spinner isDisabled={true} />}
        {isConfig && <Routes />}
      </GlobalContext.Provider>
    );
  }
}

export default GlobalProvider;