import MethodGet from "../MethodGet";
import MethodPost from "../MethodPost";
import { getmeasure, deletemeasurement } from "../../components/RouteApi/RouteApi";
import { headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

export const postMeasurement = (data, header) => {
  return MethodPost(data, header)
    .then(response => response)
    .catch(error => error);
};

export const getMeasurement = () => {
  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(getmeasure, header)
    .then(response => response)
    .catch(error => error);
};

export const deleteMeasurement = param => {
  let urlBase = deletemeasurement,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header).then(response=>response).catch(error=>error);
};
