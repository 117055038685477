import React from "react";
import Select from "react-select";
import notify from "devextreme/ui/notify";
import ContentWrapper from "../Layout/ContentWrapper";
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";

///Estilos.
import "react-toastify/dist/ReactToastify.css";
import "./docs/Roles.css";

///Componentes.
import Menu from "../Menu/Menu";
import Permission from "../Permission/Permission";
import SwalRemove from "../SweetAlert/SwalRemove";
import SpinnerAnimate from '../Progress/SpinnerAnimate';

///Servicio.
import FormValidator from "../Validation/FormValidation";
import MethodGet from "../../services/MethodGet";
import MethodPost from "../../services/MethodPost";
import { headerPost, headerGet } from "../../services/Headers";
import { roleList, createRole } from "../RouteApi/RouteApi";
import { showValidation } from "../Validation/ShowValidation";
import { Delete } from '../../services/Api/Roles';
import { COLOUR_WARNING } from "../Message/Message";
import { _JsonStorage, ExecuteFn } from '../../Global.js';

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formRole: {
        NameRole: "",
        roleOption: null
      },
      remove: {
        openModal: false,
        element: "",
        elementRemove: ""
      },
      roleOptions: [],
      deleteRole: false,
      modal: false,
      changeSelectMenu: false,
      changeSelectPermission: false,
      isSpinner: false
    };
  }

  componentDidMount() {
    ExecuteFn(this.handleRoles);
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  deleteRole = () => this.setState({ deleteRole: !this.state.deleteRole });

  handleDelete = () =>{
    if(this.state.formRole.roleOption === null){
      notify('Seleccione el elemento que desea eliminar.', COLOUR_WARNING, 4000);
    }else{
      const remove = this.state.formRole.roleOption;
      this.setState({ remove:{
        ...this.state.remove,
        openModal: true,
        element: remove.label,
        elementRemove: remove.value
      }});
    }
  }

  handleRemove = (param1, param2, param3) =>{
      if(param1 !== param2){
        this.swalShow();
        notify('El valor ingresado no coincide con el valor requerido.',COLOUR_WARNING, 4000);
      }else{
        const promise = Delete(param3);
        promise.then(result=>{
          if(result.success){
            showValidation(result, 3,'rol');
            this.handleRoles();
          }else{
            showValidation(result);
          }
          this.swalShow();
        },function(error){
          console.log(error);
        });
      } 
  }

  ///Abrir o cerrar modal de eliminar.
  swalShow = () =>
    this.setState({
      remove: {
        ...this.state.remove,
        openModal: !this.state.remove.openModal
      }
    });

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Simplificar la comprobación de errores.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Listar los roles de la aplicación.
  handleRoles = () => {
    let header = headerGet( _JsonStorage.access_token);
    MethodGet(roleList, header)
      .then(response => {
        if (response.success === true) {
          const array = [];
          response.response.forEach(element => {
            const dropDownElements = {
              label: element.nameRole,
              value: element.idRole,
              nameForm: "formRole",
              nameSelect: "roleOption"
            };
            array.push(dropDownElements);
          });
          this.setState({
            roleOptions: array,
            formRole: {
              ...this.state.formRole,
              NameRole: "",
              roleOption: null
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Función que cambia el estado a true o falsa para actualizar o no el dataTable de menu
  handleCheckMenu = () => this.setState({ changeSelectMenu: !this.state.changeSelectMenu });

  ///Función que cambia el estado a true o falsa para actualizar o no el dataTable de permisos
  handleCheckPermission = () => this.setState({ changeSelectPermission: !this.state.changeSelectPermission });

  ///Función que permite cambiar el estado de los select.
  changeOptionSelect = event => {
    if (event.value != null) {
      this.setState({
        [event.nameForm]: {
          ...this.state[event.nameForm],
          [event.nameSelect]: { label: event.label, value: event.value }
        }
      });
      this.handleCheckMenu();
      this.handleCheckPermission();
    }
  };

  ///Función para validar los campos al momento de realizar click en el boton ingresar.
  onSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );
    ///Validar campos requeridos.
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });
    if (!hasError) {
      this.handleSubmit(e);
    }
  };

  ///Función para enviar datos a api.
  handleSubmit(event) {
    event.preventDefault();
    this.handleSpinner();

    ///Datos de formulario a enviar.
    const dataFetch = new FormData();

    ///Elementos del formulario.
    dataFetch.append("NameRole", this.state.formRole.NameRole);

    ///Encabezado requerido.
    let header = headerPost(dataFetch, _JsonStorage.access_token);
    ///Se llama el metodo y se le asignan los parametros.
    MethodPost(createRole, header)
      .then(response => {
        if (response.success) {
          showValidation(response, 1,'rol');
          this.handleRoles();
        }else{
          showValidation(response);
        }
        this.handleSpinner();
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { NameRole, roleOption } = this.state.formRole;
    const { roleOptions, isSpinner } = this.state;
    const { openModal, element, elementRemove } = this.state.remove;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {" "}
            Roles
            <small>Configuración de menú y permisos por roles.</small>
          </div>
        </div>
        <Card className="card-default">
          <CardBody>
            <form onSubmit={this.onSubmit} name="formRole" autoComplete="off" className="parent">
              <SpinnerAnimate isDisabled={isSpinner} />
              <div className="form-row align-items-center">
                <div className="col-md-4">
                  <InputGroup className="mb-2">
                    <Input
                      name="NameRole"
                      placeholder="Nombre del rol"
                      invalid={
                        this.hasError("formRole", "NameRole", "required") ||
                        this.hasError("formRole", "NameRole", "alphanumspace")
                      }
                      onChange={this.validateOnChange}
                      data-validate='["required", "alphanumspace"]'
                      value={NameRole}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button type="submit" className="btn bg-primary">
                        <i className="fas fa-plus" />
                      </Button>
                    </InputGroupAddon>
                    {this.hasError("formRole", "NameRole", "alphanumspace") && (
                      <span className="invalid-feedback">
                        El campo debe tener un valor válido.
                      </span>
                    )}
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <InputGroup className="mb-2">
                    <Select
                      name="roleOption"
                      className="paddign-0 col-10"
                      placeholder="Escriba el nombre del rol"
                      options={roleOptions}
                      onChange={this.changeOptionSelect}
                      value={roleOption}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="btn bg-danger minus-square"
                        onClick={this.handleDelete}
                      >
                        <i className="fas fa-trash-alt" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
        <div className="row">
          <Menu
            roleSelect={roleOption}
            changeSelectMenu={this.state.changeSelectMenu}
            handleCheckMenu={this.handleCheckMenu}
          />
          <Permission
            roleSelect={roleOption}
            changeSelectPermission={this.state.changeSelectPermission}
            handleCheckPermission={this.handleCheckPermission}
          />
        </div>
        <SwalRemove 
          openModal={openModal}
          element={element}
          elementRemove={elementRemove}
          swalShow={this.swalShow}
          handleRemove={this.handleRemove}
        />
      </ContentWrapper>
    );
  }
}

export default withNamespaces("translations")(Roles);
