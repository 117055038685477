import React, { useContext } from 'react'

import { OidcAccess } from "../../Global";
import { GlobalContext } from "../Context/Context";

export default function LoadConfig(props) {
  const context = useContext(GlobalContext);
  return (
    <React.Fragment>
      <React.Fragment>
        <OidcAccess oidc={props.oidcUser} config={{
          KeyRecaptchat: context.data.config.keyRecaptchat,
          NameSession: context.data.config.nameSession,
        }}>
          {props.children}
        </OidcAccess>
      </React.Fragment>
    </React.Fragment>
  )
}
