import React, { Component } from "react";
import Select from "react-select";
import notify from "devextreme/ui/notify";
import Slider from "rc-slider";
import { Modal, Button, Card, CardBody, CardFooter } from "reactstrap";

import "rc-slider/assets/index.css";

import { getAppBillig } from "../../../../services/Api/AppBilling";
import { COLOUR_WARNING } from "../../../Message/Message";
import { postsubscriptionapplist } from "../../../../services/Api/Subscription";
import { showValidation } from "../../../Validation/ShowValidation";

export default class BillingPackageApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestapp: false,
      positionapp: 0,
      limit1: 236,
      limit2: 236,
      billingsend: [],
      billingbyapp: {
        codeapp: 0,
        img: "",
        name: "",
        descriptionapp: "",
        billing: []
      },
      changeSelect: {
        select: null,
        factor: 0,
        factor2: 0,
        measuredunit: "",
        cost: 0,
        price: 0,
        descriptionbilling: ""
      }
    };
  }

  componentDidUpdate() {
    if (this.props.load) {
      this.props.handleValidateLoad();
      this.handleBillingApp();
    }
  }

  handleValidateLastesApp = param => {
    let position = false;
    this.props.app.forEach((element, i) => {
      if (!element.state) {
        position = param === i ? true : false;
      }
    });
    return position;
  };

  handleValidateBilling = index => {
    if (!this.props.app[index].state) {
      const code = this.props.app[index].codeapp;
      const response = getAppBillig(code);
      const promise = new Promise((rest, rej) => {
        rest(response);
        rej(Error(response));
      });
      promise.then(
        result => {
          if (result.success) {
            const array = [];
            result.response.forEach(element => {
              const obj = {
                label: element.nameBillingCycle,
                value: element.idBillingCycle,
                factor: element.factor,
                measuredUnit: element.nameMeasuredUnit,
                price: element.cost,
                descriptionbilling: element.description
              };
              array.push(obj);
            });

            const data = this.props.app[index];
            this.setState({
              positionapp: index,
              latestapp: this.handleValidateLastesApp(index), // index + 1 === this.props.app.length ? true : false,
              billingbyapp: {
                ...this.state.billingbyapp,
                codeapp: data.codeapp,
                img: data.img,
                name: data.name,
                descriptionapp: data.description,
                billing: array
              },
              ...this.state.changeSelect,
              changeSelect: {
                select: null,
                factor: 0,
                factor2: 0,
                measuredunit: "",
                cost: 0,
                price: 0,
                descriptionbilling: ""
              }
            });
          }
        },
        function(error) {
          console.log(error);
        }
      );
      return;
    }
  };

  handleBillingApp = () => {
    let index = this.state.positionapp;
    if (this.props.app[index].state) {
      let position = 0;
      this.props.app.some((element, i) => {
        if (i >= index) {
          if (!element.state) {
            position = i;
          }
        }
        return position;
      });
      this.handleValidateBilling(position);
    } else {
      if (!this.props.app[index].state) {
        this.handleValidateBilling(index);
      }
    }
  };

  handleToggle = param => {
    switch (param) {
      case 1:
        if (
          this.state.changeSelect.descriptionbilling.lenght ===
          this.state.limit1
        ) {
          this.setState({ limit1: 236 });
        } else {
          this.setState({
            limit1: this.state.changeSelect.descriptionbilling.lenght
          });
        }
        break;
      case 2:
        if (
          this.state.billingbyapp.descriptionapp.lenght === this.state.limit2
        ) {
          this.setState({ limit2: 24 });
        } else {
          this.setState({
            limit2: this.state.billingbyapp.descriptionapp.lenght
          });
        }
        break;
      default:
        break;
    }
  };

  handleOnchangeSelect = event => {
    const price = this.handleDouble(event.price);
    this.setState({
      changeSelect: {
        ...this.state.changeSelect,
        select: { label: event.label, value: event.value },
        factor: event.factor,
        factor2: event.factor,
        measuredunit: event.measuredUnit,
        cost: event.price,
        price: price * event.factor,
        descriptionbilling: event.descriptionbilling
      }
    });
  };

  ///Obtener aplicaciones que se asignaran
  hadleObjectSend = () => {
    const array = this.state.billingsend;
    const dataOne = this.state.changeSelect;
    const app = this.state.billingbyapp.codeapp;
    const subs = this.props.subscription;
    const client = this.props.client;
    const obj = {
      factor: dataOne.factor2,
      cost: dataOne.cost,
      billingtype: dataOne.select.value,
      app: app,
      subscription: subs,
      client: client.value
    };
    array.push(obj);
    return array;
  };

  handleNext = () => {
    if (this.state.changeSelect.select === null) {
      notify(
        "Primero seleccione una facturación para esta aplicación.",
        COLOUR_WARNING,
        4000
      );
    } else {
      this.setState({
        billingsend: this.hadleObjectSend(),
        positionapp: this.state.positionapp + 1
      });
      this.props.handleValidateLoad();
    }
  };

  handleCloseModal = () => {
    this.setState({
      positionapp: 0,
      billingsend: [],
      billingbyapp: {
        ...this.state.billingbyapp,
        codeapp: 0,
        img: "",
        name: "",
        descriptionapp: "",
        billing: []
      },
      changeSelect: {
        ...this.state.changeSelect,
        select: null,
        factor: 0,
        factor2: 0,
        measuredunit: "",
        cost: 0,
        price: 0,
        descriptionbilling: ""
      }
    });
    this.props.handleOpenModal(false);
  };

  ///Asignar aplicaciones
  handleAssignSave = () => {
    if (this.state.changeSelect.select === null) {
      notify(
        "Primero seleccione una facturación para esta aplicación.",
        COLOUR_WARNING,
        4000
      );
    } else {
      this.setState({ billingsend: this.hadleObjectSend() });
      const data = this.state.billingsend;
      const response = postsubscriptionapplist(data);
      response.then(
        result => {
          if (result.success) {
            showValidation(result, 1, "aplicación");
            this.props.handleLoadSubscription();
            this.props.handleReloadGrid();
            this.props.handleOpenModalAssign();
            this.handleCloseModal();
          } else {
            showValidation(result);
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleDouble = param => {
    if (param === 0) {
      return 0;
    } else {
      let price = param.split(".");
      let value = "";
      price.forEach(element => {
        value = value + element;
      });
      return value;
    }
  };

  handleSliderLog = value => {
    const price = this.handleDouble(this.state.changeSelect.cost);
    const formula = price * value;
    this.setState({
      changeSelect: {
        ...this.state.changeSelect,
        factor2: value,
        price: formula
      }
    });
  };

  render() {
    const {
      select,
      factor,
      factor2,
      measuredunit,
      cost,
      price,
      descriptionbilling
    } = this.state.changeSelect;
    const { latestapp, limit1, limit2 } = this.state;
    const { openModal } = this.props;
    const { img, name, billing, descriptionapp } = this.state.billingbyapp;
    return (
      <div>
        <Modal isOpen={openModal}>
          <Card className="card-default">
            <CardBody>
              <div className="text-center">
                <h4>FACTURACIÓN POR APLICACIÓN</h4>
                <img className="mb-2 img-fluid thumb96" src={img} alt="App" />
              </div>
              <hr />
              <ul className="list-unstyled px-6">
                <div className="row col-md-12">
                  <div className="col-md-12">
                    <li>
                      <strong>Facturación: </strong>
                      <Select
                        name="billing"
                        options={billing}
                        placeholder="Seleccione una opción"
                        value={select}
                        onChange={this.handleOnchangeSelect}
                      />
                    </li>
                    <li>
                      <strong>Cantidad: </strong>
                      <Slider
                        onChange={this.handleSliderLog}
                        min={1}
                        max={parseInt(factor)}
                        value={parseInt(factor2)}
                        defaultValue={0}
                      />
                    </li>
                  </div>
                  <div className="col-md-6">
                    <li>
                      <strong>Nombre: </strong>
                      {name}
                    </li>
                    <li>
                      <strong>Tipo de unidad: </strong>
                      {measuredunit}
                    </li>
                    <li>
                      <strong>Tope maximo: </strong>
                      {factor}
                    </li>
                  </div>
                  <div className="col-md-6">
                    <li>
                      <strong>Cantidad ingresada: </strong>
                      {factor2}
                    </li>
                    <li>
                      <strong>Valor por unidad: </strong>$
                      {new Intl.NumberFormat().format(cost)}
                    </li>
                    <li>
                      <strong>Valor aplicación: </strong>$
                      {new Intl.NumberFormat().format(price)}
                    </li>
                  </div>
                  <div className="col-md-12">
                    <li>
                      <strong>Información de facturación: </strong>
                      {descriptionbilling.substring(0, limit1)}
                      <br />
                      {descriptionbilling === null ? null : descriptionbilling.length < limit1? null: (
                        <span className="more" onClick={e => this.handleToggle(1)}>
                          {descriptionbilling.length > limit1
                            ? "Leer menos..."
                            : "Leer mas..."}
                        </span>
                      )}
                    </li>
                    <li>
                      <strong>Información de aplicación: </strong>
                      {descriptionapp.substring(0, limit2)}
                      <br />
                      {descriptionbilling === null ? null : descriptionbilling.length < limit2? null: (
                        <span className="more" onClick={e => this.handleToggle(2)}>
                          {descriptionbilling.length > limit2
                            ? "Leer menos..."
                            : "Leer mas..."}
                        </span>
                      )}
                    </li>
                  </div>
                </div>
              </ul>
            </CardBody>
            <CardFooter className="d-flex">
              <div>
                <Button
                  type="button"
                  className="bg-secundary btn-xs"
                  onClick={this.handleCloseModal}
                >
                  Cancelar
                </Button>
              </div>
              <div className="ml-auto">
                {latestapp === true ? (
                  <Button
                    type="button"
                    className="bg-primary btn-xs"
                    onClick={this.handleAssignSave}
                  >
                    Guardar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="bg-primary btn-xs"
                    onClick={this.handleNext}
                  >
                    Siguiente
                  </Button>
                )}
              </div>
            </CardFooter>
          </Card>
        </Modal>
      </div>
    );
  }
}
