import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import UserFormAssignStandar from './UserFormAssignStandar';

export default class UserModalAssignStandar extends Component {
  render() { 
    const { handleShowModal, openModal, filter, handleChangeGrid } = this.props;
    return (
      <Modal isOpen={openModal} toggle={handleShowModal} className="modal-dialog modal-lg"> 
        <div className="modal-header">
          {" "}
          <strong>Asignación de usuario estandar a aplicación por suscripcion de empresa</strong>
          <button
            type="button"
            className="close float-right"
            data-dismiss="modal"
            aria-label="Close"  
            onClick={handleShowModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> 
        <ModalBody>
          <UserFormAssignStandar filter={filter} handleShowModal={handleShowModal} handleChangeGrid={handleChangeGrid} />
        </ModalBody>
      </Modal>
    );
  }
}
