import React, { Component } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import notify from "devextreme/ui/notify";

import FormValidator from "../Validation/FormValidation";
import { COLOUR_WARNING } from "../Message/Message";
import { sendsubscription } from "../../services/Api/Subscription";
import { showValidation } from "../Validation/ShowValidation";
import { postsubscription, putsubscription } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { _JsonStorage } from '../../Global';

export default class SubscriptionFormCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubscription: {
        nameSubscription: "",
        disabled: false,
        changebutton: false
      }, 
      identifier: 0
    };
  } 

  componentDidMount(){
    this.setState({ identifier: this.props.filterclient });
  }

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleOnSubmit = e => {
    e.preventDefault();
    if (this.state.identifier === 0) {
      notify(
        "Primero debe seleccionar una empresa para asociarla a la suscripción.",
        COLOUR_WARNING,
        5000
      );
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
      );
      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit(e);
      }
    }
  };

  handleSubmit = () => {
    let url = "";
    let header = "";

    let dataFetch = new FormData();
    dataFetch.append("nameSubscription",this.state.formSubscription.nameSubscription);
    dataFetch.append("idsuscription", this.props.subscription);
    dataFetch.append("idEnterprise", this.state.identifier);

    url = postsubscription;// this.props.subscription > 0 ? putsubscription : postsubscription;
    header =  headerPost(dataFetch, _JsonStorage.access_token);//this.props.subscription > 0 ? headerPut(dataFetch, _JsonStorage.access_token) : headerPost(dataFetch, _JsonStorage.access_token);

    let data = sendsubscription(url, header);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          showValidation(result, 1,'suscripción');
          const client = this.state.identifier;
          this.props.handleReloadSidebar();
          this.props.handleChangeFilterClient(client);
          this.props.handleOpenModal();
        }else{
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  render() {
    const { nameSubscription } = this.state.formSubscription;
    return (
      <div>
        <form name="formSubscription" autoComplete="off" onSubmit={this.handleOnSubmit}>
          <div className="form-row align-items-center">
            <div className="col-md-11">
              <InputGroup className="mb-2">
                <Input
                  type="text"
                  name="nameSubscription"
                  placeholder="Nombre suscripción"
                  value={nameSubscription}
                  onChange={this.validateOnChange}
                  invalid={ this.hasError("formSubscription","nameSubscription","required") ||
                    this.hasError("formSubscription","nameSubscription","alphanumspace")
                  }
                  data-validate='["required", "alphanumspace"]'
                />
                {this.hasError("formSubscription","nameSubscription","alphanumspace") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                <InputGroupAddon addonType="prepend">
                  <Button type="submit" className="btn bg-primary minus-square">
                    Guardar
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
