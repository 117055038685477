import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import FormBilling from "./FormBilling";
import DatagridBilling from "../Billing/DatagridBilling";

export default class ModalBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangeDatagrid: false,
      isEdit: false,
      datashared: null
    };
  }  

  handleChangeDatagrid = () => this.setState({ isChangeDatagrid: !this.state.isChangeDatagrid });

  handleShared = param =>this.setState({ datashared: param });

  handleChangeIsEdit = () =>this.setState({ isEdit: !this.state.isEdit });

  render() {
    const { openModal, showModal, cardIdentifier } = this.props;
    const { isChangeDatagrid, datashared, isEdit } = this.state;
    return (
      <div>
        <Modal isOpen={openModal} toggle={showModal}>
          <div className="modal-header">
            {" "}
            Facturación
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={showModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <FormBilling
              identifierapp={cardIdentifier}
              isEdit={isEdit}
              datashared={datashared}
              handleChangeDatagrid={this.handleChangeDatagrid}
              handleChangeIsEdit={this.handleChangeIsEdit}
            />
            <DatagridBilling
              identifierapp={cardIdentifier}
              isChangeDatagrid={isChangeDatagrid}
              handleChangeDatagrid={this.handleChangeDatagrid}
              handleShared={this.handleShared}
              handleChangeIsEdit={this.handleChangeIsEdit}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
