import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import DataGrid, {
  Column,
  SearchPanel 
} from "devextreme-react/data-grid";

import { getBilling, deleteBilling } from "../../services/Api/BillingCycle";
import { showValidation } from "../Validation/ShowValidation";

export default class DatagridBillingCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      dataPackage: [
        {
          BillingCycle: { idBillingCycle: 0 },
          nameTypeBillingCycle: "",
          nameMeasuredUnit: ""
        }
      ]
    };
  }

  componentDidMount() {
    this.props.handleChangeGrid();
  }

  componentDidUpdate() {
    this.handleLoadGrid();
  }

  handleLoadGrid = () => {
    if (this.props.isChange) {
      this.props.handleChangeGrid();
      let data = getBilling();
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            let array = [];
            result.response.forEach(element => {
              let obj = {
                BillingTypes: { idBillingTypes: element.idBillingCycle },
                nameMeasuredUnit: element.nameMeasuredUnit,
                idMeasuredUnit: element.idMeasuredUnit,
                description: element.description,
                namebilling: element.nameBillingCycle
              };
              array.push(obj);
            });
            this.setState({ dataPackage: array });
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleOnClickEdit = event =>{
       let identifier = event.row.data;
       this.props.handleEdit(identifier);
       this.setState({ selectedRowKeys: [identifier.BillingTypes.idBillingTypes] });
  }

  handleOnClickDelete = event =>{
      let identifier = event.row.data.BillingTypes.idBillingTypes;
      let data = deleteBilling(identifier);
      let promise = new Promise((rest,rej)=>{
        rest(data);
        rej(Error(data));
      });
      promise.then(result=>{
         if(result.success){
          this.props.handleChangeGrid();
         }
         showValidation(result);
      },function(error){
        console.log(error);
      });
  }

  render() {
    const { dataPackage, selectedRowKeys } = this.state;
    return (
      <div>
        <Card>
          <CardBody>
            <DataGrid
              dataSource={dataPackage}
              keyExpr={"BillingTypes.idBillingTypes"}
              showBorders={true}
              hoverStateEnabled={true}
              selectedRowKeys={selectedRowKeys}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={"Buscar..."}
              /> 
              <Column
                dataField={"namebilling"}
                caption={"Nombre tipo facturación"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                dataField={"nameMeasuredUnit"}
                caption={"Nombre unidad"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                type={"buttons"}
                dataFiled={"BillingTypes"}
                buttons={[
                  {
                    hint: "Editar",
                    icon: "edit",
                    onClick: this.handleOnClickEdit
                  },
                  {
                    hint: "Eliminar",
                    icon: "clear",
                    onClick: this.handleOnClickDelete
                  }
                ]}
              />
            </DataGrid>
          </CardBody>
        </Card>
      </div>
    );
  }
}
 