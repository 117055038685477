import React from "react";
import notify from "devextreme/ui/notify";
import { Collapse, Button } from "reactstrap";
import DataGrid, {
  Column,
  SearchPanel,
  Selection
} from "devextreme-react/data-grid";

import ModalCreate from "./ModalCreate/SubscriptionModalCreate";
import { subscriptionbyclient } from "../../services/Api/Subscription";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

class SidebarSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSubscriptions: [],
      subscription: 0,
      openModalCreate: false,
      clientfilter: 0,
      showEmployeeInfo: false,
      selectedRowKeys: []
    };
  }

  componentDidUpdate() {
    if (this.props.reloadsidebar) {
      this.props.handleReloadSidebar();
      const client = this.state.clientfilter;
      this.handleSubscriptionClient(client);
    }

    if (this.props.filterclient !== this.state.clientfilter) {
      this.setState({ clientfilter: this.props.filterclient });
      const filter = this.props.filterclient;
      this.handleSubscriptionClient(filter);
    }
  }

  handleSubscriptionClient = param => {
    let data = subscriptionbyclient(param);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.response.forEach(element => {
            let obj = {
              Subscription: { idSubscription: element.idSuscription },
              subscriptionDate: element.startDate,
              subscriptionName: element.nameSubscription
            };
            array.push(obj);
          });

          const state = this.state.dataSubscriptions;
          if (state < array && state.length > 0) {
            this.setState({ dataSubscriptions: array });
            this.handleChangeSidebarLimit();
          } else {
            this.setState({ dataSubscriptions: array });
          }
        } else {
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };  

  handleOnClickEdit = event => {
    let identifier = event.row.data.Subscription;
    this.props.handleEditSubscription(identifier.idSubscription);
    this.setState({ selectedRowKeys: [identifier.idSubscription] });
  };

  handleOpenModal = () => {
    if (this.state.clientfilter === 0) {
      notify(
        "Primero seleccione la empresa a la que desea asignar la suscripción.",
        COLOUR_WARNING,
        4000
      );
    } else {
      this.setState({ openModalCreate: !this.state.openModalCreate });
    }
  };

  onSelectionChanged({ selectedRowsData }) {
    const data = selectedRowsData;
    if (data !== undefined) {
      this.setState({ showEmployeeInfo: !!data });
    }
  }
 
  handleChangeSidebarLimit = () => { 
    const limit = this.state.dataSubscriptions.length;
    const data = this.state.dataSubscriptions[limit - 1];
    this.props.handleSharedDataEdit(data);
    this.props.handleEditSubscription(data.Subscription.idSubscription);
    this.setState({ selectedRowKeys: [data.Subscription.idSubscription] });
  };

  render() {
    const { dataSubscriptions, openModalCreate, selectedRowKeys } = this.state;
    const {
      reset,
      filterclient,
      subscription,
      handleReloadSidebar,
      handleChangeFilterClient,
      isEdit,
      handleSharedDataEdit
    } = this.props;
    return (
      <div>
        <Collapse className="mb-boxes" isOpen={true}>
          <div className="card card-default">
            <div className="card-body">
              <div className="row">
                <ul className="nav nav-pills flex-column col-md-6">
                  <li className="nav-item p-2">
                    <strong> LISTA DE SUSCRIPCIONES</strong>
                  </li>
                </ul>
                <ul className="nav nav-pills flex-column col-md-6">
                  <li className="nav-item p-2">
                    <Button
                      type="button"
                      className="bg-primary align-right"
                      title="Crear suscripción"
                      onClick={this.handleOpenModal}
                    >
                      Crear Suscripción
                    </Button>
                  </li>
                </ul>
              </div>
              <div>
                <DataGrid
                  dataSource={dataSubscriptions}
                  keyExpr={"Subscription.idSubscription"}
                  showBorders={true}
                  hoverStateEnabled={true}
                  selectedRowKeys={selectedRowKeys}
                >
                  <Selection mode={"single"} deferred={true} />
                  <SearchPanel
                    visible={true}
                    width="auto"
                    placeholder={"Filtrar suscripción..."}
                  />
                  <Column
                    dataField={"subscriptionDate"}
                    caption={"Fecha Creación"}
                    headerFilter={{ allowSearch: true }}
                  />
                  <Column 
                    dataField={"subscriptionName"}
                    caption={"Nombre Suscripción"}
                    headerFilter={{ allowSearch: true }}
                  />{" "}
                  <Column
                    type={"buttons"}
                    dataFiled={"Subscription"}
                    caption={"Acción"}
                    width={80}
                    buttons={[
                      {
                        hint: "Ver",
                        icon: "fas fa-chevron-circle-right",
                        onClick: this.handleOnClickEdit
                      }
                    ]}
                  />
                </DataGrid>
              </div>
            </div>
          </div> 
        </Collapse>
        <ModalCreate
          openModal={openModalCreate}
          reset={reset}
          filterclient={filterclient}
          subscription={subscription}
          isEdit={isEdit}
          handleSharedDataEdit={handleSharedDataEdit}
          handleOpenModal={this.handleOpenModal}
          handleReloadSidebar={handleReloadSidebar}
          handleChangeFilterClient={handleChangeFilterClient}
        />
      </div>
    );
  }
}
export default SidebarSubscription;
