import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import FormValidator from "../Validation/FormValidation";

import { COLOUR_WARNING } from "../Message/Message";
import { updatePassword } from "../../services/Api/User";
import { showValidation } from "../Validation/ShowValidation";
import { LengthMinPassword, LengthMaxPassword, _JsonStorage } from '../../Global';

export default class FormEditPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormUpdate: true,
      formUpdate: {
        currentPassword: "",
        password: "",
        confirmpassword: ""
      }
    };
  }

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleOnSubmit = event => {
    event.preventDefault();

    const form = event.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    var dataFetch = new FormData();
    dataFetch.append("currentpassword", this.state.formUpdate.currentPassword);
    dataFetch.append("password", this.state.formUpdate.confirmpassword);
    dataFetch.append("code", _JsonStorage.profile.id);

    let data = updatePassword(dataFetch);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });

    promise.then(
      response => {
        if (response.success) {
          this.setState({
            formUpdate: {
              currentPassword: "",
              password: "",
              confirmpassword: ""
            }
          });
          showValidation(response, 2, 'contraseña');
        } else if (response.state === 404) {
          showValidation(0, 0, '', 'La contraseña actual es incorrecta, por favor verifica e intenta nuevamente', COLOUR_WARNING);
        } else {
          showValidation(response);
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  render() {
    const { isFormUpdate } = this.state;
    const { password, confirmpassword, currentPassword } = this.state.formUpdate;
    return (
      <Card className="card-default">
        <form name="formUpdate" onSubmit={this.handleOnSubmit}>
          <CardHeader>
            <legend>Actualizar contraseña</legend>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <label>
                Contraseña Actual <strong className="text-danger">*</strong>{" "}
              </label>
              <Input
                type="password"
                name="currentPassword"
                placeholder="Ingrese su nueva contraseña."
                onChange={this.handleOnChange}
                invalid={this.hasError("formUpdate", "currentPassword", "required")}
                data-validate='["required"]'
                value={currentPassword}
              />
            </FormGroup>
            <FormGroup>
              <label>
                Nueva Contraseña <strong className="text-danger">*</strong>{" "}
              </label>
              <Input
                type="password"
                name="password"
                id="id-source"
                placeholder="Ingrese su nueva contraseña."
                onChange={this.handleOnChange}
                invalid={
                  this.hasError("formUpdate", "password", "required") ||
                  this.hasError("formUpdate", "password", "len") ||
                  this.hasError("formUpdate", "password", "strongpassword")
                }
                data-validate='["required","len","strongpassword"]'
                value={password}
                data-param={`[${LengthMinPassword},${LengthMaxPassword}]`}
              />
              {this.hasError("formUpdate", "password", "len") && (
                <span className="invalid-feedback">
                  La longitud minima de la contraseña es de {LengthMinPassword} y un máximo de {LengthMaxPassword} caracteres.
                </span>)}
              {this.hasError("formUpdate", "password", "strongpassword") && (
                <span className="invalid-feedback">
                  Debe contar como mínimo con una letra minúscula y un numero.
                </span>)}
            </FormGroup>
            <FormGroup>
              <label>
                Confirmar contraseña <strong className="text-danger">*</strong>{" "}
              </label>
              <Input
                type="password"
                name="confirmpassword"
                placeholder="Confirme su nueva contraseña."
                onChange={this.handleOnChange}
                invalid={
                  this.hasError("formUpdate", "confirmpassword", "required") ||
                  this.hasError("formUpdate", "confirmpassword", "equalto")
                }
                data-validate='["required","equalto"]'
                value={confirmpassword}
                data-param="id-source"
              />
              <span className="invalid-feedback">
                Las contraseñas no coinciden.
              </span>
            </FormGroup>
          </CardBody>
          <CardFooter>
            {isFormUpdate === true ? (
              <Button className="bg-success col-12">Actualizar</Button>
            ) : (
                <Button className="bg-primary col-12">Guardar</Button>
              )}
          </CardFooter>
        </form>
      </Card>
    );
  }
}
