import MethodGet from '../../services/MethodGet';
import { getPermission, getCategory } from '../../components/RouteApi/RouteApi';
import { headerGet } from '../Headers';
import { _JsonStorage } from '../../Global';

///Lista de permisos.
export function permissionLoading (){
    let header = headerGet( _JsonStorage.access_token);
    return  MethodGet(getPermission, header).then(response => response).catch(error => error);
}; 

///Lista de categorias.
export const categoriaLoading = () => { 
    let header = headerGet( _JsonStorage.access_token);
    var response = MethodGet(getCategory, header)
    .then(response=>response)
    .catch(error=>error);

    return new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    });
};
