import React, { Component } from "react";
import { translate } from "react-i18next";
import { Row } from "reactstrap";
import { appOwnerAsync, appThirAsync } from "../../services/Api/Application";
import { ExecuteFn, _JsonStorage } from '../../Global';

import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

import "./docs/SingleView.css";

class ContainerApplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
      isSpinner: false
    };
  }

  componentDidMount() {
    ExecuteFn(this.handleAppsOwner);
    ExecuteFn(this.handleAppThir);
  }

  ///Consultar aplicaciones de propietarios
  handleAppsOwner = () => {
    this.changeSpinner(true)
    let promise = appOwnerAsync();
    promise.then(
      response => {
        if (response.success || (response.objeto !== undefined && response.objeto !== null)) {
          if (response.objeto.length > 0) {
            const array = this.state.apps;
            response.objeto.forEach(element => {
              let obj = {
                name: element.nameAplication,
                logo: element.route,
                url: element.url,
                subscription: element.nameSubscription
              };
              array.push(obj);
            });
            this.setState({ apps: array });
            this.props.handleNumberApp(array.length);
          }
        }
        this.changeSpinner(false)
      },
      function (error) {
        this.changeSpinner(false)
      });
  };

  ///Consultar aplicaciones de terceros
  handleAppThir = () => {
    this.changeSpinner(true)
    let promise = appThirAsync();
    promise.then(
      response => {
        if (response.success || (response.objeto !== undefined && response.objeto !== null)) {
          if (response.objeto.length > 0) {
            const array = this.state.apps;
            response.objeto.forEach(element => {
              let obj = {
                name: element.nameAplication,
                logo: element.route,
                url: element.url,
                subscription: element.nameSubscription
              };
              array.push(obj);
            });
            this.setState({ apps: array });
            this.props.handleNumberApp(array.length);
          }
        }
        this.changeSpinner(false)
      },
      function (error) {
        this.changeSpinner(false)
      }
    );
  };

  changeSpinner = (parameter) => {
    this.setState({
      isSpinner: parameter
    })
  }

  render() {
    const { apps, isSpinner } = this.state;
    return (
      <Row>
        <div className="col-xl-12">
          <div className="card card-default" id="cardChart9">
            <div className="card-header">
              <div className="card-title">Mis aplicaciones</div>
            </div>
            <div className="card-body">
              <SpinnerDisableContent isSpinnerDisabled={isSpinner}>
                <Row>
                  {apps.length === 0 ? (
                    <div className="position-empty">
                      <h2>No cuentas con aplicaciones asignadas.</h2>
                    </div>
                  ) : (
                      apps.map((item, i) => <CardApp item={item} key={i + 1} />)
                    )}
                </Row>
              </SpinnerDisableContent>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}

const CardApp = ({ item }) => (
  <div className="position-cardapp">
    <div className="card card-default">
      <div className="card-body">
        <a
          className="text-muted float-right"
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <em className="fa fa-arrow-right"></em>
        </a>
        <div className="text-info">{item.name}</div>
        <div className="position-img">
          <img src={item.logo} alt="Sample" className="img-left thumb128" />
        </div>
      </div>
      <div className="card-footer">
        <p className="text-muted">
          <em className="fa fa-key fa-fw"></em> <span>Suscrita en: </span>
          <span className="text-dark">{item.subscription}</span>
        </p>
      </div>
    </div>
  </div>
);

export default translate("translations")(ContainerApplications);
