import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import FormCreate from "../SubscriptionFormCreate";

export default class SubscriptionModalCreate extends Component {
  render() {
    const {
      openModal,
      reset,
      filterclient,
      subscription,
      isEdit, 
      handleSharedDataEdit,
      handleOpenModal,
      handleReloadSidebar,
      handleChangeFilterClient
    } = this.props;
    return (
      <Modal isOpen={openModal} toggle={handleOpenModal}>
        <div className="modal-header">
          <strong>Crear Suscripción</strong>
          <button type="button" className="close float-right" data-dismiss="modal" aria-label="Close" onClick={handleOpenModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>  
          <FormCreate
            isEdit={isEdit}
            reset={reset}
            filterclient={filterclient}
            subscription={subscription}
            handleReloadSidebar={handleReloadSidebar}
            handleChangeFilterClient={handleChangeFilterClient}
            handleOpenModal={handleOpenModal}
            handleSharedDataEdit={handleSharedDataEdit}
          />
        </ModalBody>
      </Modal>
    );
  }
}
