import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import MeasurementForm from "./MeasurementForm";

export default class MeasurementModal extends Component {
  render() {
    const { handleOpenModal, openModal, handleChangeData, isChange } = this.props;
    return (
      <Modal className="modal-lg" isOpen={openModal} toggle={handleOpenModal}>
        <div className="modal-header">
          {" "}
          <strong>Gestión de unidades de medidas.</strong>
          <button
            type="button"
            className="close float-right"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleOpenModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <MeasurementForm handleChangeData={handleChangeData} isChange={isChange} />
        </ModalBody>
      </Modal>
    );
  }
}
