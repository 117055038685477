import React, { Component } from "react";

import { FormGroup } from "reactstrap";
import { putAvatarUser, loadAvatarUser } from "../../services/Api/Icon/Icon";
import { COLOUR_SUCCESS, COLOUR_WARNING, COLOUR_INFO } from "../Message/Message";
import { _JsonStorage } from '../../Global';
import { ProfileContext } from "./docs/Context";

import ImageUploader from "react-images-upload";
import notify from "devextreme/ui/notify";
import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

import ImgUserDefault from './docs/user.png'

import "./docs/Profile.css";

class SidebarUserBlock extends Component {
  static contextType = ProfileContext;
  constructor(props) {
    super(props)
    this.state = {
      isSpinner: false,
      avatar: ImgUserDefault
    }
  }

  componentDidMount() {
    this.handleLoadImgUser()
  }

  handleLoadImgUser = () => {
    let avatar = sessionStorage.getItem("Avatar");
    if (avatar == null) {
      let param = _JsonStorage.profile.id;
      let data = loadAvatarUser(param);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.isSuccess && result.objeto !== null) {
            sessionStorage.setItem("Avatar", JSON.stringify(result.objeto.route));
            this.setState({ avatar: result.objeto.route });
          } else {
            this.setState({ avatar: ImgUserDefault });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      let avatarParse = JSON.parse(avatar);
      this.setState({ avatar: avatarParse });
    }
  };

  handleOnClickContent = event => {
    let param = parseInt(event.currentTarget.value);
    this.props.handleChangeContent(param);
  };

  handleOnDrop = pictures => {
    for (let index = 0; index < 90; index++) {
      this.setState({ progress: index, colorProgress: "primary" });
    }

    let dataFetch = new FormData();
    let code = _JsonStorage.profile.id;

    if (code === null) {
      notify(
        "La imagen no se cargo de forma correcta, por favor intente nuevamente. " +
        "Si el problema persiste recargue su navegador de lo contrario comuníquese con un administrador",
        COLOUR_INFO,
        7000
      );
    } else {
      sessionStorage.removeItem("Avatar");
      let indexPicture = pictures.length - 1;
      dataFetch.append("file", pictures[indexPicture]);
      dataFetch.append("jsoncode", code);

      this.setState({ isSpinner: true })
      let data = putAvatarUser(dataFetch);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.isSuccess) {
            this.handleLoadImgUser()
            notify(
              result.message,
              COLOUR_SUCCESS,
              7000
            );
          } else {
            notify(
              result.message,
              COLOUR_WARNING,
              7000
            );
          }
          this.setState({ isSpinner: false })
        },
        function (error) {
        }
      );
    }
  };

  render() {
    const { content } = this.props;
    const { name } = this.context.data;
    return (
      <div className="card">
        <SpinnerDisableContent isSpinnerDisabled={this.state.isSpinner}>
          <div
            className="card-body text-center bg-center"
            style={{ backgroundImage: "url('img/bg4.jpg')" }}
          >
            <div className="row">
              <div className="col-12 text-white">
                <img
                  className="img-thumbnail circle thumb128"
                  src={this.state.avatar}
                  alt="Avatar"
                />
                <h3 className="m-0">{name}</h3>
                <ImageUploader
                  withIcon={false}
                  buttonText="Cambiar imagen de perfil"
                  label="Tamaño máximo de archivo: 1mb, acepta: jpg | png | jpeg"
                  imgExtension={[".jpg", ".png", ".jpeg"]}
                  maxFileSize={5242880}
                  className="btn-img-profile"
                  withLabel={false}
                  fileContainerStyle={{ background: "#fff0" }}
                  onChange={this.handleOnDrop}
                />
              </div>
            </div>
          </div>

          <div className="card-body text-center bg-gray-darker">
            <FormGroup>
              <div className="col-md-12">
                <button
                  type="button"
                  value={1}
                  className={`btn btn-primary col-md-12 ${(content === 1) ? 'bg-primary-light' : 'bg-primary-dark'}`}
                  onClick={this.handleOnClickContent}
                >
                  Información personal
                </button>
              </div>
            </FormGroup>

            <FormGroup>
              <div className="col-md-12">
                <button
                  type="button"
                  value={2}
                  className={`btn btn-primary col-md-12 ${(content === 2) ? 'bg-primary-light' : 'bg-primary-dark'}`}
                  onClick={this.handleOnClickContent}
                >
                  Cambiar contraseña
                </button>
              </div>
            </FormGroup>
          </div>
        </SpinnerDisableContent>
      </div>
    );
  }
}
export default SidebarUserBlock;
