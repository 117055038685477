import React, { Component } from "react";
import { FormGroup, Row, Col, Input, Button } from "reactstrap";
import FormValidator from "../Validation/FormValidation";
import notify from "devextreme/ui/notify";

import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";
import {
  ClientPostAsync,
  ClientGetNameAsync,
  ClientPutAsync
} from "../../services/Api/Application";
import { MinNumber } from '../../Global';
import SpinnerAnimate from '../Progress/SpinnerAnimate';

import "./docs/Application.css";

export default class ApplicationClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formClient: {
        id: 0,
        enabled: false,
        clientId: "", //Max-200
        protocolType: "", //Max-200
        requireClientSecret: false,
        clientName: "", //Max-200
        description: "", //Max-1000
        clientUri: "", //Max-2000
        logoUri: [], //Max-2000 --- cargar archivo y asignarlo a este campo
        requireConsent: false,
        allowRememberConsent: false,
        alwaysIncludeUserClaimsInIdToken: false,
        requirePkce: false,
        allowPlainTextPkce: false,
        allowAccessTokensViaBrowser: false,
        frontChannelLogoutUri: "", //Max-2000
        frontChannelLogoutSessionRequired: false,
        backChannelLogoutUri: "", //Max-2000
        backChannelLogoutSessionRequired: false,
        allowOfflineAccess: false,
        identityTokenLifetime: 0,
        accessTokenLifetime: 0,
        authorizationCodeLifetime: 0,
        consentLifetime: 0,
        absoluteRefreshTokenLifetime: 0,
        slidingRefreshTokenLifetime: 0,
        refreshTokenUsage: 0,
        updateAccessTokenClaimsOnRefresh: false,
        refreshTokenExpiration: 0,
        accessTokenType: 0,
        enableLocalLogin: false,
        includeJwtId: false,
        alwaysSendClientClaims: false,
        clientClaimsPrefix: "", //Max-200
        pairWiseSubjectSalt: "", //Max-200
        created: Date.now(), ///Control del servidor
        updated: Date.now(), ///Control del servidor
        lastAccessed: Date.now(), ///Control del servidor
        userSsoLifetime: 0,
        userCodeType: "", //Max-100
        deviceCodeLifetime: 0,
        nonEditable: false
      },
      isEdit: false,
      isSpinner: false
    };
  }

  componentDidUpdate() {
    let props = this.props;

    ///Resetar formulario si el usuario oculta el modal y se encontraba en el estado editar.
    if (props.openModal === false && this.state.isEdit === true) {
      this.handleResetForm();
    }

    ///Agregar datos del usuario al formulario para editar
    if (props.loadDataConfig !== null && props.openModal === true && this.state.formClient.id === 0) {
      this.props.handleEditConfigEmpty();
      let data = props.loadDataConfig;
      this.setState({
        formClient: {
          ...this.state.formClient,
          id: data.id,
          enabled: data.enabled,
          clientId: data.clientId,
          protocolType: data.protocolType,
          requireClientSecret: data.requireClientSecret,
          clientName: data.clientName,
          description: data.description,
          clientUri: data.clientUri,
          requireConsent: data.requireConsent,
          allowRememberConsent: data.allowRememberConsent,
          alwaysIncludeUserClaimsInIdToken:
            data.alwaysIncludeUserClaimsInIdToken,
          requirePkce: data.requirePkce,
          allowPlainTextPkce: data.allowPlainTextPkce,
          allowAccessTokensViaBrowser: data.allowAccessTokensViaBrowser,
          frontChannelLogoutUri: data.frontChannelLogoutUri,
          frontChannelLogoutSessionRequired:
            data.frontChannelLogoutSessionRequired,
          backChannelLogoutUri: data.backChannelLogoutUri,
          backChannelLogoutSessionRequired:
            data.backChannelLogoutSessionRequired,
          allowOfflineAccess: data.allowOfflineAccess,
          identityTokenLifetime: data.identityTokenLifetime,
          accessTokenLifetime: data.accessTokenLifetime,
          authorizationCodeLifetime: data.authorizationCodeLifetime,
          consentLifetime: data.consentLifetime,
          absoluteRefreshTokenLifetime: data.absoluteRefreshTokenLifetime,
          slidingRefreshTokenLifetime: data.slidingRefreshTokenLifetime,
          refreshTokenUsage: data.refreshTokenUsage,
          updateAccessTokenClaimsOnRefresh:
            data.updateAccessTokenClaimsOnRefresh,
          refreshTokenExpiration: data.refreshTokenExpiration,
          accessTokenType: data.accessTokenType,
          enableLocalLogin: data.enableLocalLogin,
          includeJwtId: data.includeJwtId,
          alwaysSendClientClaims: data.alwaysSendClientClaims,
          clientClaimsPrefix: data.clientClaimsPrefix,
          pairWiseSubjectSalt: data.pairWiseSubjectSalt,
          userSsoLifetime: data.userSsoLifetime,
          userCodeType: data.userCodeType,
          deviceCodeLifetime: data.deviceCodeLifetime,
          nonEditable: data.nonEditable,
          codeApp: props.appCode
        },
        isEdit: true
      });
    }
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Cambio de estado de los campos.
  handleChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Validar campos del formulario antes de guardar
  handleOnSubmit = event => {
    event.preventDefault();
    const form = event.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
    );
    ///Validar campos requeridos.
    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    ///Asignar nuevos valores al state.
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      this.handleSend();
    }
  };

  ///Obtener datos del formulario
  handleObject = () => {
    const props = this.props;
    const data = this.state.formClient;
    let obj = {
      id: data.id,
      enabled: data.enabled,
      clientId: data.clientId,
      protocolType: data.protocolType,
      requireClientSecret: data.requireClientSecret,
      clientName: data.clientName,
      description: data.description,
      clientUri: data.clientUri,
      requireConsent: data.requireConsent,
      allowRememberConsent: data.allowRememberConsent,
      alwaysIncludeUserClaimsInIdToken: data.alwaysIncludeUserClaimsInIdToken,
      requirePkce: data.requirePkce,
      allowPlainTextPkce: data.allowPlainTextPkce,
      allowAccessTokensViaBrowser: data.allowAccessTokensViaBrowser,
      frontChannelLogoutUri: data.frontChannelLogoutUri,
      frontChannelLogoutSessionRequired: data.frontChannelLogoutSessionRequired,
      backChannelLogoutUri: data.backChannelLogoutUri,
      backChannelLogoutSessionRequired: data.backChannelLogoutSessionRequired,
      allowOfflineAccess: data.allowOfflineAccess,
      identityTokenLifetime: data.identityTokenLifetime,
      accessTokenLifetime: data.accessTokenLifetime,
      authorizationCodeLifetime: data.authorizationCodeLifetime,
      consentLifetime: data.consentLifetime,
      absoluteRefreshTokenLifetime: data.absoluteRefreshTokenLifetime,
      slidingRefreshTokenLifetime: data.slidingRefreshTokenLifetime,
      refreshTokenUsage: data.refreshTokenUsage,
      updateAccessTokenClaimsOnRefresh: data.updateAccessTokenClaimsOnRefresh,
      refreshTokenExpiration: data.refreshTokenExpiration,
      accessTokenType: data.accessTokenType,
      enableLocalLogin: data.enableLocalLogin,
      includeJwtId: data.includeJwtId,
      alwaysSendClientClaims: data.alwaysSendClientClaims,
      clientClaimsPrefix: data.clientClaimsPrefix,
      pairWiseSubjectSalt: data.pairWiseSubjectSalt,
      userSsoLifetime: data.userSsoLifetime,
      userCodeType: data.userCodeType,
      deviceCodeLifetime: data.deviceCodeLifetime,
      nonEditable: data.nonEditable,
      codeApp: props.appCode
    };

    return obj;
  };

  ///Guardar o actualizar datos
  handleSend = () => {
    if (this.props.appCode === 0) {
      notify("Antes de guardar, cree la aplicación a la que se le asignara la configuración.",COLOUR_WARNING,5000);
      return;
    }

    this.handleSpinner();
    let obj = this.handleObject();

    ///Actualizar
    if (this.state.formClient.id !== 0) {
      ClientPutAsync(obj).then(
        response => {
          if (response.success) {
            showValidation(response, 2, "configuración");
          } else {
            showValidation(response);
          }
          this.handleSpinner();
        },
        function(error) {
          console.log(error);
        }
      );
    }
    ///Crear
    else if (this.state.formClient.id === 0) {
      ClientPostAsync(obj).then(
        response => {
          if (response.success) {
            showValidation(response, 1, "configuración");
            this.handleGetConfig();
          } else {
            showValidation(response);
          }
          this.handleSpinner();
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  ///Consultar configuración creada para la aplicación
  handleGetConfig = () => {
    ClientGetNameAsync(this.state.formClient.clientId).then(
      response => {
        if (response.success) {
          this.handleCastClient(response.response);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Agregar valores de la consulta al state
  handleCastClient = resource => {
    this.setState({
      formClient: {
        ...this.state.formClient,
        id: resource.id,
        enabled: resource.enabled,
        clientId: resource.clientId,
        protocolType: resource.protocolType,
        requireClientSecret: resource.requireClientSecret,
        clientName: resource.clientName,
        description: resource.description,
        clientUri: resource.clientUri,
        requireConsent: resource.requireConsent,
        allowRememberConsent: resource.allowRememberConsent,
        alwaysIncludeUserClaimsInIdToken:
          resource.alwaysIncludeUserClaimsInIdToken,
        requirePkce: resource.requirePkce,
        allowPlainTextPkce: resource.allowPlainTextPkce,
        allowAccessTokensViaBrowser: resource.allowAccessTokensViaBrowser,
        frontChannelLogoutUri: resource.frontChannelLogoutUri,
        frontChannelLogoutSessionRequired:
          resource.frontChannelLogoutSessionRequired,
        backChannelLogoutUri: resource.backChannelLogoutUri,
        backChannelLogoutSessionRequired:
          resource.backChannelLogoutSessionRequired,
        allowOfflineAccess: resource.allowOfflineAccess,
        identityTokenLifetime: resource.identityTokenLifetime,
        accessTokenLifetime: resource.accessTokenLifetime,
        authorizationCodeLifetime: resource.authorizationCodeLifetime,
        consentLifetime: resource.consentLifetime,
        absoluteRefreshTokenLifetime: resource.absoluteRefreshTokenLifetime,
        slidingRefreshTokenLifetime: resource.slidingRefreshTokenLifetime,
        refreshTokenUsage: resource.refreshTokenUsage,
        updateAccessTokenClaimsOnRefresh:
          resource.updateAccessTokenClaimsOnRefresh,
        refreshTokenExpiration: resource.refreshTokenExpiration,
        accessTokenType: resource.accessTokenType,
        enableLocalLogin: resource.enableLocalLogin,
        includeJwtId: resource.includeJwtId,
        alwaysSendClientClaims: resource.alwaysSendClientClaims,
        clientClaimsPrefix: resource.clientClaimsPrefix,
        pairWiseSubjectSalt: resource.pairWiseSubjectSalt,
        userSsoLifetime: resource.userSsoLifetime,
        userCodeType: resource.userCodeType,
        deviceCodeLifetime: resource.deviceCodeLifetime,
        nonEditable: resource.nonEditable
      },
      isEdit: true
    });
  };

  ///Limpiar formulario
  handleResetForm = () => {
    this.setState({
      formClient: {
        ...this.state.formClient,
        id: 0,
        enabled: false,
        clientId: "",
        protocolType: "",
        requireClientSecret: false,
        clientName: "",
        description: "",
        clientUri: "",
        requireConsent: false,
        allowRememberConsent: false,
        alwaysIncludeUserClaimsInIdToken: false,
        requirePkce: false,
        allowPlainTextPkce: false,
        allowAccessTokensViaBrowser: false,
        frontChannelLogoutUri: "",
        frontChannelLogoutSessionRequired: false,
        backChannelLogoutUri: "",
        backChannelLogoutSessionRequired: false,
        allowOfflineAccess: false,
        identityTokenLifetime: 0,
        accessTokenLifetime: 0,
        authorizationCodeLifetime: 0,
        consentLifetime: 0,
        absoluteRefreshTokenLifetime: 0,
        slidingRefreshTokenLifetime: 0,
        refreshTokenUsage: 0,
        updateAccessTokenClaimsOnRefresh: false,
        refreshTokenExpiration: 0,
        accessTokenType: 0,
        enableLocalLogin: false,
        includeJwtId: false,
        alwaysSendClientClaims: false,
        clientClaimsPrefix: "",
        pairWiseSubjectSalt: "",
        userSsoLifetime: 0,
        userCodeType: "",
        deviceCodeLifetime: 0,
        nonEditable: false
      },
      isEdit: false
    });
  };

  render() {
    const { isEdit, isSpinner } = this.state;
    //#region campos del formulario
    const {
      enabled,
      clientId,
      protocolType,
      requireClientSecret,
      clientName,
      description,
      clientUri,
      requireConsent,
      allowRememberConsent,
      alwaysIncludeUserClaimsInIdToken,
      requirePkce,
      allowPlainTextPkce,
      allowAccessTokensViaBrowser,
      frontChannelLogoutUri,
      frontChannelLogoutSessionRequired,
      backChannelLogoutUri,
      backChannelLogoutSessionRequired,
      allowOfflineAccess,
      identityTokenLifetime,
      accessTokenLifetime,
      authorizationCodeLifetime,
      consentLifetime,
      absoluteRefreshTokenLifetime,
      slidingRefreshTokenLifetime,
      refreshTokenUsage,
      updateAccessTokenClaimsOnRefresh,
      refreshTokenExpiration,
      accessTokenType,
      enableLocalLogin,
      includeJwtId,
      alwaysSendClientClaims,
      clientClaimsPrefix,
      pairWiseSubjectSalt,
      userSsoLifetime,
      userCodeType,
      deviceCodeLifetime,
      nonEditable
    } = this.state.formClient;
    //#endregion
    return (
      <div className="col-xl-12 col-lg-12 anyClass" style={{ height: "679px" }}>
        <form name="formClient" onSubmit={this.handleOnSubmit} autoComplete="off" className="parent">
          <SpinnerAnimate isDisabled={isSpinner} />
          <FormGroup>
            <Row>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Habilitar autenticación
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <Input
                    type="checkbox"
                    name="enabled"
                    value={enabled}
                    checked={enabled}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Identificación del cliente
                </label>
                <Input
                  type="text"
                  name="clientId"
                  value={clientId}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient", "clientId", "required") ||
                    this.hasError("formClient", "clientId", "sqlinject")
                  }
                  data-validate='["required","sqlinject"]'
                />
                 {this.hasError("formClient", "clientId", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Tipo de protocolo
                </label>
                <Input
                  type="text"
                  name="protocolType"
                  value={protocolType}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","protocolType","required")||
                    this.hasError("formClient","protocolType","sqlinject")
                  }
                  data-validate='["required","sqlinject"]'
                />
                {this.hasError("formClient", "protocolType", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Requiere contraseña
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="requireClientSecret"
                    checked={requireClientSecret}
                    value={requireClientSecret}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Nombre del cliente
                </label>
                <Input
                  type="text"
                  name="clientName"
                  value={clientName}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","clientName","required") ||
                    this.hasError("formClient","clientName","sqlinject")
                  }
                  data-validate='["required","sqlinject"]'
                />
                {this.hasError("formClient", "clientName", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">Descripción</label>
                <Input
                  type="text"
                  name="description"
                  value={description}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","description","sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                 {this.hasError("formClient", "description", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Url del cliente
                </label>
                <Input
                  type="text"
                  name="clientUri"
                  value={clientUri}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","clientUri","sqlinject") ||
                    this.hasError("formClient","clientUri","url")
                  }
                  data-validate='["sqlinject","url"]'
                />
                 {this.hasError("formClient", "clientUri", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "clientUri", "url") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Requiere consentimiento
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="requireConsent"
                    value={requireConsent}
                    checked={requireConsent}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Permitir recordar consentimiento
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="allowRememberConsent"
                    value={allowRememberConsent}
                    checked={allowRememberConsent}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Incluir reclamos de usuario en el token de identificación
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="alwaysIncludeUserClaimsInIdToken"
                    value={alwaysIncludeUserClaimsInIdToken}
                    checked={alwaysIncludeUserClaimsInIdToken}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Requiere pkce
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="requirePkce"
                    value={requirePkce}
                    checked={requirePkce}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Permitir texto sin formato pkce
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="allowPlainTextPkce"
                    value={allowPlainTextPkce}
                    checked={allowPlainTextPkce}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Permitir tokens de acceso a través del navegador
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="allowAccessTokensViaBrowser"
                    value={allowAccessTokensViaBrowser}
                    checked={allowAccessTokensViaBrowser}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="7">
                <label className="col-md-12 col-form-label">
                  Url cerrar sesión
                </label>
                <Input
                  type="text"
                  name="frontChannelLogoutUri"
                  value={frontChannelLogoutUri}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","frontChannelLogoutUri","sqlinject") ||
                    this.hasError("formClient","frontChannelLogoutUri","url")
                  }
                  data-validate='["sqlinject","url"]'
                />
                 {this.hasError("formClient", "frontChannelLogoutUri", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "frontChannelLogoutUri", "url") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Requiere url cerrar sesión
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="frontChannelLogoutSessionRequired"
                    value={frontChannelLogoutSessionRequired}
                    checked={frontChannelLogoutSessionRequired}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="7">
                <label className="col-md-12 col-form-label">
                  Url atras al cerrar sesión
                </label>
                <Input
                  type="text"
                  name="backChannelLogoutUri"
                  value={backChannelLogoutUri}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","backChannelLogoutUri","sqlinject") ||
                    this.hasError("formClient","backChannelLogoutUri","url")
                  }
                  data-validate='["sqlinject","url"]'
                />
                {this.hasError("formClient", "backChannelLogoutUri", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "backChannelLogoutUri", "url") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Requiere url atras al cerrar sesión
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="backChannelLogoutSessionRequired"
                    value={backChannelLogoutSessionRequired}
                    checked={backChannelLogoutSessionRequired}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida del token de identidad
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="allowOfflineAccess"
                    value={allowOfflineAccess}
                    checked={allowOfflineAccess}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida del token de identidad
                </label>
                <Input
                  type="number"
                  name="identityTokenLifetime"
                  value={identityTokenLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","identityTokenLifetime","number") ||
                    this.hasError("formClient","identityTokenLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                {this.hasError("formClient", "identityTokenLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "identityTokenLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida del token de acceso
                </label>
                <Input
                  type="number"
                  name="accessTokenLifetime"
                  value={accessTokenLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","accessTokenLifetime","number") ||
                    this.hasError("formClient","accessTokenLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                {this.hasError("formClient", "accessTokenLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "accessTokenLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida código de autorización
                </label>
                <Input
                  type="number"
                  name="authorizationCodeLifetime"
                  value={authorizationCodeLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","authorizationCodeLifetime","number") ||
                    this.hasError("formClient","authorizationCodeLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                  {this.hasError("formClient", "authorizationCodeLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "authorizationCodeLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida consentimiento
                </label>
                <Input
                  type="number"
                  name="consentLifetime"
                  value={consentLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","consentLifetime","number") ||
                    this.hasError("formClient","consentLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                  {this.hasError("formClient", "consentLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "consentLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida token de actualización absoluto
                </label>
                <Input
                  type="number"
                  name="absoluteRefreshTokenLifetime"
                  value={absoluteRefreshTokenLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","absoluteRefreshTokenLifetime","number") ||
                    this.hasError("formClient","absoluteRefreshTokenLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                 {this.hasError("formClient", "absoluteRefreshTokenLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "absoluteRefreshTokenLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Token de actualización deslizante de por vida
                </label>
                <Input
                  type="number"
                  name="slidingRefreshTokenLifetime"
                  value={slidingRefreshTokenLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","slidingRefreshTokenLifetime","number") ||
                    this.hasError("formClient","slidingRefreshTokenLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                   {this.hasError("formClient", "slidingRefreshTokenLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "slidingRefreshTokenLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Actualizar el uso del token
                </label>
                <Input
                  type="number"
                  name="refreshTokenUsage"
                  value={refreshTokenUsage}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","refreshTokenUsage","number") ||
                    this.hasError("formClient","refreshTokenUsage","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                {this.hasError("formClient", "refreshTokenUsage", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "refreshTokenUsage", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Actualizar las notificaciones de tokens de acceso al
                  actualizar
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="updateAccessTokenClaimsOnRefresh"
                    value={updateAccessTokenClaimsOnRefresh}
                    checked={updateAccessTokenClaimsOnRefresh}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Actualizar la expiración del token
                </label>
                <Input
                  type="number"
                  name="refreshTokenExpiration"
                  value={refreshTokenExpiration}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","refreshTokenExpiration","number") ||
                    this.hasError("formClient","refreshTokenExpiration","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                  {this.hasError("formClient", "refreshTokenExpiration", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "refreshTokenExpiration", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tipo de token de acceso
                </label>
                <Input
                  type="number"
                  name="accessTokenType"
                  value={accessTokenType}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","accessTokenType","number") ||
                    this.hasError("formClient","accessTokenType","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                {this.hasError("formClient", "accessTokenType", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "accessTokenType", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Habilitar el inicio de sesión local
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="enableLocalLogin"
                    value={enableLocalLogin}
                    checked={enableLocalLogin}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="3">
                <label className="col-md-12 col-form-label">
                  Incluir JwtId
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="includeJwtId"
                    value={includeJwtId}
                    checked={includeJwtId}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Enviar reclamaciones de clientes
                </label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="alwaysSendClientClaims"
                    value={alwaysSendClientClaims}
                    checked={alwaysSendClientClaims}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
              <Col lg="5">
                <label className="col-md-12 col-form-label">
                  Prefijo de reclamos del cliente
                </label>
                <Input
                  type="text"
                  name="clientClaimsPrefix"
                  value={clientClaimsPrefix}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","clientClaimsPrefix","sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                 {this.hasError("formClient", "clientClaimsPrefix", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Par sujeto sabio sal
                </label>
                <Input
                  type="text"
                  name="pairWiseSubjectSalt"
                  value={pairWiseSubjectSalt}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","pairWiseSubjectSalt","sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                 {this.hasError("formClient", "pairWiseSubjectSalt", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tiempo de vida sesión de usuario
                </label>
                <Input
                  type="number"
                  name="userSsoLifetime"
                  value={userSsoLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","userSsoLifetime","number") ||
                    this.hasError("formClient","userSsoLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                 {this.hasError("formClient", "userSsoLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "userSsoLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="4">
                <label className="col-md-12 col-form-label">
                  Tipo de código de usuario
                </label>
                <Input
                  type="text"
                  name="userCodeType"
                  value={userCodeType}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","userCodeType","sqlinject")
                  }
                  data-validate='["sqlinject"]'
                />
                   {this.hasError("formClient", "userCodeType", "sqlinject") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="6">
                <label className="col-md-12 col-form-label">
                  Vida útil del código del dispositivo
                </label>
                <Input
                  type="number"
                  name="deviceCodeLifetime"
                  value={deviceCodeLifetime}
                  onChange={this.handleChange}
                  invalid={
                    this.hasError("formClient","deviceCodeLifetime","number") ||
                    this.hasError("formClient","deviceCodeLifetime","min")
                  }
                  data-validate='["number","min"]'
                  data-param={MinNumber}
                />
                 {this.hasError("formClient", "deviceCodeLifetime", "number") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
                {this.hasError("formClient", "deviceCodeLifetime", "min") && (
                  <span className="invalid-feedback">
                    El campo debe tener un valor válido.
                  </span>
                )}
              </Col>
              <Col lg="6">
                <label className="col-md-12 col-form-label">No editable</label>
                <label className="switch switch-sm displayIn padding-left17">
                  <input
                    type="checkbox"
                    name="nonEditable"
                    value={nonEditable}
                    checked={nonEditable}
                    onChange={this.handleChange}
                  />
                  <span></span>
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="float-right">
            <br />
            {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}
          </FormGroup>
        </form>
      </div>
    );
  }
}

const ButtonSave = () => (
  <Button type="submit" color="primary">
    Guardar Sección
  </Button>
);
const ButtonUpdate = () => (
  <Button type="submit" className="bg-success">
    Actualizar Sección
  </Button>
);
