import React from "react";
import SweetAlert from "sweetalert-react";
import notify from "devextreme/ui/notify";
import { withNamespaces } from "react-i18next";

import { MESSAGE_REMOVE_EMPTY, COLOUR_WARNING } from "../Message/Message";

import "sweetalert-react/node_modules/sweetalert/dist/sweetalert.css";
import "react-toastify/dist/ReactToastify.css";

class SwalRemove extends React.Component {
  render() {
    const { openModal, element, handleRemove, swalShow, elementRemove } = this.props;
    return (
      <div>
        <SweetAlert 
          show={openModal}///Boleano abre o cierra modal
          title="Confirmar eliminación."
          text={"Escriba '" + element + "' para confirmar la eliminación."}///Valor confirmación eliminación.
          type="input"
          inputPlaceholder=""
          inputType="text"
          showCancelButton
          confirmButtonColor="#EE1918"
          onConfirm={event => {
            if (event === "") {
              swalShow();///Función para cerra modal.
              notify(MESSAGE_REMOVE_EMPTY, COLOUR_WARNING);
            } else {
              handleRemove(event,element,elementRemove);///Función para eliminar el elemento.
            }
          }}
          onCancel={() => {
            swalShow();
          }}
        />
      </div>
    );
  }
}

export default withNamespaces("translations")(SwalRemove);
