import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import ToggleState from "../Common/ToggleState";
import LoadTheme from "../Common/LoadTheme";
 
class Offsidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "settings",
      offsidebarReady: false
    };
  }

  componentDidMount() {
    // When mounted display the offsidebar
    this.setState({ offsidebarReady: true });
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  handleCheckboxState = node => {
    if (!node) return;
    // use element 'name' attribute to check if a class was added to body
    // to activate checkbox or deactivate accordingly
    node.checked = document.body.className.indexOf(node.name) > -1;
  };

  render() {
    return (
      this.state.offsidebarReady && (
        <aside className="offsidebar">
          {/* START Off Sidebar (right) */}
          <nav>
            <div>
              {/* Nav tabs */}
              <Nav tabs justified>
                <NavItem style={{ cursor: "pointer" }}>
                  <NavLink
                    className={
                      this.state.activeTab === "settings" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggle("settings");
                    }}
                  >
                    <em className="icon-equalizer fa-lg"></em>
                  </NavLink>
                </NavItem>
              </Nav>
              {/* Tab panes */}
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="settings">
                  <h3 className="text-center text-thin mt-4">
                    Configuración Web
                  </h3>
                  <div className="p-2" style={{ display: "none" }}>
                    <h4 className="text-muted text-thin">Temas</h4>
                    <div className="row row-flush mb-2">
                      <div className="col mb-2">
                        <div className="setting-color">
                          <LoadTheme theme="G">
                            <label>
                              <input type="radio" name="setting-theme" />
                              <span className="icon-check"></span>
                              <span className="split">
                                <span className="color bg-purple-dark"></span>
                                <span className="color bg-purple"></span>
                              </span>
                              <span className="color bg-gray-dark"></span>
                            </label>
                          </LoadTheme>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <h4 className="text-muted text-thin">Menu</h4>
                    
                    <div className="clearfix">
                      <p className="float-left">Colapsado</p>
                      <div className="float-right">
                        <label className="switch">
                          <ToggleState state="aside-collapsed">
                            <input
                              id="chk-collapsed"
                              type="checkbox"
                              name="aside-collapsed"
                              ref={this.handleCheckboxState}
                            />
                          </ToggleState>
                          <span></span>
                        </label>
                      </div>
                    </div>
                    <div className="clearfix">
                      <p className="float-left">Colapsado con texto</p>
                      <div className="float-right">
                        <label className="switch">
                          <ToggleState state="aside-collapsed-text">
                            <input
                              id="chk-collapsed-text"
                              type="checkbox"
                              name="aside-collapsed-text"
                              ref={this.handleCheckboxState}
                            />
                          </ToggleState>
                          <span></span>
                        </label>
                      </div>
                    </div>
                    <div className="clearfix">
                      <p className="float-left">Barra de desplazamiento</p>
                      <div className="float-right">
                        <label className="switch">
                          <ToggleState state="show-scrollbar" target=".sidebar">
                            <input
                              id="chk-hover"
                              type="checkbox"
                              name="show-scrollbar"
                              ref={this.handleCheckboxState}
                            />
                          </ToggleState>
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </nav>
          {/* END Off Sidebar (right) */}
        </aside>
      )
    );
  }
}

export default Offsidebar;
