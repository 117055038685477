import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";
import Select from "react-select";
import notify from "devextreme/ui/notify";

import { carouselGet, carouselDelete, carouselGetId } from "../../services/Api/Carousel";
import "./docs/Carousel.css";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

const itemDefault = [
  {
    src:
      "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
    altText: "Slide T1",
    caption: "Slide T2",
    codeCarousel: 0
  }
];

class CarouselPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, items: itemDefault, apps: [], app: null };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidUpdate() {
    if (this.props.isChangeCarousel) {
      this.props.handleChangeCarousel();
      this.handleChangePreview(this.props.carousel);
    }

    if (this.props.apps.length !== this.state.apps.length) {
      this.handleLoadApps(this.props.apps);
    }

    if (this.props.isfilter) {
      this.props.handleIsFilter(false, null);
      this.handleChangeSelect(this.props.appfilter);
    }
  }

  handleLoadApps = param => this.setState({ apps: param });

  handleChangePreview = param => {
    const value = param.length === 0 ? itemDefault : param;
    this.setState({
      items: value
    });
  };

  handleChangeSelect = value => {
    const promise = carouselGet(value.value);
    promise.then(
      response => {
        if (response.success) {
          const array = [];
          response.response.forEach(element => {
            const obj = {
              src: element.route === null ? "" : element.route,
              altText: element.header === null ? "" : element.header,
              caption: element.description === null ? "" : element.description,
              codeCarousel: element.codeCarousel
            };
            array.push(obj);
          });
          this.setState({
            items: array.length === 0 ? itemDefault : array,
            app: value
          });
        }else{
          showValidation(response);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleDelete = e => {
    if (e === 0) {
      notify("Esta imagen no se puede eliminar.", COLOUR_WARNING, 3000);
    } else {
      const promise = carouselDelete(e);
      promise.then(
        response => {
          if (response.success) {
            const array = this.handleClearFindByCode(e);
            this.setState({ items: array });
          }
          showValidation(response);
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleEdit = e =>{
    if(e === 0){
      notify("Esta imagen no se puede eliminar.", COLOUR_WARNING, 3000);
    }else{
      const promise = carouselGetId(e.codeCarousel);
      promise.then(response=>{
        if(response.success){
          console.log('service',response);
          console.log('target',e);
        }else{
          showValidation(response);
        }
      },function(error){
        console.log(error);
      });
    }
  }

  handleClearFindByCode = param => {
    const array = this.state.items;
    const array2 = this.state.items;
    array.forEach((element, i) => {
      if (element.codeCarousel === param) {
        array2.splice(i,1);
      }
    });
    return array2;
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex, items, apps, app } = this.state;
    const slides = items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.codeCarousel}
        >
          <div className="container">
            <img
              src={item.src}
              alt={item.altText}
              width={"100%"}
              height={657}
            />
            <div className="header-text hidden-xs">
              <div className="col-md-12 text-center">
                <div>
                  <button
                    type="button"
                    className="btn btn-theme btn-sm btn-min-block"
                    onClick={() => this.handleDelete(item.codeCarousel)}
                  >
                    Eliminar
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-theme btn-sm btn-min-block"
                    onClick={()=> this.handleEdit(item)}
                  >
                    Editar
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <CarouselCaption
            captionText={item.caption}
            captionHeader={item.altText}
          />
        </CarouselItem>
      );
    });

    return (
      <Card className="card-default">
        <CardHeader className="d-flex">
          <strong>Vista previa de casousel</strong>
          <Select
            name="filterCarousel"
            className="ml-auto col-md-6"
            placeholder="-FILTRO POR APLICACIÓN-"
            options={apps}
            onChange={this.handleChangeSelect}
            value={app}
          />
        </CardHeader>
        <CardBody>
          <Carousel
            activeIndex={activeIndex}
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
            {slides}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
          </Carousel>
        </CardBody>
      </Card>
    );
  }
}

export default withNamespaces("translations")(CarouselPreview);
