import {
  headerPost,
  headerPutJson,
  headerPut,
  headerPostJson,
  headerGet
} from "../Headers";
import {
  getsubcriptionusers,
  postsubscription,
  getsubscriptionid,
  putsubscriptionapp,
  getsubscriptionclient,
  postsubscriptionuser,
  putsubscription,
  postsubscriptionapp,
  deletesubscriptionapp,
  getsubscriptionapp,
  postlsubscriptionapp
} from "../../components/RouteApi/RouteApi";
import MethodPost from "../MethodPost";
import MethodGet from "../MethodGet";
import { _JsonStorage } from '../../Global';

///Subscripción por usuario
export const getSubscriptionUser = () => {
  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(getsubcriptionusers, header)
    .then(response => response)
    .catch(error => error);
};

///Crear suscripción
export const postSubscription = data => {
  let header = headerPost(data, _JsonStorage.access_token);
  return MethodPost(postsubscription, header)
    .then(response => response)
    .catch(error => error);
};

///Función para crear y actualizar suscripción.
export const sendsubscription = (url, header) => {
  return MethodPost(url, header)
    .then(response => response)
    .catch(error => error);
};

///Suscripción por id
export const getSubscriptionId = param => {
  let urlBase = getsubscriptionid,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Actualizar aplicaciones de una suscripción.
export const putSubscriptionApp = data => {
  let header = headerPutJson(data, _JsonStorage.access_token);
  return MethodPost(putsubscriptionapp, header)
    .then(response => response)
    .catch(error => error);
};

///Asignar apliaciones a suscripción.
export const postsubscriptionapplist = data => {
  let header = headerPostJson(data, _JsonStorage.access_token);
  const response = MethodPost(postlsubscriptionapp, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Suscripciones por empresa.
export const getSubscriptionByClient = param => {
  let urlBase = getsubscriptionclient,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Asignar usuario a suscripción con aplicaciones.
export const postUserSubscription = subs => {
  let array = [];
  subs.forEach(element => {
    const obj = {
      subscription: element.subscriptionid,
      appcode: element.apps.idApp,
      email: element.email
    };
    array.push(obj);
  });

  let dataFetch = new FormData();
  dataFetch.append("jsonsubscriptions", JSON.stringify(array));
  let header = headerPost(dataFetch, _JsonStorage.access_token);
  return MethodPost(postsubscriptionuser, header)
    .then(response => response)
    .catch(error => error);
};

///Suscripciones por empresa
export const subscriptionbyclient = param => {
  let urlBase = getsubscriptionclient,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Actualizar nombre de la suscripción.
export const putSubscription = (param1, param2) => {
  const formData = new FormData();
  formData.append("IdSuscription", param1);
  formData.append("NameSubscription", param2);
  let header = headerPut(formData, _JsonStorage.access_token);
  return MethodPost(putsubscription, header)
    .then(response => response)
    .catch(error => error);
};

///Asignar suscripción a aplicación.
export const postSubsApp = param => {
  const header = headerPost(param, _JsonStorage.access_token);
  const response = MethodPost(postsubscriptionapp, header)
    .then(response => response)
    .catch(error => error);

  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });

  return promise;
};

export const getSubsApp = (param1, param2) => {
  let urlBase = getsubscriptionapp,
    params = {
      subscription: param1,
      app: param2
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Eliminar aplicación de suscripción.
export const deleteSubsApp = (param1, param2) => {
  let urlBase = deletesubscriptionapp,
    params = {
      subscription: param1,
      app: param2
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};
