import React from 'react'
import ContentWrapper from "../Layout/ContentWrapper";

export default function HeaderTitle(props) {
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>
          {" "}
          {props.title}
          <small>{props.description}</small>
        </div>
      </div>
      <div className="row">
        {props.children}
      </div>
    </ContentWrapper>
  )
}
