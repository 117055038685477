import React, { Fragment } from "react";
import notify from "devextreme/ui/notify";
import { withNamespaces } from "react-i18next";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup
} from "reactstrap";

///Servicio.
import MethodGet from "../../services/MethodGet";
import MethodDelete from "../../services/MethodDelete";
import FormValidator from "../Validation/FormValidation";
import { showValidation } from "../Validation/ShowValidation";
import { headerDelete, headerGet } from "../../services/Headers";
import {
  getApplication,
  removeApplication,
  editApplication
} from "../RouteApi/RouteApi";
import { MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";
import { _JsonStorage, Time600, ExecuteFn } from "../../Global";
import { ClientGetAsync } from "../../services/Api/Application";
import { AppContext } from "./docs/AppContext";

///Componentes.
import ModalPackage from "../Package/ModalPackage";
import ModalBillingCycle from "../BillingCycle/ModalBillingCycle";
import ModalBilling from "../Billing/ModalBilling";
import SwalRemove from "../SweetAlert/SwalRemove";
import ModalCreateApp from "./Create/ApplicationModalCreate";
import ProgressAnimate from "../Progress/ProgressAnimate";

import "./docs/Application.css";


const CardWithHeader = props => (
  <Card className="card-default">
    <CardHeader><CardTitle tag="h3">{props.header}</CardTitle></CardHeader>
    <CardBody>{props.children}</CardBody>
  </Card>
)

class ApplicationDatagrid extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataOld: [],
      dataFilter: [],
      isRemove: false,
      isPackage: false,
      objapp: null,
      identifierCard: 0,
      nameRemove: "",
      identifierRemove: "",
      openBillingCycle: false,
      openBilling: false,
      formFilter: {
        search: ""
      },
      modalCreate: {
        openModalCreate: false
      },
      isDataTable: false,
      loadDataModal: null,
      loadDataConfig: null,
      progress: {
        isProgress: false
      }
    };
  }

  //#region Funciones

  ///Cargar progress
  handleProgress = () => {
    this.setState({
      progress: {
        ...this.state.progress,
        isProgress: true
      }
    });
  };

  ///Restaurar progress
  handleResetProgress = () => {
    this.setState({
      progress: {
        ...this.state.progress,
        isProgress: false
      }
    });
  };

  componentDidMount() {
    this.handleProgress();
    ExecuteFn(this.handleGetApplications);
  }

  componentWillUnmount() {
    this.handleProgress = null;
    this.handleGetApplications = null;
  }

  componentDidUpdate() {
    ///Validar si se realizo un registro para actualizar la lista de card.
    if (this.props.stateLoadDataGrid) {
      this.handleGetApplications();
    }

    ///Actualizar lista de aplicaciones
    if (this.state.isDataTable) {
      this.handleUpdateDataTable();
      this.handleGetApplications();
    }
  }

  handleGetApplications = () => {
    let header = headerGet(_JsonStorage.access_token);
    MethodGet(getApplication, header)
      .then(response => {
        if (response.success) {
          if (this.props.stateLoadDataGrid) {
            this.props.loadDataGrid();
          }

          ///Validar si el modal esta abierto y si estos datos pertenecen a el modal abierto.
          this.handleLoadData(response.objeto);
          this.setState({ data: response.objeto });
          this.handleResetProgress();
        } else {
          this.handleResetProgress();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRemove = (remove, val, isRemove) => {
    if (val !== remove) {
      this.swalShow();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, Time600);
    } else {
      ///Asignar valor a eliminar en un query string.
      let urlBase = removeApplication,
        params = {
          id: isRemove
        };
      ///Composición en la instancia URL.
      let url = new URL(urlBase);
      Object.keys(params).forEach(item =>
        url.searchParams.append(item, params[item])
      );

      let header = headerDelete(_JsonStorage.access_token);
      MethodDelete(url, header)
        .then(response => {
          if (response.success) {
            showValidation(0, 0, "", response.message, COLOUR_SUCCESS);
            this.swalShow();
            this.handleGetApplications();
          } else if (response.success === false) {
            showValidation(0, 0, "", response.message, COLOUR_WARNING);
          } else {
            showValidation(0, 0, "", "Se presento un problema de conexión, intente nuevamente." +
              "Si el problema consiste comunicate con un administrador", COLOUR_WARNING);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  showPackage = event => {
    let cardIdentifier = event.currentTarget.value;
    let array = this.state.data;
    let obj = null;
    array.forEach(element => {
      if (element.idApplication === parseInt(cardIdentifier)) {
        obj = {
          nameapp: element.nameAplication
        };
      }
    });
    this.setState({
      isPackage: !this.state.isPackage,
      identifierCard: parseInt(cardIdentifier),
      objapp: obj
    });
  };

  swalShow = event => {
    if (event !== undefined) {
      let name = event.currentTarget.name;
      let identifier = event.currentTarget.value;
      this.setState({
        isRemove: !this.state.isRemove,
        nameRemove: name,
        identifierRemove: identifier
      });
    } else {
      this.setState({ isRemove: !this.state.isRemove });
    }
  };

  handleOpenBilling = () =>
    this.setState({ openBillingCycle: !this.state.openBillingCycle });

  handleModalBilling = event => {
    let identifier = event.currentTarget.value;
    this.setState({
      identifierCard: identifier,
      openBilling: !this.state.openBilling
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });

    if ((value === "" || result.alphaspace) && this.state.dataOld.length > 0) {
      ///Mostrar todos los datos ya que no se pudo filtrar correctamente
      this.setState({ data: this.state.dataOld });
    } else {
      this.handleSearch(value);
    }
  };

  handleSearch = param => {
    if (this.state.dataOld.length === 0) {
      this.setState({ dataOld: this.state.data });
    }

    let array = [];
    this.state.data.forEach(x => {
      let name = x.nameAplication.toLowerCase();
      if (name.indexOf(param.toLowerCase()) === 0) {
        array.push(x);
      }
    });
    this.setState({ data: array });
  };

  handleResetGrid = () => {
    const sourceForm = this.state.formFilter;
    const source = this.state;
    if (sourceForm.search !== "") {
      ///Validar que se encuentren datos en el campo.
      if (source.data.length < source.dataOld.length) {
        ///Validar que los datos originales si se encuentren en el array
        this.setState({
          data: this.state.dataOld,
          formFilter: {
            ...this.state.formFilter,
            search: "",
            errors: {
              ...this.state.formFilter.errors,
              search: {
                ...this.state.formFilter.errors,
                alphaspace: false
              }
            }
          }
        });
      } else {
        ///Limpiar errores en el campo
        this.setState({
          formFilter: {
            ...this.state.formFilter,
            search: "",
            errors: {
              ...this.state.formFilter.errors,
              search: {
                ...this.state.formFilter.errors,
                alphaspace: false
              }
            }
          }
        });
      }
    }
  };

  //#endregion

  ///Abrir modal para crear o editar aplicaciones
  handleOpenModalCreate = () => {
    this.setState({
      modalCreate: {
        ...this.state.modalCreate,
        openModalCreate: !this.state.modalCreate.openModalCreate
      }
    });
  };

  ///Actualizar datatables si se crea, elimina o actualiza una aplicación.
  handleUpdateDataTable = () =>
    this.setState({ isDataTable: !this.state.isDataTable });

  ///Cargar datos en el modal si el usuario no ha cerrado y sigue trabajando en el formulario.
  handleLoadData = array => {
    if (!this.state.modalCreate.openModalCreate) {
      return;
    }

    this.setState({ loadDataModal: array });
  };

  ///Limpiar campo de loadDataTable
  handleResetLoadDataTable = () => {
    this.setState({
      loadDataModal: null
    });
  };

  ///Consultar datos de la aplicación para editar.
  handleEdit = element => {
    let idElement = element.currentTarget.id;
    let urlBase = editApplication,
      params = {
        id: document.getElementById(idElement).value
      };

    ///Composición en la instancia URL.
    let url = new URL(urlBase);
    Object.keys(params).forEach(item =>
      url.searchParams.append(item, params[item])
    );

    let header = headerGet(_JsonStorage.access_token);
    MethodGet(url, header)
      .then(response => {
        if (response.success) {
          this.handleOpenModalCreate();
          this.setState({
            loadDataModal: response.response
          });
          this.handleEditConfig(response.response.codeClient);
        } else {
          showValidation(response);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Consultar configuración de la aplicación.
  handleEditConfig = resource => {
    ClientGetAsync(resource).then(
      response => {
        if (response.success) {
          this.setState({
            loadDataConfig: response.response
          });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Restaurar el valor de la configuración al editar.
  handleEditConfigEmpty = () => this.setState({ loadDataConfig: null });

  changeViewConfigAccess = () => {
    this.context.viewListIdentity();
  }

  render() {
    const {
      isPackage,
      identifierCard,
      nameRemove,
      identifierRemove,
      isRemove,
      openBillingCycle,
      openBilling,
      data,
      objapp,
      isDataTable,
      loadDataModal,
      loadDataConfig
    } = this.state;
    const { search } = this.state.formFilter;
    const { openModalCreate } = this.state.modalCreate;
    const { isProgress } = this.state.progress;
    return (
      <div className="col-md-12">
        <CardWithHeader header="Filtrar aplicaciones">
          <form
            className="form-horizontal"
            name="formFilter"
            autoComplete="off"
          >
            <FormGroup row>
              <div className="col-12 d-sm-flex flex-row">
                <InputGroup>
                  <Input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Nombre de la aplicación"
                    value={search}
                    onChange={this.validateOnChange}
                    invalid={this.hasError(
                      "formFilter",
                      "search",
                      "alphaspace"
                    )}
                    data-validate='["alphaspace"]'
                  />
                  <InputGroupAddon addonType="append">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={this.handleResetGrid}
                      title="Eliminar filtro"
                    >
                      <em className="fas fa-eraser" />
                    </button>

                  </InputGroupAddon>
                  {this.hasError("formFilter", "search", "alphaspace") && (
                    <span className="invalid-feedback">
                      El nombre ingresado debe ser un nombre valido.
                    </span>
                  )}
                </InputGroup>
                <button
                  className="btn btn-primary ml-1 mt-3 mt-sm-0"
                  type="button"
                  onClick={this.handleOpenModalCreate}
                  title="Crear aplicación"
                >
                  <em className="fas fa-plus-square" />
                </button>
                <button
                  className="btn btn-warning ml-1 mt-3 mt-sm-0"
                  type="button"
                  onClick={this.changeViewConfigAccess}
                  title="Configurar acceso de aplicaciones"
                >
                  <em className="fas fa-wrench" />
                </button>
              </div>
            </FormGroup>
          </form>
        </CardWithHeader>

        <div className="row col-xl-10 col-lg-10">
          {data === undefined ? (
            <div className="col-md-12">
              {" "}
              <h4 className="text-center">
                Los archivos de la aplicación no se cargaron correctamente
              </h4>{" "}
            </div>
          ) : (
              data.map((x, i) => {
                return (
                  <CardView
                    key={i}
                    index={i}
                    item={x}
                    swalShow={this.swalShow}
                    isRemove={this.state.isRemove}
                    handleRemove={this.handleRemove}
                    handleEdit={this.handleEdit}
                    showPackage={this.showPackage}
                    openPackage={isPackage}
                    identifierCard={x.idApplication}
                    handleModalBilling={this.handleModalBilling}
                  />
                );
              })
            )}
        </div>

        {/* Progress cargar lista de app */}
        <ProgressAnimate isProgress={isProgress} />

        {/* Componentes agregados */}
        <ModalPackage
          isinherited={true}
          openModal={isPackage}
          showPackage={this.showPackage}
          cardIdentifier={identifierCard}
          objapp={objapp}
        />
        <SwalRemove
          swalShow={this.swalShow}
          openModal={isRemove}
          handleRemove={this.handleRemove}
          element={nameRemove}
          elementRemove={identifierRemove}
        />
        <ModalBillingCycle
          openModal={openBillingCycle}
          showModal={this.handleOpenBilling}
        />
        <ModalBilling
          openModal={openBilling}
          showModal={this.handleModalBilling}
          cardIdentifier={identifierCard}
        />

        <ModalCreateApp
          openModal={openModalCreate}
          isDataTable={isDataTable}
          loadDataModal={loadDataModal}
          loadDataConfig={loadDataConfig}
          handleOpenModal={this.handleOpenModalCreate}
          handleUpdateDataTable={this.handleUpdateDataTable}
          handleResetLoadDataTable={this.handleResetLoadDataTable}
          handleEditConfigEmpty={this.handleEditConfigEmpty}
        />
      </div>
    );
  }
}

const CardView = props => {
  return (
    <div className="col-lg-6 col-xl-4">
      <Card outline color="primary" className="mb-3">
        <CardHeader className="text-white header-card d-flex align-items-center">
          <div className="d-flex col">
            <div className="h4 m-0">
              <label className="text-black">{props.item.nameAplication}</label>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              className="btn bg-danger btn-sm"
              onClick={props.swalShow}
              name={props.item.nameAplication}
              value={props.item.idApplication}
            >
              <i className="fas fa-trash-alt fa-1x" />
            </Button>
            {""}
            <Button
              type="button"
              className="btn bg-success btn-sm"
              style={{ fontSize: "10px" }}
              onClick={props.handleEdit}
              id={"editApplication_" + props.index}
              value={props.item.idApplication}
            >
              <i className="fas fa-pen fa-1x" />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <img src={props.item.route} alt="Img" className="img-left thumb96" />
          <p>{props.item.description.length > 177 ?
            props.item.description.substring(0, 177).toUpperCase() + "..." :
            props.item.description}
          </p>
        </CardBody>
        <CardFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={props.handleModalBilling}
            value={props.identifierCard}
          >
            Facturación
          </button>{" "}
          <button
            type="button"
            className="btn btn-info"
            onClick={props.showPackage}
            value={props.identifierCard}
          >
            Paquete
          </button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default withNamespaces("translations")(ApplicationDatagrid);
