import React, { useContext } from "react";
import { GlobalContext } from "./components/Context/Context";
import { withRouter, Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group-v2";
import { AuthenticationProvider, oidcLog, withOidcSecure, InMemoryWebStorage } from "@axa-fr/react-oidc-context";

import "./ie.polyfills";

import oidcConfiguration from "./configuration";

import BasePage from "./components/Layout/BasePage";
import Base from "./components/Layout/Base";

import NoFound from "./components/Page/NoFound";

import CustomCallback from "./components/CustomCallback/index";

//#region Componentes.
import EmailEnter from "./components/Login/EmailEnter";
import ForgotPassword from "./components/Login/ForgotPassword";
import CreateUser from "./components/Login/CreateUser";
import RequestService from "./components/Enterprise/EnterpriseRequestService";

import SingleView from "./components/SingleView/SingleView";
import Roles from "./components/Roles/Roles";
import Subscription from "./components/Subscription/Subscription";
import Package from "./components/Package/Package";
import Application from "./components/Application/Application";
import SendEmail from "./components/Login/SendEmail";
import Profile from "./components/Profile/Profile";
import Enterprise from "./components/Enterprise/Enterprise";
import BillingCycle from "./components/BillingCycle/BillingCycle";
import Carousel from "./components/Carousel/Carousel";
import Notification from "./components/Notification/Notification";
import AccountActive from "./components/Login/AccountActive";
import ResendPassword from './components/Login/Help/ResendPassword';
//#endregion

const listofPages = [
  "/createUser",
  "/forgotpassword/reset",
  "/id/forgotpassword",
  "/sendemail",
  "/account/active",
  "/resend",
  "/request/client",
];


const Routes = () => {
  const context = useContext(GlobalContext);
  const location = window.location;
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      <BasePage>
        <Switch location={location}>
          <Route exact path="/createUser" component={CreateUser} />
          <Route exact path="/forgotpassword/reset" component={ForgotPassword} />
          <Route exact path="/id/forgotpassword" component={EmailEnter} />
          <Route exact path="/sendemail" component={SendEmail} />
          <Route exact path="/account/active" component={AccountActive} />
          <Route exact path="/resend" component={ResendPassword} />
          <Route exact path="/request/client" component={RequestService} />
        </Switch>
      </BasePage>
    );
  } else {
    document.body.style.backgroundColor = "#f5f7fb";
    return (
      <AuthenticationProvider
        configuration={context.data.configClient}
        loggerLevel={oidcLog.DEBUG}
        isEnabled={true}
        callbackComponentOverride={CustomCallback}
        UserStore={InMemoryWebStorage}
      >
        <Base>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              classNames="example"
              timeout={timeout}
            >
              <div style={{ height: "100%" }}>
                <Switch location={location}>
                  <Route
                    exact
                    path="/roles"
                    component={withOidcSecure(Roles)}
                  />
                  <Route
                    exact
                    path="/home"
                    component={withOidcSecure(SingleView)}
                  />
                  <Route
                    exact
                    path="/"
                    component={withOidcSecure(SingleView)}
                  />
                  <Route
                    exact
                    path="/subscription"
                    component={withOidcSecure(Subscription)}
                  />
                  <Route
                    exact
                    path="/applications"
                    component={withOidcSecure(Application)}
                  />
                  <Route
                    exact
                    path="/packages"
                    component={withOidcSecure(Package)}
                  />
                  <Route
                    exact
                    path="/profile"
                    component={withOidcSecure(Profile)}
                  />
                  <Route
                    exact
                    path="/enterprise"
                    component={withOidcSecure(Enterprise)}
                  />
                  <Route
                    exact
                    path="/facturacion"
                    component={withOidcSecure(BillingCycle)}
                  />
                  <Route
                    exact
                    path="/carousel"
                    component={withOidcSecure(Carousel)}
                  />
                  <Route
                    exact
                    path="/notification"
                    component={withOidcSecure(Notification)}
                  />
                  <Route path="*" component={NoFound} />

                  <Route to="/login" />
                </Switch>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Base>
      </AuthenticationProvider>
    );
  }
}

export default withRouter(Routes);
