import React from "react";
import { Row } from "reactstrap";
import { Link } from 'react-router-dom';
import "./docs/SendEmail.css";

class SendEmail extends React.Component {
  render() {
    return (
      <div className="mt-1">
        <div className="text-white mb-3 card bg-info">
          <div className="card-body">
            <div className="container">
              <Row className="aside-inner">
                <div className="">
                  <h3 className="letter-white">{this.props.title}</h3>
                  <blockquote className="letter-white">
                    <p className="letter-white">{this.props.message}</p>
                    <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="text-white">
                        ¿Problemas al recibir el correo electrónico?
                    </a>
                  </blockquote>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SendEmail;
