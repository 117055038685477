import MethodGet from "../MethodGet";

import { headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';
import { getClientAndResource } from "../../components/RouteApi/RouteApi";

export const getClientAndResourceAsync = async param => {
  let urlBase = getClientAndResource,
    params = {
      name: param.name,
      typeFilter: param.typeFilter,
      pageSize: param.pageSize,
      pageNumber: param.pageNumber
    };

  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet(_JsonStorage.access_token);
  try {
    const response = await MethodGet(url, header);
    return response;
  } catch (error) {
    return error;
  }
};