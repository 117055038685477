import React, { Component } from "react";

import ToggleState from "../../../Common/ToggleState";
import BillingApp from "./BillingApp";
import { getAppFilterSubs } from "../../../../services/Api/Application";

import ProgressAnimate from "../../../Progress/ProgressAnimate";
 
export default class TabContentApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataapp: [],
      dataapp2: [],
      style: false,
      databillingapp: null,
      reset: false,
      stateapp: false,
      isProgress: false
    };
    this.handleChangeStyle = this.handleChangeStyle.bind(this);
  }

  componentDidMount() {
    this.handleAppFilter();
  }

  componentDidUpdate() {
    if (this.props.filter) {
      this.props.handleOnSearch();
      this.handleFilterNameApp();
    }
  }

  ///Cargar progress
  handleProgress = () => this.setState({ isProgress: !this.state.isProgress });

  handleFilterNameApp = () => {
    if (this.props.nameSearch === "") {
      const storage = this.state.dataapp2;
      this.setState({ dataapp: storage });
    } else {
      const storage = this.state.dataapp2;
      const data = this.props.nameSearch;
      const newarray = [];

      storage.forEach(element => {
        let filter = element.name
          .substring(1, element.name.length - 1)
          .toUpperCase()
          .includes(data.substring(1, data.length - 1).toUpperCase());
        if (filter) {
          newarray.push(element);
        }
      });
      this.setState({ dataapp: newarray });
    }
  };

  handleAppFilter = () => {
    this.handleProgress();
    const code = this.props.data.code;
    let response = getAppFilterSubs(code);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.objeto.forEach(element => {
            let obj = {
              route: element.route,
              name: element.nameAplication,
              code: element.idApplication,
              url: element.url,
              description: element.description,
              billing: element.billingApplications,
              state: element.state
            };
            array.push(obj);
          });
          this.setState({ dataapp: array, dataapp2: array });
        }
        this.handleProgress();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeStyle = event => {
    this.setState({
      style: !this.state.style,
      databillingapp: event,
      reset: true,
      stateapp: event.state
    });
  };

  hadleApplyStyle = param => {
    if (param) {
      let element = document.getElementById("tabcontent");
      element.classList.add("hide-app");
    } else {
      let element = document.getElementById("tabcontent");
      element.classList.remove("hide-app");
    }
  };

  handleResetBillingApp = () => this.setState({ reset: !this.state.reset });

  render() {
    const { dataapp, style, databillingapp, reset, stateapp, isProgress } = this.state;
    const { data, handleLoadSubscription, handleReloadGrid, client } = this.props;
    return (
      <div>
        <div className="row" id="tabcontent">
          {dataapp.length === 0 ? (
            <div className="text-center">
              <strong>No se encontraron aplicaciones.</strong>
            </div>
          ) : ( 
            dataapp.map((x, i) => (
              <div
                className="mcard"
                key={i + 1}
                onClick={e => this.handleChangeStyle(x)}
              >
                <ToggleState state="aside-toggled" nopersist={true}>
                  <a href="">
                    <img className="mcard-image" src={x.route} alt="Img" />
                    <div className="mcard-body">
                      <strong className="color-black">{x.name}</strong>
                    </div>
                    {x.state === true ? (
                      <div className="mcard-footer" title="Aplicación asignada">
                        <i className="fas fa-check-circle position-check" />
                      </div>
                    ) : null}
                  </a>
                </ToggleState>
              </div>
            ))
          )}
          {/* Progress cargar lista de app */}
          <ProgressAnimate isProgress={isProgress} />
        </div>
        <BillingApp
          style={style}
          reset={reset}
          client={client}
          data={databillingapp}
          stateapp={stateapp}
          datasubscription={data}
          handleChangeStyle={this.handleChangeStyle}
          hadleApplyStyle={this.hadleApplyStyle}
          handleLoadSubscription={handleLoadSubscription}
          handleResetBillingApp={this.handleResetBillingApp}
          handleAppFilter={this.handleAppFilter}
          handleReloadGrid={handleReloadGrid}
        />
      </div>
    );
  }
}
