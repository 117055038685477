import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';

import { AppContext } from "../docs/AppContext";
import { IdentityTypeOptions } from "../docs/data";
import { getClientAndResourceAsync } from "../../../services/Api/Identity";

import CardResource from "./CardResource";
import CardClient from "./CardClient";
import PageList from "../../Extras/PageList";
import HeaderTitle from "../../Extras/HeaderTitle";
import FormValidator from "../../Validation/FormValidation";
import SpinnerDisableContent from "../../Progress/SpinnerDisableContent";

import '../docs/Identity.css'

const CardWithHeader = props => (
  <Card className="card-default">
    <CardHeader><CardTitle tag="h3">{props.header}</CardTitle></CardHeader>
    <CardBody>{props.children}</CardBody>
  </Card>
)

const ListCard = (props) => {
  const context = useContext(AppContext)
  const [state, setState] = useState({
    collection: [],
    isSpinner: false
  })
  const [page, setPage] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
    pageSize: 10
  })

  useEffect(() => {
    if (props.isSearch) filterCollection();
  }, [props.isSearch])

  useEffect(() => {
    if (props.filter === "") filterCollection();
  }, [props.filter])

  useEffect(() => {
    filterCollection();
  }, [props.selected])

  useEffect(() => {
    filterCollection();
  }, [])

  useEffect(() => {
    filterCollection();
  }, [page.pageSize, page.currentPage])

  const filterCollection = async () => {
    changeSpinner(true)

    await getClientAndResourceAsync({
      name: props.filter,
      typeFilter: props.selected,
      pageSize: page.pageSize,
      pageNumber: page.currentPage
    })
      .then(resp => {
        if (resp.isSuccess) {
          const array = resp.objeto.result.map(x => x);

          setState(state => ({
            ...state,
            collection: array
          }))

          setPage(state => ({
            ...state,
            totalCount: resp.objeto.totalCount,
            totalPages: resp.objeto.totalPages
          }))
        }
        changeSpinner(false)
      })
      .catch(err => {
        changeSpinner(false)
      })
  }

  const changeSpinner = (parameter) => {
    setState(state => ({
      ...state,
      isSpinner: parameter
    }))
  }

  const showViewCreate = () => {
    context.viewFormIdentity();
  }

  return (
    <Fragment>
      <SpinnerDisableContent isSpinnerDisabled={state.isSpinner}>
        {state.collection.length > 0 && (
          <div className="d-block d-sm-none">
            <PageList {...{ page, setPage }} />
          </div>
        )}
        <Row>
          {state.collection.map((x, i) => (
            <Col xl="3" lg="4" sm="6" key={i + 1}>
              {x.isClient && (
                <CardClient {...x} />
              )}
              {!x.isClient && (
                <CardResource {...x} />
              )}
            </Col>
          ))}
          {state.collection.length === 0 && (
            <Fragment>
              <div className="container-md py-4 container">
                <div className="text-center mb-3 pb-3">
                  <Alert color="warning">
                    <span className="h3">No cuenta con registros de clientes o recursos</span>
                  </Alert>
                  <p>Para registrar clientes o recursos puede presionar el siguiente botón
                  <Button type="button" className="btn bg-primary btn-sm" onClick={() => showViewCreate()}>Crear</Button>
                  </p>
                </div>
              </div>
            </Fragment>
          )}
        </Row>
        {state.collection.length > 0 && (
          <PageList {...{ page, setPage }} />
        )}
      </SpinnerDisableContent>
    </Fragment>
  )
}

export default function Container() {
  let timeOutValue;
  const context = useContext(AppContext)
  const [formData, setFormData] = useState({
    isSearch: false,
    selected: 1,
    filter: '',
    errors: {}
  })

  const changeInput = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    setFormData(state => ({
      ...state,
      [input.name]: value,
      isSearch: false,
      errors: {
        ...state.errors,
        [input.name]: result
      }
    }));

    keyPress();
  }

  const keyPress = () => {
    clearTimeout(timeOutValue)
    timeSearch()
  }

  const timeSearch = () => {
    timeOutValue = setTimeout(() => {
      setFormData(state => ({
        ...state,
        isSearch: true
      }))
    }, 2000)
  };

  const cleanInput = () => {
    setFormData(state => ({
      ...state,
      filter: '',
      errors: {}
    }))
  }

  const showViewCreate = () => {
    context.viewFormIdentity();
  }

  const showViewAppList = () => {
    context.viewAllClose();
  }

  const changeSelected = (e) => {
    const value = parseInt(e.target.value);
    setFormData(state => ({
      ...state,
      selected: value
    }))
  }

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    setFormData(state => ({
      ...state,
      errors: errors
    }))

    if (!hasError) {
      setFormData(state => ({
        ...state,
        isSearch: !formData.isSearch
      }))
    }
  }

  const hasError = (inputName, method) => {
    return (
      formData.errors &&
      formData.errors[inputName] &&
      formData.errors[inputName][method]
    );
  };

  const showClean = formData.filter !== '' ? true : false;

  return (
    <Fragment>
      <HeaderTitle title="Configurar acceso de aplicaciones" description="Gestionar configuraciones para el acceso de aplicaciones y recursos">
        <div className="col-md-12">
          <CardWithHeader header="Filtro de clientes y recursos">
            <Form className="form-horizontal" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
              <FormGroup row>
                <div className="col-12 d-sm-flex flex-row">
                  <select defaultValue="" className="custom-select mr-2 mb-3 mb-sm-0" onChange={(e) => changeSelected(e)}>
                    {IdentityTypeOptions.map((x, i) => (
                      <option value={x.value} key={i + 1}>{x.label}</option>
                    ))}
                  </select>
                  <InputGroup>
                    <Input
                      type="text"
                      name="filter"
                      className="form-control"
                      placeholder="Nombre del cliente o recurso"
                      value={formData.filter}
                      onChange={(e) => changeInput(e)}
                      data-validate='["required"]'
                      invalid={
                        hasError("filter", "required")
                      }
                    />
                    <InputGroupAddon addonType="append">
                      {showClean && (
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => cleanInput()}
                          title="Eliminar filtro"
                        >
                          <em className="fas fa-eraser" />
                        </button>
                      )}

                      <button
                        className="btn btn-secondary"
                        type="submit"
                        title="Buscar cliente o recurso"
                      >
                        <em className="fas fa-search" />
                      </button>
                    </InputGroupAddon>
                  </InputGroup>
                  <button
                    className="btn btn-primary ml-1 mt-3 mt-sm-0"
                    type="button"
                    onClick={() => showViewCreate()}
                    title="Crear configuración"
                  >
                    <em className="fas fa-plus-square" />
                  </button>
                  <button
                    className="btn btn-secondary ml-1 mt-3 mt-sm-0"
                    type="button"
                    onClick={() => showViewAppList()}
                    title="Lista de aplicaciones"
                  >
                    <span className="d-xl-block d-none">Lista de aplicaciones</span>
                    <em className="fas fa-th-list fa-lg d-xl-none" />
                  </button>
                </div>
              </FormGroup>
            </Form>
          </CardWithHeader>
          <ListCard filter={formData.filter} selected={formData.selected} isSearch={formData.isSearch} />
        </div>
      </HeaderTitle>
    </Fragment>
  )
}
