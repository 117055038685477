import React from "react";
import ContentWrapper from "../Layout/ContentWrapper";

import SubscriptionSidebar from "./SubscriptionSidebar";
import SubscriptionHeader from "./SubscriptionHeader";
import EnterpriseFilterCreate from "../Enterprise/EnterpriseFilterCreate";
import SubscriptionCreate from "./SubscriptionCreate";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifierclient: 0,
      subscription: 0,
      isEdit: false,
      filterClient: null,
      reloadsidebar: false,
      reset: false,
      sharedCreate: null
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  handleChangeFilterClient = param => this.setState({ identifierclient: param });

  handleChangeDataClient = param => this.setState({ filterClient: param });

  handleEditSubscription = param => this.setState({ subscription: param, isEdit: !this.state.isEdit });

  handleReloadSidebar = () => this.setState({ reloadsidebar: !this.state.reloadsidebar });

  hadleChangeSidebarReset = () => this.setState({ reset: !this.state.reset });

  handleSharedDataEdit = param => this.setState({ sharedCreate: param });

  handleNotFilterSubscription = () => this.setState({ subscription: 0 });

  render() {
    const {
      identifierclient,
      subscription,
      isEdit,
      reloadsidebar,
      reset,
      sharedCreate,
      filterClient
    } = this.state;
    return (
      <div>
        <ContentWrapper>
          <SubscriptionHeader />
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <EnterpriseFilterCreate
                isEdit={isEdit}
                subscription={subscription}
                handleChangeDataClient={this.handleChangeDataClient}
                handleChangeFilterClient={this.handleChangeFilterClient}
                hadleChangeSidebarReset={this.hadleChangeSidebarReset}
                handleEditSubscription={this.handleEditSubscription}
                handleNotFilterSubscription={this.handleNotFilterSubscription}
              />
              <SubscriptionSidebar
                reloadsidebar={reloadsidebar}
                filterclient={identifierclient}
                reset={reset}
                subscription={subscription}
                isEdit={isEdit}
                handleReloadSidebar={this.handleReloadSidebar}
                handleEditSubscription={this.handleEditSubscription}
                handleSharedDataEdit={this.handleSharedDataEdit}
                handleChangeFilterClient={this.handleChangeFilterClient}
              />
            </div>
            <div className="col-xl-8 col-lg-7">
              <SubscriptionCreate
                reset={reset}
                subscription={subscription}
                isEdit={isEdit}
                sharedCreate={sharedCreate}
                client={filterClient}
                hadleChangeSidebarReset={this.hadleChangeSidebarReset}
                handleReloadSidebar={this.handleReloadSidebar}
                handleEditSubscription={this.handleEditSubscription}
              />
            </div>
          </div>
        </ContentWrapper>
      </div>
    );
  }

  componentWillMount() {
    this.mounted = false;
  }
}

export default Subscription;
