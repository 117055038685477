import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import Select from "react-select";
import {
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import MeasurementModal from "../Measurement/MeasurementModal";
import FormValidator from "../Validation/FormValidation";
import { COLOUR_WARNING } from "../Message/Message";
import { postbillingcycle, putbillingcycle } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { ButtonSave, ButtonUpdate } from "../Element/Buttons";
import { getMeasurement } from "../../services/Api/Measurement";
import { sendBillign } from "../../services/Api/BillingCycle";
import { showValidation } from "../Validation/ShowValidation";
import { _JsonStorage, ExecuteFn } from '../../Global';

import './docs/BillingCycle.css';

export default class BillingCycleFormVertical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMeasured: false,
      isChange: false,
      formBillingCycle: {
        identifier: 0,
        nameBilling: "",
        measurement: null,
        factor: "",
        description: ""
      },
      dataMeasurement: [],
      isEdit: false
    };
  }

  componentDidUpdate() {
    if (this.state.isChange) {
      this.handleLoadMeasurement();
    }
    if (this.props.isEdit) {
      this.props.handleEdit(null);
      const data = this.props.dataEdit;
      this.handleEdit(data);
    }
  }

  componentDidMount() {
    ExecuteFn(this.handleLoadMeasurement);
  }

  handleEdit = param => {
    this.setState({
      formBillingCycle: {
        ...this.state.formBillingCycle,
        identifier: param.billingcycle.idbillingcycle,
        nameBilling: param.namebilling === null ? "" : param.namebilling,
        measurement: { label: param.measurement, value: param.measurementiden },
        factor: param.factor === null ? "" : param.factor,
        description: param.description === null ? "" : param.description
      },
      isEdit: true
    });
  };

  handleResetState = () => {
    this.setState({
      formBillingCycle: {
        ...this.state.formBillingCycle,
        identifier: 0,
        nameBilling: "",
        measurement: null,
        factor: "",
        description: ""
      },
      isEdit: false
    });
  };

  handleSubmit = () => {
    let url = "";
    let header = "";
    const formData = new FormData();
    const data = this.state.formBillingCycle;
    formData.append("namebillingcycle", data.nameBilling);
    formData.append("measurement", data.measurement.value);
    formData.append("factor", data.factor);
    formData.append("description", data.description);
    formData.append("idbillingcycle", data.identifier);

    url = this.state.isEdit === true ? putbillingcycle : postbillingcycle;
    header =
      this.state.isEdit === true ? headerPut(formData,_JsonStorage().access_token) : headerPost(formData,_JsonStorage().access_token);

    let response = sendBillign(url, header);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          this.props.handleChangeData();
          this.handleResetState();
          showValidation(result, 1, "facturación");
        }else{
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleOnSubmit = e => {
    e.preventDefault();
    if (this.state.formBillingCycle.measurement === null) {
      notify("Seleccione una unidad de medida.", COLOUR_WARNING, 4000);
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA"].includes(i.nodeName)
      );
      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });
      if (!hasError) {
        this.handleSubmit();
      }
    }
  };

  handleChangeSelect = event => {
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  handleLoadMeasurement = () => {
    let data = getMeasurement();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameMeasuredUnit,
              value: element.idMeasuredUnit,
              nameForm: "formBillingCycle",
              nameSelect: "measurement"
            };
            array.push(obj);
          });
          this.setState({ dataMeasurement: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeData = () => this.setState({ isChange: !this.state.isChange });

  handleModalMeasured = () =>
    this.setState({ modalMeasured: !this.state.modalMeasured });

  render() {
    const { modalMeasured, isChange, dataMeasurement, isEdit } = this.state;
    const {
      nameBilling,
      measurement,
      factor,
      description
    } = this.state.formBillingCycle;
    return (
      <div>
        <form name="formBillingCycle" autoComplete="off" onSubmit={this.handleOnSubmit}>
          <FormGroup>
            <label>Nombre tipo de facturación</label>
            <Input
              type="text"
              name="nameBilling"
              placeholder="Ingrese el nombre del tipo de facturación."
              value={nameBilling}
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formBillingCycle", "nameBilling", "required") ||
                this.hasError("formBillingCycle", "nameBilling", "alphanumspace")
              }
              data-validate='["required","alphanumspace"]'
            />
            {this.hasError("formBillingCycle", "nameBilling", "alphanumspace") && (
              <span className="invalid-feedback">
                El valor del campo debe ser un valor válido.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <label>Unidad de medida</label>
            <InputGroup className="">
              <Select
                name="measurement"
                placeholder="Seleccione una opción."
                // styles={{ control: styles => ({ ...styles, width: "301px" }) }}
                className="col-md-10 padding-left0"
                options={dataMeasurement}
                value={measurement}
                onChange={this.handleChangeSelect}
              />
              <InputGroupAddon addonType="prepend">
                <Button
                  type="button"
                  className="btn bg-primary"
                  title="Crear unidad de medida."
                  onClick={this.handleModalMeasured}
                >
                  <i className="fas fa-cogs" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <label>Factor</label>
            <Input
              type="text"
              name="factor"
              placeholder="Ingrese un factor."
              value={factor}
              onChange={this.handleOnChange}
              invalid={
                this.hasError("formBillingCycle", "factor", "required") ||
                this.hasError("formBillingCycle", "factor", "alphanum")
              }
              data-validate='["required","alphanum"]'
            />
            {this.hasError("formBillingCycle", "factor", "alphanum") && (
              <span className="invalid-feedback">
                El valor ingresado no es válido.
              </span>
            )}
          </FormGroup>
          <FormGroup>
            <label>Descripción</label>
            <Input
              type="textarea"
              name="description"
              placeholder="Ingrese una descripción."
              value={description}
              onChange={this.handleOnChange}
              invalid={this.hasError(
                "formBillingCycle",
                "description",
                "required"
              )}
              data-validate='["required"]'
            />
          </FormGroup>
          <FormGroup>
            {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}{" "}
            <Button
              type="button"
              color="secondary"
              onClick={this.handleResetState}
            >
              Cancelar
            </Button>
          </FormGroup>
        </form>
        <MeasurementModal
          isChange={isChange}
          handleChangeData={this.handleChangeData}
          openModal={modalMeasured}
          handleOpenModal={this.handleModalMeasured}
        />
      </div>
    );
  }
}
