import React, { Component } from "react";
import { withNamespaces, Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Badge } from "reactstrap";

import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";


import { getMenuUser } from "../../services/Api/User";
import { ExecuteFn, _JsonStorage } from "./../../Global";
import "./docs/MyLayoutStyle.css";

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>
      <Trans>{item.heading}</Trans>
    </span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? "active" : ""}>
    <Link to={item.path} title={item.description !== "" ? item.description : item.name}>
      {item.label && (
        <em tag="div" className="align-badge" color={item.label.color}>
          {item.label.value}
        </em>
      )}
      {item.icon && <em className={item.icon} />}
      <span>
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
    </Link>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => {
  const myStyle = { color: item.label.color };
  return (
    <li className={isActive ? "active" : ""}>
      <div className="nav-item" onClick={handler}>
        {item.label && <Badge tag="div" className="float-right fa fa-arrow-circle-right" />}
        {item.icon && <em className={item.icon} style={myStyle} />}
        <span>
          <Trans i18nKey={item.translate}>{item.name}</Trans>
        </span>
      </div>
      <Collapse isOpen={isOpen}>
        <ul id={item.path} className="sidebar-nav sidebar-subnav">
          {children}
        </ul>
      </Collapse>
    </li>
  );
};

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">Sub Menu</li>
);

class Sidebar extends Component {
  state = {
    collapse: {},
    Menu: [
      {
        heading: " ",
        translate: "sidebar.heading.HEADER"
      }
    ]
  };

  componentDidMount() {
    let Nav = sessionStorage.getItem("Navigation");
    if (Nav !== null && Nav !== undefined) {
      this.handleSendMenu(JSON.parse(Nav));
    } else {
      ExecuteFn(this.handleMenu);
    }

    SidebarRun(this.navigator.bind(this));
    this.buildCollapseList();
  }

  handleMenu = () => {
    let promise = getMenuUser();
    promise.then(
      response => {
        if (response.success) {
          sessionStorage.setItem(
            "Navigation",
            JSON.stringify(response.response)
          );
          this.handleSendMenu(response.response);
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleSendMenu = menu => {
    if (menu !== null) {
      let newMenu = [
        {
          heading: " ",
          translate: "sidebar.heading.HEADER"
        }
      ];

      menu.forEach(function (element) {
        newMenu.push({
          name: element.textMain,
          path: element.pathMainMenu,
          icon: element.iconMainMenu,
          translate: "gestion.nav." + element.textMain,
          label: {
            value: element.listMenu.length,
            color: element.colourMainMenu
          },
          submenu: element.listMenu.map(x => {
            return {
              name: x.textMenu,
              translate: "gestion.nav." + x.textMenu,
              path: x.pathMenu,
              description: x.description
            };
          })
        });
      });
      this.setState({ Menu: newMenu });
    }
  };

  buildCollapseList = () => {
    let collapse = {};
    this.state.Menu
      .filter(({ heading }) => !heading)
      .forEach(({ name, path, submenu }) => {
        collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path);
      });
    this.setState({ collapse });
  };

  navigator(route) {
    this.props.history.push(route);
  }

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some(p => this.props.location.pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false
          }
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName]
      }
    });
  }

  getSubRoutes = item => item.submenu.map(({ path }) => path);

  itemType = item => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
  };

  render() {
    const { Menu } = this.state;
    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {Menu.map((item, i) => {
                // heading
                if (this.itemType(item) === "heading")
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (this.itemType(item) === "menu")
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item[0].path)}
                        item={item[0]}
                        key={i}
                      />
                    );
                  if (this.itemType(item) === "submenu")
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={this.routeActive(subitem.path)}
                          />
                        ))}
                      </SidebarSubItem>
                    ];
                }
                return null; // unrecognized item
              })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

export default withNamespaces("translations")(withRouter(Sidebar));
