import React, { Component } from "react";

import ContentWrapper from "../Layout/ContentWrapper";
import BillingCycleSidebar from './BillingCycleSidebar';
import BillingCycleDatagrid from './BillingCycleDatagrid';

export default class BillingCycle extends Component {
  constructor(props){
        super(props);
        this.state={
          isEdit: false,
          isChangeData: false,
          dataEdit: null
        }
  }

  handleEdit = param => this.setState({ dataEdit: param, isEdit: !this.state.isEdit });

  handleChangeData = () => this.setState({ isChangeData: !this.state.isChangeData });

  render() {
    const { isEdit, isChangeData, dataEdit } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {" "}
            Facturación
            <small>
              Configuración, creación de los diferentes tipos de facturación.
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <BillingCycleSidebar isEdit={isEdit} handleChangeData={this.handleChangeData} dataEdit={dataEdit} handleEdit={this.handleEdit} />
          </div>
          <div className="col-xl-8 col-lg-5">
            <BillingCycleDatagrid isChangeData={isChangeData} handleChangeData={this.handleChangeData} handleEdit={this.handleEdit} />
          </div>
        </div>
      </ContentWrapper>
    );
  }
}
