import MethodGet from "../MethodGet";
import { listCountries, listCitieId } from '../../components/RouteApi/RouteApi';
import { headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

export const getCountry = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(listCountries, header).then(response=>response).catch(error=>error);
}; 

export const getCityFilter = (param) =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(listCitieId+param, header).then(response=>response).catch(error=>error);
};