import React, { Component } from "react";

import ToggleState from "../../../Common/ToggleState";
import BillingPackage from "./BillingPackage";
import { getpackage } from "../../../../services/Api/Package";

import ProgressAnimate from "../../../Progress/ProgressAnimate";

export default class TabContentPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datapackage: [],
      style: false,
      databillingpackage: null,
      reset: false,
      loadapppackage: false,
      isProgress: false
    };
  }  

  componentDidMount() {
    this.handlePackages();
  }

  ///Cargar progress
  handleProgress = () => this.setState({ isProgress: !this.state.isProgress });

  handleChangeStyle = event => {
    this.setState({
      style: !this.state.style,
      databillingpackage: event,
      reset: true,
      loadapppackage: true
    });
  };

  hadleApplyStyle = param => {
    if (param) {
      let element = document.getElementById("tabcontent2");
      element.classList.add("hide-app");
    } else {
      let element = document.getElementById("tabcontent2");
      element.classList.remove("hide-app");
    }
  };

  handlePackages = () => {
    this.handleProgress();
    let response = getpackage();
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.data.forEach(element => {
            const obj = {
              name: element.namePackage,
              description: element.description,
              code: element.idPackage
            };
            array.push(obj);
          });
          this.setState({ datapackage: array });
        }
        this.handleProgress();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeAppPackage = () =>this.setState({ loadapppackage: !this.state.loadapppackage });

  render() {
    const { subscription, handleReloadGrid, handleLoadSubscription, handleOpenModalAssign, client, apps } = this.props;
    const { datapackage, style, reset, databillingpackage, loadapppackage, isProgress } = this.state;
    return (
      <div>
        <div className="row" id="tabcontent2">
          {datapackage.length === 0
            ? null
            : datapackage.map((x, i) => (
                <div className="mcard-package" key={i + 1} onClick={e => this.handleChangeStyle(x)}>
                  <ToggleState state="aside-toggled" nopersist={true}>
                    <a href="">
                      <img className="mcard-imagepackage thumb-package" src="Img/package/p6.png" alt="Img"/>
                      <div className="mcard-bodypackage">
                        <p className="color-black">
                          <strong>Paquete: </strong> 
                          {x.name}
                        </p>
                      </div>
                    </a>
                  </ToggleState>
                </div>
          ))}
          {/* Progress cargar lista de app */}
          <ProgressAnimate isProgress={isProgress} />
        </div>
        <BillingPackage
          client={client}
          apps={apps}
          style={style}
          reset={reset}
          data={databillingpackage}
          subscription={subscription}
          loadapppackage={loadapppackage}
          handleChangeStyle={this.handleChangeStyle}
          hadleApplyStyle={this.hadleApplyStyle}
          handleChangeAppPackage={this.handleChangeAppPackage}
          handleReloadGrid={handleReloadGrid}
          handleLoadSubscription={handleLoadSubscription}
          handleOpenModalAssign={handleOpenModalAssign}
        />
      </div>
    );
  }
}
