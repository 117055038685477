import React, { Component } from "react";
import { Collapse } from "reactstrap";
import DataGrid, {
  Column,
  SearchPanel
} from "devextreme-react/data-grid";

import { getapplication } from '../../services/Api/Application';
import { ExecuteFn } from '../../Global';

import ProgressAnimate from "../Progress/ProgressAnimate";

import './docs/Application.css';

export default class ApplicationFilter extends Component {
  constructor(props) {
    super(props);
    this.dataGrid = null;
    this.state = {
      dataGrid: [],
      isProgress: false
    };
  }

  componentDidMount() {
    ExecuteFn(this.handleLoadDataGrid);
  }

  ///Cargar progress
  handleProgress = () => this.setState({ isProgress: !this.state.isProgress });

  handleLoadDataGrid = () => {
    this.handleProgress();
    let data = getapplication();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(result => {
      if (result.success) {
        let array = [];
        result.objeto.forEach(element => {
          let obj = { Application: { idApplication: element.idApplication }, nameApplication: element.nameAplication }
          array.push(obj);
        });
        this.setState({ dataGrid: array });
      }
      this.handleProgress();
    }, function (error) {
      console.log(error);
    });
  }

  handleAddApplication = (event) => {
    let data = event.row.data.Application.idApplication;
    this.props.handleAddAplication(data);
  }

  render() {
    const { dataGrid, isProgress } = this.state;
    return (
      <Collapse className="mb-boxes" isOpen={true}>
        <div className="card card-default">
          <div className="card-body">
            <ul className="nav nav-pills flex-column">
              <li className="nav-item p-2">
                <h4 className="">Lista de Aplicaciones</h4>
              </li>
            </ul>
            <DataGrid
              id={"grid-container"}
              dataSource={dataGrid}
              keyExpr={"Application.idApplication"}
              ref={ref => (this.dataGrid = ref)}
              showBorders={true}
            >
              <SearchPanel
                visible={true}
                width={210}
                placeholder={"Buscar aplicación..."}
              />
              <Column
                dataField={"nameApplication"}
                caption={"Aplicaciones"}
                headerFilter={{ allowSearch: true }}
              />
              <Column
                type={"buttons"}
                dataFiled={"Application"}
                width={40}
                buttons={[
                  {
                    cssClass: "size-icondatagrid",
                    hint: "Agregar",
                    icon: "fas fa-plus-square",
                    onClick: this.handleAddApplication
                  }
                ]}
              />
            </DataGrid>
          </div>

          {/* Progress cargar lista de app */}
          <ProgressAnimate isProgress={isProgress} />
        </div>
      </Collapse>
    );
  }
}
