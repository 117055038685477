import React, { Fragment, useState, useContext, useEffect } from 'react'
import VerifyProvider, { VerifyContext } from "./docs/VerificationContext";


import { Card, CardBody, CardHeader, Input, FormGroup, Button, Alert } from "reactstrap";
import { GetUserVerificationAsync, SendVerificationAsync, ChangeContactAsync, VerifyPhoneAsync } from "../../services/Api/User";

import MaskedInput from 'react-maskedinput'
import FormValidator from "../Validation/FormValidation";
import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

import './docs/VerificationContact.css';

const connectionError = "Se presento un falló de conexión, intente recargar su navegador y verificar su conexión a internet.";

const ContentSection = (props) => {
  return (
    <Fragment>
      <div className="float-right cursor-pointer" title={props.title} onClick={() => props.setContent(props.nextContent)}>
        <em className={`fas fa-${props.icon}`}></em>
      </div>
    </Fragment>
  )
}

const TitleHeader = (props) => {
  const context = useContext(VerifyContext);

  const refreshVerifyContact = () => {
    context.handleChangeState('getVerify', true)
  }

  const closeVerifyContact = () => {
    context.handleChangeState('isClose', true)
  }

  return (
    <Fragment>
      <span className="h5 text-bold mb-0">
        {!props.validationEmail && !props.validationPhone && (
          "Activar cuenta de acceso | Verificar numero de teléfono"
        )}
        {!props.validationEmail && props.validationPhone && (
          "Activar cuenta de acceso"
        )}
        {props.validationEmail && !props.validationPhone && (
          "Verificar numero de teléfono"
        )}
      </span>

      <div className="card-tool float-right">
        <em className="fas fa-times" title="Cerrar verificación de datos de contacto" onClick={() => closeVerifyContact()}></em>
        <em className="fas fa-sync" title="Actualizar verificación de datos de contacto" onClick={() => refreshVerifyContact()}></em>
      </div>
    </Fragment>
  )
}

const TextCard = (props) => {
  const showControlPhone = !props.validationPhone && props.validationEmail && !props.isError ? true : false;
  const showControlEmail = !props.validationEmail && props.validationPhone && !props.isError ? true : false;
  const showControlAll = !props.validationEmail && !props.validationPhone && !props.isError ? true : false;

  return (
    <Fragment>
      <p className="card-text">
        {showControlAll && (
          <Fragment>
            La cuenta de acceso no ha sido activa y el número de teléfono no ha sido verificado. Esta información es necesaria que se encuentre verificada para poder tener acceso
            a los distintos productos, servicios o procesos que se integran con <a href="https://ayudasinai.siempre.net.co/snet-core/" target="_blank">SNET CORE</a>.
          </Fragment>
        )}
        {showControlEmail && (
          <Fragment>
            La cuenta de acceso no ha sido activada. El correo electrónico se solicita para ingresar a distintos productos, servicios o procesos que se integran con
            <a href="https://ayudasinai.siempre.net.co/snet-core/" target="_blank" > SNET CORE</a>.
            Por ello es vital que esta información se encuentre validada.
          </Fragment>
        )}
        {showControlPhone && (
          "El número de teléfono ho ha sido verificado, por favor valide esta información "
        )}
        {props.isError && (
          "Se presento un falló de conexión. Presione el botón refrescar, de lo contrario actualice su navegador o verifique su conexión a internet"
        )}
      </p>
    </Fragment>
  )
}

const ControlPhone = (props) => {
  const context = useContext(VerifyContext);
  const [formData, setFormData] = useState({
    numberPhoneOld: '',
    numberPhone: '',
    codePhone: '',
    errorCodePhone: false,
    errors: {},
    isAlert: false,
    isResponse: false,
    isVerifyPhone: false,
    message: ''
  })

  useEffect(() => {
    mapFormData()
  }, [props.phone])

  const mapFormData = () => {
    let condition = props.phone !== "" && props.phone !== null && props.phone !== undefined ? true : false;
    if (condition) {
      setFormData(state => ({
        ...state,
        numberPhoneOld: props.phone,
        numberPhone: props.phone
      }))
    }
  }

  const onChangeInput = event => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    setFormData(state => ({
      ...state,
      [input.name]: value,
      errors: {
        ...state.errors,
        [input.name]: result
      }
    }));
  };

  const hasError = (inputName, method) => {
    return (
      formData.errors &&
      formData.errors[inputName] &&
      formData.errors[inputName][method]
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    setFormData(state => ({
      ...state,
      isAlert: false,
      errors: errors
    }))

    const isCodePhone = formData.codePhone === "" || formData.codePhone === null || formData.codePhone === undefined ? true : false;

    if (isCodePhone) {
      setFormData(state => ({
        ...state,
        errorCodePhone: true
      }))
    }

    if (!hasError && !isCodePhone) {
      verifyCodePhone()
    }
  }

  const changePhoneContact = async () => {
    context.handleChangeState('isSpinner', true)
    await ChangeContactAsync({
      phone: formData.numberPhone
    })
      .then(resp => {
        if (resp.isSuccess) {
          setFormData(state => ({
            ...state,
            numberPhoneOld: formData.numberPhone,
            isAlert: true,
            isResponse: true,
            message: resp.message
          }))
        } else if (resp.isSuccess === false) {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: resp.message
          }))
        } else {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: connectionError
          }))
        }
        context.handleChangeState('isSpinner', false)
      })
      .catch(err => {
        setFormData(state => ({
          ...state,
          isAlert: true,
          isResponse: false,
          message: connectionError
        }))
        context.handleChangeState('isSpinner', false)
      })
  }

  const sendCodeVerifyPhone = async () => {
    context.handleChangeState('isSpinner', true)
    await SendVerificationAsync()
      .then(resp => {
        if (resp.isSuccess) {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: true,
            message: resp.message
          }))
        } else if (resp.isSuccess === false) {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: resp.message
          }))
        } else {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: connectionError
          }))
        }
        context.handleChangeState('isSpinner', false)
      })
      .catch(err => {
        setFormData(state => ({
          ...state,
          isAlert: true,
          isResponse: false,
          message: connectionError
        }))
        context.handleChangeState('isSpinner', false)
      })
  }

  const verifyCodePhone = async () => {
    context.handleChangeState('isSpinner', true)
    await VerifyPhoneAsync(formData.codePhone.replace(/ /g, ''))
      .then(resp => {
        if (resp.isSuccess) {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: true,
            isVerifyPhone: true,
            message: resp.message
          }))
        } else if (resp.isSuccess === false) {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: resp.message
          }))
        } else {
          setFormData(state => ({
            ...state,
            isAlert: true,
            isResponse: false,
            message: connectionError
          }))
        }
        context.handleChangeState('isSpinner', false)
      })
      .catch(err => {
        setFormData(state => ({
          ...state,
          isAlert: true,
          isResponse: false,
          message: connectionError
        }))
        context.handleChangeState('isSpinner', false)
      })
  }

  const nexAction = () => {
    if (isClose) context.handleChangeState('isClose', true);
    else {
      context.handleChangeState('getVerify', true);
    }
  }

  const colorAlert = formData.isResponse ? 'success' : 'warning';

  const faIconAlert = formData.isResponse ? 'check-circle' : 'exclamation-circle';

  const isClose = !props.validationEmail ? false : true;

  const changePhone = (formData.numberPhone !== formData.numberPhoneOld) ||
    (formData.numberPhone === "" || formData.numberPhone === null || formData.numberPhone === undefined) ? true : false;

  return (
    <Fragment>
      <form className="row justify-content-center" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
        <FormGroup className="w-75">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-phone" />
              </span>
            </div>
            <Input type="text"
              name="numberPhone"
              placeholder="Número de teléfono"
              value={formData.numberPhone}
              onChange={(e) => onChangeInput(e)}
              data-validate='["required","phone"]'
              invalid={
                hasError("numberPhone", "required") ||
                hasError("numberPhone", "phone")
              }
            />
            <div className="input-group-prepend">
              {changePhone && (
                <Button type="button" className="bg-success" title="Guardar número de teléfono" onClick={() => changePhoneContact()}>
                  <i className="fa fa-save fs-lg" />
                </Button>
              )}
              {!changePhone && (
                <Button type="button" className="bg-primary" title="Solicitar código de verificación" onClick={() => sendCodeVerifyPhone()}>
                  <i className="fa fa-paper-plane fs-lg" />
                </Button>
              )}
            </div>
          </div>
          {hasError("numberPhone", "phone") && (
            <span className="text-danger fs-sm">
              El valor ingresado no corresponde a un numero de teléfono valido.
            </span>
          )}
        </FormGroup>
        <FormGroup className="w-75">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-paste" />
              </span>
            </div>
            <MaskedInput
              name="codePhone"
              className="form-control"
              mask="1 1 1 1 1 1"
              size="20"
              onChange={(e) => onChangeInput(e)}
            />
          </div>
          {formData.errorCodePhone && (
            <span className="text-danger fs-sm">
              El código de verificación es requerido
            </span>
          )}
        </FormGroup>
        <FormGroup className="w-75 row justify-content-center">
          <button type="submit" className="btn btn-success btn-oval">Validar Código</button>
        </FormGroup>
      </form>
      {formData.isAlert && (
        <Alert color={colorAlert}>
          <em className={`fa fa-${faIconAlert} fa-lg fa-fw`}></em>
          {formData.message}
        </Alert>
      )}
      {formData.isVerifyPhone && (
        <div className="row justify-content-center">
          <FormGroup className="w-75 row justify-content-center">
            <button type="button" className={`btn btn-purple btn-oval`} onClick={() => nexAction()}>
              <span className="h5">Verificar y cerrar</span>
            </button>
          </FormGroup>
        </div>
      )}
    </Fragment>
  )
}

const ControlEmail = (props) => {
  const context = useContext(VerifyContext);
  const [responseEmail, setResponseEmail] = useState({
    isError: false,
    isAlert: false,
    isResponse: false,
    message: ''
  });

  const requestActiveAccount = async () => {
    context.handleChangeState('isSpinner', true)
    await SendVerificationAsync()
      .then(resp => {
        if (resp.isSuccess) {
          setResponseEmail({
            isAlert: true,
            isResponse: true,
            message: resp.message
          })
        } else if (resp.isSuccess === false) {
          setResponseEmail({
            isAlert: true,
            message: resp.message
          })
        } else {
          setResponseEmail(state => ({
            ...state,
            isError: true,
            isAlert: true,
            isResponse: false,
            message: connectionError
          }))
        }
        context.handleChangeState('isSpinner', false)
      })
      .catch(err => {
        setResponseEmail(state => ({
          ...state,
          isError: true,
          isAlert: true,
          isResponse: false,
          message: connectionError
        }))
        context.handleChangeState('isSpinner', false)
      })
  }

  const verifyContact = () => {
    context.handleChangeState('getVerify', true);
    setResponseEmail(state => ({
      ...state,
      isAlert: false,
      isResponse: false,
      message: ''
    }))
  }

  const colorAlert = responseEmail.isResponse ? 'success' : 'warning';

  const faIconAlert = responseEmail.isResponse ? 'check-circle' : 'exclamation-circle';

  return (
    <Fragment>
      <div className={`row justify-content-center`}>
        <FormGroup className="w-75 row justify-content-center">
          <button type="button" className="btn btn-info btn-oval btn-lg" onClick={() => requestActiveAccount()}>
            <span className="h5">Solicitar activar cuenta</span>
          </button>
          {props.validationPhone && responseEmail.isResponse && (
            <button type="button" className="btn btn-purple btn-oval btn-lg ml-2" onClick={() => verifyContact()}>
              <span className="h5">Verificar / Cerrar</span>
            </button>
          )}
        </FormGroup>

        {!props.validationPhone && responseEmail.isResponse && (
          <FormGroup className="w-75 row justify-content-center">
            <button type="button" className="btn btn-purple btn-oval btn-lg" onClick={() => props.setContent(2)}>
              <span className="h5">Verificar número de teléfono</span>
            </button>
          </FormGroup>
        )}
      </div>
      {responseEmail.isAlert && (
        <Alert color={colorAlert}>
          <em className={`fa fa-${faIconAlert} fa-lg fa-fw`}></em>
          {responseEmail.message}
        </Alert>
      )}
      <span className="text-muted">Nota: Las cuentas de usuario que no sean activadas serán eliminadas a futuro.</span>
    </Fragment>
  )
}

const ControlAll = (props) => {
  const [content, setContent] = useState(1)

  return (
    <Fragment>
      {content === 1 && (
        <div className="animated fadeInLeft">
          <ContentSection nextContent={2} setContent={setContent} icon="arrow-right" title="Verificar número de teléfono" />
          <ControlEmail {...{
            validationEmail: props.validationEmail,
            validationPhone: props.validationPhone,
            email: props.email,
            phone: props.phone,
            setContent: setContent
          }} />
        </div>
      )}
      {content === 2 && (
        <div className="animated fadeInRight">
          <ContentSection nextContent={1} setContent={setContent} icon="arrow-left" title="Solicitar activar cuenta" />
          <ControlPhone {...{
            validationEmail: props.validationEmail,
            validationPhone: props.validationPhone,
            email: props.email,
            phone: props.phone,
            setContent: setContent
          }} />
        </div>
      )}
    </Fragment>
  )
}

function VerificationContact() {
  const context = useContext(VerifyContext);
  const [data, setData] = useState({
    isError: false,
    validationEmail: true,
    validationPhone: true,
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (context.data.getVerify) getUserVerification();
  }, [context.data.getVerify])

  const getUserVerification = async () => {
    if (context.data.getVerify) context.handleChangeState('getVerify', false)
    context.handleChangeState('isSpinner', true)
    await GetUserVerificationAsync()
      .then(resp => {
        if (resp.isSuccess) {
          let respData = resp.objeto;
          setData(state => ({
            ...state,
            validationEmail: respData.validationEmail,
            validationPhone: respData.validationPhone,
            email: respData.email,
            phone: respData.phone
          }))
        }
        context.handleChangeState('getVerify', false)
        context.handleChangeState('isSpinner', false)
      })
      .catch(err => {
        setData(state => ({
          ...state,
          isError: true
        }))
        context.handleChangeState('isSpinner', false)
      })
  }

  const showControlPhone = !data.validationPhone && data.validationEmail && !data.isError ? true : false;
  const showControlEmail = !data.validationEmail && data.validationPhone && !data.isError ? true : false;
  const showControlAll = !data.validationEmail && !data.validationPhone && !data.isError ? true : false;
  const showComponent = (data.validationEmail && data.validationPhone) || context.data.isClose ? false : true

  return (
    <Fragment>
      {(showComponent) && (
        <div className="position-fixed position-card-verify z-1000 pl-3 animated zoomInRight">
          <Card className="card-default">
            <SpinnerDisableContent isSpinnerDisabled={context.data.isSpinner}>
              <CardHeader>
                <TitleHeader {...data} />
              </CardHeader>
              <CardBody>
                <TextCard {...data} />
                {showControlPhone && (
                  <ControlPhone {...data} />
                )}
                {showControlEmail && (
                  <ControlEmail {...data} />
                )}
                {showControlAll && (
                  <ControlAll {...data} />
                )}
                {data.isError && (
                  <Fragment>
                    <div className="row justify-content-center">
                      <FormGroup className="w-75 row justify-content-center">
                        <button type="button" className="btn btn-warning btn-oval btn-lg" onClick={() => getUserVerification()}>
                          <span className="h5">Refrescar</span>
                        </button>
                      </FormGroup>
                    </div>
                  </Fragment>
                )}
              </CardBody>
            </SpinnerDisableContent>
          </Card>
        </div>
      )}
    </Fragment>
  )
}

export default function VerifyComponent() {
  return (
    <VerifyProvider>
      <VerificationContact />
    </VerifyProvider>
  )
}