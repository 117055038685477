import React from "react";
import Select from "react-select";
import FormValidator from "../Validation/FormValidation";
import { withNamespaces } from "react-i18next";
import {
  Input,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

///Notificaciones.
import notify from "devextreme/ui/notify";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

///Metodo y ruta de acceso.
import {
  postMenu,
  postMainMenu,
  getAllMainMenu,
  putMenu
} from "../RouteApi/RouteApi";
import { headerPost, headerPut, headerGet } from "../../services/Headers";
import MethodPost from "../../services/MethodPost";
import MethodGet from "../../services/MethodGet";

///Servicio.
import { listMainMenu } from "../../services/Api/Menu";
import { listIcon } from "../../services/Api/Icon/Icon";
import { _JsonStorage } from '../../Global';


import SpinnerAnimate from '../Progress/SpinnerAnimate';

///Botones del modal.
const ButtonFooter = props => {
  if (props.isTypeModal) {
    return (
      <Button type="submit" className="bg-success">
        Actualizar
      </Button>
    );
  } else {
    return (
      <Button type="submit" color="primary">
        Guardar
      </Button>
    );
  }
};

class ModalMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptionMulti: [],
      dropDownIcon: [],
      mainMenuOption: [],
      formMenu: {
        nameMenu: "",
        descriptionMenu: "",
        mainMenu: null,
        urlMenu: "",
        nameController: "",
        colourMenu: "#ff0000",
        iconMenu: null
      },
      isSpinner: false
    };
  }

  componentDidMount() {
    this.handleMainMenus();
    this.loadDropDownIcon();
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  handleMainMenus = () => {
    let promise = listMainMenu();
    promise.then(
      response => {
        if (response.success) {
          let array = [];
          response.response.forEach(x => {
            let obj = {
              label: x.textMainMenu,
              value: x.idMainMenu,
              nameForm: "formMenu",
              nameSelect: "mainMenu"
            };
            array.push(obj);
          });
          this.setState({ mainMenuOption: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Función que carga la lista de iconos
  loadDropDownIcon = () => {
    let getLocalStorage = localStorage.getItem("icon");
    if (getLocalStorage !== null) {
      let array = JSON.parse(getLocalStorage);
      let empty = array.length > 0? this.handleCastIcon(array): null;
    } else if (getLocalStorage === null) {
      let promise = listIcon();
      promise.then(
        response => {
          if (response.success) {
            localStorage.setItem("icon", JSON.stringify(response.response));
            this.handleCastIcon(response.response);
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  ///Agregar lista de iconos a array del select
  handleCastIcon = array => {
    let icons = [];
    array.forEach(x => {
      let obj = {
        label: <span className={x.nameIcon} />,
        value: x.idIcon,
        nameForm: "formMenu",
        nameSelect: "iconMenu"
      };
      icons.push(obj);
    });
    this.setState({ dropDownIcon: icons });
  };

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cambio de estado de los elementos select del Dom.
  handleChangeSelectMulti = event => {
    ///Cambiar de estado los valores de los select.
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Función para validar los campos al momento de realizar click en el boton ingresar.
  onSubmit = e => {
    e.preventDefault();
    if (
      this.state.formMenu.iconMenu === null ||
      this.state.formMenu.colourMenu === ""
    ) {
      notify(
        "Verifica haber selecciona un menu, icono y un color.",
        COLOUR_WARNING
      );
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "COLOR"].includes(i.nodeName)
      );
      ///Validar campos requeridos.
      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit(e);
      }
    }
  };

  ///Función para enviar datos a api.
  handleSubmit(event) {
    event.preventDefault();
    this.handleSpinner();

    const dataFetch = new FormData();
    let idMaiMenu =
      this.state.formMenu.mainMenu != null
        ? this.state.formMenu.mainMenu.value
        : this.state.formMenu.mainMenu;
    let url = null;
    let header = null;

    ///Validar si se va a guardar un menu o subMenu.
    if (idMaiMenu != null) {
      dataFetch.append("Text", this.state.formMenu.nameMenu);
      dataFetch.append("Description", this.state.formMenu.descriptionMenu);
      dataFetch.append("IdMainMenu", idMaiMenu);
      dataFetch.append("Url", this.state.formMenu.urlMenu);
      dataFetch.append("Controller", this.state.formMenu.nameController);
      dataFetch.append("Colour", this.state.formMenu.colourMenu);
      dataFetch.append("IdIcon", this.state.formMenu.iconMenu.value);

      ///Validar si el submit es para crear o actualizar un elemento.
      ///Dependiendo se asigna los requisitos para realizar la solicitud.
      if (this.props.openModalEdit === true) {
        dataFetch.append("IdMenu", this.props.item.idMenu);
        url = putMenu;
        header = headerPut(dataFetch, _JsonStorage.access_token);
      } else {
        url = postMenu;
        header = headerPost(dataFetch, _JsonStorage.access_token);
      }
    } else {
      url = postMainMenu;
      dataFetch.append("TextMainMenu", this.state.formMenu.nameMenu);
      dataFetch.append(
        "DescriptionMainMenu",
        this.state.formMenu.descriptionMenu
      );
      dataFetch.append("UrlMainMenu", this.state.formMenu.urlMenu);
      dataFetch.append(
        "ControllerMainMenu",
        this.state.formMenu.nameController
      );
      dataFetch.append("ColourMainMenu", this.state.formMenu.colourMenu);
      dataFetch.append("IdIconMainMenu", this.state.formMenu.iconMenu.value);

      header = headerPost(dataFetch, _JsonStorage.access_token);
    }

    ///Se llama el metodo y se le asignan los parametros.
    MethodPost(url, header)
      .then(response => {
        if (response.success) {
          
          if (this.props.openModalEdit) {
            showValidation(response, 2, "menu");
          } else {
            showValidation(response, 1, "menu");
          }

          this.chageSelect();
          this.props.onChangeMenu();
          this.props.onClickModal();
          this.setStateForm();
        } else {
          showValidation(response);
        }
        this.handleSpinner();
      })
      .catch(error => {
        console.log(error);
      });
  }

  ///Resetear campos del formulario.
  setStateForm = () => {
    this.setState({
      formMenu: {
        nameMenu: "",
        descriptionMenu: "",
        urlMenu: "",
        nameController: "",
        colourMenu: "",
        mainMenu: null,
        iconMenu: null
      }
    });
  };

  ///Actualizar el select de las macros
  chageSelect = () => {
    let header = headerGet( _JsonStorage.access_token);
    MethodGet(getAllMainMenu, header)
      .then(response => {
        if (response.success) {
          let array = [];
          response.response.forEach(function(elements) {
            let dropDownElements = {
              label: elements.textMainMenu,
              value: elements.idMainMenu,
              nameForm: "formMenu",
              nameSelect: "mainMenu"
            };
            array.push(dropDownElements);
          });
          this.setState({ mainMenuOption: array });
        } else {
          console.log(response.error);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Resetear formulario.
  setStateForm = () => {
    this.setState({
      formMenu: {
        nameMenu: "",
        descriptionMenu: "",
        mainMenu: null,
        urlMenu: "",
        nameController: "",
        colourMenu: "#ff0000",
        iconMenu: null
      }
    });
  };

  ///Función para validar el tipo de modal a mostrar (crear-editar).
  handleEdit = () => {
    if (this.props.openModalEdit === true) {
      ///Asignar icono pre seleccioado.
      let labelWithIcon = <span className={this.props.item.nameIcon} />;
      let dropDownElements = {
        label: labelWithIcon,
        value: this.props.item.idIcon,
        nameForm: "formMenu",
        nameSelect: "iconMenu"
      };

      ///Cargar los datos al modal si va a editar.
      this.setState({
        formMenu: {
          nameMenu: this.props.item.text,
          descriptionMenu: this.props.item.description,
          mainMenu: {
            label: this.props.item.textMainMenu,
            value: this.props.item.idMainMenu
          },
          urlMenu: this.props.item.url,
          nameController: this.props.item.controller,
          colourMenu: this.props.item.colour,
          iconMenu: dropDownElements
        }
      });
    } else {
      this.setStateForm();
    }
  };

  render() {
    const { isSpinner } = this.state;
    return (
      <div>
        <Modal onOpened={this.handleEdit} isOpen={this.props.openModal} toggle={this.openModal}>
          <div className="modal-header" toggle={this.openModal}>
            REGISTRO DE MENÚ
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.onClickModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={this.onSubmit} name="formMenu" autoComplete="off" className="parent">
            <SpinnerAnimate isDisabled={isSpinner} />
            <ModalBody>
              <FormGroup>
                <label>Nombre</label>
                <Input
                  type="text"
                  name="nameMenu"
                  placeholder="Nombre menú..."
                  invalid={
                    this.hasError("formMenu", "nameMenu", "required") ||
                    this.hasError("formMenu", "nameMenu", "alphaspace")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formMenu.nameMenu}
                />
                {this.hasError("formMenu", "nameMenu", "alphaspace") && (
                  <span className="invalid-feedback">
                    El campo debe ser un nombre válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Descripcion</label>
                <Input
                  type="textarea"
                  name="descriptionMenu"
                  placeholder="Descripcion del menú..."
                  invalid={
                    this.hasError("formMenu", "descriptionMenu", "required") ||
                    this.hasError("formMenu", "descriptionMenu", "maxlen")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required","maxlen"]'
                  value={this.state.formMenu.descriptionMenu}
                  data-param="200"
                />
                {this.hasError("formMenu", "descriptionMenu", "maxlen") && (
                  <span className="invalid-feedback">
                    La longitud del campo supera la requerida.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label title="No ingresar este campo en un registro se asumira que se esta imgresando un menú principal">
                  Menú pricipal (Opcional)
                </label>
                <Select
                  name="mainMenu"
                  multi
                  simpleValue
                  placeholder="---- SELECCIONA UNA OPCIÓN ----"
                  value={this.state.formMenu.mainMenu}
                  onChange={this.handleChangeSelectMulti}
                  options={this.state.mainMenuOption}
                />
              </FormGroup>
              <FormGroup>
                <label>Url menú</label>
                <Input
                  type="text"
                  name="urlMenu"
                  placeholder="Ejemplo: /ruta"
                  invalid={this.hasError("formMenu", "urlMenu", "required")}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formMenu.urlMenu}
                />
              </FormGroup>
              <FormGroup>
                <label>Controlador (Opcional)</label>
                <Input
                  type="text"
                  name="nameController"
                  placeholder="Nombre controlador..."
                  onChange={this.validateOnChange}
                  value={this.state.formMenu.nameController}
                />
              </FormGroup>
              <div className="row">
                <div className="col">
                  <label className="sr-only">Icono</label>
                  <Select
                    name="iconMenu"
                    multi
                    simpleValue
                    placeholder="SELECCIONA UNA OPCIÓN"
                    value={this.state.formMenu.iconMenu}
                    onChange={this.handleChangeSelectMulti}
                    options={this.state.dropDownIcon}
                  />
                </div>
                <div className="col">
                  <label className="sr-only">Color</label>
                  <Input
                    type="color"
                    name="colourMenu"
                    className="form-control"
                    invalid={this.hasError(
                      "fromMenu",
                      "colourMenu",
                      "required"
                    )}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    value={this.state.formMenu.colourMenu}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonFooter isTypeModal={this.props.openModalEdit} />{" "}
              <Button color="secondary" onClick={this.props.onClickModal}>
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default withNamespaces("translations")(ModalMenu);
