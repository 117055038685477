import React, { Component } from "react";
import { Link } from "react-router-dom";

import { appThirAsync, appOwnerAsync } from "../../services/Api/Application";
import { _JsonStorage } from "../../Global";

import "../../styles/extra/AppShortcuts.css";

class AppShortcuts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ddOpen: false,
      appsClient: [],
      appsThird: [],
      clickComponent: false
    };
  }

  componentDidMount() {
    this.handleAppsUser();
    this.handleAppsShared();
    document.addEventListener("mousedown", this.handleCloseComponent, false);
  }

  handleCloseComponent = e => {
    if (!e.target.classList.contains("moreapp")) {
      this.props.handleCloseComponent(false);
      document.removeEventListener(
        "mousedown",
        this.handleCloseComponent,
        false
      );
    }
  };

  ///Consultar aplicaciones de propietario
  handleAppsUser = () => {
    const navContext = _JsonStorage.profile.id;
    const promise = appOwnerAsync(navContext);
    promise.then(
      response => {
        if (response.success) {
          this.handleCastAppUser(response.objeto);
        } else if (response.response != null) {
          if (response.response.length > 0) {
            this.handleCastAppUser(response.objeto);
          }
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Castear aplicaciones de propietario
  handleCastAppUser = resource => {
    const array = [];
    resource.forEach(element => {
      const obj = {
        img: element.route,
        url: element.url,
        name: element.nameAplication,
        subscription: element.nameSubscription,
        propietarie: element.nameClient
      };
      array.push(obj);
    });
    this.setState({ appsClient: array });
  };

  ///Consultar aplicaciones de terceros
  handleAppsShared = () => {
    const navContext = _JsonStorage.profile.id;
    const promise = appThirAsync(navContext);
    promise.then(
      response => {
        if (response.success) {
          this.handleCastAppsShared(response.objeto);
        } else if (response.response != null) {
          if (response.response.length > 0) {
            this.handleCastAppsShared(response.objeto);
          }
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Castear aplicaciones de terceros
  handleCastAppsShared = reource => {
    const array = [];
    const validate = [];
    reource.forEach(element => {
      if (validate.indexOf(element.nameAplication) === -1) {
        const obj = {
          nameapp: element.nameAplication,
          url: element.url,
          img: element.route,
          subscription: element.nameSubscription
        };
        array.push(obj);
        validate.push(element.nameAplication);
      }
    });
    this.setState({ appsThird: array });
  };

  toggle = () =>
    this.setState({
      ddOpen: !this.state.ddOpen
    });

  render() {
    const { appsClient, appsThird } = this.state;
    return (
      <div className="app-position moreapp animated pulse">
        <div className="card card-default app-shorcutsposition moreapp top-appshortcuts pr-2">
          <div className="card-header dropdown-header bg-trans-gradient moreapp">
            <h4 className="m-0 text-center color-white moreapp">
              Mis Aplicaciones
              <br />
              <small className="mb-0 opacity-80 moreapp">
                Aplicaciones de usuario y complementos
              </small>
            </h4>
          </div>
          <div className="card-body moreapp">
            <div className="custom-scroll h-100 moreapp">
              <ul className="app-list moreapp">
                {appsClient.length === 0
                  ? null
                  : appsClient.map((x, i) => (
                    <li key={i + 1} className="moreapp col-md-12">
                      <a
                        href={x.url}
                        className="app-list-item hover-white moreapp"
                        target="_blank"
                        rel="noopener noreferrer"
                        title={x.name}
                      >
                        {
                          x.img === "" || x.img === null || x.img === undefined ? null :
                            <span className="icon-stack">
                              <img
                                className="smallPicture moreapp"
                                src={x.img}
                                alt="icon"
                              />
                            </span>
                        }
                        <span className="app-suscription-1 moreapp">
                          <strong>Aplicación: </strong>
                          {x.name}
                        </span>
                        <span className="app-propietarie moreapp">
                          <strong>Propietario: </strong>
                          {x.propietarie}
                        </span>
                        <span className="app-suscription moreapp">
                          <strong>Suscripción: </strong>
                          {x.subscription}
                        </span>
                      </a>
                    </li>
                  ))}
                {appsThird.length === 0 ? null : (
                  <li className="moreapp">
                    <div className="moreapp">
                      <hr className="separation moreapp" />
                      <h5 className="other-apps moreapp">Otras Aplicaciones</h5>
                    </div>
                  </li>
                )}
                {appsThird.length === 0
                  ? null
                  : appsThird.map((x, i) => (
                    <li key={i + 1} className="moreapp col-md-12">
                      <a
                        href={x.url}
                        className="app-list-item hover-white moreapp"
                        target="_blank"
                        rel="noopener noreferrer"
                        title={x.name}
                      >
                        {
                          x.img === "" || x.img === null || x.img === undefined ? null :
                            <span className="icon-stack">
                              <img
                                className="smallPicture moreapp"
                                src={x.img}
                                alt="icon"
                              />
                            </span>
                        }
                        <span className="app-suscription-1 moreapp">
                          <strong>Aplicación: </strong>
                          {x.nameapp}
                        </span>
                        <span className="app-propietarie moreapp">
                          <strong>Propietario: </strong>
                        </span>
                        <span className="app-suscription moreapp">
                          <strong>Suscripción: </strong>
                          {x.subscription}
                        </span>
                      </a>
                    </li>
                  ))}
                <li className="potition-li moreapp">
                  <Link
                    to="/subscription"
                    path="/subscription"
                    className="btn btn-secondary position-btn moreapp"
                  >
                    Agregar mas
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppShortcuts;
