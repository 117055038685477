import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import {
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";
import Select from "react-select";

import { getclientbyuser } from "../../services/Api/Enterprise";
import { COLOUR_WARNING } from "../Message/Message";
import { _JsonStorage, ExecuteFn } from '../../Global';

import "./docs/Enterprise.css";

export default class EnterpriseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      enterprise: null
    };
  }

  componentDidUpdate() {
    if (this.props.isLoad) {
      this.props.handleReload();
      this.handleEnterprise();
    }
  }

  componentDidMount() {
    ExecuteFn(this.handleEnterprise);
  }

  ///Consultar empresas
  handleEnterprise = () => {
    let param = _JsonStorage.profile.id;
    let data = getclientbyuser(param);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.socialReason,
              value: element.enterpriseId
            };
            array.push(obj);
          });
          this.setState({ clients: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Filtrar empresa
  handleChangeSelect = event => {
    this.setState({ enterprise: { label: event.label, value: event.value } });
    this.props.handleFilter(event.value, false);
  };

  handleEdit = () => {
    if (this.state.enterprise == null) {
      notify("Primero seleccione una empresa.", COLOUR_WARNING, 3000);
    } else {
      this.props.handleEnterprise(); 
      this.props.handleFilter(this.state.enterprise.value, true);
    }
  };
 
  render() {
    const { clients, enterprise } = this.state;
    return (
      <div>
        <Card className="card-default">
          <CardBody>
            <form name="formfilter">
              <div className="form-row align-items-center">
                <div className="col-md-3">
                  <h4>Filtro de empresa</h4>
                </div>
                <div className="col-md-8">
                  <label>Mis empresas</label>
                  <InputGroup>
                    <Select
                      name="enterprise"
                      className="width-select"
                      placeholder="Selecciona una opción"
                      options={clients}
                      value={enterprise}
                      onChange={this.handleChangeSelect}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="btn bg-success minus-square"
                        onClick={this.handleEdit}
                      >
                        <i className="fas fa-pen" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}
