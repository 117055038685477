import MethodPost from "../MethodPost";
import MethodGet from "../MethodGet";
import {
  clientuser,
  getclietbysubscription,
  getfilterid,
  getuserclient,
  getuserstandarclient,
  deleteusercliet,
  getclientidcode,
  putImg
} from "../../components/RouteApi/RouteApi";
import { headerDelete, headerPost, headerPostJson, headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

export const sendClient = (url, header) => {
  return MethodPost(url, header)
    .then(response => response)
    .catch(error => error);
}; 

export const getclientbyclienttype = param => {
  let urlBase = clientuser,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getclientbysubscription = param => {
  let urlBase = getclietbysubscription,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getclientid = param => {
  let urlBase = getfilterid,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getuserclientid = param => {
  let urlBase = getuserclient,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getuserclientstadar = param => {
  let urlBase = getuserstandarclient,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const deleteuserclientbyemail = (param, client) => {
  let urlBase = deleteusercliet,
    params = {
      value: param,
      id: client
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerDelete( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getasigns = (param1, param2) => {
  let obj ={
    token: param1
  }

  let header = headerPostJson(obj, _JsonStorage.access_token);
  const response = MethodPost(param2, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

export const getclientcode = param => {
  let urlBase = getclientidcode,
    params = {
      code: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const putImgClient = (img, client) => {
  let Nav = _JsonStorage.profile.id;
  let formData = new FormData();
  formData.append("file", img[img.length - 1]);
  formData.append("EnterpriseId", client);
  formData.append("code", Nav);

  let header = headerPost(formData, _JsonStorage.access_token);
  let response = MethodPost(putImg, header)
    .then(response => response)
    .catch(error => error);
  return new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
};
