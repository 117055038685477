import moment from "moment-with-locales-es6";
moment.locale("es");

const nowDate = Date.now();
const lastDate = new Date(2019, 12, 10, 5, 40);

export const IdentityList = [
  {
    "isClient": true,
    "clientName": 'web_core',
    "clientId": "spa.core",
    "appName": "CORE",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },
  {
    "isClient": true,
    "clientName": 'web_core_1',
    "clientId": "spa.core",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },
  {
    "isClient": true,
    "clientName": 'web_core',
    "clientId": "spa.core",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },
  {
    "isClient": true,
    "clientName": 'web_core_2',
    "clientId": "spa.core",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },
  {
    "isClient": true,
    "clientName": 'web_core_3',
    "clientId": "spa.core",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },
  {
    "isClient": true,
    "clientName": 'web_core_4',
    "clientId": "spa.core",
    "grantType": "authorization_code",
    "accessTokenLifeTime": "3600",
    "identityTokenLifeTime": "300",
    "redirectUris": ["https://localhost:44329/authentication/callback"],
    "postLogoutRedirectUris": ["https://localhost:44329/"],
    "scopes": ["openid", "email", "id", "role", "api-core", "api-notification", "profile"]
  },


  {
    "isClient": false,
    "enabled": true,
    "name": "api-core",
    "displayName": "Service Core",
    "description": "Recurso que brinda la información necesaria para el control de la aplicación web de core",
    "created": moment(lastDate).format('LLL'),
    "updated": moment(nowDate).format('LLL'),
    "lastAccessed": moment(nowDate).format('LLL'),
    "nonEditable": false,

    "apiClaims": [
      { "id": 1, "type": "id" }
    ],
    "apiProperties": [
      { key: '', value: 0 }
    ],
    "apiScopes": [
      {
        "name": "api-sinai",
        "displayName": "Service Sinai",
        "description": "Alcance del servicio de core para permitir el ingreso al servicio de sinai",
        "required": false,
        "emphasize": false,
        "showInDiscoveryDocument": true,
        //Claims de los alcances de la Api
        "scopeClaims": [
          { "id": 1, "type": 'role' },
          { "id": 2, "type": 'id' }
        ]
      }
    ]
  }
];

export const IdentityTypeOptions = [
  {
    "label": "Cliente",
    "value": 1
  },
  {
    "label": "Recurso",
    "value": 2
  }
];

export const GrantTypes = [
  { label: 'Password', value: 'password' },
  { label: 'AuthorizationCode', value: 'authorization_code' },
  { label: 'ClientCredentials', value: 'client_credentials' },
  { label: 'RefreshToken', value: 'refresh_token' },
  { label: 'Implicit', value: 'implicit' },
  { label: 'Saml2Bearer', value: 'urn:ietf:params:oauth:grant-type:saml2-bearer' },
  { label: 'JwtBearer', value: 'urn:ietf:params:oauth:grant-type:jwt-bearer' },
  { label: 'DeviceCode', value: 'urn:ietf:params:oauth:grant-type:device_code' },
  { label: 'TokenExchange', value: 'urn:ietf:params:oauth:grant-type:token-exchange' },
]