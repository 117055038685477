import React from 'react';
import { Link } from 'react-router-dom';

const getDate = new Date();

const NotFound = props => (
  <div className="abs-center wd-xl">
    <div className="text-center mb-4">
      <div className="text-lg mb-3">404</div>
      <p className="lead m-0">No pudimos encontrar esta página.</p>
      <p>La página que busca no existe.</p>
    </div>
    <ul className="list-inline text-center text-sm mb-4">
      <li className="list-inline-item">
        <Link to="home" className="lead">Volver al Inicio</Link>
      </li>
    </ul>
    <div className="p-3 text-center">
      <span className="mr-2">&copy;</span>
      <span>{getDate.getFullYear()}</span>
      <span className="mx-2">-</span>
      <span>SNET CORE</span>
    </div>
  </div>
)

export default NotFound;