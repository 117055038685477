import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";

import Remove from "../SweetAlert/SwalRemove";
import Detail from "./SubscriptionModalDetailApp";
import { deleteSubsApp } from "../../services/Api/Subscription";
import { getfindbysubscription } from "../../services/Api/Application";
import { MESSAGE_REMOVE_DIFFERENT, COLOUR_INFO, COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";
import { Time500 } from '../../Global';

import ProgressAnimate from "../Progress/ProgressAnimate";

export default class SubscriptionDatagrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 0,
      dataapp: [],
      isload: false,
      price: null,
      delete: {
        openModal: false,
        element: "",
        elementRemove: 0
      },
      detailapp: {
        openModalApp: false,
        code: 0
      },
      isProgress: false
    };
  }

  componentDidUpdate() {
    if (this.props.subscription > 0) {
      if (this.state.filter !== this.props.subscription) {
        this.setState({ filter: this.props.subscription });
        this.handleDataApp();
      }
    }

    if (this.props.reset) {
      this.setState({
        filter: this.props.subscription === 0 ? 0 : this.state.filter,
        dataapp: []
      });
    }

    // if (this.props.isEdit) {
    //   this.handleDataApp();
    // }

    if (this.props.reloadgrid) {
      this.props.handleReloadGrid();
      this.handleDataApp();
    }
  }

  ///Cargar progress
  handleProgress = () => this.setState({ isProgress: !this.state.isProgress });

  handleDataApp = () => {
    this.handleProgress();
    let subscription = this.props.subscription;
    let data = getfindbysubscription(subscription);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.objeto.forEach(element => {
            let obj = {
              idapp: element.idApplication,
              nameapp: element.nameAplication,
              img: element.route,
              description: element.description,
              totalprice: element.amount * element.factor
            };
            array.push(obj);
          });
          this.setState({ dataapp: array });
          this.props.handleGetApps(array);
        }
        this.handleProgress();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleAddApp = () => {
    if (this.props.dataapp !== null) {
      let add = false;
      this.state.dataapp.forEach(element => {
        if (element.idapp === this.props.dataapp.idapp) {
          add = true;
        }
      });

      if (add) {
        notify(
          "La aplicación ya se encuentra asignada en esta suscripción.",
          COLOUR_INFO,
          4000
        );
        add = false;
      } else {
        this.props.handleCleanDataApp();
        let dataPush = this.state.dataapp;
        let priceprops = this.props.pricetotal;
        dataPush.push(this.props.dataapp);
        this.setState({ dataapp: dataPush, price: priceprops });
      }
    }
  };

  hadleRemoveElement = event => {
    let element = null;
    let indentifier = event.currentTarget.value;
    let dataapp = this.state.dataapp;
    dataapp.forEach(function (x, i) {
      if (x.idapp === parseInt(indentifier)) {
        element = x;
        // price = x.totalprice;
        // dataapp.splice(i, 1);
      }
    });
    // this.props.handleRemovePrice(price);
    this.setState({
      // dataapp: dataapp,
      delete: {
        openModal: true,
        element: element.nameapp,
        elementRemove: element.idapp
      }
    });
  };

  handleRemove = (param1, param2, param3) => {
    if (param1 !== param2) {
      this.handleSwalShow();
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, Time500);
    } else {
      const app = param3;
      const subs = this.props.subscription;
      const promise = deleteSubsApp(subs, app);
      promise.then(
        result => {
          if (result.success) {
            showValidation(0, 0, '', result.message, COLOUR_SUCCESS);
            this.props.handleEditSubscription(subs);
            this.handleDataApp();
            this.handleSwalShow();
          } else if (result.success === false) {
            showValidation(0, 0, '', result.message, COLOUR_WARNING);
          } else {
            showValidation(0, 0, '', 'Se presento una falló de conexión, intente nuevamente' +
              'Si el problema persiste comunique con un administrador', COLOUR_WARNING);
          }
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  handleSwalShow = () => {
    this.setState({
      delete: {
        ...this.state.delete,
        openModal: false,
        element: "",
        elementRemove: 0
      }
    });
  };

  handleCleanData = () => {
    if (this.props.subscription === 0 && this.state.dataapp.length > 0) {
      this.setState({ dataapp: [] });
    }
  };

  handleAsignApp = (param1, param2) => {
    this.setState({
      detailapp: {
        ...this.state.detailapp,
        openModalApp: param2,
        code: param1
      }
    });
  };

  handleModalDetail = () => {
    this.setState({
      detailapp: {
        ...this.state.detailapp,
        openModalApp: !this.state.detailapp.openModalApp,
        code: 0
      }
    });
  };

  render() {
    const { subscription } = this.props;
    const { dataapp, isProgress } = this.state;
    const { openModalApp, code } = this.state.detailapp;
    const { openModal, element, elementRemove } = this.state.delete;
    return (
      <form name="formSubscription">
        <div className="container">
          <div className="row">
            {dataapp.length === 0
              ? null
              : dataapp.map((x, i) => (
                <div className="col-lg-4" key={i + 1}>
                  <Card outline color="primary" className="mb-3">
                    <CardHeader className="bg-primary d-flex">
                      <label>{x.nameapp}</label>
                      <button type="button" className="btn bg-danger ml-auto" name={"app_" + (i + 1)} value={x.idapp} onClick={this.hadleRemoveElement}>
                        <i className="fas fa-trash-alt" />
                      </button>
                    </CardHeader>
                    <CardBody>
                      <img src={x.img} alt="Icon" className="img-left thumb96"
                      />
                      <p>
                        {x.description.substring(0, 29) + "..."}
                        <span
                          className="more"
                          onClick={e => this.handleAsignApp(x.idapp, true)}
                        >
                          {" "}
                            Ver detalle.
                          </span>
                      </p>
                    </CardBody>
                    <CardFooter>
                      <strong>
                        ${new Intl.NumberFormat().format(x.totalprice)}
                      </strong>
                    </CardFooter>
                  </Card>
                </div>
              ))}
          </div>

          {/* Progress cargar lista de app */}
          <ProgressAnimate isProgress={isProgress} />
        </div>
        <Remove
          openModal={openModal}
          element={element}
          elementRemove={elementRemove}
          handleRemove={this.handleRemove}
          swalShow={this.handleSwalShow}
        />
        <Detail
          openModal={openModalApp}
          code={code}
          subscription={subscription}
          handleChangeModal={this.handleModalDetail}
          handleAsignApp={this.handleAsignApp}
        />
      </form>
    );
  }
}
