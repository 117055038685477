import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import ApplicationWizard from "./ApplicationWizardVertical";

import "../docs/Application.css";

export default class ApplicationModalCreate extends Component {
  render() {
    const {
      openModal,
      handleOpenModal,
      isDataTable,
      handleUpdateDataTable,
      loadDataModal,
      loadDataConfig,
      handleResetLoadDataTable,
      handleEditConfigEmpty
    } = this.props;
    return (
      <section>
        <Modal className="modal-lg height-modallg" isOpen={openModal} toggle={handleOpenModal}>
          <div className="modal-header">
            <h4>Gestionar Aplicación</h4>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleOpenModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <ApplicationWizard
              isDataTable={isDataTable}
              loadDataModal={loadDataModal}
              loadDataConfig={loadDataConfig}
              openModal={openModal}
              handleUpdateDataTable={handleUpdateDataTable}
              handleResetLoadDataTable={handleResetLoadDataTable}
              handleEditConfigEmpty={handleEditConfigEmpty}
            />
          </ModalBody>
        </Modal>
      </section>
    );
  }
}
