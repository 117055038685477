import React, { Fragment } from 'react'

export const textRequirePkce = () => (
  <Fragment>
    Especifica si los clientes que utilizan un tipo de concesión basado en un código de autorización deben enviar una clave de prueba (predeterminado en <code>true</code>).
    <br />
    <a href="https://oauth.net/2/pkce/" target="_blank">Mas Información sobre PKCE</a>
  </Fragment>
)

export const allowPlainTextPkce = () => (
  <Fragment>
    Especifica si los clientes que usan PKCE pueden usar un desafío de código de texto sin formato (no recomendado, y por defecto <code>false</code>)
  </Fragment>
)

export const allowOfflineAccess = () => (
  <Fragment>
    Especifica si este cliente puede solicitar tokens de actualización (solicitar el offline_access alcance)
    <br />
    <a href="https://identityserver4.readthedocs.io/en/latest/search.html?q=allowOfflineAccess&check_keywords=yes&area=default" target="_blank">Mas información</a>
  </Fragment>
)

export const allowAccessTokensViaBrowser = () => (
  <Fragment>
    Especifica si este cliente puede recibir tokens de acceso a través del navegador. Esto es útil para fortalecer los flujos que permiten múltiples tipos de respuesta
    (por ejemplo, al no permitir un cliente de flujo híbrido que se supone debe usar el código <code>id_token</code> para agregar el tipo de respuesta del token y, por lo tanto,
    filtrar el token al navegador.
    <br />
    <a href="https://identityserver4.readthedocs.io/en/latest/search.html?q=allowAccessTokensViaBrowser&check_keywords=yes&area=default#" target="_blank">Mas información</a>
  </Fragment>
)

export const clientSecrets = () => (
  <Fragment>
    Credenciales para acceder al punto final del token. <code>ClientSecrets</code>
    <br />
    <a href="https://identityserver4.readthedocs.io/en/latest/search.html?q=clientSecrets&check_keywords=yes&area=default#" target="_blank">Mas información</a>
  </Fragment>
)

export const allowedGrantTypes = () => (
  <Fragment>
    Especifica los tipos de concesión que el cliente puede utilizar. Usa la <code>GrantTypes</code> clase para combinaciones comunes.
    <br />
    <a href="https://identityserver4.readthedocs.io/en/latest/search.html?q=allowedGrantTypes&check_keywords=yes&area=default" target="_blank">Mas información</a>
  </Fragment>
)

export const redirectUris = () => (
  <Fragment>
    En algunos escenarios, deberá redirigir a la página de inicio de sesión de su aplicación. Este URI debe apuntar a una ruta en su aplicación que debería redirigir al <code>/authorize</code> punto final de su aplicación
    <br />
    <a href="https://identityserver4.readthedocs.io/en/latest/reference/client.html?highlight=redirect%20uris" target="_blank">Mas información</a>
    <br />
    Una vez que el usuario se autentica, solo volveremos a llamar a cualquiera de estas URL. Puede especificar varias URL
  </Fragment>
)

export const postLogoutUris = () => (
  <Fragment>
    Un conjunto de URL válidas para redireccionar después de cerrar sesión. Puede especificar varias URL válidas
    <a href="https://identityserver4.readthedocs.io/en/latest/reference/client.html?highlight=logout#authentication-logout" target="_blank"> Mas información</a>
  </Fragment>
)

export const corsOrigins = () => (
  <Fragment>
    Los orígenes permitidos son URL a las que se les permitirá realizar solicitudes desde el cliente a APIs. De forma predeterminada,
    se permitirán todas sus URL de devolución de llamada. Este campo le permite ingresar otros orígenes si lo necesita. Puede especificar varias URL válidas.
    Puede utilizar <code>https://*.sinai.net.co</code> URL de la organización marcadores de posición en estas URL.
    <a href="https://identityserver4.readthedocs.io/en/latest/topics/cors.html?highlight=cors" target="_blank"> Mas información</a>
  </Fragment>
)