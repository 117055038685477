import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import notify from "devextreme/ui/notify";
import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

import FormValidator from "../Validation/FormValidation";
import { showValidation } from "../Validation/ShowValidation";
import {
  getUserProfile,
  getDocumentType,
  updateProfile
} from "../../services/Api/User";
import { genreget } from "../../services/Api/Genre";
import { getCountry, getCityFilter } from "../../services/Api/Region";
import { INCORRECT_FORMAT, COLOUR_WARNING } from "../Message/Message";
import { _JsonStorage, ExecuteFn } from '../../Global';
import { ProfileContext } from "./docs/Context";

import "react-datetime/css/react-datetime.css";

export default class FormProfile extends Component {
  static contextType = ProfileContext;
  constructor(props) {
    super(props);
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.state = {
      isSpinner: false,
      formProfile: {
        firstName: "",
        lastName: "",
        documentType: null,
        documentNumber: "",
        genre: null,
        birthDate: month + "/" + day + "/" + year,
        countryBirth: null,
        cityBirth: null,
        countryExpedition: null,
        cityExpedition: null,
        countryResidence: null,
        cityResidence: null
      },
      countriesBirth: [],
      countriesExpedition: [],
      countriesResidence: [],
      cityBirths: [],
      cityExpeditions: [],
      cityResidences: [],
      genres: [],
      documentTypes: []
    };
    this.changeSpinner = this.changeSpinner.bind(this);
  }

  componentDidMount() {
    this.handleUserProfile();
    this.handleCountry();
    this.handleGender();
    this.handleDocumentType();
  }

  handleSubmit = () => {
    const formData = new FormData();
    const value = this.state.formProfile;
    const storage = localStorage.getItem("storage");
    formData.append("firstName", value.firstName);
    formData.append("lastName", value.lastName);
    formData.append("idDocumentType", value.documentType.value);
    formData.append("numberDocument", value.documentNumber);
    formData.append("idGenre", value.genre.value);
    formData.append("datestring", value.birthDate);
    formData.append("idCityBirth", value.cityBirth.value);
    formData.append("idCityExpedition", value.cityExpedition.value);
    formData.append("idCityResidence", value.cityResidence.value);
    formData.append("cookie", JSON.parse(storage));
    formData.append("CodeUser", _JsonStorage.profile.id);

    this.changeSpinner(true)
    let data = updateProfile(formData);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        showValidation(result.message, 'success');
        this.changeSpinner(false)
      },
      function (error) {
        this.changeSpinner(false)
      }
    );
  };

  handleOnSubmit = e => {
    e.preventDefault();
    let date = new Date();
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getUTCFullYear();

    let date1 = new Date(month + "/" + day + "/" + year);
    let date2 = new Date(this.state.formProfile.birthDate);
    let validate = date1 - date2;

    if (this.state.formProfile.birthDate === null) {
      notify("La fecha de nacimiento es requerida.", COLOUR_WARNING, 4000);
    } else {
      if (validate < parseInt("395470800000")) {
        notify(
          "Verifique que la fecha ingresada concuerde con una edad superior a los 12 años.",
          COLOUR_WARNING,
          4000
        );
      } else {
        const form = e.target;
        const inputs = [...form.elements].filter(i =>
          ["INPUT", "SELECT"].includes(i.nodeName)
        );
        const { errors, hasError } = FormValidator.bulkValidate(inputs);
        this.setState({
          [form.name]: {
            ...this.state[form.name],
            errors
          }
        });
        if (!hasError) {
          this.handleSubmit();
        }
      }
    }
  };

  handleOnChangeDateTime = event => {
    if (event._isValid === true) {
      let dates = new Date(event._d);
      let day = dates.getDate();
      day = day < 10 ? "0" + day : day;

      let month = dates.getMonth() + 1;
      month = month < 10 ? "0" + month : month;

      let year = dates.getFullYear();
      let getDate = year + "/" + month + "/" + day;
      let form = "formProfile";
      let input = "birthDate";
      this.setState({
        [form]: {
          ...this.state[form],
          [input]: getDate
        }
      });
    } else {
      notify(INCORRECT_FORMAT, COLOUR_WARNING);
    }
  };

  handleOnChangeSelect = event => {
    this.setState({
      [event.form]: {
        ...this.state[event.form],
        [event.type]: { label: event.label, value: event.value }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  handleChangeCountry = selectedOption => {
    this.handleOnChangeSelect(selectedOption);

    let data = getCityFilter(selectedOption.value);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        let city = [];
        switch (selectedOption.type) {
          case "countryBirth":
            result.response.forEach(element => {
              let obj = {
                label: element.nameCity,
                value: element.idCity,
                form: "formProfile",
                type: "cityBirth"
              };
              city.push(obj);
            });
            this.setState({ cityBirths: city });
            break;
          case "countryExpedition":
            result.response.forEach(element => {
              let obj = {
                label: element.nameCity,
                value: element.idCity,
                form: "formProfile",
                type: "cityExpedition"
              };
              city.push(obj);
            });
            this.setState({ cityExpeditions: city });
            break;
          case "countryResidence":
            result.response.forEach(element => {
              let obj = {
                label: element.nameCity,
                value: element.idCity,
                form: "formProfile",
                type: "cityResidence"
              };
              city.push(obj);
            });
            this.setState({ cityResidences: city });
            break;
          default:
            break;
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleDocumentType = () => {
    let data = getDocumentType();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameDocumentType,
              value: element.idDocumentType,
              type: "documentType",
              form: "formProfile"
            };
            array.push(obj);
          });
          this.setState({ documentTypes: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleGender = () => {
    let array2 = [];
    let response = window.localStorage.getItem("genres");
    if (response === null) {
      let promise = genreget();
      promise.then(
        response => {
          if (response.success) {
            const array = [];
            response.response.forEach(x => {
              const obj = {
                label: x.nameGenre,
                value: x.idGenre,
                type: "genre",
                form: "formProfile"
              };
              array.push(obj);
            });
            this.setState({ genres: array });
            localStorage.setItem("genres", JSON.stringify(array));
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      let array = JSON.parse(response);
      array.forEach(element => {
        let obj = {
          label: element.label,
          value: element.value,
          type: "genre",
          form: "formProfile"
        };
        array2.push(obj);
      });
      this.setState({ genres: array2 });
    }
  };

  handleCountry = () => {
    let array1 = [],
      array2 = [],
      array3 = [],
      array0 = [];
    let response = window.localStorage.getItem("countries");
    if (response === null) {
      let data = getCountry();
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });

      promise.then(
        result => {
          if (result.success) {
            result.response.forEach(element => {
              let obj0 = {
                label: element.nameCountry,
                value: element.idCountry
              };
              let obj1 = {
                label: element.nameCountry,
                value: element.idCountry,
                type: "countryBirth",
                form: "formProfile"
              };
              let obj2 = {
                label: element.nameCountry,
                value: element.idCountry,
                type: "countryExpedition",
                form: "formProfile"
              };
              let obj3 = {
                label: element.nameCountry,
                value: element.idCountry,
                type: "countryResidence",
                form: "formProfile"
              };

              array0.push(obj0);
              array1.push(obj1);
              array2.push(obj2);
              array3.push(obj3);
            });
            window.localStorage.setItem("countries", JSON.stringify(array0));
            this.setState({
              countriesBirth: array1,
              countriesExpedition: array2,
              countriesResidence: array3
            });
          }
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      let data = JSON.parse(response);
      data.forEach(element => {
        let obj1 = {
          label: element.label,
          value: element.value,
          type: "countryBirth",
          form: "formProfile"
        };
        let obj2 = {
          label: element.label,
          value: element.value,
          type: "countryExpedition",
          form: "formProfile"
        };
        let obj3 = {
          label: element.label,
          value: element.value,
          type: "countryResidence",
          form: "formProfile"
        };
        array1.push(obj1);
        array2.push(obj2);
        array3.push(obj3);
      });
      this.setState({
        countriesBirth: array1,
        countriesExpedition: array2,
        countriesResidence: array3
      });
    }
  };

  handleUserProfile = () => {
    this.changeSpinner(true)
    const promise = getUserProfile();
    promise.then(
      response => {
        if (response.success) {
          let user = response.objeto;
          let date =
            user.birthDate !== null ? user.birthDate.substring(0, 10) : null;

          this.setState({
            formProfile: {
              firstName: user.firstName,
              lastName: user.lastName,
              documentType: {
                label: user.nameDocumentType,
                value: user.idDocumentType
              },
              documentNumber:
                user.numberDocument == null ? "" : user.numberDocument,
              genre: { label: user.nameGenre, value: user.idGenre },
              birthDate: date,
              countryBirth: {
                label: user.nameCountryBirth,
                value: user.idCountryBirth
              },
              cityBirth: {
                label: user.nameCityBirth,
                value: user.idCityBirth
              },
              countryExpedition: {
                label: user.nameCountryExpedition,
                value: user.idCountryExpedition
              },
              cityExpedition: {
                label: user.nameCityExpedition,
                value: user.idCityExpedition
              },
              countryResidence: {
                label: user.nameCountryResidence,
                value: user.idCountryResidence
              },
              cityResidence: {
                label: user.nameCityResidence,
                value: user.idCityResidence
              }
            }
          });

          let phone = user.phone !== null ? user.phone : "";
          this.context.handleChangeState('email', user.email);
          this.context.handleChangeState('phone', phone);
          this.context.handleChangeState('name', `${user.firstName} ${user.lastName}`);

          this.cityDefaultUserProfile(user)
        } else {
          showValidation(response);
        }
        this.changeSpinner(false)
      },
      function (error) {
        this.changeSpinner(false)
      }
    );
  };

  cityDefaultUserProfile = (user) => {
    if (user.idCountryBirth !== null && user.idCountryBirth > 0) this.handleChangeCountry({
      label: user.nameCountryBirth,
      value: user.idCountryBirth,
      type: "countryBirth",
      form: "formProfile"
    })

    if (user.idCountryExpedition !== null && user.idCountryExpedition > 0) this.handleChangeCountry({
      label: user.nameCountryExpedition,
      value: user.idCountryExpedition,
      type: "countryExpedition",
      form: "formProfile"
    })

    if (user.idCountryResidence !== null && user.idCountryResidence > 0) this.handleChangeCountry({
      label: user.nameCountryResidence,
      value: user.idCountryResidence,
      type: "countryResidence",
      form: "formProfile"
    })

  }

  changeSpinner = (parameter) => {
    this.setState({ isSpinner: parameter })
  }

  render() {
    const {
      countriesBirth,
      countriesExpedition,
      countriesResidence,
      genres,
      cityBirths,
      cityExpeditions,
      cityResidences,
      documentTypes,
      isSpinner
    } = this.state;
    const {
      firstName,
      lastName,
      documentType,
      documentNumber,
      genre,
      birthDate,
      countryBirth,
      cityBirth,
      countryExpedition,
      cityExpedition,
      countryResidence,
      cityResidence
    } = this.state.formProfile;

    return (
      <Card className="card-default">
        <SpinnerDisableContent isSpinnerDisabled={isSpinner}>
          <form
            name="formProfile"
            onSubmit={this.handleOnSubmit}
            style={{ backgroundColor: "#FFFFFF" }}
            autoComplete="off"
          >
            <CardHeader>
              <legend>Información básica</legend>
            </CardHeader>
            {/*Cuerpo formulario */}
            <CardBody>
              {/*Datos personales */}
              <FormGroup className="row">
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Nombres
                  </label>
                  <div className="col-md-12">
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="Nombres completo"
                      value={firstName}
                      onChange={this.handleOnChange}
                      invalid={
                        this.hasError("formProfile", "firstName", "required") ||
                        this.hasError("formProfile", "firstName", "alphaspace")
                      }
                      data-validate='["required","alphaspace"]'
                    />
                    {this.hasError("formProfile", "firstName", "alphaspace") && (
                      <span className="invalid-feedback">
                        El campo debe ser un nombre válido.
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Apellidos
                  </label>
                  <div className="col-md-12">
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Apellidos completos"
                      value={lastName}
                      onChange={this.handleOnChange}
                      invalid={
                        this.hasError("formProfile", "lastName", "required") ||
                        this.hasError("formProfile", "lastName", "alphaspace")
                      }
                      data-validate='["required","alphaspace"]'
                    />
                    {this.hasError("formProfile", "lastName", "alphaspace") && (
                      <span className="invalid-feedback">
                        El campo debe ser un campo válido.
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Tipo documento
                </label>
                  <div className="col-md-12">
                    <Select
                      name="documentType"
                      placeholder="Seleccione una opción"
                      value={documentType}
                      options={documentTypes}
                      onChange={this.handleOnChangeSelect}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Número documento
                </label>
                  <div className="col-md-12">
                    <Input
                      type="text"
                      name="documentNumber"
                      placeholder="Número de documento"
                      value={documentNumber}
                      onChange={this.handleOnChange}
                      invalid={
                        this.hasError(
                          "formProfile",
                          "documentNumber",
                          "alphanum"
                        ) ||
                        this.hasError("formProfile", "documentNumber", "required")
                      }
                      data-validate='["alphanum","required"]'
                    />
                    {this.hasError(
                      "formProfile",
                      "documentNumber",
                      "alphanum"
                    ) && (
                        <span className="invalid-feedback">
                          El campo debe ser un campo válido.
                        </span>
                      )}
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="row">
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    País expedición
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="countryExpedition"
                      placeholder="Seleccione una opción"
                      value={countryExpedition}
                      options={countriesExpedition}
                      onChange={this.handleChangeCountry}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Ciudad expedición
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="cityExpedition"
                      placeholder="Seleccione una opción"
                      value={cityExpedition}
                      options={cityExpeditions}
                      onChange={this.handleOnChangeSelect}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    Fecha nacimiento
                  </label>
                  <div className="col-md-12">
                    <Datetime
                      name="birthDate"
                      inputProps={{ className: "form-control" }}
                      value={birthDate}
                      dateFormat="YYYY-MM-DD"
                      onChange={this.handleOnChangeDateTime}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="col-md-12 text-left col-form-label">
                    País nacimiento
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="countryBirth"
                      placeholder="Seleccione una opción"
                      value={countryBirth}
                      options={countriesBirth}
                      onChange={this.handleChangeCountry}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="row">
                <div className="col-md-6">
                  <label className="col-md-12 text-left col-form-label">
                    Ciudad nacimiento
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="cityBirth"
                      placeholder="Seleccione una opción"
                      value={cityBirth}
                      options={cityBirths}
                      onChange={this.handleOnChangeSelect}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="col-md-12 text-left col-form-label">
                    Género
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="genre"
                      placeholder="Seleccione una opción"
                      value={genre}
                      options={genres}
                      onChange={this.handleOnChangeSelect}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="row">
                <div className="col-md-6">
                  <label className="col-md-12 text-left col-form-label">
                    País residencia
                  </label>
                  <div className="col-md-12">
                    <Select
                      className="test-test"
                      id="select-residence"
                      name="countryResidence"
                      value={countryResidence}
                      options={countriesResidence}
                      onChange={this.handleChangeCountry}
                      commonProps
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="col-md-12 text-left col-form-label">
                    Ciudad residencia
                  </label>
                  <div className="col-md-12">
                    <Select
                      name="cityResidence"
                      placeholder="Seleccione una opción"
                      value={cityResidence}
                      options={cityResidences}
                      onChange={this.handleOnChangeSelect}
                    />
                  </div>
                </div>
              </FormGroup>
            </CardBody>
            <CardFooter>
              <FormGroup className="row">
                <div className="col-md-12">
                  <Button className="btn-block bg-success">Actualizar</Button>
                </div>
              </FormGroup>
            </CardFooter>
          </form>
        </SpinnerDisableContent>
      </Card>
    );
  }
}