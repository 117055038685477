import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import SwalRemove from "../SweetAlert/SwalRemove";
import UserModalAssignStandar from "../User/UserModalAssignStandar";
import {
  standarfindbyclient,
  standardelete
} from "../../services/Api/Enterprise";
import { COLOUR_WARNING } from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";

export default class EnterpriseAccordionUserStandar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRemove: false,
      elementRemove: "",
      element: '',
      isChange: false,
      changedata: 0,
      dataUsers: [],
      selectedRowKeys: [],
      openModalAssign: false
    };
  }

  componentDidUpdate() {
    this.handleChangeData();
  }

  handleChangeData = () => {
    if (
      (this.state.changedata !== this.props.filter && this.props.filter > 0) ||
      this.state.isChange === true
    ) {
      this.setState({ changedata: this.props.filter, isChange: false });
      let identifier = this.props.filter;
      let promise = standarfindbyclient(identifier);
      promise.then(
        result => {
          if (result.success) {
            let array = [];
            result.response.forEach(element => {
              if (element.email !== null) {
                let obj = {
                  User: { code: element.idUser },
                  UserName: element.firstName + " " + element.lastName,
                  UserEmail: element.email,
                  Subscription: element.nameSubscription,
                  App: element.nameApp,
                  State: element.stateRequest
                };
                array.push(obj);
              }
            });
            this.setState({ dataUsers: array });
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleAssignUser = () => {
    if (this.props.filter > 0) {
      this.setState({ openModalAssign: !this.state.openModalAssign });
    } else {
      notify(
        "Antes de intentar asignar un usuario, primero seleccione una empresa.",
        COLOUR_WARNING,
        4000
      );
    }
  };

  handleValidateDelete = event => {
    let row = event.row.data;
    this.setState({ openRemove: true, element: row.UserEmail, elementRemove: row.User.code });
  };

  handleUpdateGrid = param => {
    const array = [];
    this.state.dataUsers.forEach(element => {
      if(element.User.code !== param){
        array.push(element);
      }
    });
    this.setState({ dataUsers: array });
  };

  handleRemove = (param1, param2, param3) => {
    if (param1 !== param2) {
      notify(
        "El valor ingresado no coincide con el registro a eliminar.",
        COLOUR_WARNING,
        3000
      );
    } else {
      this.handleShowRemove();
      const promise = standardelete(param3);
      promise.then(
        response => {
          if (response.success) {
            this.handleUpdateGrid(param3);
          }
          showValidation(response);
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  handleChangeGrid = () => this.setState({ isChange: !this.state.isChange });

  handleShowRemove = () =>
    this.setState({ openRemove: !this.state.openRemove });

  render() {
    const {
      dataUsers,
      selectedRowKeys,
      openModalAssign,
      openRemove,
      elementRemove,
      element
    } = this.state;
    const { filter } = this.props;
    return (
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Usuarios estandar
              <button
                type="button"
                className="btn float-right bg-primary"
                onClick={this.handleAssignUser}
              >
                Asignar Usuario
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <DataGrid
                id={"grid-container"}
                dataSource={dataUsers}
                keyExpr={"User.code"}
                ref={ref => (this.dataGrid = ref)}
                selectedRowKeys={selectedRowKeys}
                showBorders={true}
              >
                <SearchPanel
                  visible={true}
                  width={360}
                  placeholder={"Filtrar usuario..."}
                />
                <Column
                  dataField={"UserName"}
                  caption={"Usuario"}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  dataField={"UserEmail"}
                  caption={"Correo"}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  dataField={"State"}
                  caption={"Estado"}
                  width={84}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  dataField={"Subscription"}
                  caption={"Suscripción asignada"}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  dataField={"App"}
                  caption={"Aplicación asignada"}
                  headerFilter={{ allowSearch: true }}
                />
                <Column
                  type={"buttons"}
                  dataFiled={"User"}
                  width={50}
                  buttons={[
                    {
                      hint: "Eliminar",
                      icon: "fas fa-trash-alt",
                      onClick: this.handleValidateDelete
                    }
                  ]}
                />
              </DataGrid>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <UserModalAssignStandar
          handleShowModal={this.handleAssignUser}
          openModal={openModalAssign}
          filter={filter}
          handleChangeGrid={this.handleChangeGrid}
        />
        <SwalRemove
          openModal={openRemove}
          element={element}
          elementRemove={elementRemove}
          swalShow={this.handleShowRemove}
          handleRemove={this.handleRemove}
        />
      </Accordion>
    );
  }
}
