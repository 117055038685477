import MethodPost from "../MethodPost";
import MethodGet from "../MethodGet";
import { headerPost, headerGet } from "../Headers";
import {
  postCarousel,
  getCarousel,
  deleteCarousel,
  getAppCarousel,
  getIdCarousel
} from "../../components/RouteApi/RouteApi";
import { _JsonStorage } from '../../Global';

///Crear las imagenes de los carousel por aplicación.
export const carouselPost = param => {
  const formData = new FormData();
  formData.append("appcode", param.app.value);
  formData.append("header", param.textHeader);
  formData.append("order", param.order);
  formData.append("description", param.description);
  formData.append("file", param.picture);
  formData.append("nameimg", param.picture.name);

  const header = headerPost(formData, _JsonStorage.access_token);
  const response = MethodPost(postCarousel, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Consultar las imagenes del carousel por aplicación.
export const carouselGet = param => {
  let urlBase = getCarousel,
    params = {
      code: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );
  let header = headerGet( _JsonStorage.access_token);

  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Eliminar imagen de carousel por id
export const carouselDelete = param => {
  let urlBase = deleteCarousel,
    params = {
      code: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Consultar imagenes del carousel que va en el login
export const carouselGetApp = param => {
  let urlBase = getAppCarousel,
    params = {
      value: param,
      code: "c031e600-0700-4b00-aa00-5c9eda692a00"
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Consultar carousel por id
export const carouselGetId = param => {
  let urlBase = getIdCarousel,
    params = {
      value: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
  const promise = new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
  return promise;
};
