import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import PackageCreate from './PackageCreate';

export default class ModalPackageCreate extends Component {
  render() {
    const { fOpenModal, isOpenModal, isEdit, valueChange, isChangeSelect, handleChangeInputEdit } = this.props;
    return (
      <div>
        <Modal className="modal-lg" isOpen={isOpenModal} toggle={fOpenModal}>
          <div className="modal-header">
            {
              isEdit=== true?"ACTUALIZAR PAQUETE": "CREAR PAQUETE"
            }
            <button 
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={fOpenModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> 
          <ModalBody>
            <PackageCreate 
                isEdit={isEdit} 
                valueChange={valueChange} 
                fOpenModal={fOpenModal}
                isChangeSelect={isChangeSelect}
                handleChangeInputEdit={handleChangeInputEdit}
                handleIsUpdate={()=> console.log('')}
            />
          </ModalBody> 
        </Modal>
      </div>
    );
  }
}
