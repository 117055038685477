import React, { Fragment, useContext, useState, useEffect } from 'react'

import { Col, Card, CardHeader, CardBody, CardFooter, Collapse, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { AppContext } from "../docs/AppContext";

import classnames from 'classnames';
import HeaderTitle from "../../Extras/HeaderTitle";
import IdentityFormBasic from "./IdentityFormBasic";
import IdentityListSidebar from "../Identity/IdentityListSidebar";

import '../docs/Identity.css'

const Settings = () => {
  const [state, setState] = useState({
    activeTab: '1'
  })

  const toggleTab = tab => {
    if (state.activeTab !== tab) {
      setState(state => ({
        ...state,
        activeTab: tab
      }));
    }
  }

  return (
    <Fragment>
      <div role="tabpanel">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: state.activeTab === '1' })}
              onClick={() => toggleTab('1')}>
              Configuración
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <IdentityFormBasic />
          </TabPane>
        </TabContent>
      </div>

    </Fragment>
  )
}


export default function IdentityForm() {
  const context = useContext(AppContext)

  const [actionForm, setActionForm] = useState({
    title: '',
    isCreate: false
  })

  const [stateSidebar, setSidebar] = useState(false)

  useEffect(() => {
    if (context.data.dismissIdentityListSidebar) sidebar();
    return () => {
      if (context.data.dismissIdentityListSidebar) sidebar();
    }
  }, [context.data.dismissIdentityListSidebar])

  const viewApps = () => {
    context.viewAllClose();
  }

  const viewConfigs = () => {
    context.viewListIdentity();
  }


  const sidebar = () => {
    let sidebar = window.localStorage.getItem('dismissIdentityListSidebar')
    if (sidebar !== null) setSidebar(false)
    else {
      setSidebar(true)
    }

    context.handleChangeState('dismissIdentityListSidebar', false)
  }

  const openSidebar = () => {
    window.localStorage.removeItem('dismissIdentityListSidebar')
    context.handleChangeState('dismissIdentityListSidebar', true)
  }

  const ActionForm = actionForm.isCreate ? 'Crear' : 'Modificar';
  const ClientOrResource = actionForm.isCreate ? 'cliente o Recurso' :
    context.data.isClient ? 'cliente' : 'recurso';

  return (
    <Fragment>
      <HeaderTitle title="Configurar acceso de aplicaciones" description="Gestionar configuraciones para el acceso de aplicaciones y recursos">
        {(stateSidebar && !actionForm.isCreate) && (
          <IdentityListSidebar />
        )}
        <Col>
          <Card className="card-default">
            <CardHeader className="d-flex flex-row align-items-center">
              {!stateSidebar && (
                <em className="fas fa-angle-double-right fa-lg mr-3 cursor-content" onClick={() => openSidebar()} />
              )}
              <span className="mb-0">{ActionForm} {ClientOrResource}</span>
            </CardHeader>
            <CardBody>

              <Settings />

            </CardBody>
            <CardFooter>
              <button type="button" className="btn btn-info mr-2" onClick={() => viewApps()}>
                Lista de aplicaciones
              </button>
              <button type="button" className="btn btn-info" onClick={() => viewConfigs()}>
                Lista de configuraciones
              </button>
              <div className="float-right">
                <button type="button" className="btn btn-success" onClick={() => viewConfigs()}>
                  Guardar
                </button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </HeaderTitle>
    </Fragment>
  )
}
