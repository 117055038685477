import React from "react";
import ContentWrapper from "../Layout/ContentWrapper";

import PackageFilter from "./PackageFilter";
import ApplicationFilter from "../Application/ApplicationFilter";
import ApplicationCard from "../Application/ApplicationCard";

class SidebarPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPackage: 0,
      addaplication: 0
    };
  }

  handleChangeFilter = param => {
    this.setState({ filterPackage: param.value });
  };

  handleAddAplication = param => {
    this.setState({ addaplication: param });
  };

  handleResetState = () =>this.setState({ addaplication: 0 });

  render() {
    const { filterPackage, addaplication, isaddapplication } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {" "}
            Paquetes
            <small>Configuración de paquetes.</small>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-4"> 
            {/* START mailbox list */}
            <ApplicationFilter handleAddAplication={this.handleAddAplication} />
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card card-default">
              <div className="card-body">
                {/*Componente que permite filtrar componentes con crud. */}
                <PackageFilter handleChangeFilter={this.handleChangeFilter} />
                <ApplicationCard
                  filterPackage={filterPackage}
                  addapplication={addaplication}
                  isaddapplication={isaddapplication}
                  handleResetState={this.handleResetState}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default SidebarPackage;
