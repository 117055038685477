import React, { Component } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { getasigns } from "../../services/Api/Client";
import { getasign, postAssign } from "../RouteApi/RouteApi";
import { _JsonStorage, Pattern } from '../../Global';

import queryString from "query-string";
import Header from "../Login/Header";

export default class EnterpriseRequestService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      continue: "",
      state: false,
      code:  _JsonStorage.profile.id,
      message: "",
      success: false
    };
  }

  ///Validar el continue del query string si es para administrador o estándar
  componentDidMount() {
    this.handleRequestService();
  }

  handleRequestService = () => {
    const query = queryString.parse(window.location.search);
    let param = query.token;
    const url = query.continue === "Standar" ? postAssign : getasign;
    let promise = getasigns(param, url);
    promise.then(
      result => {
        if (result.success) {
          this.setState({ message: result.message, success: true });
        } else if (result.success === false) {
          this.setState({ message: result.message, success: false });
        } else {
          this.setState({
            message: "Se presento un falló en la conexión, intente actualizar su navegador." +
              "Si el problema persiste comuníquese con un administrador", success: false
          });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  render() {
    const { code, state, success, message } = this.state;
    return (
      <>
        <div
          className="size-page"
          style={{
            background: { Pattern },
            height: "100%",
            overflow: "hidden",
            backgroundColor: "#ffffff"
          }}
        >
          <Header />
          <br />
          <br />
          <div className="wrapper">
            <aside>
              <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                <div className="row">
                  <div className="col-xl-12">
                    <h2 className="fs-xxl fw-500 mt-4 text-center">
                      Gracias por hacer parte de la comunidad Siempre.Net
                        <br />
                      <small className="h3 fw-300 mt-3 mb-5 opacity-70 hidden-sm-down">
                        Estamos verificando la asignación de su cuenta, por favor espere.
                        </small>
                    </h2>
                  </div>
                  <div className="col-xl-6 ml-auto mr-auto">
                    <div className="card p-4 rounded-plus bg-faded align-items-center justify-content-center">
                      {
                        message === "" ?
                          <div className="ball-spin-fade-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                          :
                          <div className={`alert ${success ? 'alert-success' : 'alert-warning'}`} role="alert">
                            <strong>{message}</strong>
                          </div>
                      }
                      <br />
                      <br />
                      <Link to="/createUser" className="h3">
                        <i class="fas fa-address-card mr-2"></i> Registrarme
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>

      </>
    );
  }
}
