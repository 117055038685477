import React, { Component } from 'react'
import Style from 'style-it';

export default class SpinnerDisabled extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { isSpinnerDisabled, classProp } = this.props;
        return (
            <>
                {isSpinnerDisabled ? <div style={Styles}>
                    <div className={classProp === undefined ? "whirl duo" : `${classProp} whirl duo`} role="status">
                        <span className="sr-only" />
                    </div>
                </div> : null}
                {
                    Style.it(`
                        .disabled-content{
                            pointer-events: ${isSpinnerDisabled ? 'none' : 'initial'};
                            opacity: ${isSpinnerDisabled ? '.5' : 'initial'};
                        }
                    `, <div className={classProp === undefined ? "disabled-content" : `${classProp} disabled-content`}> {this.props.children}</div>)
                }
            </>
        )
    }
}

const Styles = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 1
}