import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Header from "./Header";
import "./docs/AccountActive.css";

import { Domain } from "../../Global";
import { ActivateAccount } from "../../services/Api/User";
import { showValidation } from "../Validation/ShowValidation";

import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

export default class AccountActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryString: queryString.parse(this.props.location.search),
      email: "",
      isCorrect: false,
      isSpinner: true
    };
    this.handleActivateAccount = this.handleActivateAccount.bind(this)
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#544d81";

    let queryString = this.state.queryString;
    this.setState({ email: queryString.Email !== undefined ? queryString.Email : "" });

    if (queryString.Token !== undefined) {
      this.handleActivateAccount();
    } else {
      this.setState({ isCorrect: true });
    }
  }


  handleActivateAccount = () => {
    let configService = window.sessionStorage.getItem("confStandar_service");
    if (configService === null) {
      setTimeout(() => {
        this.handleActivateAccount();
      }, 30000);

      return;
    }

    let queryString = this.state.queryString;
    ActivateAccount(queryString.Token)
      .then(response => {
        if (!response.success) {
          showValidation(response);
          this.setState({ isCorrect: true });
        }
        this.setState({ isSpinner: false });

        const routeRedirect = queryString.ClientRedirect;
        if (routeRedirect !== "" && routeRedirect !== undefined && routeRedirect !== null) {
          this.setState({ isSpinner: true });
          window.location.href = routeRedirect;
        }
      })
      .catch(function (error) {
        this.setState({ isSpinner: false });
        showValidation(0, 0, '', 'Se presento un falló de conexión. Intente nuevamente recargando el navegador, ' +
          'si el problema persiste verifique su conexión a internet de lo contrario comunique a un administrador', 'warning');
      });
  }

  render() {
    const { email, isCorrect } = this.state;
    const Correct = () => (
      <small className="h3 fw-300 mt-3 mb-5 opacity-70 hidden-sm-down">
        La cuenta <strong>{email}</strong> ha sido activada.
      </small>
    );

    const Problem = () => (
      <small className="h3 fw-300 mt-3 mb-5 opacity-70 hidden-sm-down">
        La cuenta <strong>{email}</strong> presento problemas al momento de ser
        activada,
        <br />
        por favor actualice su contraseña para terminar los últimos pasos con su cuenta.
      </small>
    );

    return (
      <div
        className="size-page"
        style={{
          background:
            'url("img/svg/pattern-1.svg")no-repeat center bottom fixed',
          height: "100%",
          overflow: "hidden",
          backgroundColor: "#ffffff"
        }}
      >
        <Header />
        <SpinnerDisableContent isSpinnerDisabled={this.state.isSpinner}>
          {!this.state.isSpinner && (
            <div className="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div className="row">
                <div className="col-xl-12">
                  <h2 className="fs-xxl fw-500 mt-4 text-center">
                    Gracias por registrarte!
                   <br />
                    {isCorrect === false ? <Correct /> : <Problem />}
                  </h2>
                </div>
                <div className="col-xl-6 ml-auto mr-auto">
                  <div className="card p-4 rounded-plus bg-faded">
                    {isCorrect === false ? (
                      <a href={Domain} className="h4 nav-link">
                        <i className="fa fa-chevron-right mr-2"></i> Iniciar sesión
                      </a>
                    ) : (
                        <Link to="/forgotpassword/reset" path="/forgotpassword/reset" className="h4 nav-link">
                          <i className="fa fa-chevron-right mr-2"></i> Actualizar contraseña
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </SpinnerDisableContent>
      </div>
    );
  }
}
