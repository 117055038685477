import React, { Component } from "react";
import notify from "devextreme/ui/notify";
import { Button, Input } from "reactstrap";
 
import ModalAssignation from "./AssignationApp/SubscriptionModalAssignation";
import FormValidator from "../Validation/FormValidation";
import {
  getSubscriptionId,
  putSubscription
} from "../../services/Api/Subscription";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_INFO, COLOUR_WARNING } from "../Message/Message";

export default class SubscriptionAssignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubscription: {
        code: 0,
        nameSubscription: "",
        PriceSubscription: 0,
        dateSubscription: null
      },
      openModal: false,
      appBilling: null,
      stipulatedprice: 0,
      standbyselect: null,
      openModalAssignation: false
    };
  }

  componentDidUpdate() {
    if (this.props.priceremove !== null) {
      this.handlePriceRemove();
    }

    if (this.props.reset) {
      this.handleResetState();
    }

    if (this.props.sharedCreate !== null) {
      if (
        this.state.stipulatedprice !== this.props.sharedCreate.totalSubscription
      ) {
        this.setState({
          stipulatedprice: this.props.sharedCreate.totalSubscription
        });
      }
    } else {
      if (this.props.sharedCreate === null && this.state.stipulatedprice > 0) {
        this.setState({ stipulatedprice: 0 });
      }
    }

    if (this.props.isEdit) {
      this.handleLoadSubscription();
    }
  }

  handleResetState = () => {
    this.setState({
      formSubscription: {
        ...this.state.formSubscription,
        code: 0,
        nameSubscription: "",
        PriceSubscription: 0,
        dateSubscription: null
      }
    });
  };

  handleSaveInstantly = param => {
    const code = this.state.formSubscription.code;
    const response = putSubscription(code, param);
    const promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          showValidation(response, 2,'suscripción');
          this.props.handleReloadSidebar();
        }else{
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleHasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleValidateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  handleSaveOnBlur = () => {
    if (this.state.formSubscription.errors !== undefined) {
      const isValidate = this.state.formSubscription.errors.nameSubscription;
      if (!isValidate.alphanumspace && !isValidate.required) {
        const param = this.state.formSubscription.nameSubscription;
        this.handleSaveInstantly(param);
      }
    }
  };

  handleLoadSubscription = () => {
    const code = this.props.subscription;
    let response = getSubscriptionId(code);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const data = result.response;
          this.setState({
            formSubscription: {
              ...this.state.formSubscription,
              code: data.idSuscription,
              nameSubscription: data.nameSubscription,
              PriceSubscription: data.totalSubscription,
              dateSubscription: data.startDate
            }
          });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeSelectPackage = event => {
    if (this.props.subscription === 0) {
      notify(
        "Para agregar paquetes primero seleccione la suscripción a la que desea asignarla.",
        COLOUR_INFO,
        5000
      );
    } else {
      this.setState({
        [event.nameForm]: {
          ...this.state[event.nameForm],
          [event.nameSelect]: { label: event.label, value: event.value }
        }
      });

      if (event.app.length === 0) {
        notify(
          "Este paquete no cuenta con aplicaciones asignadas.",
          COLOUR_INFO,
          5000
        );
      } else {
        event.app.forEach(element => {
          let data = element;
          let promise = new Promise(rest => {
            rest(data);
          });

          promise.then(result => {
            let obj = {
              idapp: result.idApp,
              nameapp: result.nameApp,
              description: result.descriptionApp,
              price: result.price,
              img: result.img,
              label: result.nameApp,
              value: result.idApp
            };
            this.handleOpenModal(obj);
          });
        });
      }
    }
  };

  handleStipulatedPrice = param => {
    this.setState({ stipulatedprice: this.state.stipulatedprice + param });
    this.props.handlePriceTotal(this.state.stipulatedprice + param);
  };

  handlePriceRemove = () => {
    this.props.handleRemovePrice(null);
    let param = this.props.priceremove;
    this.setState({ stipulatedprice: this.state.stipulatedprice - param });
  };

  handleAddApp = () => {
    let event = this.state.standbyselect;
    if (event === null) {
      notify(
        "Primero debe seleccionar una aplicación para asignarla a la suscripción.",
        COLOUR_INFO,
        4000
      );
    } else {
      this.handleOpenModal(event);
    }
  };

  handleOpenModal = param =>
    this.setState({ openModal: !this.state.openModal, appBilling: param });

  handleOpenModalAssignation = () => {
    if (this.state.formSubscription.code === 0) {
      notify(
        "Para agregar aplicaciones primero seleccione la suscripción que desea actualizar.",
        COLOUR_WARNING,
        4000
      );
    } else {
      if (!this.state.openModalAssignation) {
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
      } else {
        document.getElementsByTagName("html")[0].style.overflow = "auto";
      }

      this.setState({ openModalAssignation: !this.state.openModalAssignation });
    }
  };

  render() {
    const { openModalAssignation } = this.state;
    const {
      nameSubscription,
      PriceSubscription,
      dateSubscription
    } = this.state.formSubscription;
    const { handleReloadGrid, subscription, client, apps } = this.props;
    return (
      <div>
        <form name="formSubscription" autoComplete="off">
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <Input
                type="text"
                name="nameSubscription"
                placeholder="Nombre suscripción"
                value={nameSubscription}
                onChange={this.handleValidateOnChange}
                onBlur={this.handleSaveOnBlur}
                invalid={
                  this.handleHasError(
                    "formSubscription",
                    "nameSubscription",
                    "required"
                  ) ||
                  this.handleHasError(
                    "formSubscription",
                    "nameSubscription",
                    "alphanumspace"
                  )
                }
                data-validate='["required","alphanumspace"]'
              />
              {this.handleHasError(
                "formSubscription",
                "nameSubscription",
                "alphanumspace"
              ) && (
                <span className="invalid-feedback">
                  El campo debe tener un valor válido.
                </span>
              )}
            </div>
            <div className="col-md-6">
              <Button
                type="button"
                className="btn bg-primary"
                onClick={this.handleOpenModalAssignation}
              >
                Agregar Aplicación
              </Button>
            </div>
          </div>
          <div className="form-row align-items-center">
            <div className="col-md-5">
              <strong>
                Precio estipulado factura mes: $
                {new Intl.NumberFormat().format(PriceSubscription)}{" "}
              </strong>
            </div>
            <div className="col-md-4">
              <strong>
                Fecha facturación:{" "}
                {
                  dateSubscription //new Intl.DateTimeFormat('en-US').format(sharedCreate.startDate)
                }
              </strong>
            </div>
          </div>
          <br />
        </form>
        <ModalAssignation
          isOpen={openModalAssignation}
          client={client}
          apps={apps}
          openModal={this.handleOpenModalAssignation}
          subscription={subscription}
          data={this.state.formSubscription}
          handleLoadSubscription={this.handleLoadSubscription}
          handleReloadGrid={handleReloadGrid}
        />
      </div>
    );
  }
}
