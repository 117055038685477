// https://github.com/chriso/validator.js
import validator from "validator";

/**
 * Helper methods to validate form inputs
 * using controlled components
 */
const FormValidator = {
  /**
   * Validate input element
   * @param element Dome element of the input
   * Uses the following attributes
   *     data-validate: array in json format with validation methods
   *     data-param: used to provide arguments for certain methods.
   */
  validate(element) {
    const isCheckbox = element.type === "checkbox";
    const value = isCheckbox ? element.checked : element.value;
    const name = element.name;
    let validate = name === "" ? true : false;
    let result = [];

    if (validate) {
      return result;
    } else {
      if (!name) throw new Error("Input name must not be empty.");

      // use getAttribute to support IE10+
      const param = element.getAttribute("data-param");
      const validations = JSON.parse(element.getAttribute("data-validate"));

      if (validations && validations.length) {
        /*  Result of each validation must be true if the input is invalid
                    and false if valid. */
        validations.forEach(m => {
          switch (m) {
            case "required":
              result[m] = isCheckbox
                ? value === false
                : validator.isEmpty(value);
              break;
            case "email":
              if (value !== "") {
                result[m] = !validator.isEmail(value);
              }
              break;
            case "strongpassword":
              result[m] = !validator.isStrongPassword(value, {
                minNumbers: 1,
                minLowercase: 1,
                minLength: 0,
                minUppercase: 0,
                minSymbols: 0
              });
              break;
            case "number":
              if (value !== "") {
                result[m] = !validator.isNumeric(value);
              }
              break;
            case "integer":
              result[m] = !validator.isInt(value);
              break;
            case "alphanum":
              if (value !== "") {
                result[m] = !validator.isAlphanumeric(value);
              }
              break;
            case "url":
              if (value !== "") {
                result[m] = !validator.isURL(value);
              }
              break;
            case "alpha":
              result[m] = !validator.isAlpha(value);
              break;
            case "phone":
              if (value !== "") {
                result[m] = !validator.isMobilePhone(value);
              }
              break;
            case "alphanumspace":
              result[m] = this.alphaNumSpace(value);
              break;
            case "alphaspace":
              result[m] = this.alphaSpace(value);
              break;
            case "isnegative":
              result[m] = !this.negative(value);
              break;
            case "direction":
              result[m] = this.direction(value);
              break;
            case "equalto":
              if (value !== "") {
                // here we expect a valid ID as param
                const value2 = document.getElementById(param).value;
                result[m] = !validator.equals(value, value2);
              }
              break;
            case "minlen":
              if (value !== "") {
                result[m] = !validator.isLength(value, { min: param });
              }
              break;
            case "maxlen":
              if (value !== "") {
                result[m] = !validator.isLength(value, { max: param });
              }
              break;
            case "len":
              const [min, max] = JSON.parse(param);
              result[m] = !validator.isLength(value, { min, max });
              break;
            case "min":
              result[m] = !validator.isInt(value, {
                min: validator.toInt(param)
              });
              break;
            case "max":
              result[m] = !validator.isInt(value, {
                max: validator.toInt(param)
              });
              break;
            case "list":
              const list = JSON.parse(param);
              result[m] = !validator.isIn(value, list);
              break;
            case "sqlinject":
              result[m] = this.sqlInject(value);
              break;
            default:
              alert(
                "¡Advertencia! Una función no se termino de ejecutar satisfactoriamente."
              );
              //throw new Error('Unrecognized validator.');
              break;
          }
        });
      }

      return result;
    }
  },

  /**
   * Bulk validation of input elements.
   * Used with form elements collection.
   * @param  {Array} inputs Array for DOM element
   * @return {Object}       Contains array of error and a flag to
   *                        indicate if there was a validation error
   */
  /*
  *Permitir puntos /\b.\b/
   */
  alphaNumSpace(value) {
    if (value !== "") {
      let patt = new RegExp(
        /^[azAZ-zA-Z-0-9-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ]+(\s*[a-zA-Z-0-9-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ]*)*$/i
      );
      if (patt.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  },
  alphaSpace(value) {
    if (value !== "") {
      let patt = new RegExp(
        /^[azAZ-zA-Z-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ]+(\s*[a-zA-Z-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ]*)*$/i
      );
      if (patt.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  },
  direction(value) {
    if (value !== "") {
      let patt = new RegExp(
        /^[azAZ-zA-Z-0-9-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ#/\b.\b/]+(\s*[a-zA-Z-0-9-ZñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙ#\b.\b]*)*$/i
      );
      if (patt.test(value)) {
        return false;
      } else {
        return true;
      }
    }
  },
  sqlInject(value) {
    var str = value.replace(/[><$=&#]/g, '');
    if (str.length < value.length) {
      return true;
    } else {
      return false;
    }
  },
  bulkValidate(inputs) {
    let errors = {},
      hasError = false;

    inputs.forEach(input => {
      let result = this.validate(input);
      errors = { ...errors, [input.name]: result };
      if (!hasError) hasError = Object.keys(result).some(val => result[val]);
    });

    return {
      errors,
      hasError
    };
  },
  negative(value) {
    let number = Math.sign(value);
    let val = false;
    switch (number) {
      case 1:
        val = true;
        break;
      case 0:
        val = true;
        break;
      case -1:
        val = false;
        break;
      case -0:
        val = false;
        break;
      default:
        val = false;
        break;
    }
    return val;
  }
};

export default FormValidator;
