import React from "react";
import FormValidator from "../Validation/FormValidation";
import notify from "devextreme/ui/notify";
import { Redirect } from "react-router-dom";
import { Input } from "reactstrap";
import { showValidation } from "../Validation/ShowValidation";
import { MESSAGE_REQUIRED, COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";

///Servicio
import MethodPost from "../../services/MethodPost";
import { headerPost } from "../../services/Headers";
import { sendemail } from "../RouteApi/RouteApi";


import { Domain, Pattern } from "../../Global";

///Componente.
import SpinnerAnimate from "../Progress/SpinnerAnimate";
import SendEmail from "./SendEmail";
import Header from "./Header";
import {
  NOTIFY_SUCCESS_FORGOTPASSWORD,
  TITLE_NOTIFY_UPDATE_PASSWORD
} from "../Message/Message";

//Estilo
import "./docs/ForgotPassword.css";


const moonLanding = new Date();

class EmailEnter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [],
      redirect: false,
      notify: false,
      sendMessage: [],
      sendEmail: false,
      isSpinner: false,
      formEmailEnter: {
        email: ""
      }
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (hasError) {
      notify(MESSAGE_REQUIRED, COLOUR_WARNING, 4000);
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    ///Datos de formulario.
    var formData = new FormData();
    formData.append("email", this.state.formEmailEnter.email);

    let header = headerPost(formData);
    this.setState({ isSpinner: true })
    MethodPost(sendemail, header)
      .then(response => {
        if (response.success) {
          this.setState({
            sendEmail: response.success,
            formEmailEnter: {
              ...this.state.formEmailEnter,
              email: ""
            }
          });
          showValidation(0, 0, '', response.message, COLOUR_SUCCESS);
        } else if (response.success === false) {
          showValidation(0, 0, '', response.message, COLOUR_WARNING);
        }
        this.setState({ isSpinner: false })
      })
      .catch(error => {
        showValidation(0, 0, '', 'Se presento un falló en la conexión, intente nuevamente.' +
          ' Si el problema persiste actualice su navegador e intente nuevamente de lo contrario comunique a un administrador', COLOUR_WARNING);
      });
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  handleRedirect = () => {
    this.setState({ route: ["/login"], redirect: true });
  };

  render() {
    const { sendEmail, isSpinner } = this.state;
    return (
      <div
        className="size-page"
        style={{
          background: { Pattern },
          height: "100%",
          overflow: "hidden",
          backgroundColor: "#ffffff"
        }}
      >
        <Header />
        <br />
        <br />
        <div className="wrapper content">
          <aside>
            <div className="block-center mt-4 wd-xl bg-white">
              {/* START card */}
              <div className="card-default card">
                <div className="card-header text-center">
                  <a href={Domain}>
                    <img
                      className="block-center rounded"
                      src="img/CORE_LOGO_253.png"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div className="card-body">
                  <p className="text-center py-2">¿OLVIDÓ SU CONTRASEÑA?</p>
                  <h5>Ingrese su dirección de correo electrónico</h5>
                  <form
                    name="formEmailEnter"
                    onSubmit={this.onSubmit}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <label className="text-muted">Email</label>
                      <div className="input-group with-focus">
                        <Input
                          className="form-control border-right-0"
                          name="email"
                          type="email"
                          placeholder="Correo electrónico"
                          value={this.state.formEmailEnter.email}
                          onChange={this.validateOnChange}
                          invalid={
                            this.hasError(
                              "formEmailEnter",
                              "email",
                              "required"
                            ) || this.hasError("formEmailEnter", "email", "email")
                          }
                          data-validate='["required", "email"]'
                        />
                        <div className="input-group-append">
                          <span className="input-group-text text-muted bg-transparent border-left-0">
                            <em className="fa fa-envelope" />
                          </span>
                        </div>
                        {this.hasError("formEmailEnter", "email", "email") && (
                          <span className="invalid-feedback">
                            El campo debe tener un formato valido.
                          </span>
                        )}
                      </div>
                    </div>
                    <SpinnerAnimate isDisabled={isSpinner} />
                    <button type="submit" className="btn btn-principal btn-block">
                      <span className="letter-white">Enviar</span>
                    </button>
                  </form>
                </div>
              </div>
              {/* END card */}
              <div className="p-3 text-center">
                <span className="mr-1">&copy;</span>
                <span>{moonLanding.getFullYear()}</span>
                <span className="mx-1">-</span>
                <span>CORE</span>
                <br />
                <span>SIEMPRE.NET</span>
              </div>
            </div>
          </aside>
          {this.state.route.map((item, i) => {
            if (this.state.redirect) {
              return <Redirect key={i + 1} to={item} />;
            }
            return true;
          })}
          {sendEmail ? (
            <SendEmail
              title={TITLE_NOTIFY_UPDATE_PASSWORD}
              message={NOTIFY_SUCCESS_FORGOTPASSWORD}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default EmailEnter;
