import React, { Component } from "react";
import { Card, CardBody, CardFooter, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import notify from "devextreme/ui/notify";
import NumberFormat from "react-number-format";

import FormValidator from "../Validation/FormValidation";
import { showValidation } from "../Validation/ShowValidation";
import { getBilling } from "../../services/Api/BillingCycle";
import { postAppBilling } from "../../services/Api/AppBilling";
import { COLOUR_WARNING } from "../Message/Message";
import { ExecuteFn } from '../../Global';

import SpinnerAnimate from '../Progress/SpinnerAnimate';


export default class FormBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeBillingTypes: [],
      formBilling: {
        billing: null,
        price: "",
        description: "",
        cost: 0
      },
      minimumunit: "",
      valueFactor: 0,
      value: 0,
      isSpinner: false
    };
  }

  componentDidMount() {
    ExecuteFn(this.handleBillingTypes);
  }

  componentDidUpdate() {
    if (this.props.isEdit) {
      this.props.handleChangeIsEdit();
      this.handleEdit();
    }
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  handleEdit = () => {
    const data = this.props.datashared;
    this.setState({
      formBilling: {
        ...this.state.formBilling,
        billing: { label: data.nameBilling, value: data.Billing.idBilling },
        price: data.costbase,
        description: data.description,
        cost: data.costbase
      },
      valueFactor: data.factor,
      value: parseInt(data.costbase) * parseInt(data.factor)
    });
  };

  handleBillingTypes = () => {
    let data = getBilling();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameBillingCycle,
              value: element.idBillingCycle,
              nameForm: "formBilling",
              nameSelect: "billing",
              minimumunit: element.minimumUnit,
              description: element.description,
              factor: element.factor,
              measument: element.nameMeasuredUnit,
              baseUnit: element.baseUnit
            };
            array.push(obj);
          });
          this.setState({ typeBillingTypes: array });
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleChangeSelect = event => {
    let temp = this.state.formBilling;
    this.setState({
      formBilling: {
        ...this.state.formBilling,
        billing: { label: event.label, value: event.value },
        description: event.description,
        price: temp.price,
        cost: temp.cost
      },
      minimumunit: event.baseUnit + " " + event.measument,
      valueFactor: event.factor,
      value: temp.price * event.factor
    });
  };

  handleValidateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });

    if (false) {
      const factor = this.state.valueFactor;
      const persist = this.state.formBilling;

      let priceMoney = value.substring(1, value.length);
      this.setState({
        formBilling: {
          ...this.state.formBilling,
          billing: persist.billing,
          price: persist.price,
          description: persist.description,
          cost: priceMoney
        },
        value: priceMoney * factor
      });
    }
  };

  handleOnSubmit = e => {
    e.preventDefault();
    if (
      this.state.formBilling.billing === null ||
      this.state.formBilling.cost === 0 ||
      this.state.formBilling.description === ""
    ) {
      notify(
        "Ingrese un tipo de facturación, precio y su descripción.",
        COLOUR_WARNING,
        5000
      );
    } else {
      this.handleSubmit();
    }
  };

  handleResetStateForm = () => {
    this.setState({
      formBilling: {
        ...this.state.formBilling,
        billing: null,
        price: " ",
        description: "",
        cost: 0
      },
      minimumunit: "",
      valueFactor: 0,
      value: 0
    });
  };

  handleFormatMoney = param => {
    let response = 0;
    let priceOne = param.substring(2, 5);
    let priceTwo = param.substring(5, 9);
    let priceThree = param.substring(9, 13);
    let price = priceOne + priceTwo + priceThree;
    let priceBase = price.split("_");
    let value = priceBase[0].split(" ");

    let positionone = value[0] !== undefined ? value[0] : null;
    let positiontwo = value[1] !== undefined ? value[1] : null;
    let positiontree = value[2] !== undefined ? value[2] : null;

    if (positionone === null || positionone < "0") {
      notify("Ingrese un valor valido.", COLOUR_WARNING, 4000);
    } else {
      if (positiontree !== null && positiontwo !== null) {
        response = value[0] + value[1] + value[2];
      } else {
        if (positiontwo !== null && positionone !== null) {
          response = value[0] + value[1];
        } else {
          response = value[0];
        }
      }
    }
    return response;
  };

  handleSubmit() {
    this.handleSpinner();
    const dataFetch = new FormData();
    const data = this.state.formBilling;
    dataFetch.append("IdApplication", this.props.identifierapp);
    dataFetch.append("IdBillingCycle", data.billing.value);
    dataFetch.append("Description", data.description);
    dataFetch.append("Cost", data.cost);

    let dataResponse = postAppBilling(dataFetch);
    let promise = new Promise((rest, rej) => {
      rest(dataResponse);
      rej(Error(dataResponse));
    });
    promise.then(
      result => {
        if (result.success) {
          showValidation(result, 1, "facturación");
          this.props.handleChangeDatagrid();
          this.handleResetStateForm();
        } else {
          showValidation(result);
        }
        this.handleSpinner();
      },
      function(error) {
        console.log(error);
      }
    );
  }

  handleChangePrice = event => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    const empty$ = value.substring(1, value.length);
    const emptyCome = empty$.split(",");
    let price = 0;
    emptyCome.forEach(x => {
      price = price + x;
    });

    const factor = this.state.valueFactor;
    const total = price.substring(1, price.length);

    this.setState({
      formBilling: {
        ...this.state.formBilling,
        price: total,
        cost: total
      },
      value: total * factor
    });
  };

  render() {
    const { typeBillingTypes, minimumunit, value, isSpinner } = this.state;
    const { billing, price, description } = this.state.formBilling;
    return (
      <div>
        <Card>
          <form name="formBilling" onSubmit={this.handleOnSubmit}
            autoComplete="off" className="parent">
            <SpinnerAnimate isDisabled={isSpinner} />
            <CardBody>
              <FormGroup>
                <Select
                  name="billing"
                  placeholder="Tipo facturación"
                  options={typeBillingTypes}
                  onChange={this.handleChangeSelect}
                  value={billing}
                />
                {minimumunit === "" ? null : (
                  <strong>
                    Defina el costo de la unidad mínima de medida que esta
                    expresada en: <h4>{minimumunit}</h4>
                  </strong>
                )}
              </FormGroup>
              <FormGroup>
                <NumberFormat
                  type="text"
                  name="price"
                  className="form-control"
                  placeholder="Precio por unidad minima"
                  onChange={this.handleChangePrice}
                  value={price}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <div className="float-right">
                  <strong>${new Intl.NumberFormat().format(value)}</strong>
                </div>
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Descripción"
                  onChange={this.handleValidateOnChange}
                  value={description}
                />
              </FormGroup>
            </CardBody>
            <CardFooter className="flexbox position-button">
              <div className="float-right">
                <button type="submit" className="btn bg-primary">
                  Asignar
                </button>
                {"  "}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleResetStateForm}
                >
                  Restaurar Formulario
                </button>
              </div>
            </CardFooter>
          </form>
        </Card>
      </div>
    );
  }
}
