import React, { Component } from "react";
import { Modal, ModalBody, InputGroup, Input } from "reactstrap";

import TabsAssignation from "./SubscriptionTabsAssignation";
import FormValidator from "../../Validation/FormValidation";
import "../docs/Subscription.css";

export default class SubscriptionModalAssignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSearch: {
        search: ""
      },
      filter: false
    };
  }

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      },
      filter: true
    });
  };

  handleOnSearch = () => this.setState({ filter: !this.state.filter });

  handleOnReset = () => {
    this.setState({
      formSearch: {
        ...this.state.formSearch,
        search: ""
      }
    });
  };

  render() {
    const { filter } = this.state;
    const { search } = this.state.formSearch;
    const {
      isOpen,
      openModal,
      data,
      handleLoadSubscription,
      handleReloadGrid,
      subscription,
      client,
      apps
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={this.openModal} className="modal-lg">
        <form name="formSearch" autoComplete="off">
          <div className="modal-header bg-purple" toggle={this.openModal}>
            <strong className="color-with">Mercado de Aplicaciones</strong>
            <InputGroup className="col-md-8">
              <Input
                type="text"
                name="search"
                placeholder="Buscar aplicación"
                value={search}
                onChange={this.handleOnChange}
              />
            </InputGroup>

            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={openModal}
            >
              <span aria-hidden="true" className="color-with size-12">
                &times;
              </span>
            </button>
          </div>
        </form>
        <ModalBody className="scroll-modalbody h770">
          <div className="row">
            <div className="col-md-6">
              <strong>
                Nombre de suscripción:{" "}
                {data !== null ? data.nameSubscription : ""}{" "}
              </strong>
            </div>
            <div className="col-md-6">
              <strong>
                Precio actual suscripción:
                {data !== null
                  ? <label className="bg-purple price-rounded precious-border">${new Intl.NumberFormat().format(data.PriceSubscription)} </label> : 0}
              </strong>
            </div>
          </div>
          <br />
          <div>
            <TabsAssignation
              data={data}
              filter={filter}
              client={client}
              apps={apps}
              nameSearch={search}
              subscription={subscription}
              handleLoadSubscription={handleLoadSubscription}
              handleReloadGrid={handleReloadGrid}
              handleOnSearch={this.handleOnSearch}
              handleOpenModalAssign={openModal}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
