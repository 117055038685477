import MethodGet from "../MethodGet";
import {
  getclient,
  getstandarfindbyclient,
  deletstandar
} from "../../components/RouteApi/RouteApi";
import { headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

///empresas por usuario
export const getclientbyuser = param => {
  let urlBase = getclient,
    params = {
      value: param
    };

  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

///Usuarios estandar por empresa
export const standarfindbyclient = param => {
  let urlBase = getstandarfindbyclient,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header)
  .then(response => response)
  .catch(error => error);
  const promise = new Promise((rest,rej)=>{
    rest(response);
    rej(Error(response));
  });
  return promise;
};

///Eliminar usuario standar
export const standardelete = param =>{
  let urlBase = deletstandar,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);
  const response = MethodGet(url, header)
  .then(response => response)
  .catch(error => error);
  const promise = new Promise((rest,rej)=>{
    rest(response);
    rej(Error(response));
  });
  return promise;
}
