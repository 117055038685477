import React, { useState } from 'react';

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';

export function PopoverItem(props) {

  const { id, placement, title, body, popoverOpen } = props.popover;
  return (
    <span>
      <Popover placement={placement} isOpen={popoverOpen} target={'Popover-' + id} toggle={() => props.toggle()}>
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </Popover>
    </span>
  )
}