import React, { Component } from "react";
import { Collapse } from "reactstrap";

import EnterpriseForm from "./EnterpriseForm";

export default class EnterpriseSidebar extends Component {
  render() {
    const {
      handleEdit,
      handleEnterprise,
      filter,
      isEdit,
      isEnterprise,
      handleChangeDetail,
      handleReload
    } = this.props;
    return (
      <div>
        <Collapse className="mb-boxes" isOpen={true}>
          <div className="card card-default">
            <div className="card-body">
              <ul className="nav nav-pills flex-column">
                {isEnterprise === false ? (
                  <li className="nav-item p-2">REGISTRO DE EMPRESA</li>
                ) : (
                  <li className="nav-item p-2">ACTUALIZACIÓN DE EMPRESA</li>
                )}
              </ul>  
              <EnterpriseForm 
                filter={filter}
                isEdit={isEdit}
                handleEdit={handleEdit}
                handleEnterprise={handleEnterprise}
                handleChangeDetail={handleChangeDetail}
                handleReload={handleReload}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
