import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

///Componentes
import ContentWrapper from "../Layout/ContentWrapper";
import CarouselHeader from "./CarouselHeader";
import CarouselBody from "./CarouselBody";

class Carousel extends Component {
  render() {
    return (
      <ContentWrapper>
        <CarouselHeader />
        <CarouselBody />
      </ContentWrapper>
    );
  }
}

export default withNamespaces("translations")(Carousel);
