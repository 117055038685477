import React from "react";
import Select from "react-select";
import FormValidator from "../Validation/FormValidation";
import { withNamespaces } from "react-i18next";
import {
  Input,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

///Notificaciones.
import notify from "devextreme/ui/notify";
import { showValidation } from "../Validation/ShowValidation";
import { COLOUR_WARNING } from "../Message/Message";

///Servicio.
import { categoriaLoading } from "../../services/Api/Permission";
import { postPermission, putPermission } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import MethodPost from "../../services/MethodPost";
import { _JsonStorage } from '../../Global';

import SpinnerAnimate from '../Progress/SpinnerAnimate';

///Estilo css.
import "./docs/StyleModal.css";

///Tipo de boton a mostrar en el modal.
const ButtonFooter = props => {
  if (props.isTypeButton) {
    return (
      <Button type="submit" className="bg-success">
        Actualizar
      </Button>
    );
  } else {
    return (
      <Button type="submit" color="primary">
        Guardar
      </Button>
    );
  }
};

class ModalPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryOption: [],
      formPermission: {
        namePermission: "",
        code: "",
        description: "",
        order: "",
        idCategory: null
      },
      isSpinner: false
    };
  }

  componentDidMount(){
    ///Cargar categorias
    this.handleCategories();
  }

  handleCategories = ()=>{
      let promise = categoriaLoading();
      promise.then(response=>{
        if(response.success){
          let array = [];
          response.response.forEach(x => {
              let obj = { 
                label: x.nameCategory, 
                value: x.idCategory, 
                nameSelect: 'idCategory', 
                nameForm: 'formPermission' 
              };
              array.push(obj);
          });
          this.setState({ categoryOption: array });
        }
      },function(error){
        console.log(error);
      });
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cambio de estado de los elementos select del Dom.
  handleChangeSelectMulti = event => {
    ///Cambiar de estado los valores de los select.
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Función para validar los campos al momento de realizar click en el boton ingresar.
  onSubmit = e => {
    e.preventDefault();
    if (this.state.formPermission.idCategory === null) {
      notify("Verifica haber selecciona una categoria.", COLOUR_WARNING);
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "COLOR"].includes(i.nodeName)
      );
      ///Validar campos requeridos.
      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit(e);
      }
    }
  };

  ///Función para enviar datos a api.
  handleSubmit(event) {
    event.preventDefault();
    this.handleSpinner();

    let header = null;
    let url = null;

    ///Datos de formulario a enviar.
    const dataFetch = new FormData();
    ///Elementos del formulario.
    dataFetch.append("Description", this.state.formPermission.description);
    dataFetch.append("Order", this.state.formPermission.order);
    dataFetch.append("NamePermission",this.state.formPermission.namePermission);
    dataFetch.append("code", this.state.formPermission.code);
    dataFetch.append("IdCategory", this.state.formPermission.idCategory.value);

    ///Validar si los datos son para actualizar o un nuevo registro.
    if (this.props.openModalEdit) {
      dataFetch.append("IdPermission", this.props.item.idPermission);
      // dataFetch.append("Code", this.props.item.code);
      header = headerPut(dataFetch, _JsonStorage.access_token);
      url = putPermission;
    } else {
      header = headerPost(dataFetch, _JsonStorage.access_token);
      url = postPermission;
    }

    MethodPost(url, header)
      .then(response => {
        if (response.success) {
          if (this.props.openModalEdit) {
            showValidation(response, 2, "permiso");
          } else {
            showValidation(response, 1, "permiso");
          }

          this.props.onChangeTable();
          this.props.onClickModal();
          this.setStateForm();
        } else {
          showValidation(response);
        }
        this.handleSpinner();
      })
      .catch(error => {
        console.log(error);
      });
  }

  ///Resetear formulario.
  setStateForm = () => {
    this.setState({
      formPermission: {
        namePermission: "",
        code: "",
        description: "",
        order: "",
        idCategory: null
      }
    });
  };

  ///Función para validar el tipo de modal a mostrar (crear-editar).
  handleEdit = () => {
    if (this.props.openModalEdit === true) {
      ///Cargar los datos al modal si va a editar.
      this.setState({
        formPermission: {
          namePermission: this.props.item.namePermission,
          code: this.props.item.code,
          description: this.props.item.description,
          order: this.props.item.order,
          idCategory: {
            label: this.props.item.nameCategory,
            value: this.props.item.idCategory
          }
        }
      });
    } else {
      this.setStateForm();
    }
  };

  render() {
    const { isSpinner } = this.state;
    return (
      <div>
        <Modal onOpened={this.handleEdit} isOpen={this.props.openModal} toggle={this.openModal}>
          <div className="modal-header" toggle={this.openModal}>
            REGISTRO DE PERMISOS
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.onClickModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={this.onSubmit} name="formPermission" autoComplete="off" className="parent">
          <SpinnerAnimate isDisabled={isSpinner} />
            <ModalBody>
              <FormGroup>
                <label>Nombre</label>
                <Input
                  type="text"
                  name="namePermission"
                  placeholder="Nombre permiso..."
                  invalid={
                    this.hasError(
                      "formPermission",
                      "namePermission",
                      "required"
                    ) ||
                    this.hasError(
                      "formPermission",
                      "namePermission",
                      "alphaspace"
                    )
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formPermission.namePermission}
                />
                {this.hasError(
                  "formPermission",
                  "namePermission",
                  "alphaspace"
                ) && (
                  <span className="invalid-feedback">
                    El campo debe ser un nombre válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Codigo</label>
                <Input
                  type="text"
                  name="code"
                  placeholder="Codigo..."
                  invalid={
                    this.hasError(
                      "formPermission",
                      "code",
                      "required"
                    ) ||
                    this.hasError(
                      "formPermission",
                      "code",
                      "alphaspace"
                    )
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formPermission.code}
                />
                {this.hasError(
                  "formPermission",
                  "code",
                  "alphaspace"
                ) && (
                  <span className="invalid-feedback">
                    El campo debe ser un codigo válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Descripción</label>
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Descripción permiso..."
                  invalid={
                    this.hasError(
                      "formPermission",
                      "description",
                      "required"
                    ) ||
                    this.hasError("formPermission", "description", "alphaspace")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formPermission.description}
                />
                {this.hasError(
                  "formPermission",
                  "description",
                  "alphaspace"
                ) && (
                  <span className="invalid-feedback">
                    El campo debe ser un campo válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Orden</label>
                <Input
                  type="number"
                  name="order"
                  placeholder="Ejemplo: 0-9"
                  invalid={
                    this.hasError("formPermission", "order", "required") ||
                    this.hasError("formPermission", "order", "number") ||
                    this.hasError("formPermission", "order", "isnegative")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "number","isnegative"]'
                  value={this.state.formPermission.order}
                />
                {this.hasError("formPermission", "order", "number") && (
                  <span className="invalid-feedback">
                    El campo debe ser un campo válido.
                  </span>
                )}
                {this.hasError("formPermission", "order", "isnegative") && (
                  <span className="invalid-feedback">
                    El valor ingresado no debe de ser negativo.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label> Categoria </label>
                <Select
                  name="idCategory"
                  multi
                  simpleValue
                  placeholder="---- SELECCIONA UNA OPCIÓN ----"
                  value={this.state.formPermission.idCategory}
                  onChange={this.handleChangeSelectMulti}
                  options={this.state.categoryOption}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <ButtonFooter isTypeButton={this.props.openModalEdit} />{" "}
              <Button color="secondary" onClick={this.props.onClickModal}>
                Cancelar
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
export default withNamespaces("translations")(ModalPermission);
