import React, { Component } from "react";
import EnterpriseSidebar from "./EnterpriseSidebar";
import EnterpriseFilter from "./EnterpriseFilter";
import ContentWrapper from "../Layout/ContentWrapper";
import EnterpriseInfo from "./EnterpriseInfo";

export default class Enterprise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 0,
      isEdit: false, 
      isEnterprise: false,
      isChangeDetail: false,
      isLoad: false,
      isRedirect: false,
      redirectEnterprise: null
    };
  }

  handleFilter = (param1, param2) =>
    this.setState({ filter: param1, isEdit: param2 });

  handleEdit = () => this.setState({ isEdit: !this.state.isEdit });

  handleEnterprise = () =>
    this.setState({ isEnterprise: !this.state.isEnterprise });

  handleChangeDetail = () =>
    this.setState({ isChangeDetail: !this.state.isChangeDetail });

  handleReload = () => this.setState({ isLoad: !this.state.isLoad });

  handleRedirect = () =>this.setState({ isRedirect: !this.state.isRedirect });

  handleDataEnterprise = param =>this.setState({ redirectEnterprise: param });

  render() {
    const { filter, isEdit, isEnterprise, isChangeDetail, isLoad } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {" "}
            Empresa
            <small>Configuración de empresa.</small>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-5">
            <EnterpriseSidebar
              handleEdit={this.handleEdit}
              filter={filter}
              isEdit={isEdit}
              isEnterprise={isEnterprise}
              handleEnterprise={this.handleEnterprise}
              handleChangeDetail={this.handleChangeDetail}
              handleReload={this.handleReload}
            />
          </div> 
          <div className="col-xl-8 col-lg-7">
            <EnterpriseFilter
              isLoad={isLoad}
              handleFilter={this.handleFilter}
              handleEnterprise={this.handleEnterprise}
              handleReload={this.handleReload}
            />
            <EnterpriseInfo
              filter={filter}
              isEdit={isEdit}
              isChangeDetail={isChangeDetail}
              handleFilter={this.handleFilter}
              handleEdit={this.handleEdit}
              handleChangeDetail={this.handleChangeDetail}
            />
          </div>
        </div>
      </ContentWrapper>
    );
  }
}
