import React, { Component } from "react";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import { Row, Col } from "reactstrap";
import { Input, FormGroup, Button, Progress } from "reactstrap";

///Dev extreme
import notify from "devextreme/ui/notify";

//#region Componentes - Funciones.
import FormValidator from "../Validation/FormValidation";
import { showValidation } from "../Validation/ShowValidation";
import { GetApplicationType } from "../../services/Api/ApplicationType";
import { CodeConsecutiveAsync } from "../../services/Api/Application";
import { COLOUR_WARNING, COLOUR_SUCCESS } from "../Message/Message";

import MethodPost from "../../services/MethodPost";

import { postApplication, putApplication } from "../RouteApi/RouteApi";
import { headerPost } from "../../services/Headers";
import { _JsonStorage, ExecuteFn } from "../../Global";
import SpinnerAnimate from '../Progress/SpinnerAnimate';

import "./docs/Application.css";
//#endregion

export default class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.fileInput = null;
    this.imgReft = null;
    this.state = {
      collapse: true,
      dataApplicationType: [],
      formApplication: {
        identifier: null,
        nameApplication: "",
        descriptionApplication: "",
        urlApplication: "",
        idAplicationType: null,
        storage: true,
        internalCode: "",
        Systemconsecutive: ""
      },
      pictures: [],
      progress: 0,
      isEdit: false,
      isSpinner: false
    };
    this.formApplication = React.createRef();
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    ExecuteFn(this.handleTypes);
    ExecuteFn(this.handleConsecutive);
  }

  componentDidUpdate() {
    let props = this.props;

    ///Agregar dato de la aplicación si fue recién creada.
    if (
      props.loadDataModal !== null &&
      props.openModal === true &&
      this.state.formApplication.identifier === null
    ) {
      let dataApp = props.loadDataModal;
      let data = null;
      if (dataApp.length > 0) {
        let element = dataApp.find(
          x => x.nameAplication === this.state.formApplication.nameApplication
        );
        data = element;
      } else if (dataApp !== null) {
        data = dataApp;
      }

      let appType = {
        label: data.nameApplicationType,
        value: data.idAplicationType,
        nameForm: "formApplication",
        nameSelect: "idAplicationType"
      };

      this.setState({
        formApplication: {
          ...this.state.formApplication,
          identifier: data.idApplication,
          nameApplication:
            data.nameAplication === null ? "" : data.nameAplication,
          descriptionApplication:
            data.description === null ? "" : data.description,
          urlApplication: data.url,
          idAplicationType: appType,
          storage: data.storage,
          internalCode: data.internalCode === null ? "" : data.internalCode
        },
        isEdit: true
      });

      this.props.handleAppCode(data.idApplication);
      this.handleFormValid();
    }

    ///Restear formulario si el usuario oculta el modal y se encontraba en el estado editar.
    if (props.openModal === false && this.state.isEdit === true) {
      this.setStateForm();
      this.props.handleResetLoadDataTable();
    }
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () => this.setState({ isSpinner: !this.state.isSpinner });

  ///Formulario es valido
  handleFormValid = () => {
    this.props.handleFormAppValid();
  };

  ///Consultar código consecutivo.
  handleConsecutive = () => {
    let _this = this;
    CodeConsecutiveAsync()
      .then(response => {
        if (response.success) {
          if (!_this.state.isEdit) {
            _this.setState({
              formApplication: {
                ..._this.state.formApplication,
                internalCode: response.response
              }
            });
          } else {
            _this.setState({
              formApplication: {
                ..._this.state.formApplication,
                Systemconsecutive: response.response
              }
            });
          }
        }
      },
        function (error) {
          console.log(error);
        }
      );
  };

  ///Cargar tipos de aplicaciones
  handleTypes = () => {
    let promise = GetApplicationType();
    promise.then(
      response => {
        if (response.success) {
          let array = [];
          response.response.forEach(x => {
            let obj = {
              label: x.nameAplicationType,
              value: x.idAplicationType,
              nameForm: "formApplication",
              nameSelect: "idAplicationType"
            };
            array.push(obj);
          });
          this.setState({ dataApplicationType: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Función para guardar una aplicación.
  onSubmit = e => {
    e.preventDefault();
    if (this.state.formApplication.idAplicationType === null) {
      notify(
        "Verifica haber seleccionado un tipo de aplicación.",
        COLOUR_WARNING
      );
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
      );
      ///Validar campos requeridos.
      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      ///Asignar nuevos valores al state.
      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit();
      }
    }
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cambio de estado de los elementos select del Dom.
  handleChangeSelectMulti = event => {
    ///Cambiar de estado los valores de los select.
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  handleSubmit = () => {
    this.handleSpinner();

    let _this = this;
    let header = null;
    let url = null;

    const dataFetch = new FormData();
    const data = this.state.formApplication;
    const code = _JsonStorage.profile.id;
    dataFetch.append("NameAplication", data.nameApplication);
    dataFetch.append("Description", data.descriptionApplication);
    dataFetch.append("Url", data.urlApplication);
    dataFetch.append("code", code);
    dataFetch.append("storage", data.storage);
    dataFetch.append("internalcode", data.internalCode);

    if (this.state.pictures.length > 0) {
      let index = this.state.pictures.length - 1;
      dataFetch.append("File", this.state.pictures[index]);
    }

    if (data.idAplicationType.value !== undefined) {
      dataFetch.append("IdAplicationType", data.idAplicationType.value);
    } else {
      dataFetch.append("IdAplicationType", data.idAplicationType[0].value);
    }

    ///Validar si los datos son para actualizar o crear un nuevo registro.
    if (data.identifier !== null) {
      dataFetch.append("IdApplication", data.identifier);
      url = putApplication;
    } else {
      url = postApplication;
    }

    header = headerPost(dataFetch, _JsonStorage.access_token);
    MethodPost(url, header)
      .then(response => {
        if (response.success) {
          showValidation(0, 0, "", response.message, COLOUR_SUCCESS);
          this.setState({ progress: 0 });
          _this.props.handleUpdateDataTable();
        } else if (response.success === false) {
          showValidation(0, 0, "", response.message, COLOUR_WARNING);
        } else {
          showValidation(0, 0, "", "Se presento una falla de conexión, Por favor intente nuevamente." +
            "Si el problema persiste comunique a su administrador", COLOUR_WARNING);
        }
        this.handleSpinner();
      })
      .catch(error => {
        console.log(error);
      });
  }


  setStateForm = () => {
    this.setState({
      formApplication: {
        ...this.state.formApplication,
        identifier: null,
        nameApplication: "",
        descriptionApplication: "",
        urlApplication: "",
        idAplicationType: null,
        logo: null,
        storage: true,
        internalCode: ""
      },
      isEdit: false,
      pictures: [],
      progress: 0
    });
  };

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
      progress: 0
    });

    for (let index = 0; index < 100; index++) {
      this.setState({ progress: index });
    }
  }

  render() {
    const { isEdit, isSpinner } = this.state;
    const {
      descriptionApplication,
      storage,
      internalCode,
      Systemconsecutive
    } = this.state.formApplication;
    return (
      <div className="col-xl-12 col-lg-12 anyClass" style={{ height: "auto" }}>
        <form name="formApplication" id="formApplication" onSubmit={this.onSubmit}
          autoComplete="off" className="parent">
          <SpinnerAnimate isDisabled={isSpinner} />
          <FormGroup>
            <Row>
              <Col lg="6">
                <label>Nombre</label>
                <Input
                  type="text"
                  name="nameApplication"
                  placeholder="Nombre de la aplicación."
                  invalid={
                    this.hasError(
                      "formApplication",
                      "nameApplication",
                      "required"
                    ) ||
                    this.hasError(
                      "formApplication",
                      "nameApplication",
                      "alphaspace"
                    )
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formApplication.nameApplication}
                />
                {this.hasError(
                  "formApplication",
                  "nameApplication",
                  "alphaspace"
                ) && (
                    <span className="invalid-feedback">
                      El campo debe ser un nombre válido.
                    </span>
                  )}
              </Col>
              <Col lg="6">
                <label>Tipo</label>
                <Select
                  name="idAplicationType"
                  placeholder="-SELECCIONE UNA OPCIÓN-"
                  options={this.state.dataApplicationType}
                  onChange={this.handleChangeSelectMulti}
                  value={this.state.formApplication.idAplicationType}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col lg="6">
                <label>Url</label>
                <Input
                  type="url"
                  name="urlApplication"
                  placeholder="Url de la aplicación."
                  invalid={
                    this.hasError("formApplication","urlApplication","required")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formApplication.urlApplication}
                />
              </Col>
              <Col lg="6">
                <label>Código de aplicación</label>
                <Input
                  type="text"
                  name="internalCode"
                  placeholder="Ingrese el cogido de la aplicación"
                  title="Ingrese el cogido de la aplicación"
                  invalid={this.hasError(
                    "formApplication",
                    "internalCode",
                    "required"
                  )}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={internalCode}
                />
                {isEdit === false ? null : (
                  <strong>
                    Código consecutivo del sistema: {Systemconsecutive}
                  </strong>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <label>Descripción</label>
            <Input
              type="textarea"
              name="descriptionApplication"
              placeholder="Descripción de la aplicación."
              rows="5"
              invalid={
                this.hasError(
                  "formApplication",
                  "descriptionApplication",
                  "required"
                ) ||
                this.hasError(
                  "formApplication",
                  "descriptionApplication",
                  "maxlen"
                )
              }
              onChange={this.validateOnChange}
              data-validate='["required","maxlen"]'
              value={descriptionApplication}
              data-param="500"
            />
            {this.hasError(
              "formApplication",
              "descriptionApplication",
              "maxlen"
            ) && (
                <span className="invalid-feedback">
                  La longitud del campo es muy extensa.
                </span>
              )}
          </FormGroup>
          <FormGroup className="row">
            <div className="col-md-6">
              <label>Con almacenamiento </label>
              <label className="checkbox c-checkbox c-checkbox-rounded">
                <Input
                  type="checkbox"
                  name="storage"
                  id="roundedcheckbox10"
                  checked={storage}
                  onChange={this.validateOnChange}
                />
                <span className="fa fa-check" />
              </label>
            </div>
          </FormGroup>
          <FormGroup>
            <ImageUploader
              withIcon={true}
              buttonText="Elegir imagen"
              onChange={this.onDrop}
              label="Tamaño máximo de archivo: 1mb, acepta: jpg | png"
              imgExtension={[".jpg", ".png"]}
              maxFileSize={5242880}
            />
            <Progress color="success" value={this.state.progress}>
              Archivo listo para subir
            </Progress>
          </FormGroup>
          <FormGroup className="float-right">
            <br />
            {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}
          </FormGroup>
        </form>
      </div>
    );
  }
}

const ButtonSave = () => (
  <Button type="submit" color="primary">
    Guardar Sección
  </Button>
);
const ButtonUpdate = () => (
  <Button type="submit" className="bg-success">
    Actualizar Sección
  </Button>
);
