import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Button } from 'reactstrap';

import { deleteNotifications } from "../../services/Api/Notification";
import { showValidation } from "../Validation/ShowValidation";
import "./docs/Notification.css";

class NotificationOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkMessage: false,
      isDelete: false,
      isDisableOption: false
    };
  }

  componentDidUpdate() {
    this.handleIsDelete();
    this.handleIsDisableOption();
  }

  ///Validar las opciones de las notificaciones
  handleIsDisableOption = () =>{
    if(this.props.notify_array > 0 && 
       this.state.isDisableOption === true){
        this.setState({ isDisableOption: false });
    }

    if(this.props.notify_array === 0 &&
       this.state.isDisableOption === false){
        this.setState({ isDisableOption: true });
    }
  }

  ///Mostrar o ocultar boton eliminar
  handleIsDelete = () => {
    if (this.props.checked_array.length > 0 &&
      this.state.isDelete === false) {
      this.setState({ isDelete: true });
    }

    if(this.props.checked_array.length === 0 &&
       this.state.isDelete === true){
       this.setState({ isDelete: false });
    }
  };

  ///Eliminar notificaciones
  handleRemoveMessage = () => {
    ///Petición de servicio para eliminar los mensajes.
    deleteNotifications(this.props.checked_array)
      .then(response => {
        if (response.success) {
          showValidation(response);
          this.props.handleRemoveElement(this.props.checked_array);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  ///Función que solicita chekear todos los check de la lista
  handleCheckMessages = () => {
    this.setState({
      checkMessage: !this.state.checkMessage,
      isDelete: !this.state.checkMessage
    });
    this.props.handleChekedAll(!this.state.checkMessage);
  };

  render() {
    const { checkMessage, isDelete, isDisableOption } = this.state;
    return (
      <>
        {/* START action buttons */}
        <div className="d-flex mb-2">
          <div className="btn-group">
            <Button
              className="btn btn-secondary btn-sm checkbtn"
              type="button"
              title="Anterior"
              disabled={isDisableOption}
            >
              <em className="fas fa-reply text-gray-dark"></em>
            </Button>
            <Button
              className="btn btn-secondary btn-sm checkbtn"
              type="button"
              title="Devolver todo"
              disabled={isDisableOption}
            >
              <em className="fas fa-reply-all text-gray-dark"></em>
            </Button>
            <Button
              className="btn btn-secondary btn-sm checkbtn"
              type="button"
              title="Siguiente"
              disabled={isDisableOption}
            >
              <em className="fas fa-share text-gray-dark"></em>
            </Button>
            <Button
              className="btn btn-secondary btn-sm checkbtn"
              type="button"
              title="Seleccionar todos los mensajes"
              disabled={isDisableOption}
              onClick={this.handleCheckMessages}
            >
              {checkMessage === false ? (
                <span className="fa fa-check"></span>
              ) : (
                <span className="fa fa-check-double"></span>
              )}
            </Button>
            {/* Boton eliminar mensajes */}
            {isDelete === false ? null : (
              <Button
                className="btn btn-secondary btn-sm checkbtndelete"
                type="button"
                title="Eliminar"
                onClick={this.handleRemoveMessage}
              >
                <em className="fa fa-times text-gray-dark"></em>
              </Button>
            )}
          </div>
        </div>
        {/* END action buttons */}
      </>
    );
  }
}

export default withNamespaces("translations")(NotificationOption);
