import React from "react";

export const ProfileContext = React.createContext({});

export const ProfileConsumer = ProfileContext.Consumer;

class ProfileProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
    }

    this.handleChangeState = this.handleChangeState.bind(this);
  }

  handleChangeState = (name, value, name2 = null) => {
    if (name2 === null) this.setState({ [name]: value });
    else {
      this.setState({
        [name]: {
          ...this.state[name],
          [name2]: value
        }
      })
    }
  }

  render() {
    const data = this.state;
    const handleChangeState = this.handleChangeState;
    return (
      <ProfileContext.Provider value={{ data, handleChangeState }}>
        {this.props.children}
      </ProfileContext.Provider>
    );
  }
}

export default ProfileProvider;