import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import UserFormAssignAdmin from './UserFormAssignAdmin'; 
 
export default class UserModalAssignAdmin extends Component {
  render() { 
    const { handleShowModal, openModal, filter, handleChangeGrid } = this.props;
    return (
      <Modal isOpen={openModal} toggle={handleShowModal}>
        <div className="modal-header">
            {" "}
            <strong>Asignación de usuarios administradores a empresa</strong>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleShowModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        <ModalBody>
          <UserFormAssignAdmin filter={filter} handleShowModal={handleShowModal} handleChangeGrid={handleChangeGrid} />
        </ModalBody>
      </Modal>
    )
  }
}
