import React, { Component } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  FormGroup
} from "reactstrap";

import UserDatagridAssignStandar from "./UserDatagridAssignStandar";
import FormValidator from "../Validation/FormValidation";
import { getuseremail } from "../../services/Api/User";
import { showValidation } from "../Validation/ShowValidation";

export default class UserFormAssignStandar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSubscription: [],
      formAssignUser: {
        email: "",
        nameUser: "",
        email2: "",
        code: ""
      },
      isSelect: false
    };
  }

  ///Permitir resetear los select del datagrid
  handleIsSelectChange = () =>
    this.setState({ isSelect: !this.state.isSelect });

  ///Cambio de estado de los input
  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cargar errores de los campos
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Validar datos del formulario
  handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });

    if (!hasError) {
      this.handleSubmit();
    }
  };

  ///Consumir datos del servicio
  handleSubmit = () => {
    let email = this.state.formAssignUser.email;
    let data = getuseremail(email);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          this.setState({
            formAssignUser: {
              ...this.state.formAssignUser,
              nameUser:
                result.response.firstName + " " + result.response.lastName,
              email2: result.response.email,
              code: result.response.code
            }
          });
        } else {
          this.setState({
            isSelect: true,
            formAssignUser: {
              ...this.state.formAssignUser,
              nameUser: "",
              email2: ""
            }
          });
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  render() {
    const { filter, handleShowModal, handleChangeGrid } = this.props;
    const { email, nameUser, email2, code } = this.state.formAssignUser;
    const { isSelect } = this.state;
    return (
      <form
        name="formAssignUser"
        onSubmit={this.handleOnSubmit}
        autoComplete="off"
      >
        <label>Buscar usuario por correo electrónico</label>
        <InputGroup>
          <Input
            type="text"
            name="email"
            placeholder="Example@example.com"
            value={email}
            onChange={this.handleOnChange}
            invalid={
              this.hasError("formAssignUser", "email", "required") ||
              this.hasError("formAssignUser", "email", "email")
            }
            data-validate='["required","email"]'
          />
          <InputGroupAddon addonType="append">
            <Button className="bg-purple">Buscar</Button>
          </InputGroupAddon>
          {this.hasError("formAssignUser", "email", "email") && (
            <span className="invalid-feedback">
              El valor ingresado debe tener un formato correcto.
            </span>
          )}
        </InputGroup>
        <br />
        <div className="form-row align-items-center">
          <div className="col-md-6">
            <FormGroup>
              <Input
                type="text"
                name="nameuser"
                placeholder="Nombre de usuario"
                disabled={true}
                value={nameUser}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup>
              <Input
                type="text"
                name="email"
                placeholder="Correo de usuario"
                disabled={true}
                value={email2}
              />
            </FormGroup>
          </div>
        </div>
        <div className="">
          <UserDatagridAssignStandar
            filter={filter}
            user={code}
            isSelect={isSelect}
            email={email2}
            handleShowModal={handleShowModal}
            handleChangeGrid={handleChangeGrid}
            handleIsSelectChange={this.handleIsSelectChange}
          />
        </div>
      </form>
    );
  }
}
