import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { FormGroup, Input } from "reactstrap";

import { getSubsApp } from "../../services/Api/Subscription";

export default class SubscriptionModalDetailApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app: 0,
      formDetails: {
        namesubscription: "",
        startdate: "",
        pricetotal: "",
        img: "",
        nameapp: "",
        descriptionapp: "",
        billingtype: "",
        measuredunit: "",
        factor: "",
        priceapp: "",
        descripcionbilling: "",
        url: ""
      }
    };
  }

  componentDidUpdate() {
    if (this.props.code !== this.state.app) {
      this.props.handleAsignApp(0, true);
      this.setState({ app: this.props.code });
      this.handleAppDetails();
    }
  }

  handleAppDetails = () => {
    const code =
      this.state.app !== this.props.code ? this.state.app : this.props.code;
    const subs = this.props.subscription;
    if (code > 0) {
      let promise = getSubsApp(subs, code);
      promise.then(
        result => {
          if (result.success) {
            const data = result.objeto;
            this.setState({
              formDetails: {
                ...this.state.formDetails,
                namesubscription: data.nameSubscription,
                startdate: data.startDate,
                pricetotal: "$" + new Intl.NumberFormat().format(data.priceTotal),
                img: data.img,
                nameapp: data.nameApp,
                descriptionapp: data.descriptionApp,
                billingtype: data.nameBillingType,
                measuredunit: data.nameMeasuredUnit,
                factor: data.factor,
                priceapp: "$" + new Intl.NumberFormat().format(data.cost),
                descripcionbilling: data.descriptionBillingType,
                url: data.url
              }
            });
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  render() {
    const { openModal, handleChangeModal } = this.props;
    const {
      namesubscription,
      startdate,
      pricetotal,
      img,
      nameapp,
      descriptionapp,
      billingtype,
      measuredunit,
      factor,
      priceapp,
      descripcionbilling,
      url
    } = this.state.formDetails;
    return (
      <div>
        <Modal
          isOpen={openModal}
          toggle={handleChangeModal}
          className="modal-lg"
        >
          <div className="modal-header">
            <strong>
              Detalle de datos de la aplicación en su suscripción{" "}
            </strong>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleChangeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <form name="formDetails">
              <FormGroup className="col-md-12">
                <strong>Datos de suscripción</strong>
                <div className="row">
                  <div className="col-md-4">
                    <label>Nombre suscripción</label>
                    <Input
                      type="text"
                      name="namesubscription"
                      value={namesubscription}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Fecha creación</label>
                    <Input
                      type="text"
                      name="startdate"
                      value={startdate}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Valor suscripción</label>
                    <Input
                      type="text"
                      name="pricetotal"
                      disabled={true}
                      value={pricetotal}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="col-md-12">
                <strong>Datos de aplicación</strong>
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src={img}
                      alt="Img"
                      className="img-left thumb128"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Nombre aplicación</label>
                        <Input
                          type="text"
                          name="nameapp"
                          disabled={true}
                          value={nameapp}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>Descripción</label>
                        <Input
                          type="textarea"
                          name="descriptionapp"
                          disabled={true}
                          value={descriptionapp}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <label>Tipo facturación</label>
                    <Input
                      type="text"
                      name="billingtype"
                      disabled={true}
                      value={billingtype}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Tipo de unidad</label>
                    <Input
                      type="text"
                      name="measuredunit"
                      disabled={true}
                      value={measuredunit}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Cantidad</label>
                    <Input
                      type="text"
                      name="factor"
                      disabled={true}
                      value={factor}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Precio por unidad</label>
                    <Input
                      type="text"
                      name="priceapp"
                      disabled={true}
                      value={priceapp}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <label>Descripción del tipo de facturación</label>
                    <Input
                      type="textarea"
                      name="descripcionbilling"
                      disabled={true}
                      value={descripcionbilling}
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Dirección web</label>
                    <Input type="text" name="url" disabled={true} value={url} />
                  </div>
                </div>
              </FormGroup>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
