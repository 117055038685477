import React, { Component } from "react";
import Select from "react-select";
import notify from "devextreme/ui/notify";
import DataGrid, { Column, SearchPanel } from "devextreme-react/data-grid";
import { Button } from "reactstrap";

import {
  getSubscriptionByClient,
  postUserSubscription
} from "../../services/Api/Subscription";
import { getfindbysubscription } from "../../services/Api/Application";
import { COLOUR_WARNING } from "../Message/Message";
import { showValidation } from "../Validation/ShowValidation";

export default class UserDatagridAssignStandar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datagrid: [],
      selectedRowKeys: [],
      dataSubscriptions: [],
      subscription: null,
      dataapps: [],
      app: null,
      email: ""
    };
  }

  componentDidUpdate() {
    if (this.state.email !== this.props.email) {
      this.handleReset();
    }

    if (this.props.isSelect) {
      this.props.handleIsSelectChange();
      this.setState({ subscription: null, app: null });
    }
  }

  handleReset = () => {
    this.setState({ email: this.props.email, subscription: null, app: null });
  };

  componentDidMount() {
    this.handleSubscription();
  }

  ///Cargar suscripciones por empresa.
  handleSubscription = () => {
    let client = this.props.filter;
    let data = getSubscriptionByClient(client);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.response.forEach(element => {
            let obj = {
              label: element.nameSubscription,
              value: element.idSuscription,
              name: "subscription"
            };
            array.push(obj);
          });
          this.setState({ dataSubscriptions: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  ///Consulta de aplicaciones por suscripción.
  handleFilterApps = param => {
    const response = getfindbysubscription(param.value);
    const promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(promise));
    });

    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.objeto.forEach(element => {
            const obj = {
              label: element.nameAplication,
              value: element.idApplication
            };
            array.push(obj);
          });
          this.setState({ dataapps: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  };

  handleUploadApp = param => {
    let exist = false;
    let array = [];
    let array2 = this.state.datagrid;
    const subscription = this.state.subscription;
    ///Objeto que se cargara en el grid.
    let obj = {
      apps: { idApp: param.value },
      email: this.props.email,
      nameApp: param.label,
      nameSubs: subscription.label,
      subscriptionid: subscription.value
    };

    ///Busca si el usuario que ingreso ya se encuentra en la grid donde estan los datos que se asociaran.
    array2.forEach(element => {
      if (
        element.apps.idApp === param.value &&
        element.email === this.props.email
      ) {
        exist = true;
      }
    });

    ///Valida si el usuario ya esta asignado en el proceso de asignación.
    if (!exist) {
      array2.forEach(element => {
        array.push(element);
      });

      array.push(obj);
      this.setState({ datagrid: array });
    } else {
      notify(
        "Esta aplicación con esta suscripción ya se encuentra en el proceso de asociarse con" +
        "el usuario filtrado.",
        COLOUR_WARNING,
        5000
      );
    }
  };

  ///Cambio de estado de los select
  handleChangeSelect = param => {
    if (this.props.user === "") {
      notify(
        "Primero busque al usuario al que desea asignar las aplicaciones de la suscripción.",
        COLOUR_WARNING,
        5000
      );
    } else {
      if (param.name === "subscription") {
        this.setState({ subscription: { label: param.label, value: param.value } });
        this.handleFilterApps(param);
      } else {
        this.setState({ app: { label: param.label, value: param.value } });
        this.handleUploadApp(param);
      }
    }
  };

  ///Eliminar asignación del grid.
  handleDeleteElement = event => {
    let row = event.row.data;
    let data = this.state.datagrid;
    let array = [];
    let index = null;
    data.forEach((element, i) => {
      if (element.apps.idApp === row.apps.idApp) {
        index = i;
      }
    });

    if (index !== null) {
      data.splice(index, 1);
    }

    data.forEach(element => {
      array.push(element);
    });

    this.setState({ datagrid: array });
  };

  ///Asignar usuarios a suscripción con aplicaciones.
  handleAssignUser = () => {
    let subs = this.state.datagrid;
    let data = postUserSubscription(subs);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          this.props.handleChangeGrid();
          this.props.handleShowModal();
        }
        showValidation(result);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  render() {
    const {
      datagrid,
      selectedRowKeys,
      dataSubscriptions,
      subscription,
      dataapps,
      app
    } = this.state;
    return (
      <>
        <div>
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <Select
                name="subscription"
                placeholder="Suscripciones por empresa"
                options={dataSubscriptions}
                value={subscription}
                onChange={this.handleChangeSelect}
              />
            </div>
            <div className="col-md-6">
              <Select
                name="app"
                placeholder="Aplicaciones por suscripción"
                options={dataapps}
                value={app}
                onChange={this.handleChangeSelect}
              />
            </div>
          </div>
          <br />
          <DataGrid
            id={"grid-container"}
            dataSource={datagrid}
            keyExpr={"apps.idApp"}
            ref={ref => (this.dataGrid = ref)}
            selectedRowKeys={selectedRowKeys}
            showBorders={true}
          >
            <SearchPanel
              visible={true}
              width={360}
              placeholder={"Filtrar suscripción."}
            />
            <Column
              dataField={"email"}
              caption={"Usuario"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"nameSubs"}
              caption={"Suscripción Asignada"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              dataField={"nameApp"}
              caption={"Aplicación Asignada"}
              headerFilter={{ allowSearch: true }}
            />
            <Column
              caption={"Eliminar"}
              type={"buttons"}
              dataFiled={"apps"}
              width={50}
              buttons={[
                {
                  hint: "Eliminar",
                  icon: "fas fa-trash-alt",
                  onClick: this.handleDeleteElement
                }
              ]}
            />
          </DataGrid>
        </div>
        <div>
          {datagrid.length === 0 ? null : (
            <Button
              type="button"
              className="bg-primary"
              style={{ float: "right" }}
              onClick={this.handleAssignUser}
            >
              Asignar Usuario
            </Button>
          )}
        </div>
      </>
    );
  }
}
