import MethodPost from "../../MethodPost";
import MethodGet from "../../../services/MethodGet";
import {
  getAllIcon,
  postavatar,
  putavataruser,
  geticonuser
} from "../../../components/RouteApi/RouteApi";
import { headerPost, headerPut, headerGet } from "../../Headers";
import { _JsonStorage } from '../../../Global';

///Consultar lista de iconos
export const listIcon = () => {
  let header = headerGet(_JsonStorage.access_token);
  let response = MethodGet(getAllIcon, header)
    .then(response => response)
    .catch(error => error);

  return new Promise((rest, rej) => {
    rest(response);
    rej(Error(response));
  });
};

export const loadAvatarUser = param => {
  let urlBase = geticonuser,
    params = {
      value: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet(_JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const sendAvatarUser = param => {
  let header = headerPost(param, _JsonStorage.access_token);
  return MethodPost(postavatar, header)
    .then(response => response)
    .catch(error => error);
};

export const putAvatarUser = param => {
  let header = headerPut(param, _JsonStorage.access_token);
  return MethodPost(putavataruser, header)
    .then(response => response)
    .catch(error => error);
};
