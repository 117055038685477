import MethodGet from "../MethodGet";
import MethodDelete from "../MethodDelete";
import {
  applicationfinbypackage,
  removeapplicationpackage,
  postapplicationpackage,
  getapppackages
} from "../../components/RouteApi/RouteApi";
import { headerDelete, headerPostJson, headerGet } from "../Headers";
import MethodPost from "../MethodPost";
import { _JsonStorage } from '../../Global';

export const getapplicationpackage = (param1, param2)=> {
  let urlBase = applicationfinbypackage,
    params = {
      package: param1,
      subs: param2
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const deletegetapplicationpackage = (application, packagecode) => {
  let urlBase = removeapplicationpackage,
    params = {
      application: application,
      package: packagecode
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

    let header = headerDelete( _JsonStorage.access_token);

  return MethodDelete(url, header)
    .then(response => response)
    .catch(error => error);
};

export const postaddapplication = (applications, packages) => {
  let obj = {
    IdApplication: [applications],
    IdPackage: [packages]
  };
  let header = headerPostJson(obj, _JsonStorage.access_token);
  return MethodPost(postapplicationpackage, header)
    .then(response => response)
    .catch(error => error);
};

export const getapppackage = () => {
  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(getapppackages, header)
    .then(response => response)
    .catch(error => error);
};
