/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';
import ContextProvider from "./components/Context/Context";

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';

const spinner = document.getElementById('spinner');
if (spinner && !spinner.hasAttribute('hidden')) {
    spinner.setAttribute('hidden', 'true');
}

class App extends Component {
    render() {
        // specify base href from env variable 'WP_BASE_HREF'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global WP_BASE_HREF */
        //production - development
        const basename = process.env.NODE_ENV.trim() === 'production' ? '/' : (WP_BASE_HREF || '/')
        return (
            <BrowserRouter basename={basename}>
                <ContextProvider />
            </BrowserRouter>
        );

    }
}

export default App;
