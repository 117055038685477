import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import Select from "react-select";
import notify from "devextreme/ui/notify";

///Componentes
import FormValidator from "../Validation/FormValidation";
import { getapplication } from "../../services/Api/Application";
import { carouselPost, carouselGet } from "../../services/Api/Carousel";
import { COLOUR_WARNING } from "../Message/Message";

import "./docs/Carousel.css";
import { showValidation } from "../Validation/ShowValidation";
import { ExecuteFn } from '../../Global';
import SpinnerAnimate from '../Progress/SpinnerAnimate'

class CarouselForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayApp: [],
      pictureDefault: [],
      formCarousel: {
        app: null,
        textHeader: "",
        order: 1,
        description: "",
        picture: null
      },
      isSpinner: false
    };
  }

  ///Despues de mostar el componente
  componentDidMount() {
    ExecuteFn(this.handleLoadApp);
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Validar datos antes de guardar
  handleOnSubmit = e => {
    e.preventDefault();
    const formu = this.state.formCarousel;
    if (formu.picture === null || formu.app == null) {
      notify(
        "Recuerde seleccionar una aplicación y la imagen.",
        COLOUR_WARNING,
        3000
      );
    } else {
      const form = e.target;
      const inputs = [...form.elements].filter(i =>
        ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
      );

      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      this.setState({
        [form.name]: {
          ...this.state[form.name],
          errors
        }
      });

      if (!hasError) {
        this.handleSubmit();
      }
    }
  };

  ///Enviar datos al servicio
  handleSubmit = () => {
    this.handleSpinner();
    const data = this.state.formCarousel;
    const promise = carouselPost(data);
    promise.then(
      response => {
        if (response.success) {
          this.handleResetForm();
          this.props.handleIsFilter(true, data.app);
        }
        showValidation(response);
        this.handleSpinner();
      },
      function(error) {
        console.log(error);
      }
    );
  };
 
  handleResetForm = () => {
    this.props.handleUploadImg("img/img-default-view.jpg");
    this.setState({
      pictureDefault: [],
      formCarousel: {
        ...this.state.formCarousel,
        app: null,
        textHeader: "",
        order: 1,
        description: "",
        picture: null
      }
    });
    this.props.handleUploadHeader('');
    this.props.handleUploadDescription('');
  }; 

  ///Cargar lista de aplciaciones
  handleLoadApp = () => {
    const response = getapplication();
    const promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [
            { label: "General", value: "c031e600-0700-4b00-aa00-5c9eda692a00" }
          ];
          result.objeto.forEach(element => {
            const obj = {
              label: element.nameAplication,
              value: element.codeApp
            };
            array.push(obj);
          });
          this.setState({ arrayApp: array });
          this.props.handleLoadApps(array);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Cargar datos de la imagen seleccionada
  handleChangeImg = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      if (file.type.substring(0, 5) !== "image") {
        notify(
          "El archivo seleccionado debe ser una imagen valida.",
          COLOUR_WARNING,
          3000
        );
      } else {
        reader.onloadend = () => {
          this.props.handleUploadImg(reader.result);
          this.setState({
            pictureDefault: reader.result,
            formCarousel: {
              ...this.state.formCarousel,
              picture: file
            }
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }; 

  ///Cambiar de estados los campos
  handleChangeInput = e => {
    const input = e.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });

    ///Validar si el input que se esta modificando es el encabezado que se mostrara en la imagen
    if (input.name === "textHeader") {
      this.props.handleUploadHeader(value);
    }

    ///Validar si el input que se esta modificando es la descripción que se mostrara en la imagen
    if (input.name === "description") {
      this.props.handleUploadDescription(value);
    }
  };

  ///Cambiar de estado los select
  handleChangeSelect = value => {
    this.setState({
      formCarousel: {
        ...this.state.formCarousel,
        app: value
      }
    });

    ///Consultar imagenes del carousel que pertenece a x aplicación seleccionada
    const promise = carouselGet(value.value);
    promise.then(
      response => {
        if (response.success) {
          const array = [];
          response.response.forEach(element => {
            const obj = {
              src: element.route === null? '': element.route,
              altText: element.header === null? '': element.header,
              caption: element.description === null? '': element.description,
              codeCarousel: element.codeCarousel
            }
            array.push(obj);
          });
          this.props.handleCaruselPreview(array);
          this.props.handleChangeCarousel();
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Validar valores ingresados a los campos
  handleHasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    const { arrayApp, isSpinner } = this.state;
    const { app, textHeader, order, description } = this.state.formCarousel;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Card className="card-default">
              <CardHeader>
                <strong>Formulario de configuración de carousel</strong>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleOnSubmit} name="formCarousel" 
                  autoComplete="off">
                  <SpinnerAnimate isDisabled={isSpinner} />
                  <div className="form-row align-items-center col-md-12">
                    <FormGroup className="col-md-6">
                      <label>Aplicación</label>
                      <Select
                        name="app"
                        placeholder="-SELECCIONE UNA OPCIÓN-"
                        value={app}
                        options={arrayApp}
                        onChange={this.handleChangeSelect}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label>Orden</label>
                      <Input
                        type="number"
                        name="order"
                        min={1}
                        value={order}
                        onChange={this.handleChangeInput}
                        invalid={this.handleHasError(
                          "formCarousel",
                          "order",
                          "required"
                        )}
                        data-validate='["required"]'
                      />
                      <span className="invalid-feedback">
                        El campo es requerido.
                      </span>
                    </FormGroup>
                  </div>
                  <div className="form-row align-items-center col-md-12">
                    <FormGroup className="col-md-6">
                      <label>Imagen</label>
                      <Input
                        type="file"
                        name="picture"
                        onChange={this.handleChangeImg}
                      />
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <label>Encabezado</label>
                      <Input
                        type="text"
                        name="textHeader"
                        placeholder="Encabezado de la imagen (Opcional)"
                        maxLength={94}
                        value={textHeader}
                        onChange={this.handleChangeInput}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row align-items-center col-md-12">
                    <FormGroup className="col-md-12">
                      <label>Descripción</label>
                      <Input
                        type="textarea"
                        name="description"
                        placeholder="Descripción de la imagen (Opcional)"
                        maxLength={94}
                        value={description}
                        onChange={this.handleChangeInput}
                      />
                    </FormGroup>
                  </div>
                  <div className="form-row align-items-center col-md-12">
                    <FormGroup className="col-md-6 d-flex">
                      <Button
                        type="button"
                        className="bg-secundary ml-auto btn-block"
                        onClick={this.handleResetForm}
                      >
                        Cancelar
                      </Button>
                    </FormGroup>
                    <FormGroup className="col-md-6 d-flex">
                      <Button
                        type="submit"
                        className="bg-primary ml-auto btn-block"
                      >
                        Agregar
                      </Button>
                    </FormGroup>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces("translations")(CarouselForm);
