import React, { Component } from "react";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";

import { COLOUR_WARNING, COLOUR_SUCCESS, MESSAGE_REMOVE_DIFFERENT } from "../Message/Message";
import { getpackage, removePackage } from "../../services/Api/Package";
import ModalPackageCreate from "./ModalPackageCreate";
import SwalRemove from "../SweetAlert/SwalRemove";
import { showValidation } from "../Validation/ShowValidation";
import { Time900, ExecuteFn } from "../../Global";

import notify from "devextreme/ui/notify";

export default class PackageFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayPackage: [],
      isModal: false,
      isEdit: false,
      isChangeValue: { label: "", value: 0 },
      isOpenRemove: false,
      nameRemove: "",
      idRemove: 0,
      valueSelect: null,
      description: ""
    };
  }

  componentDidMount() {
    ExecuteFn(this.handleDropDownPackage);
  }

  handleDropDownPackage = () => {
    let data = getpackage();
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          let array = [];
          result.data.forEach(element => {
            let obj = {
              label: element.namePackage,
              value: element.idPackage,
              description: element.description
            };
            array.push(obj);
          });
          this.setState({ arrayPackage: array });
        }
      },
      function (error) {
        console.log(error);
      }
    );
  }

  handleChangeModal = () => {
    this.setState({ isModal: !this.state.isModal, isEdit: false });
  };

  handleEditModal = () => {
    if (this.state.isChangeValue.value !== 0) {
      this.setState({ isModal: !this.state.isModal, isEdit: true });
    } else {
      notify("Primero seleccione un paquete.", COLOUR_WARNING, 5000);
    }
  };

  handleChangeSelect = event => {
    this.props.handleChangeFilter(event);
    this.setState({
      isChangeValue: event,
      valueSelect: event,
      description: event.description
    });
  };

  handleChangeValueSelect = () => {
    this.handleDropDownPackage();
  };

  handleRemoveChange = () => {
    if (this.state.isChangeValue.value > 0) {
      this.setState({
        isOpenRemove: !this.state.isOpenRemove,
        nameRemove: this.state.isChangeValue.label,
        idRemove: this.state.isChangeValue.value
      });
    } else {
      notify("Primero seleccione un paquete.", COLOUR_WARNING, 5000);
    }
  };

  handleRemove = (user, system, identifier) => {
    if (system !== user) {
      notify(MESSAGE_REMOVE_DIFFERENT, COLOUR_WARNING, Time900);
    } else {
      let data = removePackage(identifier);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        response => {
          if (response.success) {
            showValidation(0, 0, "", response.message, COLOUR_SUCCESS)
            this.handleDropDownPackage();
          } else if (response.success == false) {
            showValidation(0, 0, "", response.message, COLOUR_WARNING)
          } else {
            showValidation(0, 0, "", "Se presento un falló en la conexión, intente nuevamente" +
              "Si el problema persiste comunique con un administrador", COLOUR_WARNING)
          }

          this.setState({
            isOpenRemove: false,
            nameRemove: "",
            idRemove: 0,
            valueSelect: null,
            description: ""
          });
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  handleChangeInputEdit = param => {
    this.setState({
      valueSelect: { label: param.label, value: this.state.valueSelect.value },
      description: param.description
    });
  };

  render() {
    const {
      arrayPackage,
      isModal,
      isEdit,
      isChangeValue,
      isOpenRemove,
      nameRemove,
      idRemove,
      valueSelect,
      description
    } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>
            <h4>Filtro de paquetes</h4>
          </CardHeader>
          <CardBody>
            <div className="form-row align-items-center">
              <div className="col-md-12">
                <InputGroup className="mb-2">
                  <Select
                    className="col-9 padding-0"
                    placeholder="Lista de paquetes"
                    options={arrayPackage}
                    value={valueSelect}
                    onChange={this.handleChangeSelect}
                  />
                  <InputGroupAddon addonType="prepend">
                    <Button
                      className="btn bg-danger minus-square"
                      title="Eliminar"
                      onClick={this.handleRemoveChange}
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                    <Button
                      className="btn bg-primary minus-square"
                      title="Agregar"
                      onClick={this.handleChangeModal}
                    >
                      <i className="fas fa-plus-square" />
                    </Button>
                    <Button
                      className="btn bg-success minus-square"
                      title="Editar"
                      onClick={this.handleEditModal}
                    >
                      <i className="fas fa-pen" />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <div>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <ModalPackageCreate
          isOpenModal={isModal}
          isEdit={isEdit}
          fOpenModal={this.handleChangeModal}
          valueChange={isChangeValue}
          isChangeSelect={this.handleChangeValueSelect}
          handleChangeInputEdit={this.handleChangeInputEdit}
        />
        <SwalRemove
          openModal={isOpenRemove}
          swalShow={this.handleRemoveChange}
          element={nameRemove}
          elementRemove={idRemove}
          handleRemove={this.handleRemove}
        />
      </div>
    );
  }
}
