import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

///Componentes.
import PackageCreate from "./PackageCreate";
import PackageDatagridSimple from "./PackageDatagridSimple";

class ModalPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: false
    };
  }
 
  handleIsUpdate = state => this.setState({ isUpdate: state });

  render() {
    const { isUpdate } = this.state;
    const {
      openModal,
      showPackage,
      cardIdentifier,
      isinherited
    } = this.props;
    return (
      <>
        <Modal className="modal-lg" isOpen={openModal} toggle={showPackage}>
          <div className="modal-header">
            ASIGNACIÓN DE PAQUETE DE LA APLICACIÓN &nbsp;
            {/* {objapp !== null ? objapp.nameapp.toUpperCase() : " "} */}
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={showPackage}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            {/*Componente para crear paquetes. */}
            <PackageCreate
              showPackage={showPackage}
              isEdit={false}
              handleIsUpdate={this.handleIsUpdate}
              isinherited={isinherited}
              isChangeSelect={()=>console.log('')}
            />
            {/*Componente lista de paquetes. */}
            <PackageDatagridSimple
              showPackage={showPackage}
              cardIdentifier={cardIdentifier}
              isUpdate={isUpdate}
              handleIsUpdate={this.handleIsUpdate}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ModalPackage;
