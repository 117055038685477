import React, { Component } from "react";
import { Card, CardBody, CardFooter, Button } from "reactstrap";
import DataGrid, {
  Column,
  SearchPanel,
  Selection
} from "devextreme-react/data-grid";

import { getpackage, getpackagecheck } from "../../services/Api/Package";
import { showValidation } from "../Validation/ShowValidation";

import MethodPost from "../../services/MethodPost";
import { headerPostJson } from "../../services/Headers";
import { postapplicationpackage } from "../RouteApi/RouteApi";
import { _JsonStorage } from "../../Global";

import ProgressAnimate from "../Progress/ProgressAnimate";

class PackageDatagridSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPackage: [{ Package: { idPackage: 0 }, namePackage: "" }],
      selectedRowKeys: [],
      packageCheck: [],
      didMount: false,
      isProgress: false
    };
  }

  componentDidMount() {
    this.handleDataPackage();
    this.handleFetchCheckDefault();
  }

  componentDidUpdate() {
    this.handleChangeDataGrid(this.props.isUpdate);
  }

  ///Cargar progress
  handleProgress = () =>
    this.setState({
      isProgress: !this.state.isProgress
    });

  ///Cargar lista de paquetes.
  handleDataPackage = () => {
    this.props.handleIsUpdate(false);
    this.handleProgress();
    var data = getpackage();
    var promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });

    promise.then(
      result => {
        if (result.data.length > 0) {
          this.state.dataPackage.shift();
          let array = [];
          result.data.forEach(element => {
            const obj = { Package: element, namePackage: element.namePackage };
            array.push(obj);
          });
          this.setState({ dataPackage: array });
        }
        this.handleProgress();
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Asignar lista de item selecionados al state.
  onSelectionChanged = ({ selectedRowKeys }) => {
    this.setState({ selectedRowKeys });

    if (!this.state.didMount) {
      this.onFetchCheck();
    } else {
      this.setState({ didMount: false });
    }
  };

  ///Enviar data.
  onFetchCheck = () => {
    this.handleProgress();
    if (!this.state.didMount) {
      let obj = {
        IdApplication: [this.props.cardIdentifier],
        IdPackage: this.state.selectedRowKeys
      };

      let header = headerPostJson(obj, _JsonStorage.access_token);
      MethodPost(postapplicationpackage, header)
        .then(response => {
          this.handleProgress();
          showValidation(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  ///Chequear paquetes asignados.
  handleFetchCheckDefault = () => {
    
    let identifierCard = this.props.cardIdentifier;
    let data = getpackagecheck(identifierCard);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });

    promise.then(
      result => {
        if (result.success) {
          this.setState({
            selectedRowKeys: result.data,
            didMount: result.data.length > 0 ? true : false
          });
        }
        
      },
      function(error) {
        console.log(error);
      }
    );
  };

  ///Actualizar grid cuando se relice un registro.
  handleChangeDataGrid = isUpdate => {
    if (isUpdate) {
      this.handleDataPackage();
    }
  };

  render() {
    const { showPackage } = this.props;
    const { dataPackage, selectedRowKeys, isProgress } = this.state;
    return (
      <div>
        <Card>
          <CardBody>
            <DataGrid
              id={"grid-container"}
              dataSource={dataPackage}
              keyExpr={"Package.idPackage"}
              onSelectionChanged={this.onSelectionChanged}
              ref={ref => (this.dataGrid = ref)}
              selectedRowKeys={selectedRowKeys}
              showBorders={true}
            >
              <SearchPanel
                visible={true}
                width={240}
                placeholder={"Buscar..."}
              />
              <Selection mode={"multiple"} deferred={false} />
              <Column
                dataField={"namePackage"}
                caption={"Nombre paquete"}
                headerFilter={{ allowSearch: true }}
              />
            </DataGrid>
          </CardBody>
          <ProgressAnimate isProgress={isProgress} />
          <CardFooter>
            <Button color="secondary" onClick={showPackage}>
              Cerrar
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default PackageDatagridSimple;
