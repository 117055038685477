import React, { useState, useContext, useEffect, Fragment } from 'react'

import { ProfileContext } from "./docs/Context";
import { showValidation } from "../Validation/ShowValidation";
import { Card, CardHeader, CardBody, FormGroup, Input, Button } from "reactstrap";
import { ChangeContactAsync, SendVerificationAsync } from "../../services/Api/User";


import FormValidator from "../Validation/FormValidation";
import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

function FormContact() {
  const context = useContext(ProfileContext)
  const [isSpinner, setIsSpinner] = useState(false)
  const [formData, setFormData] = useState({
    emailOld: '',
    email: '',
    emailDisabled: true,

    phoneOld: '',
    phone: '',
    phoneDisabled: true,

    errors: {}
  })

  useEffect(() => {
    mapContact()
  }, [context.data.email, context.data.phone])

  const mapContact = () => {
    let userInfo = context.data;
    if (userInfo.email !== "") {
      setFormData(state => ({
        ...state,
        emailOld: userInfo.email,
        email: userInfo.email
      }))
    }

    if (userInfo.phone !== "") {
      setFormData(state => ({
        ...state,
        phoneOld: userInfo.phone,
        phone: userInfo.phone
      }))
    }
  }

  const hasError = (inputName, method) => {
    return (
      formData.errors &&
      formData.errors[inputName] &&
      formData.errors[inputName][method]
    );
  };

  const onChangeInput = event => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    setFormData(state => ({
      ...state,
      [input.name]: value,
      errors: {
        ...state.errors,
        [input.name]: result
      }
    }));
  };

  const changeControlEmail = () => {
    setFormData(state => ({
      ...state,
      emailDisabled: !formData.emailDisabled
    }))
  }

  const cancelControlEmail = () => {
    changeControlEmail()
    setFormData(state => ({
      ...state,
      email: formData.emailOld
    }))
  }

  const changeControlPhone = () => {
    setFormData(state => ({
      ...state,
      phoneDisabled: !formData.phoneDisabled
    }))
  }

  const cancelControlPhone = () => {
    changeControlPhone()
    setFormData(state => ({
      ...state,
      phone: formData.phoneOld
    }))
  }

  const changeContact = () => {
    setIsSpinner(true)
    ChangeContactAsync({
      email: formData.email,
      phone: formData.phone,
      codeUser: ''
    })
      .then(resp => {
        if (resp.isSuccess) {
          setFormData(state => ({
            ...state,
            emailOld: formData.email,
            emailDisabled: true,

            phoneOld: formData.phone,
            phoneDisabled: true,

            errors: {}
          }));

          showValidation(0, 0, '', resp.message, 'success')
        } else {
          showValidation(0, 0, '', resp.message, 'warning')
        }

        setIsSpinner(false)
      })
      .catch(err => {
        setIsSpinner(false)
        showValidation(0, 0, '', 'Se presento un falló de conexión. Intente nuevamente, si el problema persiste actualice su navegador e intente nuevamente', 'warning')
      })
  }

  const onSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    setFormData(state => ({
      ...state,
      errors: errors
    }))

    if (!hasError) {
      changeContact()
    }
  }

  const sendVerification = async () => {
    setIsSpinner(true)
    await SendVerificationAsync()
      .then(resp => {
        if (resp.isSuccess) {
          showValidation(0, 0, '', resp.message, 'success')
        } else {
          showValidation(0, 0, '', resp.message, 'warning')
        }
        setIsSpinner(false)
      })
      .catch(err => {
        setIsSpinner(false)
        showValidation(0, 0, '', 'Se presento un falló de conexión. Por favor recargue su navegador, verifique su conexión a internet e intente nuevamente', 'warning')
      });
  }

  return (
    <Card className="card-default">
      <SpinnerDisableContent isSpinnerDisabled={isSpinner}>
        <CardHeader>
          <legend>
            Información de contacto
            <div className="card-tool float-right">
              <button type="button" className="btn bg-primary-dark btn-sm" title="Solicitar verificación de información de contacto" onClick={() => sendVerification()}>
                <i className="fa fa-paper-plane fa-lg"></i>
              </button>
            </div>
          </legend>
        </CardHeader>
        <CardBody>
          <form style={{ backgroundColor: "#FFFFFF" }} autoComplete="off" onSubmit={(e) => onSubmit(e)}>
            <FormGroup className="row">
              <div className="col-md-12">
                <label className="col-md-12 text-left col-form-label">
                  Correo electrónico
                </label>
                <div className="col-md-12">
                  <div className="input-group">
                    <Input
                      type="text"
                      name="email"
                      placeholder="Correo electrónico de acceso"
                      value={formData.email}
                      disabled={formData.emailDisabled}
                      onChange={(e) => onChangeInput(e)}
                      invalid={
                        hasError("email", "required") ||
                        hasError("email", "email")
                      }
                      data-validate='["required","email"]'
                    />
                    <div className="input-group-append">
                      {formData.emailDisabled && (
                        <Button type="button" className="btn-labeled btn bg-warning" onClick={() => changeControlEmail()}>
                          <span className="btn-label">
                            <i className="fa fa-sync-alt" />
                          </span>
                          Cambiar
                        </Button>
                      )}
                      {!formData.emailDisabled && (
                        <Fragment>
                          <Button type="submit" className="btn-labeled btn bg-success">
                            <span className="btn-label">
                              <i className="fa fa-check" />
                            </span>
                            Guardar
                          </Button>
                          <Button type="button" className="btn-labeled btn bg-secondary" onClick={() => cancelControlEmail()}>
                            <span className="btn-label">
                              <i className="fa fa-times" />
                            </span>
                            Cancelar
                          </Button>
                        </Fragment>
                      )}
                    </div>
                    {hasError("email", "email") && (
                      <span className="invalid-feedback">
                        El valor ingresado no corresponde a un correo electrónico valido
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="row">
              <div className="col-md-12">
                <label className="col-md-12 text-left col-form-label">
                  Teléfono
                </label>
                <div className="col-md-12">
                  <div className="input-group">
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Número de teléfono"
                      value={formData.phone}
                      disabled={formData.phoneDisabled}
                      onChange={(e) => onChangeInput(e)}
                      invalid={
                        hasError("phone", "required") ||
                        hasError("phone", "phone")
                      }
                      data-validate='["required","phone"]'
                    />
                    <div className="input-group-append">
                      {formData.phoneDisabled && (
                        <Button type="button" className="btn-labeled btn bg-warning" onClick={() => changeControlPhone()}>
                          <span className="btn-label">
                            <i className="fa fa-sync-alt" />
                          </span>
                          Cambiar
                        </Button>
                      )}
                      {!formData.phoneDisabled && (
                        <Fragment>
                          <Button type="submit" className="btn-labeled btn bg-success">
                            <span className="btn-label">
                              <i className="fa fa-check" />
                            </span>
                            Guardar
                          </Button>
                          <Button type="button" className="btn-labeled btn bg-secondary" onClick={() => cancelControlPhone()}>
                            <span className="btn-label">
                              <i className="fa fa-times" />
                            </span>
                            Cancelar
                          </Button>
                        </Fragment>
                      )}
                    </div>
                    {hasError("phone", "phone") && (
                      <span className="invalid-feedback">
                        El valor ingresado no corresponde a un numero de teléfono valido.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </FormGroup>
          </form>
        </CardBody>
      </SpinnerDisableContent>
    </Card>
  )
}

export default FormContact;