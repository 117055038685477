import MethodGet from '../MethodGet';
import { listCountries } from '../../components/RouteApi/RouteApi';
import { headerGet } from '../Headers';
import { _JsonStorage } from '../../Global';

export const countriesget = () =>{
    let header = headerGet( _JsonStorage.access_token);
    const response = MethodGet(listCountries, header).then(response=>response).catch(error=>error);
    const promise = new Promise((rest,rej)=>{
        rest(response);
        rej(Error(response));
    }); 
    return promise;
}