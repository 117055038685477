import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

import "./docs/Notification.css";

const NotFound = () => (
  <div className="padding-top40">
    <img className="padding-left45" src="img/img_message.png" alt="Icon" />
    <h4 className="padding-left23">Seleccionar un elemento para leerlo</h4>
  </div>
);

class NotificationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identitier: 0,
      displayName: "",
      subject: "",
      processName: "",
      message: "",
      linkButton: "",
      from: "",
      date: ""
    };
  }

  componentWillUpdate() {
    this.handleUpdateMessage();
  }

  ///Cargar el mensaje en la vista.
  handleUpdateMessage = () => {
    const x = this.props.message;
    if (x !== null) {
      if (this.state.identitier !== x.identitier && x.identitier > 0) {
        this.setState({
          identitier: x.identitier,
          displayName: x.abbreviation,
          subject: x.subject,
          processName: x.processName,
          message: x.message,
          linkButton: x.link,
          from: x.from,
          date: x.date
        });
      }
    }
  };

  render() {
    const {
      identitier,
      subject,
      from,
      date,
      processName,
      message,
      linkButton
    } = this.state;
    return (
      <div className="height-100">
        {identitier === 0 ? (
          <NotFound />
        ) : (
          <div className="height-100">
            <div className="col-md-12">
              <strong>Asunto: </strong> {subject}
            </div>
            <div className="col-md-12">
              <strong>De: </strong> {from}
            </div>
            <div className="col-md-12">{date}</div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>{processName}</h4>
                </div>
                <div className="card-body">
                  <p>{message}</p>
                  {linkButton !== "" ? (
                    <a className="btn btn-primary" href={linkButton} target="_blank" rel="noopener noreferrer" >
                      Link
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withNamespaces("translations")(NotificationMessage);
