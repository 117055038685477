import React, { Component } from "react";
import { InputGroup, Input, Button, Card, CardBody } from "reactstrap";

import FormValidator from "../Validation/FormValidation";
import MethodPost from "../../services/MethodPost";
import { showValidation } from "../Validation/ShowValidation";
import { editpackaged } from "../../services/Api/Package";
import { COLOUR_WARNING } from "../Message/Message";
import { postpackage, updatepackage } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { _JsonStorage } from '../../Global';

import notify from "devextreme/ui/notify";
import SpinnerAnimate from '../Progress/SpinnerAnimate';

import "./docs/Package.css";

class PackageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isUpdate: false,
      formPackage: {
        idPackage: 0,
        namePackage: "",
        typePackage: null,
        description: ""
      },
      isSpinner: false
    };
  };

  componentDidMount() {
    this.handlePackageEdit = this.handlePackageEdit();
  };

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Cambio de estado del state y valida los campos al instatnte.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Cambio de estado de los select..
  handleChangeSelect = event => {
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Validar campos al hacer submit.
  onSubmit = event => {
    event.preventDefault();
    const form = event.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT", "TEXTAREA", "COLOR"].includes(i.nodeName)
    );
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState({ [form.name]: { ...this.state[form.name], errors } });

    if (!hasError) {
      this.handleSubmit();
    }
  };

  handleChangeInputProps = () =>{
    let obj = {
      label: this.state.formPackage.namePackage,
      description: this.state.formPackage.description
    }
    this.props.handleChangeInputEdit(obj);
  };

  ///Enviar datos al servicio.
  handleSubmit = () => {
    this.handleSpinner();
    let route = null;
    let header = null;
    const dataFetch = new FormData();
    dataFetch.append("namepackage", this.state.formPackage.namePackage);
    dataFetch.append("description", this.state.formPackage.description);

    if (!this.props.isEdit) {
      route = postpackage;
      header = headerPost(dataFetch, _JsonStorage.access_token);
    } else {
      dataFetch.append("idPackage", this.state.formPackage.idPackage);
      route = updatepackage;
      header = headerPut(dataFetch, _JsonStorage.access_token);
    }

    MethodPost(route, header)
      .then(response => {
        this.handleSpinner();
        if(response.success){
          if (!this.props.isEdit) {
            showValidation(response, 1,'paquete');
            this.handleEmptyField();
            this.setState({ isUpdate: !this.state.isUpdate });
            this.props.isChangeSelect();
            this.props.handleIsUpdate(true);
            this.props.fOpenModal();
          } else {
            showValidation(response, 2,'paquete');
            this.props.fOpenModal();
            this.props.isChangeSelect();
            this.handleChangeInputProps();
          }
        }else{
          showValidation(response);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  ///Limpiar campos.
  handleEmptyField = () => {
    this.setState({
      formPackage: {
        namePackage: "",
        typePackage: null,
        description: ""
      }
    });
  };

  ///Agregar datos de paquete al modal.
  handlePackageEdit = () => {
    if (this.props.isEdit) {
      let valueSelect = this.props.valueChange;
      if (valueSelect.value > 0) {
        let data = editpackaged(valueSelect.value);
        let promise = new Promise((rest, rej) => {
          rest(data);
          rej(Error(data));
        });
        promise.then(
          result => {
            if (result.success) {
              this.setState({
                formPackage: {
                  idPackage: result.response.idPackage,
                  namePackage: result.response.namePackage,
                  typePackage: {
                    label: result.response.namePackageType,
                    value: result.response.idPackageType
                  },
                  description: result.response.description
                }
              });
            }
          },
          function(error) {
            console.log(error);
          }
        );
      } else {
        notify("Primero seleccione un paquete.", COLOUR_WARNING, 5000);
      }
    }
  };

  render() {
    const { namePackage, description } = this.state.formPackage;
    const { isinherited } = this.props;
    const { isSpinner } = this.state;
    return (
      <Card>
        <form name="formPackage" onSubmit={this.onSubmit} autoComplete="off" className="parent">
          <SpinnerAnimate isDisabled={isSpinner} />
          <CardBody>
            {/* Campos alineados nombre y tipo. */}
            <div className="form-row align-items-center">
              <div className="col-10">
                <InputGroup className="mb-2">
                  <Input
                    name="namePackage"
                    placeholder="Nombre del paquete"
                    invalid={
                      this.hasError("formPackage", "namePackage", "required") ||
                      this.hasError(
                        "formPackage",
                        "namePackage",
                        "alphanumspace"
                      )
                    }
                    data-validate='["required","alphanumspace"]'
                    value={namePackage}
                    onChange={this.validateOnChange}
                  />
                  {this.hasError(
                    "formPackage",
                    "namePackage",
                    "alphanumspace"
                  ) && (
                    <span className="invalid-feedback">
                      El campo debe ser un nombre valido.
                    </span>
                  )}
                </InputGroup>
              </div>
              <div className="col-2">
                <InputGroup className="mb-2">
                  {isinherited === true ? (
                    <Button className="bg-primary btn-right btn-block">
                      Crear
                    </Button>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Guardar
                      </button>
                    </>
                  )}
                </InputGroup>
              </div>
            </div>

            {/* Descripción. */}
            <div className="form-row align-items-center">
              <div className="col-12">
                <Input
                  type="textarea"
                  name="description"
                  placeholder="Descripción del paquete. 
                    Ejemplo: Este paquete tendra el grupo de aplicaciones que seran gratuitas para el usuario."
                  invalid={this.hasError(
                    "formPackage",
                    "description",
                    "required"
                  )}
                  data-validate='["required"]'
                  value={description}
                  onChange={this.validateOnChange}
                />
              </div>
            </div>
            <div />
          </CardBody>
        </form>
      </Card>
    );
  }
}

export default PackageCreate;
