import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

import FormBillingCycle from "./FormBillingCycle";
import DataGridBillingCycle from "./DatagridBillingCycle";

export default class ModalBillingCycle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChange: false, 
      isEdit: false,
      dataEdit: null
    };
  }

  handleChangeGrid = () => this.setState({ isChange: !this.state.isChange });

  handleEdit = data =>
    this.setState({ isEdit: !this.state.isEdit, dataEdit: data });

  render() {
    const { openModal, showModal } = this.props;
    const { isChange, isEdit, dataEdit } = this.state;
    return (
      <div>
        <Modal className="modal-lg" isOpen={openModal} toggle={showModal}>
          <div className="modal-header">
            {" "}
            Ciclo Facturación
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={showModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <FormBillingCycle
              handleChangeGrid={this.handleChangeGrid}
              isEdit={isEdit}
              dataEdit={dataEdit}
              handleEdit={this.handleEdit}
            />
            <DataGridBillingCycle
              handleChangeGrid={this.handleChangeGrid}
              isChange={isChange}
              handleEdit={this.handleEdit}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
