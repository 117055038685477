import React, { Component } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";

import EnterpriseAccordion from "./EnterpriseAccordion";
import EnterpriseAccordionUser from "./EnterpriseAccordionUser";
import EnterpriseAccordionUserStandar from './EnterpriseAccordionUserStandar';
import SubscriptionAccordion from "../Subscription/SubscriptionAccordion";

export default class EnterpriseInfo extends Component {
  ///Recibir y enviar props del valor filtrado en EnterpriseFilter a los componentes de acordión
  render() { 
    const { filter, isEdit, handleChangeDetail, isChangeDetail } = this.props;
    return ( 
      <Card className="card-default">
        <CardHeader>
          <h4>Datos de la empresa</h4>
        </CardHeader> 
        <CardBody> 
          <EnterpriseAccordion  
          filter={filter}
          isEdit={isEdit} 
          handleChangeDetail={handleChangeDetail}
          isChangeDetail={isChangeDetail}
          />
          <SubscriptionAccordion filter={filter} isEdit={isEdit}  />
          <EnterpriseAccordionUser filter={filter} isEdit={isEdit} /> 
          <EnterpriseAccordionUserStandar filter={filter} isEdit={isEdit} />
        </CardBody>
      </Card>
    );
  }
}
