import React, { Component } from "react";

import { _JsonStorage } from '../../Global';

import ProfileProvider from "./docs/Context";

import SidebarProfile from "../Profile/SidebarProfile";

class ProfileComponent extends Component {
  render() {
    return (
      <div>
        <SidebarProfile />
      </div>
    );
  }
}


const ComponentWithContext = props => {
  return (
    <ProfileProvider>
      <ProfileComponent {...props} />
    </ProfileProvider>
  )
}

export default ComponentWithContext;