import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Card, CardHeader, CardBody } from "reactstrap";

import "./docs/Carousel.css";

class CarouselImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureDefault: "img/img-default-view.jpg",
      header: "",
      description: ""
    };
  } 

  componentDidUpdate() {
    ///Validar si la imagen cambio
    if (
      (this.props.picture !== this.state.pictureDefault) &
      (this.props.picture.length > 0)
    ) {
      this.handleUploadImg(this.props.picture);
    }

    ///Validar si el encabezado cambio.
    if (this.props.header !== this.state.header) {
      this.handleUploadHeader(this.props.header);
    }

    ///Validar si la descripción cambio
    if (this.props.description !== this.state.description) {
      this.handleUploadDescription(this.props.description);
    }
    
  }

  ///Cargar datos de la imagen en el componente para validar cuando el usuario cambie la imagen
  handleUploadImg = e => this.setState({ pictureDefault: e });

  ///Cargar datos del encabezado para validar cuando el usuario cambie el texto
  handleUploadHeader = e => this.setState({ header: e });

  ///Cargar datos de la descripción para validar cuando el usuario cambie el texto
  handleUploadDescription = e => this.setState({ description: e });

  render() {
    const { pictureDefault, header, description } = this.state;
    return (
      <Card className="card-default">
        <CardHeader>
          <strong>Vista previa de imagen</strong>
        </CardHeader>
        <CardBody>
          <div className="col-md-12">
            <figure>
              <img
                src={pictureDefault}
                alt="ImgPreview"
                width={"100%"}
                height={272}
              />
              <figcaption className="textImg-header">{header}</figcaption>
              <figcaption className="textImg-body">{description}</figcaption>
            </figure>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withNamespaces("translations")(CarouselImage);
