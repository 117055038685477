import React from 'react'

export const hasError = (formData, inputName, method) => {
  return (
    formData.errors &&
    formData.errors[inputName] &&
    formData.errors[inputName][method]
  );
};

export const hasErrorMessage = (formData, inputName, method, message) => {
  return hasError(formData, inputName, method) && (
    <span className="invalid-feedback">
      {message}
    </span>
  )
}