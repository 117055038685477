import React, { Fragment, useState, useContext, useEffect } from 'react'

import { AppContext } from "../docs/AppContext";
import { IdentityTypeOptions } from "../docs/data";
import { Col, Input, InputGroupAddon, Alert } from "reactstrap";
import { getClientAndResourceAsync } from "../../../services/Api/Identity";

import CardResource from "./CardResource";
import CardClient from "./CardClient";
import PageList from "../../Extras/PageList";
import Scrollable from '../../Common/Scrollable'
import FormValidator from "../../Validation/FormValidation";
import SpinnerDisableContent from "../../Progress/SpinnerDisableContent";

export default function IdentityListSidebar() {
  let timeOutValue;
  const context = useContext(AppContext);

  const { arrayClientOrResource, isClientOrResource } = context.data;

  const [formData, setFormData] = useState({
    selected: 1,
    filter: '',
    errors: {}
  })
  const [state, setState] = useState({
    collection: [],
    isSpinner: false
  })
  const [page, setPage] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
    pageSize: 10
  })

  useEffect(() => {
    clientOrResource()
  }, [isClientOrResource])

  const clientOrResource = () => {
    if ((arrayClientOrResource.length > 0 && state.collection.length === 0) && !isClientOrResource) {
      setState(state => ({
        ...state,
        collection: arrayClientOrResource
      }))
    } else if ((arrayClientOrResource.length === 0 && state.collection.length === 0 && !isClientOrResource) || isClientOrResource) {
      filterCollection();
    }
  }

  const filterCollection = async () => {
    changeSpinner(true)
    await getClientAndResourceAsync({
      name: formData.filter,
      typeFilter: formData.selected,
      pageSize: page.pageSize,
      pageNumber: page.currentPage
    })
      .then(resp => {
        if (resp.isSuccess) {
          const array = resp.objeto.result.map(x => x);

          setState(state => ({
            ...state,
            collection: array
          }))

          setPage(state => ({
            ...state,
            totalCount: resp.objeto.totalCount,
            totalPages: resp.objeto.totalPages
          }))
        }
        changeSpinner(false)
        if (isClientOrResource) context.handleChangeState('isClientOrResource', false)
      })
      .catch(err => {
        changeSpinner(false)
        if (isClientOrResource) context.handleChangeState('isClientOrResource', false)
      })
  }

  const changeSpinner = (parameter) => {
    setState(state => ({
      ...state,
      isSpinner: parameter
    }))
  }

  const changeSelected = (e) => {
    const value = parseInt(e.target.value);
    setFormData(state => ({
      ...state,
      selected: value
    }))

    context.handleChangeState('isClientOrResource', true)
  }

  const changeInput = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    setFormData(state => ({
      ...state,
      [input.name]: value,
      errors: {
        ...state.errors,
        [input.name]: result
      }
    }));

    keyPress();
  }

  const keyPress = () => {
    clearTimeout(timeOutValue)
    timeSearch()
  }

  const timeSearch = () => {
    timeOutValue = setTimeout(() => {
      context.handleChangeState('isClientOrResource', true)
    }, 2000)
  }

  const handleDismiss = () => {
    context.handleChangeState('dismissIdentityListSidebar', true)
    window.localStorage.setItem('dismissIdentityListSidebar', true)
  }

  const handleRefresh = () => context.handleChangeState('isClientOrResource', true)

  return (
    <Fragment>
      <Col lg="5" xl="4" className="animated bounceInLeft d-none d-lg-block">
        <div className="card card-default">
          <div className="card-header d-flex flex-row">
            <h4 className="card-title">Configuraciones</h4>
            <div className="card-tool d-flex justify-content-end col">
              <em onClick={() => handleRefresh()} className="fas fa-sync"></em>
              <em onClick={() => handleDismiss()} className="fa fa-times"></em>
            </div>
          </div>
          <div className="card-body">
            <form className="form-horizontal" autoComplete="off">
              <div className="input-group mb-2">
                <select defaultValue="" className="custom-select mb-3 mb-sm-0" onChange={(e) => changeSelected(e)}>
                  {IdentityTypeOptions.map((x, i) => (
                    <option value={x.value} key={i + 1}>{x.label}</option>
                  ))}
                </select>
              </div>
              <div className="input-group mb-3">
                <Input
                  type="text"
                  name="filter"
                  className="form-control"
                  placeholder="Nombre del cliente o recurso"
                  value={formData.filter}
                  onChange={(e) => changeInput(e)}
                />
                <InputGroupAddon addonType="append">
                  <button className="btn btn-secondary" type="button">
                    <em className="fa fa-search" />
                  </button>
                </InputGroupAddon>
              </div>
            </form>
            <SpinnerDisableContent isSpinnerDisabled={state.isSpinner}>
              <Scrollable className="list-group" height="517">
                {state.collection.map((x, i) => (
                  <Fragment key={i + 1}>
                    {x.isClient && (
                      <CardClient {...x} />
                    )}
                    {!x.isClient && (
                      <CardResource {...x} />
                    )}
                  </Fragment>
                ))}
                {state.collection.length === 0 && (
                  <Fragment>
                    <div className="container-md py-4 container">
                      <div className="text-center mb-3 pb-3">
                        <Alert color="warning">
                          <span className="h3">No cuenta con registros de clientes o recursos</span>
                        </Alert>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Scrollable>
            </SpinnerDisableContent>
            {state.collection.length > 0 && (
              <PageList {...{ page, setPage }} />
            )}
          </div>
        </div>
      </Col>
    </Fragment>
  )
}
