import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Card, CardBody } from "reactstrap";

import ContentWrapper from "../Layout/ContentWrapper";
import NotificationMore from "./NotificationList";
import NotificationMessage from "./NotificationMessage";
import "./docs/Notification.css";

class Notification extends Component {
  constructor(props){
    super(props);
    this.state={
      message: null
    }
  }

  handleViewMessage = obj =>this.setState({ message: obj });

  render() {
    const { message } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            Notificaciones
            <small>Bandeja de notificaciones.</small>
          </div>
        </div>
        <div className="row">
          <Card className="col-xl-7 col-lg-6 card-default">
            <CardBody>
              <NotificationMore handleViewMessage={this.handleViewMessage} />
            </CardBody>
          </Card>
          <Card className="col-xl-5 col-lg-6 border-leftcolor card-default">
            <CardBody className="height-100">
              <NotificationMessage message={message} />
            </CardBody>
          </Card>
        </div>
      </ContentWrapper>
    );
  }
}

export default withNamespaces("translations")(Notification);
