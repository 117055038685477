import React, { Component } from "react";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";
import { getapplication } from "../../services/Api/Application";
import { TimeSetTime5 } from "../../Global";

import SpinnerDisableContent from "../Progress/SpinnerDisableContent";

export default class ContainerApplicationAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
      isSpinner: false
    };
  }

  componentDidMount() {
    // this.handleIsContext();
    this.handleApps();
  }

  ///Validar si el nav context se encuentra en sesión
  handleIsContext = () => {
    let navContext = sessionStorage.getItem("NavContext");
    let _this = this;
    if (navContext === null) {
      setTimeout(function () {
        _this.handleIsContext();
      }, TimeSetTime5);
    } else {
      this.handleApps();
    }
  };

  ///Consultar aplicaciones
  handleApps = () => {
    this.changeSpinner(true)
    const response = getapplication();
    const promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          const array = [];
          result.objeto.forEach(element => {
            let obj = {
              name: element.nameAplication,
              logo: element.route,
              url: element.url
            };
            array.push(obj);
          });
          this.setState({ apps: array });
        }
        this.changeSpinner(false)
      },
      function (error) {
        this.changeSpinner(false)
      }
    );
  };

  changeSpinner = (parameter) => {
    this.setState({
      isSpinner: parameter
    })
  }

  render() {
    const { apps, isSpinner } = this.state;
    return (
      <Row>
        <div className="col-xl-12">
          <div className="card card-default" id="cardChart9">
            <div className="card-header">
              <div className="card-title">
                Aplicaciones de Siempre.Net disponibles
              </div>
            </div>
            <div className="card-body">
              <SpinnerDisableContent isSpinnerDisabled={isSpinner}>
                <Row>
                  {apps.length === 0 ? (
                    <div className="position-empty">
                      <h2>No se encuentran aplicaciones disponibles.</h2>
                    </div>
                  ) : (
                      apps.map((item, i) => <CardApp item={item} key={i + 1} />)
                    )}
                </Row>
              </SpinnerDisableContent>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}

const CardApp = ({ item }) => (
  <div className="position-cardapp">
    <div className="card card-default">
      <div className="card-body">
        <a
          className="text-muted float-right"
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <em className="fa fa-arrow-right"></em>
        </a>
        <div className="text-info">{item.name}</div>
        <div className="position-img">
          <img src={item.logo} alt="Sample" className="img-left thumb128" />
        </div>
      </div>
      <div className="card-footer">
        <p className="text-muted">
          <em className="fa fa-cart-arrow-down fa-fw"></em>
          <span className="text-dark">
            <Link to="/subscription" path="/subscription">
              {" "}
              Adquirir
            </Link>
          </span>
        </p>
      </div>
    </div>
  </div>
);
