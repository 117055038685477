import MethodGet from '../MethodGet';
import MethodPost from '../MethodPost';
import { getbilllingcycle, getmeasure, getbilling, deletebilling } from '../../components/RouteApi/RouteApi';
import { headerGet } from '../Headers';
import { _JsonStorage } from '../../Global';

export const getTypeBylling = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(getbilllingcycle, header).then(response=>response).catch(error=>error);
}

export const getMeasures = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(getmeasure, header).then(response=>response).catch(error=>error);
}

export const postBilling = (url, header) =>{
    return MethodPost(url,header).then(response=>response).catch(error=>error);
}

export const sendBillign = (url,header) =>{
    return MethodPost(url,header).then(response=>response).catch(error=>error);
}

export const getBilling = () =>{
    let header = headerGet( _JsonStorage.access_token);
    return MethodGet(getbilling, header).then(response=>response).catch(error=>error);
}

export const deleteBilling = (param) =>{
    let urlBase = deletebilling,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>url.searchParams.append(item, params[item]));

  let header = headerGet( _JsonStorage.access_token);
  return MethodGet(url, header).then(respose=>respose).catch(error=>error);
}
