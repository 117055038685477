import React from 'react';
import LoadConfig from "../Extras/LoadConfig";

const BasePage = props => (
    <LoadConfig>
        <div className="wrapper">
            {props.children}
        </div>
    </LoadConfig>
)

export default BasePage;
