import React, { Component } from "react";
import { FormGroup, Input, Button } from "reactstrap";

import FormValidator from "../Validation/FormValidation";
import MeasurementDatagrid from "./MeasurementDatagrid";

import { postMeasurement } from "../../services/Api/Measurement";
import { showValidation } from "../Validation/ShowValidation";
import { postmeasuredunits, putmeasuredunits } from "../RouteApi/RouteApi";
import { headerPost, headerPut } from "../../services/Headers";
import { _JsonStorage } from "../../Global";

export default class MeasurementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMeasurement: {
        identifier: 0,
        nameUnit: "",
        baseunit: "",
        classification: ""
      },
      isEdit: false
    };
  }

  hadleEdit = param => {
    this.setState({
      formMeasurement: {
        ...this.state.formMeasurement,
        identifier:
          param.measurement != null ? param.measurement.idMeasuredUnit : 0,
        nameUnit: param.name != null ? param.name : "",
        baseunit: param.baseunit != null ? param.baseunit : "",
        classification: param.classification != null ? param.classification : ""
      },
      isEdit: true
    });
  };

  handleResetState = () => {
    this.setState({
      formMeasurement: {
        ...this.state.formMeasurement,
        nameUnit: "",
        baseunit: "",
        classification: ""
      },
      isEdit: false
    });
  };

  handleSubmit = () => {
    let url = "";
    let header = "";
    const formData = new FormData();
    const data = this.state.formMeasurement;
    formData.append("nameunit", data.nameUnit);
    formData.append("baseunit", data.baseunit);
    formData.append("classification", data.classification);
    formData.append("code", data.identifier);

    url = this.state.isEdit === true ? putmeasuredunits : postmeasuredunits;
    header =
      this.state.isEdit === true
        ? headerPut(formData, _JsonStorage.access_token)
        : headerPost(formData, _JsonStorage.access_token);

    let response = postMeasurement(url, header);
    let promise = new Promise((rest, rej) => {
      rest(response);
      rej(Error(response));
    });
    promise.then(
      result => {
        if (result.success) {
          this.handleResetState();
          ///Pasar prop que actualiza la lista del grid y la lista de select.
          this.props.handleChangeData();
          this.setState({ isEdit: false });
          showValidation(result, 1, "unidad de medida");
        } else {
          showValidation(result);
        }
      },
      function(error) {
        console.log(error);
      }
    );
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );
    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });
    if (!hasError) {
      this.handleSubmit();
    }
  };

  handleOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    const { isChange, handleChangeData } = this.props;
    const { isEdit } = this.state;
    const { nameUnit, baseunit, classification } = this.state.formMeasurement;
    return (
      <div>
        <form
          name="formMeasurement"
          autoComplete="off"
          onSubmit={this.handleOnSubmit}
        >
          <FormGroup className="row">
            <div className="col-md-4">
              <label>Nombre</label>
              <Input
                type="text"
                name="nameUnit"
                placeholder="Nombre de la unidad de medida."
                value={nameUnit}
                onChange={this.handleOnChange}
                invalid={
                  this.hasError("formMeasurement", "nameUnit", "required") ||
                  this.hasError("formMeasurement", "nameUnit", "alphanumspace")
                }
                data-validate='["required","alphanumspace"]'
              />
              {this.hasError(
                "formMeasurement",
                "nameUnit",
                "alphanumspace"
              ) && (
                <span className="invalid-feedback">
                  El campo debe ser un nombre válido.
                </span>
              )}
            </div>
            <div className="col-md-4">
              <label>Unidad base</label>
              <Input
                type="text"
                name="baseunit"
                placeholder="Unidad base de la unida de medida."
                value={baseunit}
                onChange={this.handleOnChange}
                invalid={
                  this.hasError("formMeasurement", "baseunit", "required") ||
                  this.hasError("formMeasurement", "baseunit", "alphanum")
                }
                data-validate='["required","alphanum"]'
              />
              {this.hasError("formMeasurement", "baseunit", "alphanum") && (
                <span className="invalid-feedback">
                  El campo debe tener un valor válido.
                </span>
              )}
            </div>
            <div className="col-md-4">
              <label>Magnitud</label>
              <Input
                type="text"
                name="classification"
                placeholder="Magnitud."
                value={classification}
                onChange={this.handleOnChange}
                invalid={
                  this.hasError(
                    "formMeasurement",
                    "classification",
                    "required"
                  ) ||
                  this.hasError("formMeasurement", "classification", "alphanum")
                }
                data-validate='["required","alphanum"]'
              />
              {this.hasError(
                "formMeasurement",
                "classification",
                "alphanum"
              ) && (
                <span className="invalid-feedback">
                  El campo debe tener un valor válido.
                </span>
              )}
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="col-md-6">
              {isEdit === true ? (
                <Button className="bg-success btn-block">Actualizar</Button>
              ) : (
                <Button className="bg-primary btn-block">Crear</Button>
              )}
            </div>
            <div className="col-md-6">
              <Button
                type="button"
                className="bg-secondary btn-block"
                onClick={this.handleResetState}
              >
                Restaurar Formulario
              </Button>
            </div>
          </FormGroup>
          <div>
            <MeasurementDatagrid
              handleChangeData={handleChangeData}
              isChange={isChange}
              hadleEdit={this.hadleEdit}
            />
          </div>
        </form>
      </div>
    );
  }
}
