import MethodGet from "../MethodGet";
import MethodPost from "../MethodPost";
import MethodDelete from "../MethodDelete";
import {
  getidappbilling,
  postappbilling,
  deleteappbilling,
  getbillingbyapp
} from "../../components/RouteApi/RouteApi";
import { headerPost, headerDelete, headerGet } from "../Headers";
import { _JsonStorage } from '../../Global';

export const postAppBilling = data => {
  let header = headerPost(data, _JsonStorage.access_token);
  return MethodPost(postappbilling, header)
    .then()
    .catch();
};

export const getAppBillig = param => {
  let urlBase = getidappbilling,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};

export const deleteAppBilling = param => {
  let urlBase = deleteappbilling,
    params = {
      id: param
    };
  ///Composición en la instancia URL.
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerDelete( _JsonStorage.access_token);

  return MethodDelete(url, header)
    .then(response => response)
    .catch(error => error);
};

export const getBillingByApp = param => {
  let urlBase = getbillingbyapp,
    params = {
      id: param
    };
  let url = new URL(urlBase);
  Object.keys(params).forEach(item =>
    url.searchParams.append(item, params[item])
  );

  let header = headerGet( _JsonStorage.access_token);

  return MethodGet(url, header)
    .then(response => response)
    .catch(error => error);
};
