import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Progress,
  Button
} from "reactstrap";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import FormValidator from "../Validation/FormValidation";
import notify from "devextreme/ui/notify";

import { ButtonSave, ButtonUpdate } from "../Element/Buttons";
import { COLOUR_WARNING } from "../Message/Message";
import { getapplicationtypes } from "../../services/Api/ApplicationType";
import {
  getapplicationid,
  sendapplication
} from "../../services/Api/Application";
import { postApplication, putApplication } from "../RouteApi/RouteApi";
import { showValidation } from "../Validation/ShowValidation";

import SpinnerAnimate from '../Progress/SpinnerAnimate';

export default class ModalApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataApplicationType: [],
      formApplication: {
        identifier: null,
        nameApplication: "",
        descriptionApplication: "",
        urlApplication: "",
        idAplicationType: null,
        internalCode: ""
      },
      pictures: [],
      progress: 0,
      isEdit: false,
      isSpinner: false
    };
  }

  componentDidUpdate() {
    this.handleLoadApplicationType();
    this.handleLoadApplication();
    this.handleOnDrop();
  }

  ///Mostrar o ocultar spiner de carga.
  handleSpinner = () =>this.setState({ isSpinner: !this.state.isSpinner });

  ///Función para enviar datos a api.
  handleSubmit(event) {
    event.preventDefault();
    this.handleSpinner();
    let url = null;

    ///Datos de formulario a enviar.
    const dataFetch = new FormData();
    ///Elementos del formulario.
    dataFetch.append("NameAplication",this.state.formApplication.nameApplication);
    dataFetch.append("Description",this.state.formApplication.descriptionApplication);
    let index = this.state.pictures.length - 1;
    dataFetch.append("File", this.state.pictures[index]);
    dataFetch.append("Url", this.state.formApplication.urlApplication);
    dataFetch.append("internalCode", this.state.formApplication.internalCode);

    if (this.state.formApplication.idAplicationType.value !== undefined) {
      dataFetch.append("IdAplicationType",this.state.formApplication.idAplicationType.value);
    } else {
      dataFetch.append("IdAplicationType",this.state.formApplication.idAplicationType[0].value);
    }

    ///Validar si los datos son para actualizar o crear un nuevo registro.
    if (this.state.formApplication.identifier != null) {
      dataFetch.append("IdApplication", this.state.formApplication.identifier);
      url = putApplication;
    } else {
      url = postApplication;
    }

    let data = sendapplication(url, dataFetch);
    let promise = new Promise((rest, rej) => {
      rest(data);
      rej(Error(data));
    });
    promise.then(
      result => {
        if (result.success) {
          if (this.state.formApplication.identifier) {
            showValidation(result, 2, "aplicación");
          } else {
            showValidation(result, 1, "aplicación");
          }

          this.setStateForm();
          this.props.handleIsReload();
          this.setState({ progress: 0 });
        }
        this.handleSpinner();
      },
      function(error) {
        console.log(error);
      }
    );
  }

  ///Función para cargar datos de aplicación por id.
  handleLoadApplication = () => {
    if (this.props.identifier > 0) {
      this.props.handleReset();
      let identifier = this.props.identifier;
      let data = getapplicationid(identifier);
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          let data = result.response;
          this.setState({
            formApplication: {
              identifier: data.idApplication,
              nameApplication: data.nameAplication,
              descriptionApplication: data.description,
              urlApplication: data.url,
              idAplicationType: {
                label: data.nameApplicationType,
                value: data.idAplicationType
              },
              internalCode: data.internalCode
            }
          });
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  ///Cargar los tipos de aplicación.
  handleLoadApplicationType = () => {
    if (this.state.dataApplicationType.length === 0) {
      let data = getapplicationtypes();
      let promise = new Promise((rest, rej) => {
        rest(data);
        rej(Error(data));
      });
      promise.then(
        result => {
          if (result.success) {
            let array = [];
            result.response.forEach(element => {
              let obj = {
                label: element.nameAplicationType,
                value: element.idAplicationType,
                nameForm: "formApplication",
                nameSelect: "idAplicationType"
              };
              array.push(obj);
            });
            this.setState({ dataApplicationType: array });
          }
        },
        function(error) {
          console.log(error);
        }
      );
    }
  };

  ///Función para guardar una aplicación.
  onSubmit = e => {
    e.preventDefault();
    if (this.state.formApplication.idAplicationType === null) {
      notify("Verifica haber selecciona una aplicación.", COLOUR_WARNING);
    } else {
      if (this.props.isEdit === false && this.state.pictures.length === 0) {
        notify("Verifica haber seleccionado una imagen.", COLOUR_WARNING);
      } else {
        const form = e.target;
        const inputs = [...form.elements].filter(i =>
          ["INPUT", "SELECT", "TEXTAREA", "FILE"].includes(i.nodeName)
        );
        ///Validar campos requeridos.
        const { errors, hasError } = FormValidator.bulkValidate(inputs);
        ///Asignar nuevos valores al state.
        this.setState({
          [form.name]: {
            ...this.state[form.name],
            errors
          }
        });

        if (!hasError) {
          this.handleSubmit(e);
        }
      }
    }
  };

  ///Limpiar formulario.
  setStateForm = () => {
    this.setState({
      formApplication: {
        nameApplication: "",
        descriptionApplication: "",
        urlApplication: "",
        idAplicationType: null,
        logo: null
      },
      isEdit: false,
      progress: 0
    });
    this.props.showPackage();
  };

  ///Cambio de estado de los elementos select del Dom.
  handleChangeSelectMulti = event => {
    ///Cambiar de estado los valores de los select.
    this.setState({
      [event.nameForm]: {
        ...this.state[event.nameForm],
        [event.nameSelect]: { label: event.label, value: event.value }
      }
    });
  };

  ///Permite el cambio de estado del state.
  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.type === "checkbox" ? input.checked : input.value;

    ///Validar el tipo de datos en los campos.
    const result = FormValidator.validate(input);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  ///Función que se encarga de mostrar los errores en la vista.
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  ///Cargar imagen.
  handleOnDrop = picture => {
    if (picture !== undefined) {
      this.setState({
        pictures: this.state.pictures.concat(picture),
        progress: 0
      });

      for (let index = 0; index < 100; index++) {
        this.setState({ progress: index });
      }
    }
  };

  render() {
    const { openModal, showPackage, isEdit } = this.props;
    const { isSpinner } = this.state;
    return (
      <div>
        <Modal className="modal-lg" isOpen={openModal} toggle={showPackage}>
          <div className="modal-header">
            {" "}
            <strong>ACTUALIZAR APLICACIÓN</strong>
            <button
              type="button"
              className="close float-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={showPackage}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <form name="formApplication" id="formApplication" onSubmit={this.onSubmit} 
              autoComplete="off" className="parent">
              <SpinnerAnimate isDisabled={isSpinner} />
              <FormGroup>
                <label>Nombre</label>
                <Input
                  type="text"
                  name="nameApplication"
                  placeholder="Nombre aplicación..."
                  invalid={
                    this.hasError(
                      "formApplication",
                      "nameApplication",
                      "required"
                    ) ||
                    this.hasError(
                      "formApplication",
                      "nameApplication",
                      "alphaspace"
                    )
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "alphaspace"]'
                  value={this.state.formApplication.nameApplication}
                />
                {this.hasError(
                  "formApplication",
                  "nameApplication",
                  "required"
                ) && (
                  <span className="invalid-feedback">
                    El campo es requerido.
                  </span>
                )}
                {this.hasError(
                  "formApplication",
                  "nameApplication",
                  "alphaspace"
                ) && (
                  <span className="invalid-feedback">
                    El campo debe ser un nombre válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Descripción</label>
                <Input
                  type="textarea"
                  name="descriptionApplication"
                  placeholder="Descripción de la aplicación..."
                  invalid={this.hasError(
                    "formApplication",
                    "descriptionApplication",
                    "required"
                  )}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.formApplication.descriptionApplication}
                />
                {this.hasError(
                  "formApplication",
                  "descriptionApplication",
                  "required"
                ) && (
                  <span className="invalid-feedback">
                    El campo es requerido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Url</label>
                <Input
                  type="url"
                  name="urlApplication"
                  placeholder="Url de la aplicación..."
                  invalid={
                    this.hasError(
                      "formApplication",
                      "urlApplication",
                      "required"
                    ) ||
                    this.hasError("formApplication", "urlApplication", "url")
                  }
                  onChange={this.validateOnChange}
                  data-validate='["required", "url"]'
                  value={this.state.formApplication.urlApplication}
                />
                {this.hasError(
                  "formApplication",
                  "urlApplication",
                  "required"
                ) && (
                  <span className="invalid-feedback">
                    El campo es requerido.
                  </span>
                )}
                {this.hasError("formApplication", "urlApplication", "url") && (
                  <span className="invalid-feedback">
                    El campo debe ser un nombre válido.
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <label>Tipo</label>
                <Select
                  name="idAplicationType"
                  multi
                  simpleValue
                  placeholder="-ESCRIBA UNA OPCIÓN-"
                  options={this.state.dataApplicationType}
                  onChange={this.handleChangeSelectMulti}
                  value={this.state.formApplication.idAplicationType}
                />
              </FormGroup>
              <FormGroup>
                <ImageUploader
                  withIcon={true}
                  buttonText="Elegir imagen"
                  onChange={this.handleOnDrop}
                  label="Tamaño máximo de archivo: 5mb, acepta: jpg | gif | png"
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                />
                <Progress
                  bar
                  animated
                  color="success"
                  value={this.state.progress}
                >
                  Archivo cargo correctamente
                </Progress>
              </FormGroup>
              <FormGroup>
                {isEdit === true ? <ButtonUpdate /> : <ButtonSave />}{" "}
                <Button color="secondary" onClick={() => this.setStateForm()}>
                  Cancelar
                </Button>
              </FormGroup>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
